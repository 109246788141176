import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";
import axios from "axios";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSIcon from "../../../FUSComponents/FUSIcon/FUSIcon";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSUploadFile from "../../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import { toast } from "react-toastify";
function ViewTerritory() {
  const [territoryData, setTerritoryData] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [showModal, setShowModal] = useState({
    state: false,
    type: ""
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    territory_name: "",
    is_active: "",
    type: "ALL",
  });

  const getTerritoryData = () => {
    MstService.crudAgencyTerritory({
      type: "ALL",
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setTerritoryData(res?.data?.data);
        } else {
          setTerritoryData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getTerritoryData();
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const territoryModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: "INSERT",
    }));
    setShowModal((prev) => ({ ...prev, state: true, type: "ADD_EDIT" }));
  };

  const handleClose = () => {
    setIsEdit(false);
    setFormData({
      territory_name: "",
      type: "ALL",
      is_active: "",
    });
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const addNewTerritory = () => {
    setIsEdit(false);
    MstService.crudAgencyTerritory(formData)
      .then((res) => {
        setShowModal((prev) => ({ ...prev, state: false }));
        if (res?.data?.errorCode === 0) {
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsLoading((isLoading) => !isLoading);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFormData({
      territory_name: "",
      is_active: "",
      type: "ALL",
    });
  };

  const handleEditClick = (row) => {
    setIsEdit(true);
    MstService.crudAgencyTerritory({ type: "GET", id: row.id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setFormData(res?.data?.data[0]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditTerritory = () => {
    MstService.crudAgencyTerritory({ ...formData, type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsEdit(false);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            dept_name: "",
            type: "ALL",
            is_active: "",
          });
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((row) => {
    MstService.crudAgencyTerritory({ id: row.id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setTerritoryData((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  // for handling excel upload

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "PLease ensure your file constains territories !"
      );
      if (!confirm) {
        setIsLoading((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      const successToast = toast.success(
        "Please Upload a Excel file",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}uploadAgencyTerritory`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
            setIsLoading(!isLoading);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const columns = [
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-success">Active</span>

            ) : (
              <span className="text-danger">InActive</span>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "territory_name",
      text: "Territory  Name",
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({ ...prev, state: true, type: "ADD_EDIT" }));
                    handleEditClick(row);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>


          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: territoryData.length,
      },
    ], // A numeric array is also available. the territory of above example is custom the text
  };
  return (
    <>
      <div className="container ">
        <h6>View territory</h6>
        <div className="row d-flex justify-content-end">
          <div className="col-5 ">
            <button
              className="btn bg-success text-white align-item-center"
              onClick={territoryModal}
            >
              <FUSIcon iconName={"FaPen"} iconSrc={"fa"} /> Add Territory
              Category
            </button>
            <button
              className="btn bg-success text-white align-item-center ms-3"
              onClick={() => setShowModal((prev) => ({ ...prev, state: true, type: "UPLOAD" }))}
            >
              <FUSIcon iconName={"FaDownload"} iconSrc={"fa"} />Import Territory Category
            </button>
          </div>
        </div>

        <div
          className="col-10"
        >
          <BootstrapTable
            keyField="id"
            data={territoryData}
            columns={columns}
            sizePerPage={5}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
          />
        </div>

        {showModal.state ? (
          <FUSModal
            title={showModal.type === "ADD_EDIT" ? !isEdit ? "Add Territory Details" : "Edit Territory Details" : "Add Visit Details"}
            showModal={showModal.state}
            handleClose={handleClose}
            size={"md"}
            centered
          >
            {showModal.type === "ADD_EDIT" ? (
              <Form>
                <Row>
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <div>
                      <span className="text_style">
                        {isEdit ? "Edit Territory" : " New Territory"}
                      </span><br />
                      <Form.Control
                        className="p-2 text_style mt-1"
                        onChange={handleChange}
                        type="text"
                        name="territory_name"
                        value={formData.territory_name}
                        placeholder="Enter here..."
                      />
                    </div>
                    {isEdit && (
                      <div>
                        <span className="text_style">Status</span><br />
                        <Form.Select
                          className="p-2 text_style mt-1"
                          name="is_active"
                          value={formData.is_active}
                          onChange={handleChange}
                        >
                          <option value="true">Activate</option>
                          <option value="false">Deactivate </option>
                        </Form.Select>
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <div className="mt-3">
                  {formData.territory_name &&
                    formData.territory_name !== "" &&
                    formData.territory_name !== null &&
                    formData.territory_name !== undefined ? (
                    <FUSButton
                      className={"form-control"}
                      buttonType="primary"
                      onClick={isEdit ? handleEditTerritory : addNewTerritory}
                      labelText={"Save"}
                    />
                  ) : null}
                </div>
              </Form>

            ) : (
              <Form>
                <Row>
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <div>
                      <div className="row d-flex justify-content-center mt25">
                        <div className="col-md-auto">
                          <Form.Group controlId="formFileLg" className="mb-3">
                            <FUSUploadFile
                              title="Upload Territory"
                              onChange={changeHandler}
                            />
                            <div className="row-bottom-margin mt25">
                              {isFilePicked && (
                                <Form.Group as={Col}>
                                  <Form.Label>
                                    {isFilePicked ? (
                                      <div>
                                        <p className="p0">
                                          {`Filename: ${selectedFile?.name} `}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  Filetype: ${selectedFile?.type} `}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                        </p>
                                      </div>
                                    ) : (
                                      <span>Select a file to show details</span>
                                    )}
                                  </Form.Label>
                                  &nbsp; &nbsp; &nbsp; &nbsp;
                                  <FUSButton
                                    buttonType="primary"
                                    labelText={"Upload"}
                                    onClick={handleSubmission}
                                  >

                                  </FUSButton>
                                </Form.Group>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Form>
            )
            }
          </FUSModal>
        ) : null}

      </div>
    </>
  );
}

export default ViewTerritory;
