import React from "react";
import "./FUSEmptyList.css";
import noDataImage from "../../../assets/noDataImage.jpg";
export const FUSEmptyList = (props) => {
  const { title, subTitle } = props;
  return (
    <div className="flex_container">
      <div>
        <div>
          <img src={noDataImage} className="imageStyle" />
        </div>
        <div className="pt-3 d-flex flex-column gap-2 pe-3">
          <span className="emptyTextHeading midnightBlueColor">{title}</span>
          <span className="grayColor">{subTitle}</span>
        </div>
      </div>
    </div>
  );
};
