import React, { useEffect, useState } from "react";
import Select from "react-select";
import MstService from "../../../services/mst.service";
import _ from "lodash";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import "./CreateWprkflow.css";
import FUSIcon from "../../../FUSComponents/FUSIcon/FUSIcon";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
function CreateWorkflow() {
  const [departmentRoles, setDepartmentRoles] = useState([
    { dept_name: null, dept_id: null, role_data: null, selected: false },
  ]); // state to handle department and role data selection change

  const [formData, setFormData] = useState({
    workflow_name: "",
  }); // state to handle workflow name
  const [isButtonVisible, setIsButtonVisivble] = useState(false); // state to handle create workflow button visibility
  const [deptRolesData, setDeptRolesData] = useState([]); // state to handle getDepartmentAndRoles's api response
  const [roleOptions, setRoleOptions] = useState([]); // state for handling available role options

  useEffect(() => {
    // api for getting all departments and roles
    MstService.getDepartmentAndRoles()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDeptRolesData(res?.data?.data);
          setRoleOptions(
            res?.data?.data
              ?.map((item) =>
                item.role_data?.map((sub) => ({
                  value: sub.role_id,
                  label: sub.role_name,
                }))
              )
              .flat(1)
          );
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  // function to handle department selection change
  const handleDepartmentChange = (index, selectedOption) => {
    if (!_.isNull(selectedOption)) {
      const newDeptRoles = [...departmentRoles];
      newDeptRoles[index] = {
        dept_id: selectedOption.value,
        dept_name: selectedOption.label,
        role_data: null,
      };
      const roles = deptRolesData
        .filter((item) => item.dept_id === selectedOption.value)
        .map((item) =>
          item.role_data?.map((sub) => ({
            value: sub.role_id,
            label: sub.role_name,
          }))
        )
        .flat(1);
      setRoleOptions(roles);
      setDepartmentRoles(newDeptRoles);
    } else {
      const newDeptRoles = [...departmentRoles];
      newDeptRoles[index] = {
        dept_id: null,
        dept_name: null,
        role_data: null,
      };
      setDepartmentRoles(newDeptRoles);
      setRoleOptions(
        deptRolesData
          ?.map((item) =>
            item.role_data?.map((sub) => ({
              value: sub.role_id,
              label: sub.role_name,
            }))
          )
          .flat(1)
      );
    }
  };

  // funtion to handle role selection change
  const handleRoleChange = (index, selectedOption) => {
    const newDeptRoles = [...departmentRoles];
    newDeptRoles[index] = { ...newDeptRoles[index], role_data: selectedOption };
    setDepartmentRoles(newDeptRoles);
    if (index > 0 && selectedOption !== null) {
      setIsButtonVisivble(true);
    } else {
      setIsButtonVisivble(false);
    }
  };

  // function to handle plus icon click i.e to add new department and role row
  const handleAddSelection = (index) => {
    const updatedDeptRoles = [...departmentRoles];
    const selectedRow = updatedDeptRoles[index];

    if (selectedRow.dept_name === null) {
      const successToast = toast.warn("Select a department", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else if (selectedRow.role_data === null) {
      const successToast = toast.warn("Select a role", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      selectedRow.selected = true;
      const newSelection = {
        dept_id: null,
        dept_name: null,
        role_data: null,
        selected: false,
      };
      updatedDeptRoles.push(newSelection);
      setDepartmentRoles(updatedDeptRoles);
    }
  };

  // function to handle minus icon click i.e to remove  department and role row
  const handleRemoveSelection = (sectionIndex) => {
    const newDeptRoles = [...departmentRoles];
    newDeptRoles.splice(sectionIndex, 1);
    const cloneData = newDeptRoles.map((item, index) => {
      if (index === sectionIndex - 1) {
        return { ...item, selected: false };
      }
      return item;
    });
    setDepartmentRoles(cloneData);
    if (sectionIndex === 1) {
      setIsButtonVisivble(false);
    }
  };

  // function to handle create workflow api
  const handleSubmit = () => {
    const hasNulldata = departmentRoles.some(
      (entry) => entry.dept_id === null && entry.role_data === null
    );
    if (formData.workflow_name === "") {
      const successToast = toast.warn("Enter workflow name", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else if (hasNulldata) {
      const successToast = toast.warn("Please fill all fields", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      const filteredData = departmentRoles.filter(
        (entry) =>
          entry.dept_id !== null &&
          entry.role_data !== null &&
          entry.role_data.value !== null
      );

      let parentRoleValue = null;
      const hierarchy = [];

      filteredData.forEach((entry, index) => {
        if (index > 0 && entry.role_data) {
          hierarchy.push({
            parent_role_id: parentRoleValue,
            role_id: entry.role_data.value,
          });
        }
        if (entry.role_data) {
          parentRoleValue = entry.role_data.value;
        }
      });

      const obj = {
        workflow_name: formData.workflow_name,
        description: null,
        heirarchyArray: hierarchy,
      };

      MstService.addHierarchyToRoles(obj)
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            const successToast = toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
            setFormData({
              workflow_name: "",
            });
            setDepartmentRoles([{ department: null, role_data: null }]);
            setIsButtonVisivble(false);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  return (
    <>
      <LayoutContainer
        title1={"Workflow"}
        title2={"Workflow"}
        title3={"Create Workflow"}
        right={
          <>
            {isButtonVisible && (
              <div>
                <FUSButton
                  buttonType="primary"
                  labelText={" Create Workflow"}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </>
        }
      >
        <div className="px-1 py-2 overflow-y-auto">
          <div className="row">
            <div className="col-md-8">
              <span className="text-secondary">Enter Workflow Name</span>
              <div className="mt-1">
                <input
                  className="px-3 py-2 border_div_style form-control"
                  placeholder="Enter Name"
                  name="workkflow_name"
                  value={formData.workflow_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      workflow_name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {departmentRoles.map((selection, index) => (
            <div className="mt-3" key={index}>
              <div className="row mb-3 align-items-center">
                <div className="col-md-4 ">
                  <div>
                    <span className="text-secondary">Select Department</span>
                    <div className="mt-1">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selection.department}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption) =>
                          handleDepartmentChange(index, selectedOption)
                        }
                        options={deptRolesData?.map((item) => ({
                          value: item.dept_id,
                          label: item.dept_name,
                        }))}
                        menuPlacement="auto"
                        placeholder="Select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "dark",
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <span className="text-secondary">Select Role</span>
                    <div className="mt-1">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selection.role_data}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption) =>
                          handleRoleChange(index, selectedOption)
                        }
                        options={roleOptions}
                        placeholder="Select"
                        menuPlacement="auto"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "dark",
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                {index === 0 && !selection.selected && (
                  <div className="col-md-1 pt-4">
                    <span
                      className="add_color mt-4"
                      onClick={() => handleAddSelection(index)}
                    >
                      <FUSIcon
                        iconSrc={"md"}
                        iconName={"MdOutlineAddCircle"}
                        size={24}
                      />
                    </span>
                  </div>
                )}
                {index > 0 && !selection.selected && (
                  <>
                    <div className="col-md-1 pt-4">
                      <span
                        className="add_color mt-4"
                        onClick={() => handleRemoveSelection(index)}
                      >
                        <FUSIcon
                          iconSrc={"fa6"}
                          iconName={"FaCircleMinus"}
                          size={24}
                        />
                      </span>

                      <span
                        className="add_color mt-4 ms-2"
                        onClick={() => handleAddSelection(index)}
                      >
                        <FUSIcon
                          iconSrc={"md"}
                          iconName={"MdOutlineAddCircle"}
                          size={28}
                        />
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </LayoutContainer>
    </>
  );
}

export default CreateWorkflow;
