/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ride from "../../services/ride.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import _ from "lodash";

const DriverType = () => {
  const [driverTypeList, setDriverType] = useState([]);
  const [driverState, setDriverState] = useState({
    is_active: false,
    disp_text: null,
  });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const columns = [
    {
      dataField: "disp_text",
      text: "Display Text Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "is_active",
      text: "Activated",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <a
                href="#"
                className="text-success"
                onClick={() => handleUpdate(row)}
              >
                YES
              </a>
            ) : (
              <a
                href="#"
                className="error-text"
                onClick={() => handleUpdate(row)}
              >
                NO
              </a>
            )}
          </>
        );
      },
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: 20,
      },
    ],
  };

  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDIT_DRIVER_TYPE",
    }));
    setDriverState(row);
  };

  useEffect(() => {
    getAllDriverType();
  }, []);

  const getAllDriverType = async () => {
    try {
      const res = await ride.getAllDriverType();
      setDriverType([]);
      if (res?.data?.errorCode === 0) {
        setDriverType(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const insertDriverType = async () => {
    try {
      const result = await ride.insertDriverType(driverState);
      if (result?.data?.errorCode === 0) {
        getAllDriverType();
        setDriverState({ disp_text: null, is_active: false });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else if (result?.data?.errorCode === -409) {
        getAllDriverType();
        setDriverState({ disp_text: null, is_active: false });
        const successToast = toast.warning(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleUpdate = async (row) => {
    try {
      const result = await ride.updateDriverType(
        _.isNil(row)
          ? driverState
          : { ...row, is_active: row?.is_active ? false : true }
      );
      if (result?.data?.errorCode === 0) {
        getAllDriverType();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setDriverState({
          disp_text: null,
          is_active: false,
        });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  return (
    <LayoutContainer
      title1="Driver Type"
      title2="Driver Type"
      title3="Driver Type"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Driver Type"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_DRIVER_TYPE",
              }));
            }}
          />
        </>
      }
    >
      <div className="px-3 py-3">
        <div className="col-md-12">
          <BootstrapTable
            classes="border border-1"
            keyField="price"
            data={driverTypeList}
            columns={columns}
            pagination={paginationFactory(featureListOption)}
            filter={filterFactory()}
          />
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={showModal.type === "ADD_DRIVER_TYPE" ? "Add Driver Type" : ""}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setDriverState({
              disp_text: null,

              is_active: false,
            });
          }}
          centered
        >
          {showModal.type === "ADD_DRIVER_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Driver Display Text</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Display Text"
                    value={driverState.disp_text}
                    onChange={(e) =>
                      setDriverState((prev) => ({
                        ...prev,
                        disp_text: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Active</label>

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked={driverState?.is_active}
                      onChange={(e) =>
                        setDriverState((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {driverState?.is_active
                        ? "Driver Activated"
                        : "Driver is not Activated"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                {driverState.disp_text && driverState.is_active ? (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      onClick={() => {
                        insertDriverType();
                        setShowModal((prev) => ({ ...prev, state: false }));
                      }}
                    ></FUSButton>
                  </>
                ) : (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      disabled
                    ></FUSButton>
                  </>
                )}
              </div>
            </div>
          )}
        </FUSModal>
      )}
      {showEditModal.state && (
        <FUSModal
          title={
            showEditModal.type === "EDIT_DRIVER_TYPE" ? "Edit Driver Type" : ""
          }
          showModal={showEditModal.state}
          size={"md"}
          handleClose={() => {
            setEditShowModal((prev) => ({ ...prev, state: false }));
            setDriverState({
              disp_text: null,
              is_active: false,
            });
          }}
          centered
        >
          {showEditModal.type === "EDIT_DRIVER_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Driver Type Code</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Display Text"
                    value={driverState.disp_text}
                    onChange={(e) =>
                      setDriverState((prev) => ({
                        ...prev,
                        disp_text: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Active</label>

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked={driverState?.is_active}
                      onChange={(e) =>
                        setDriverState((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {driverState?.is_active
                        ? "Driver Activated"
                        : "Driver is not Activated"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleUpdate()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default DriverType;
