import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import TokenService from "../../services/token.service";
import _, { filter } from "lodash";
import VARIABLES from "../../services/variable.service";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { StoreContext } from "../../store/StoreContext";
import { textFilter } from "react-bootstrap-table2-filter";
import util from "../../services/util";
import DataTable from "../DataTable/DataTable";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import "./OpenPartyReport.css";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import { toast } from "react-toastify";
const OpenPartyReport = ({ collectionType }) => {
  const token = TokenService.getUser();
  const [reportTable, setReporttable] = useState([]);
  const [reportTableTally, setReporttableTally] = useState([]);
  const [recieved, setReceived] = useState({});
  const [recievedAdhoc, setReceivedAdhoc] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [cashDataTally, setCashDataTally] = useState([]);
  const [onlineDataTally, setOnlineDataTally] = useState([]);
  const [tallyImportedStatus, setTallyImportedStatus] = useState([]);
  const [totalTallyData, setTotalTallyData] = useState([]);
  const [cashTallyCollector, serCashTallyCollector] = useState("");
  const [onlineTallyCollector, serOnlineTallyCollector] = useState("");
  const [multipleMargData, setmultipleMargData] = useState([]);
  const [isMultipleMarg, setIsMultipleMarg] = useState(false);
  const [margRemark, setMargRemark] = useState();
  const [margSerialNumber, setMargSerialNumber] = useState(0);
  const [showModal, setShow] = useState({
    state: false,
    type: null,
  });
  const [rowData, setRowData] = useState("");
  const [margValue, setMargValue] = useState(0);
  const [isUpdateEnable, setIsUpdateEnable] = useState(false);
  const [maxMargNumber, setMaxMargNumber] = useState(0);
  const [financialList, setFinancialList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [FyStartDate, setFyStartDate] = useState([]);

  const setFilterData = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const getCurrentDay = () => {
    var d = new Date();
    return d;
  };

  const handleClose = (state) => setShow((prev) => ({ ...prev, state }));

  const [fromDate, setFromDate] = useState(
    collectionType === "HISTORY" ? getPreviousDay() : getCurrentDay()
  );
  const [fillter, setFilter] = useState({
    collector_id: "-1",
    payment_type_id: "-1",
    financial_id: "-1",
    collection_date:
      collectionType === "HISTORY" ? getPreviousDay() : getCurrentDay(),
  });
  const { isFunctionalityEnabled } = useContext(StoreContext);

  useEffect(() => {
    if (
      !isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      )
    ) {
      MstService.getCollector()
        .then((response) => {
          setCollectorList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFilterData("collector_id", "ALL");
    setFilterData("payment_type_id", "ALL");
  }, []);

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  const getData = () => {
    MstService.generateOpenCollectionReport({
      params: fillter,
    })
      .then((response) => {
        setReporttable(response?.data?.data?.reportTable);

        setReceived(response?.data?.data?.received);
        setReceivedAdhoc(response?.data?.data?.recievedAdhoc);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.getFinancialListByAgencyId()
      .then((response) => {
        setFinancialList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.getCompanyListByAgencyId()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    if (
      token?.agent?.agent_user_id === "47503f6e-9674-e3ca-0f2e-755c95d6d38d"
    ) {
      setExcelData({});
      MstService.getRLExportData({
        from_date: getISODate(fromDate),
        to_date: getISODate(fromDate),
      })
        .then((response) => {
          setExcelData(response?.data?.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }

    if (
      isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      )
    ) {
      MstService.getCollectorByAdminSubmitted({
        date: getISODate(fromDate),
        memo_type: VARIABLES.MEMO_TYPE.OPEN_PARTY,
      })
        .then((response) => {
          setCollectorList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
    MstService.getlargestMargNumber()
      .then((response) => {
        if (response.data?.errorCode == 0) {
          setMaxMargNumber(response.data.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      getData();
    }
  }, [fillter, fromDate]);

  const rowStyleForBillMissing = (row) => {
    const style = {};
    if (row?.is_bill_missing === "R") {
      style.backgroundColor = "#750089";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
    }

    return style;
  };

  const dateFormatter = (cell) => {
    if (cell === "01 Jan, 1900 12:01 AM") {
      return "";
    }
    return cell;
  };

  const renderTableData = (data) => {
    return (
      data?.length > 0 &&
      data.map((element, i) => (
        <>
          <tr key={i} style={rowStyleForBillMissing(element)}>
            <td>{element.format_date} </td>
            <td>{element.ref_no}</td>
            <td>{element.party_name}</td>
            <td>{element.collector_code}</td>
            <td>{element.name}</td>
            <td>{element.opening}</td>
            <td>{element.pending}</td>
            <td>{element.payment_type === "CASH" ? element.payment : 0}</td>
            <td>{element.payment_type === "ONLINE" ? element.payment : 0}</td>
            <td>{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
            <td>{dateFormatter(element?.format_updated_on)}</td>
            <td>
              {element.payment_type === "ONLINE" || "CHEQUE"
                ? element.payment_ref
                : 0}
            </td>
          </tr>
        </>
      ))
    );
  };

  const handleMargMultipleSeries = async (e) => {
    e.preventDefault();
    const postdata = {
      data: multipleMargData,
      marg_remarks: margRemark,
      marg_number: margSerialNumber,
      start_date: fromDate,
      financial_id: fillter.financial_id,
      tag: "OPEN",
    };
    await MstService.updateMultiPleMargNumber(postdata)
      .then((response) => {
        getData();

        const successToast = toast.success(
          `${response?.data?.message}`,
          { position: "bottom-left", autoClose: false }
        );

        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setShow((prev) => ({ ...prev, state: false }));
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const UpdateMargNumber = async (item) => {
    const postdata = {
      marg_number: item !== null ? item?.marg_serial : margSerialNumber,
      marg_remark: margRemark,
      row_id: item !== null ? item?.id : rowData?.id,
      ref_no: item !== null ? item?.ref_no : rowData?.ref_no,
      collection_date: fromDate,
      financial_id: fillter?.financial_id,
      compnay_id: fillter?.compnay_id,
      is_marg_recorded:
        item !== null ? item?.is_marg_recorded : rowData?.is_marg_recorded,
      tag: "OPEN",
    };

    if (postdata?.marg_number == null && postdata?.marg_number == undefined) {
      const successToast = toast.warn(
        "Please enter marg number",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return false;
    } else {
      await MstService.updateMargNumber(postdata)
        .then((response) => {
          if (response.data.errorCode == 0) {
            getData();
            setShow((prev) => ({ ...prev, state: true, type: "EDIT" }));
            const successToast = toast.success(
              `${response.data?.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
          const successToast = toast.success(
            `${response.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const DownloadCollectionSheet = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportTable);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `collectionRecord_${new Date().toLocaleString()}.xlsx`
    );
  };

  const modalVisible = (row) => {
    setShow((prev) => ({ ...prev, state: true, type: "EDIT" }));
    setRowData(row);
    setMargSerialNumber(row?.marg_serial);
    setMargRemark(row?.marg_remark);
  };

  const columns = [
    {
      dataField: "format_date",
      text: "Date",
      sort: true,
      filter: textFilter(),
      footer: "Total",
      editable: false,
    },
    {
      dataField: "ref_no",
      text: "Ref. No.",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "party_name",
      text: "Party",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "collector_code",
      text: "Collector Code",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },

    {
      dataField: "name",
      text: "Collector Name",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "opening",
      text: "Opening (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "pending",
      text: "Pending (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment",
      text: "Payment (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      editable: false,
      footer: "",
    },

    {
      dataField: "format_updated_on",
      text: "Payment Received On",
      sort: true,
      editable: false,
      footer: "",
      formatter: dateFormatter,
    },
    {
      dataField: "marg_remark",
      text: "marg remark",
      sort: true,
      editable: false,
      footer: "",
      hidden:
        collectionType !== "HISTORY" &&
        isFunctionalityEnabled(
          ROLES_PERMISSIONS.FEATURES.REPORTS,
          ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
        ),
    },
    {
      dataField: "marg_serial",
      text: "marg serial",
      sort: true,
      editable: false,
      footer: "",
      hidden:
        collectionType !== "HISTORY" &&
        isFunctionalityEnabled(
          ROLES_PERMISSIONS.FEATURES.REPORTS,
          ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
        ),
    },
    {
      dataField: "remove",
      text: "Action",
      hidden:
        collectionType !== "HISTORY" &&
        isFunctionalityEnabled(
          ROLES_PERMISSIONS.FEATURES.REPORTS,
          ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
        ),
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_marg_recorded == false &&
              row?.marg_serial !== null &&
              row?.marg_serial !== undefined ? (
              <Button
                style={{ marginRight: 10 }}
                onClick={() => UpdateMargNumber(row)}
              >
                Record In Marg
              </Button>
            ) : null}
            {row?.is_marg_recorded == true ? (
              <span
                style={{
                  color: "green",
                  marginRight: 10,
                }}
              >
                Marg is Recorded
              </span>
            ) : null}
            <FUSIcon
              iconSrc={"fi"}
              iconName={"FiEdit"}
              title="Edit"
              style={{ cursor: "pointer" }}
              size={17}
              color={row?.is_bill_missing === "R" ? "#fff" : "#000"}
              onClick={() => modalVisible(row)}
            />
          </>
        );
      },
      editable: false,
      footer: "",
      style: {
        width: "15%",
      },
    },
  ];

  const handleOnSelect = (row, isSelect) => {
    if (isSelect && row.is_marg_recorded) {
      const successToast = toast.error(
        "You can not select of already marg recorded !!",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return false; // return false to deny current select action
    } else {
      let dayArr = multipleMargData;
      const index = dayArr.findIndex((item) => item.id === row.id);
      if (index > -1) {
        dayArr.splice(index, 1);
      } else {
        dayArr.push(row);
      }
      setmultipleMargData(() => [...dayArr]);
      return true;
    }
  };

  const getMargSerialNumber = async () => {
    if (fillter.payment_type_id === "-1" || fillter.collector_id === "-1") {
      const successToast = toast.success(
        "Please select payment type or collector",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      await MstService.InsertMargSeries({
        filter: fillter,
        start_marg_number: margValue,
        start_date: fromDate,
        type: "OPENPARTY",
      })
        .then((response) => {
          if (response.data.errorCode == 0) {
            getData();
          } else {
            const successToast = toast.success(
              `${response.data?.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setmultipleMargData(() =>
        rows.filter((r) => r.is_marg_recorded === false).map((r) => r)
      );
      return rows.filter((r) => r.is_marg_recorded === false).map((r) => r.id);
    } else {
      setmultipleMargData(() => []);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectColumn:
      !isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      ) ||
      fillter?.collector_id === "ALL" ||
      fillter?.payment_type_id === "ALL",
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const MaincashAmount = parseFloat(
    _.isNil(recieved?.cash) ? 0 : recieved?.cash
  );
  const MainonlineAmount = parseFloat(
    _.isNil(recieved?.online) ? 0 : recieved?.online
  );
  const MainchequeAmount = parseFloat(
    _.isNil(recieved?.cheque) ? 0 : recieved?.cheque
  );
  const MainBeatTotalAmount = parseFloat(
    MaincashAmount + MainonlineAmount + MainchequeAmount
  );

  const AdhocCashAmount = parseFloat(
    _.isNil(recievedAdhoc?.cash) ? 0 : recievedAdhoc?.cash
  );
  const AdhocOnlineAmount = parseFloat(
    _.isNil(recievedAdhoc?.online) ? 0 : recievedAdhoc?.online
  );
  const AdhocChequeAmount = parseFloat(
    _.isNil(recievedAdhoc?.cheque) ? 0 : recievedAdhoc?.cheque
  );
  const AdhocBeatTotalAmount = parseFloat(
    AdhocCashAmount + AdhocOnlineAmount + AdhocChequeAmount
  );

  useEffect(() => {
    var arrCash = tallyImportedStatus.filter(
      (item) => item?.cash_check === true
    );
    var arrOnline = tallyImportedStatus.filter(
      (item) => item?.online_check === true
    );
    let strCashCollectorName = "";
    let strOnlineCollectorName = "";
    arrCash.forEach((r) => {
      strCashCollectorName += `${r?.name} , `;
    });
    arrOnline.forEach((r) => {
      strOnlineCollectorName += `${r?.name} , `;
    });
    serCashTallyCollector(strCashCollectorName);
    serOnlineTallyCollector(strOnlineCollectorName);
  }, [tallyImportedStatus]);

  useEffect(() => {
    MstService.getFinancialDataById({ financial_id: fillter.financial_id })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setFyStartDate(response?.data?.data);
          setFromDate(
            fillter?.financial_id != "1"
              ? new Date()
              : new Date(response?.data?.data[0]?.end_date)
          );
        } else {
          setFyStartDate([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [fillter.financial_id]);

  return (
    <>
      <div>
        <div className="border_bottom_color">
          <div className="row">
            <div className="col-10 px-0">
              <div className="row">
                {collectionType === "HISTORY" && (
                  <>
                    <div class="col">
                      <div>
                        <span className="text-secondary">Select FY</span>
                        <div className="mt-1">
                          <Form.Control
                            className="px-3 py-2 border_color form-control"
                            as="select"
                            onChange={(e) =>
                              setFilterData("financial_id", e.target.value)
                            }
                          >
                            <option value="ALL">Select Financial Year</option>
                            {financialList?.map((item) => {
                              return (
                                <option value={item.id}>
                                  {item.display_value}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </div>
                    </div>
                    {fillter.financial_id !== "-1" ? (
                      <div class="col">
                        <div>
                          <span className="text-secondary">Select Company</span>
                          <div className="mt-1">
                            <Form.Control
                              className="px-3 py-2 border_color form-control"
                              as="select"
                              onChange={(e) =>
                                setFilterData("compnay_id", e.target.value)
                              }
                            >
                              <option value="ALL">Select Company</option>
                              {companyList?.map((item) => {
                                return (
                                  <option value={item.company_name}>
                                    {item.company_name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col">
                      <div>
                        <span className="text-secondary">Select Date</span>
                        <div className="mt-1">
                          <DatePicker
                            className="px-3 py-2 border_color rounded rounded-1"
                            dateFormat="yyyy-MM-dd"
                            selected={fromDate}
                            placeholderText="DD-MM-YYYY"
                            maxDate={new Date(FyStartDate[0]?.end_date)}
                            onChange={(date) => {
                              setFilterData("collection_date", date);
                              setFromDate(date);
                            }}
                            minDate={new Date(FyStartDate[0]?.start_date)}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-3">
                  <div>
                    <span className="text-secondary">Collector</span>
                    <div className="mt-1">
                      <Form.Control
                        className="px-3 py-2 border_color form-control"
                        as="select"
                        onChange={(e) =>
                          setFilterData("collector_id", e.target.value)
                        }
                      >
                        <option value="ALL">All</option>
                        {collectorList?.map((item) => (
                          <option
                            key={item.collector_user_id}
                            value={item.collector_user_id}
                          >
                            {item.name}
                          </option>
                        ))}
                        ;
                      </Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span className="text-secondary">Payment Type</span>
                    <div className="mt-1">
                      <Form.Control
                        className="px-3 py-2 border_color form-control"
                        as="select"
                        onChange={(e) =>
                          setFilterData("payment_type_id", e.target.value)
                        }
                      >
                        <option value="ALL">All</option>
                        {paymentTypeList?.map((item) => {
                          return (
                            <option value={item.id}>{item.payment_type}</option>
                          );
                        })}
                        ;
                      </Form.Control>
                    </div>
                  </div>
                </div>
                {collectionType === "HISTORY" &&
                  isFunctionalityEnabled(
                    ROLES_PERMISSIONS.FEATURES.REPORTS,
                    ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
                  ) &&
                  fillter?.collector_id !== "ALL" &&
                  fillter?.payment_type_id !== "ALL" ? (
                  <div class="col">
                    <div>
                      <span className="text-secondary">
                        Last Marg no. {maxMargNumber}
                      </span>
                      <div className="mt-1">
                        <div class="input-group mb-3">
                          <input
                            type="number"
                            className="px-3 py-2 border_color form-control"
                            value={margValue}
                            onChange={(e) => setMargValue(e.target.value)}
                            placeholder="enter Last Marg no.."
                            aria-label="Recipient's username with two button addons"
                            aria-describedby="button-addon4"
                            min={0}
                            max={999999999}
                            style={{ width: "40px" }}
                          />
                        </div>
                      </div>
                      <button
                        class="btn btn-outline-success"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          if (
                            parseInt(margValue) >= 0 &&
                            margValue > maxMargNumber
                          ) {
                            getMargSerialNumber(margValue);
                          } else {

                            const successToast = toast.error(
                              "Marg value should be greater then last marg value !",
                              { position: "bottom-left", autoClose: false }
                            );
                            setTimeout(() => {
                              toast.dismiss(successToast);
                            }, 2000);
                            setMargValue(0);
                          }
                        }}
                      >
                        {isUpdateEnable ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-2  d-flex justify-content-end align-items-end">
              <br />
              <div className="position-relative">
                <FUSButton
                  iconSrc="tb"
                  iconName="TbFileXFilled"
                  iconSize={20}
                  className={"excel_btn_style"}
                  onClick={() => DownloadCollectionSheet()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-2">
          <div className="mt-3 table_border_color mx-1 mb-3 d-flex flex-column rounded rounded-2 overflow-hidden">
            <div className="row border_bottom_table">
              <div className="col-sm-3 px-0 col-12">
                <div className="table-heading dark_bg_grey py-sm-0 py-3">
                  <span></span>
                </div>
              </div>
              <div className="col-12 col-sm-9 px-0">
                <div className="table-content">
                  <div className="table-content-child table_heading_text dark_bg_grey">
                    Cash
                  </div>
                  <div className="table-content-child table_heading_text dark_bg_grey">
                    Online
                  </div>
                  <div className="table-content-child table_heading_text dark_bg_grey">
                    Cheque
                  </div>
                  <div className="table-content-child table_heading_text dark_bg_grey">
                    Total
                  </div>
                </div>
              </div>
            </div>
            <div className="row border_bottom_table">
              <div className="col-sm-3 px-0 col-12">
                <div className="table-heading low_bg_slate py-sm-0 py-3">
                  <span className="table_label_text">Total</span>
                </div>
              </div>
              <div className="col-12 col-sm-9 px-0">
                <div className="table-content">
                  <div className="table-content-child  bg-light">
                    <span className="table_label_text fw-normal">
                      ₹{MaincashAmount + AdhocCashAmount}
                    </span>
                  </div>
                  <div className="table-content-child  bg-white">
                    <span className="table_label_text fw-normal">
                      ₹{MainonlineAmount + AdhocOnlineAmount}
                    </span>
                  </div>
                  <div className="table-content-child  bg-light">
                    <span className="table_label_text fw-normal">
                      ₹{MainchequeAmount + AdhocChequeAmount}
                    </span>
                  </div>
                  <div className="table-content-child  bg-white">
                    <span className="table_label_text fw-normal">
                      ₹{MainBeatTotalAmount + AdhocBeatTotalAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-1">
            <div
              className="react-bootstrap tableScrollable"
              style={{ marginBottom: 35 }}
            >
              {multipleMargData.length > 0 ? (
                <div className="d-flex justify-content-end align-items-end">
                  <FUSButton
                    buttonType="primary"
                    labelText={"Add Serial Marg No."}
                    onClick={() =>
                      setShow((prev) => ({
                        ...prev,
                        state: true,
                        type: "multiple",
                      }))
                    }
                  />
                </div>
              ) : null}
              <DataTable
                id="id"
                data={reportTable}
                columns={columns}
                sizePerPage={5}
                selectRow={selectRow}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal.state && (
        <FUSModal
          title={
            showModal.type == "EDIT"
              ? `${"Reset record for : "} ${rowData.ref_no} | ${rowData.party_name
              }`
              : "Multiple Marg Update !"
          }
          showModal={showModal.state}
          handleClose={() => handleClose(false)}
          size={"md"}
          centered
        >
          {showModal.type == "EDIT" ? (
            <>
              <Form>
                <Row>
                  <Form.Group as={Col} md="12" className="mx-0 px-0">
                    {rowData?.is_marg_recorded == false ? (
                      <div>
                        <span className="label_style">Marg Number</span>
                        <Form.Control
                          className="py-2 my-1 text_style"
                          onChange={(e) => setMargSerialNumber(e.target.value)}
                          value={margSerialNumber}
                          type="number"
                          readOnly={rowData?.is_marg_recorded}
                          placeholder="Edit Marg Number"
                        />
                      </div>
                    ) : null}
                    <div className="mt-2">
                      <span className="label_style">Remark</span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        onChange={(e) => setMargRemark(e.target.value)}
                        value={margRemark}
                        as="textarea"
                        placeholder="Leave a comment here"
                      />
                    </div>
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end gap-3 mt-3">
                  {margRemark &&
                    margRemark !== "" &&
                    margRemark !== null &&
                    margRemark !== undefined ? (
                    <FUSButton
                      className={"form-control"}
                      buttonType="primary"
                      onClick={() => UpdateMargNumber(null)}
                      labelText={"Save Changes"}
                    />
                  ) : null}
                </div>
              </Form>
            </>
          ) : (
            <Form>
              <Row>
                <Form.Group as={Col} md="12" className="mx-0 px-0">
                  <div>
                    <span className="label_style">Marg Number</span>
                    <Form.Control
                      className="py-2 my-1 text_style"
                      onChange={(e) => setMargSerialNumber(e.target.value)}
                      value={margSerialNumber}
                      type="number"
                      placeholder="Edit Marg Number"
                    />
                  </div>
                  <div className="mt-2">
                    <span className="label_style">Remark</span>
                    <Form.Control
                      className="py-2 my-1 text_style"
                      onChange={(e) => setMargRemark(e.target.value)}
                      value={margRemark}
                      as="textarea"
                      placeholder="Leave a comment here"
                    />
                  </div>
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-end gap-3 mt-3">
                {margRemark &&
                  margRemark !== "" &&
                  margRemark !== null &&
                  margRemark !== undefined ? (
                  <FUSButton
                    className={"form-control"}
                    buttonType="primary"
                    onClick={handleMargMultipleSeries}
                    labelText={"Save Changes"}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </FUSModal>
      )}
    </>
  );
};

export default OpenPartyReport;
