import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row } from "react-bootstrap";
import RideService from "../../services/ride.service";
import _ from "lodash";
import OfferService from "../../services/offer.service";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const AddRewardPoint = () => {
  const [userMobile, setUserMobile] = useState("");
  const [userData, setUserData] = useState(null);
  const [points, SetPoints] = useState(0);
  const [data, setData] = useState([]);

  const columns = [
    {
      dataField: "full_name",
      text: "User Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "login_phone",
      text: "Mobile",
      sort: true,
      filter: textFilter(),
    },
    { dataField: "to_char", text: "Date & Time", sort: true },
    { dataField: "reward_points", text: "Points", sort: true },
  ];

  const handleSubmit = () => {
    RideService.getUserDetails({ phone_no: userMobile })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const user = res?.data?.data[0];
          setUserData(user);
          setUserMobile("");
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
          setUserMobile("");
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const insertReward = () => {
    let postdata = {
      user_id: userData?.id,
      reward_points: parseInt(points),
      reward_type: "Event",
    };
    OfferService.insertRewardPoints(postdata)
      .then((res) => {
        const errorToast = toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(errorToast);
        }, 2000);
        setUserData(null);
        setUserMobile("");
        SetPoints(0);
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const getRewardList = () => {
    OfferService.getUsrQuizeRewardsList()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setData(res?.data?.data);
        } else {
          const errorToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  useEffect(() => {
    getRewardList();
  }, []);

  return (
    <LayoutContainer title1="Wallet" title2="Reward" title3="Event">
      <div className="py-2 px-1">
        <div className="border_bottom_style">
          <Row className="d-flex align-items-end gap-2 gap-sm-0">
            <Col md={3}>
              <div>
                <span>Mobile Number</span>
                <input
                  className="form-control py-2 mt-1"
                  placeholder="Enter here........"
                  value={userMobile}
                  name="userMobile"
                  onChange={(e) => setUserMobile(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
            <Col md={3}>
              <FUSButton
                className={"py-2"}
                buttonTextStyle={"fs-6"}
                buttonType={userMobile.length === 10 ? "primary" : "secondary"}
                labelText={"Fetch User Information"}
                onClick={handleSubmit}
                disabled={userMobile.length !== 10}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-3 px-2 mx-1">
          {userData && (
            <div>
              <Row className="mb-3 align-item-center">
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Name</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.full_name}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Mobile Number</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.login_phone}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Points</label>
                    </div>
                    <div className="col-8">
                      <input
                        value={points}
                        className="form-control py-2 mt-1"
                        type="number"
                        placeholder="enter reward points"
                        maxLength={5}
                        onChange={(e) => SetPoints(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col md={2}>
                  <div className="px-0">
                    {" "}
                    <FUSButton
                      disabled={points <= 0}
                      className={"py-2"}
                      labelText={"Add Points"}
                      buttonType="primary"
                      onClick={() => insertReward()}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="px-3 py-3">
            <div className="col-md-12">
              <BootstrapTable
                classes="border border-1"
                keyField="full_name"
                data={data}
                columns={columns}
                filter={filterFactory()}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default AddRewardPoint;
