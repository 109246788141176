import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import DepartmentServices from "../../../services/departmentServices";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import DatePicker from "react-datepicker";

import * as XLSX from "xlsx";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
const ViewDispatch = () => {
  const [dispatchPlan, setDispatchPlan] = useState([]);
  const [selectedDate, setDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [itemDetails, setItemDetails] = useState({
    itemData: [],
    category: "",
    itemName: "",
    itemValue: "",
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const pdf = useRef();
  const getDispatchPlan = async () => {
    const dispatchPlanDate = formatDate(selectedDate);
    const params = {
      dispatched_date: dispatchPlanDate,
    };
    await DepartmentServices.getDailydispatchData(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDispatchPlan(res?.data?.data);
        } else {
          setDispatchPlan([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getItemDispatchDetailsByOrderId = async (Itemid, orderId) => {
    const params = {
      item_id: Itemid,
      order_id: orderId,
    };
    await DepartmentServices.getItemDispatchDetailsByOrderId(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setItemDetails((prev) => ({
            ...prev,
            itemData: res?.data?.data,
          }));
        } else {
          setItemDetails({});
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useLayoutEffect(() => {
    getDispatchPlan();
  }, [selectedDate]);

  const DownloadDispatchExcel = () => {
    let worksheet;
    const dispatchData = dispatchPlan?.map(
      ({
        formatted_date,
        description,
        sub_order_id,
        dispatched_by,
        party_id,
        quantity_dispatched,
        item_id,
        ...rest
      }) => ({
        ...rest,
        order_date: formatted_date,
        item_price: parseFloat(rest.item_price).toFixed(2),
      })
    );
    worksheet = XLSX.utils.json_to_sheet(dispatchData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `Dispatch ${new Date().toLocaleString()}.xlsx`);
  };
  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="Dispatch Plan"
      right={<></>}
    >
      <div className="border_bottom_style px-2">
        <div className="row justify-content-between ">
          <div className="col-md-6">
            <span className="text-secondary">Select Date</span>
            <br />
            <DatePicker
              className="py-2 px-2 form-control mt-1"
              dateFormat="yyyy-MM-dd"
              selected={selectedDate}
              value={selectedDate}
              onChange={(date) => {
                setDate(date);
              }}
            />
          </div>
          {dispatchPlan?.length > 0 && (
            <div className="col-md-6 d-flex justify-content-end mt-3">
              <FUSButton
                iconSrc="tb"
                iconName="TbFileXFilled"
                iconSize={20}
                className={"excel_btn_style"}
                onClick={() => DownloadDispatchExcel()}
              />
            </div>
          )}
        </div>
      </div>
      <div className="px-3 py-2">
        <div className="col-md-12">
          <div ref={pdf}>
            <table
              className="table table-bordered border border-1 fixed-table-header "
              id="table-to-xls"
            >
              <thead>
                <tr>
                  <th>Party Name </th>
                  <th>Order No.</th>
                  <th>Order Date</th>
                  <th>Category </th>
                  <th>Item </th>

                  <th>Dispatched Quantity</th>
                  <th>Remaining Quantity</th>
                  <th>Dispatched Qty. Price (₹) </th>
                  <th>Dispatched Qty. Item weight (Kg.)</th>
                  <th>Department </th>
                  <th>Dispatched By</th>
                </tr>
              </thead>
              <tbody>
                {dispatchPlan?.length > 0 ? (
                  dispatchPlan?.map((item) => (
                    <tr>
                      <td>{item?.party_name}</td>
                      <td>{item?.order_id}</td>
                      <td>{item?.formatted_date}</td>
                      <td>{item?.category_name}</td>

                      <td
                        className="text-primary text-decoration-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModal(true);
                          setItemDetails((prev) => ({
                            ...prev,
                            itemName: item?.name,
                            category: item?.category_name,
                            itemValue: item?.unit_value,
                          }));
                          getItemDispatchDetailsByOrderId(
                            item?.item_id,
                            item?.order_id
                          );
                        }}
                      >{`${item?.name} (${item?.total_quantity} ${item?.unit_name} )`}</td>

                      <td className="text-center">
                        {item?.quantity_dispatched}
                      </td>
                      <td className="text-center">
                        {item?.remaining_quantity}
                      </td>
                      <td className="text-center">
                        {parseFloat(item?.item_price).toFixed(2)}
                      </td>
                      <td className="text-center">{item?.item_weight}</td>
                      <td>{item?.department_name}</td>
                      <td>{item?.dispatcher_name}</td>
                    </tr>
                  ))
                ) : (
                  <FUSEmptyList title={"No Data Found"} />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FUSModal
          title="Dispatched Item Details"
          showModal={modal}
          size={"lg"}
          handleClose={() => setModal(false)}
          centered
        >
          <>
            <div>
              <p className="fw-bold ">
                {`${itemDetails?.category} - ${itemDetails?.itemName}(${itemDetails?.itemValue})`}
              </p>
            </div>
            <table className="table table-bordered border border-1 ">
              <thead>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Dispatched Quantity </th>
                <th>Dispatch Date</th>
                <th>Item Weight (KG) </th>
              </thead>
              <tbody>
                {itemDetails?.itemData?.length > 0 &&
                  itemDetails?.itemData?.map((item) => (
                    <tr>
                      <td>{item?.order_id}</td>
                      <td>{item?.formatted_date}</td>
                      <td>{`${item?.quantity_dispatched} ${item?.unit_name}`}</td>
                      <td>{item?.dispatched_date}</td>
                      <td>{item?.item_weight}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        </FUSModal>
      </div>
    </LayoutContainer>
  );
};

export default ViewDispatch;
