import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { now } from "lodash";
import "./GenerateCashMemo.css";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

const GodownHistoryCashMemoReceipt = (props) => {
  const [collectorData, setCollectorData] = useState([]);
  const [total, setTotal] = useState({});
  const [totalCashMemo, setTotalCashMemo] = useState({});
  const componentPDF = useRef();
  const componentPDF2 = useRef();
  const componentPDF3 = useRef();
  const [showLoader, setShowLoader] = useState(false);

  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    setShowLoader(true);
    MstService.generateGodownCashMemoForAllCollectorHistory({
      from_date: getISODate(props?.date),
      to_date: getISODate(props?.date),
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setCollectorData(res?.data?.data);
          setTotal(res?.data?.total);
          const {
            agent_name,
            beat_date,
            collectorNames,
            total_adhoc_cash,
            total_adhoc_cheque,
            total_adhoc_online,
            total_cash,
            total_cheque,
            total_online,
            total_godown_cash,
            total_godown_online,
            total_godown_cheque,
            total_open_cash,
            total_open_online,
            total_open_cheque,
            ...rest
          } = res?.data?.total;
          const totalWithoutZero = () => {
            let obj = {};
            Object.entries(rest).forEach(([keys, value]) => {
              if (value !== 0) {
                obj = { ...obj, [keys]: value };
              }
            });
            return obj;
          };
          const totalWithoutZerovalues = totalWithoutZero();
          setTotalCashMemo(totalWithoutZerovalues);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      })
      .finally(() => setShowLoader(false));
  }, [props?.date]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Denomination Details",
  });
  const generatePDF2 = useReactToPrint({
    content: () => componentPDF2.current,
    documentTitle: "Cash Summary",
  });
  const generatePDF3 = useReactToPrint({
    content: () => componentPDF3.current,
    documentTitle: "Cash Deposit Slip",
  });

  const formatCurrency = (item) => {
    if (item && item.startsWith("rs_")) {
      return item.slice(3);
    } else if (item && item.startsWith("coin_")) {
      return item.slice(5);
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="container">
        {showLoader && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        {collectorData.length > 0 ? (
          <div>
            {collectorData.length > 0 ? (
              <div className="d-flex justify-content-end pe-5">
                <div className="d-flex align-items-center gap-2 me-5">
                  <div>
                    <ReactHTMLTableToExcel
                      id="btnExport"
                      className="download-table-xls-button btn btn-success block "
                      table="table-to-cashmemo1"
                      filename={`Denomination Details${now().toLocaleString()}`}
                      sheet="tablexls"
                      buttonText={
                        <FUSIcon
                          iconSrc={"si"}
                          iconName={"SiMicrosoftexcel"}
                          size={20}
                          color="white"
                        />
                      }
                    />
                  </div>
                  <div>
                    <button className="btn btn-success" onClick={generatePDF}>
                      <FUSIcon
                        iconSrc={"bi"}
                        iconName={"BiSolidFilePdf"}
                        size={20}
                        color="white"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              ref={componentPDF}
              style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
            >
              <table id="table-to-cashmemo1">
                <div className="my-class" role="region" tabindex="0">
                  <table style={{ marginTop: 20 }}>
                    <thead>
                      <tr align="center">
                        <th colspan="8">Cash Memo Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          colspan="8"
                          className="font-weight-class"
                          align="center"
                        >
                          Total Cash : ₹
                          {parseFloat(
                            total?.total_godown_cash || "0.00"
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-class" align="left">
                          Agency Name
                        </td>
                        <td colSpan={7} className="font-weight-class">
                          {total?.agent_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-class" align="left">
                          Collector Name
                        </td>
                        <td colSpan={7} className="font-weight-class">
                          {total?.collectorNames}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-class" align="left">
                          Beat Date
                        </td>
                        <td colSpan={7} className="font-weight-class">
                          {total?.beat_date}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-weight-class"
                          align="left"
                          rowSpan={4}
                        >
                          Total
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={7}
                        >
                          Godown
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={3}
                        >
                          Cash
                        </td>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={2}
                        >
                          Online
                        </td>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={2}
                        >
                          Cheque
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={3}
                        >
                          ₹{total?.total_godown_cash || "0.00"}
                        </td>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={2}
                        >
                          ₹{total?.total_godown_online || "0.00"}
                        </td>
                        <td
                          className="font-weight-class"
                          align="center"
                          colSpan={2}
                        >
                          ₹{total?.total_godown_cheque || "0.00"}
                        </td>
                      </tr>
                      <tr align="center">
                        <th colspan="8">Denomination Details</th>
                      </tr>
                      <tr>
                        <th colSpan={3}>Note</th>
                        <th colSpan={3}>Count</th>
                        <th colSpan={2}>Amount</th>
                      </tr>

                      {Object.keys(totalCashMemo)?.map((item) => (
                        <tr>
                          <td className="fw-bold" colSpan={3}>
                            {formatCurrency(item)}/-
                          </td>
                          <td colSpan={3}>{totalCashMemo[item]}</td>
                          <td colSpan={2}>
                            ₹{`${formatCurrency(item)}` * totalCashMemo[item]}/-
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>Verified By</td>
                        <td colspan="7"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </table>
            </div>
            {collectorData.length > 0 ? (
              <div className="d-flex justify-content-end pe-5 mt-3">
                <div className="d-flex align-items-center gap-2 me-5">
                  <div>
                    <ReactHTMLTableToExcel
                      id="btnExport"
                      className="download-table-xls-button btn btn-success block "
                      table="table-to-cashmemo2"
                      filename={`Cash Summary${now().toLocaleString()}`}
                      sheet="tablexls"
                      buttonText={
                        <FUSIcon
                          iconSrc={"si"}
                          iconName={"SiMicrosoftexcel"}
                          size={20}
                          color="white"
                        />
                      }
                    />
                  </div>
                  <div>
                    <button className=" btn btn-success" onClick={generatePDF2}>
                      <FUSIcon
                        iconSrc={"bi"}
                        iconName={"BiSolidFilePdf"}
                        size={20}
                        color="white"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              ref={componentPDF2}
              style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
            >
              <table id="table-to-cashmemo2">
                <div className="my-class" role="region" tabindex="0">
                  <table style={{ marginTop: 20 }}>
                    <thead>
                      <tr align="center">
                        <th colspan="16">Cash Memo Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="font-weight-class"
                          rowSpan={2}
                          colSpan={3}
                        >
                          Collector
                        </td>

                        <td
                          className="font-weight-textAlign-class"
                          colSpan={13}
                        >
                          Godown
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-textAlign-class" colSpan={5}>
                          Cash
                        </td>
                        <td className="font-weight-textAlign-class" colSpan={4}>
                          Online
                        </td>
                        <td className="font-weight-class" colSpan={4}>
                          Cheque
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-class" colSpan={3}>
                          Total
                        </td>

                        <td colSpan={5}>
                          ₹{total?.total_godown_cash || "0.00"}
                        </td>
                        <td colSpan={4}>
                          ₹{total?.total_godown_online || "0.00"}
                        </td>
                        <td colSpan={4}>
                          ₹{total?.total_godown_cheque || "0.00"}
                        </td>
                      </tr>
                      {collectorData?.length > 0 &&
                        collectorData?.map((item) => (
                          <tr>
                            <td colSpan={3}>{item?.collectorDetails?.name}</td>

                            <td colSpan={5}>
                              ₹
                              {item?.demonizationDetails[0]?.godown_cash ||
                                "0.00"}
                            </td>
                            <td colSpan={4}>
                              ₹
                              {item?.demonizationDetails[0]?.godown_online ||
                                "0.00"}
                            </td>
                            <td colSpan={4}>
                              ₹
                              {item?.demonizationDetails[0]?.godown_cheque ||
                                "0.00"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </table>
            </div>
            {collectorData.length > 0 ? (
              <div className="d-flex justify-content-end pe-5 mt-3">
                <div className="d-flex align-items-center gap-2 me-5">
                  <div>
                    <ReactHTMLTableToExcel
                      id="btnExport"
                      className="download-table-xls-button btn btn-success block "
                      table="table-to-xls"
                      filename={`Cash Deposit Slip${now().toLocaleString()}`}
                      sheet="tablexls"
                      buttonText={
                        <FUSIcon
                          iconSrc={"si"}
                          iconName={"SiMicrosoftexcel"}
                          size={20}
                          color="white"
                        />
                      }
                    />
                  </div>
                  <div>
                    <button className=" btn btn-success" onClick={generatePDF3}>
                      <FUSIcon
                        iconSrc={"bi"}
                        iconName={"BiSolidFilePdf"}
                        size={20}
                        color="white"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              ref={componentPDF3}
              style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
            >
              <table id="table-to-xls">
                {collectorData?.length > 0 &&
                  collectorData?.map((item) => {
                    const {
                      submission_date,
                      task_id,
                      open_online,
                      open_cheque,
                      open_cash,
                      online,
                      is_submitted,
                      is_admin_submitted,
                      godown_online,
                      godown_cheque,
                      godown_cash,
                      created_on,
                      created_by,
                      collector_id,
                      cheque,
                      cash,
                      agency_user_id,
                      beat,
                      agency_id,
                      adhoc_online,
                      adhoc_cheque,
                      adhoc_cash,
                      ...rest
                    } = item?.demonizationDetails[0];
                    const filterZeroValues = () => {
                      let obj = {};
                      Object.entries(rest).forEach(([keys, value]) => {
                        if (value !== "0") {
                          obj = { ...obj, [keys]: value };
                        }
                      });
                      return obj;
                    };
                    const nonZeroValues = filterZeroValues();

                    return (
                      <div className="my-class" role="region" tabindex="0">
                        <table style={{ marginTop: 20 }}>
                          <thead>
                            <tr align="center">
                              <th colspan="2">Cash Deposit Slip</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colspan="2"
                                className="font-weight-class"
                                align="center"
                              >
                                Total Cash :{" "}
                                {(
                                  parseFloat(cash || "0.00") +
                                  parseFloat(adhoc_cash || "0.00") +
                                  parseFloat(godown_cash || "0.00") +
                                  parseFloat(open_cash || "0.00")
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-class">Agency Name</td>
                              <td className="font-weight-class">
                                {item?.agent?.agent_name}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-class">
                                Collector Name
                              </td>
                              <td className="font-weight-class">
                                {item?.collectorDetails?.name}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-class">Beat Date</td>
                              <td className="font-weight-class">
                                {submission_date}
                              </td>
                            </tr>
                            <tr>
                              {(godown_cash !== "0" ||
                                godown_cheque !== "0" ||
                                godown_online !== "0") && (
                                <td colspan="2">
                                  <table>
                                    <tr>
                                      <td className="font-weight-class">
                                        Godown Cash
                                      </td>
                                      <td className="font-weight-class">
                                        ₹{godown_cash || "0.00"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-weight-class">
                                        Godown Online
                                      </td>
                                      <td className="font-weight-class">
                                        ₹{godown_online || "0.00"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-weight-class">
                                        Godown Cheque
                                      </td>
                                      <td className="font-weight-class">
                                        ₹{godown_cheque || "0.00"}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                        <table style={{ marginBottom: 50 }}>
                          <thead>
                            <tr align="center">
                              <th colspan="3">Cash Details</th>
                            </tr>
                            <tr>
                              <th className="font-weight-class">Note</th>
                              <th className="font-weight-class">Count</th>
                              <th className="font-weight-class">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(nonZeroValues)?.map((item) => (
                              <tr>
                                <td className="fw-bold">
                                  {formatCurrency(item)}/-
                                </td>
                                <td>{nonZeroValues[item]}</td>
                                <td>
                                  ₹
                                  {`${formatCurrency(item)}` *
                                    nonZeroValues[item]}
                                  /-
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tr>
                            <td>Verified By</td>
                            <td colspan="2"></td>
                          </tr>
                        </table>
                      </div>
                    );
                  })}
              </table>
            </div>
          </div>
        ) : (
          <div className="main py-5 text-center">
            <h1 className="mainHeading">History Data Not Found</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default GodownHistoryCashMemoReceipt;
