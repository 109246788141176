import api from "./api";

const getLOB = (data) => {
  return api.post("/getLobByVendorIdForAdmin", data);
};
const addProduct = (item_details) => {
  return api.post("/addProduct", { product_details: item_details });
};
const addProductLOB = (product_lob) => {
  return api.post("/addProductLOB", { product_lob });
};

const addItemByVendor = (product_details) => {
  return api.post("/addItemByVendor", product_details);
};

const getProductCategoryByLOBID = (lob_id) => {
  return api.post("/getProductCategoryByLOBID", { lob_id });
};

const addProductCategoryByLOBID = (category_details) => {
  return api.post("/addProductCategoryByLOBID", { category_details });
};

const getAllProductsByCategoryId = (data) => {
  return api.post("/getVendorsproductByCategory", data);
};
const getAttributesByCategory = (data) => {
  return api.post("/getAttributesByCategory", data);
};

const getAllItemByVendorId = (flag) => {
  return api.post("/getAllItemByVendorId", flag);
};
const getAllDeactivatedItemByVendorId = (flag) => {
  return api.post("/getAllDeactivatedItemByVendorId", flag);
};
const updateItem = (item_details, id) => {
  return api.post("/updateItem", { item_details, id });
};
const deleteItem = (itemData) => {
  return api.post("/deleteItem", itemData);
};
const activateItem = (itemData) => {
  return api.post("/activateItem", itemData);
};
const updateBulkUpload = (itemData) => {
  return api.post("/updateBulkUpload", itemData);
};
const uploadProductList = (file) => {
  return api.post("/uploadProductList", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const uploadProductImage = (file) => {
  return api.post("/UploadImage", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const deleteImage = (postData) => {
  return api.post("/deleteImage", postData);
};
const updateBulkItemDiscount = (itemData) => {
  return api.post("/updateBulkItemDiscount", itemData);
};

const getAllProductAttributes = () => {
  return api.get("/getAllProductAttributes");
};
const financialYearCrud = (postData) => {
  return api.post("/financialYearCrud", postData);
};
const updateItemAvailability = (postData) => {
  return api.post("/updateItemAvailability", postData);
};
const getAllserviceRepDetailsByVendorId = (postData) => {
  return api.post("/getAllserviceRepDetailsByVendorId", postData);
};
const makeServiceRepresentativeByVendor = (postData) => {
  return api.post("/makeServiceRepresentativeByVendor", postData);
};
const removeServiceRepresentative = (postData) => {
  return api.post("/removeServiceRepresentative", postData);
};
const fetchDetailsByPhoneNo = (postData) => {
  return api.post("/fetchDetailsByPhoneNo", postData);
};

const checkExistingUIConfig = (postData) => {
  return api.post("/checkExistingUIConfig", postData);
};
const deleteAllItemDiscount = (postData) => {
  return api.post("/deleteAllItemDiscount", postData);
};

const ProductService = {
  getLOB,
  updateItem,
  deleteItem,
  addProduct,
  deleteImage,
  activateItem,
  addProductLOB,
  addItemByVendor,
  updateBulkUpload,
  uploadProductList,
  uploadProductImage,
  getAllItemByVendorId,
  getAttributesByCategory,
  getProductCategoryByLOBID,
  addProductCategoryByLOBID,
  getAllProductsByCategoryId,
  getAllDeactivatedItemByVendorId,
  updateBulkItemDiscount,
  getAllProductAttributes,
  financialYearCrud,
  updateItemAvailability,
  getAllserviceRepDetailsByVendorId,
  makeServiceRepresentativeByVendor,
  removeServiceRepresentative,
  fetchDetailsByPhoneNo,
  checkExistingUIConfig,
  deleteAllItemDiscount,
};

export default ProductService;
