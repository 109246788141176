/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GoogleMap, PolylineF, Marker } from "@react-google-maps/api";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";

const CollectorMap = () => {
  const [mapRef, setMapRef] = useState();
  const [defaultCenter, setDefaultCenter] = useState({});
  const [snappedCoordinates, setSnappedCoordinates] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [collectorTrack, setCollectorTrack] = useState({});

  const items = useLocation();
  const date = items?.state?.item?.date
    ? items?.state?.item?.date
    : new Date().toISOString();

  const getCollectorTrack = () => {
    MstService.getCollectorTrack({
      userid: items?.state?.item?.user_id,
      date: date,
    })
      .then((response) => {
        if (response?.data?.errorCode === "0") {
          setCollectorTrack(response?.data?.payment);
        }

        setSnappedCoordinates([]);
        let snappedCoordinates = [];
        for (var i = 0; i < response?.data?.data?.payment?.length; i++) {
          var obj = {
            lat: response?.data?.data?.payment[i]?.latitude,
            lng: response?.data?.data?.payment[i]?.longitude,
          };

          snappedCoordinates.push(obj);
        }
        setSnappedCoordinates(snappedCoordinates);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorTrack();
  }, []);

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const handleMarkerClick = (id, lat, lng, name) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, name });
    setIsOpen(true);
  };
  const onLoad = (map) => {
    setMapRef(map);
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const getCurrentGeoLocation = async () => {
    const position = await getCurrentPosition();
    setDefaultCenter({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
  };

  useEffect(() => {
    getCurrentGeoLocation();
    getCollectorTrack();
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={13}
      center={defaultCenter}
      onLoad={onLoad}
    >
      {snappedCoordinates.map(({ lat, lng }, ind) => (
        <>
          <Marker
            key={ind}
            position={{
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            }}
            draggable={false}
          ></Marker>
        </>
      ))}
      <PolylineF
        defaultPosition={defaultCenter}
        path={snappedCoordinates}
        geodesic={true}
        strokeColor={"#0000FF"}
        strokeOpacity={1.0}
        strokeWeight={2}
      />
    </GoogleMap>
  );
};

export default CollectorMap;
