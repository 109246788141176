import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import _, { set } from "lodash";
import DepartmentServices from "../../../services/departmentServices";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { FaAsterisk } from "react-icons/fa6";
import FileSaver from "file-saver";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import FUSIcon from "../../../FUSComponents/FUSIcon/FUSIcon";
import { PARTY_MASTER_SAMPLE } from "../../../services/PartyMasterSample";
import { useMediaQuery } from "react-responsive";
const PartyMaster = () => {
  const modalTypes = {
    bulk_upload: "BULK_UPLOAD",
    add_party: "ADD_PARTY",
    editModal: "EDIT",
    deleteModal: "DELETE",
  };
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [partyStatus, setPartyStatus] = useState([
    {
      value: true,
      label: "Activated",
    },
    {
      value: false,
      label: "Deactivated",
    },
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [reload, setReload] = useState(true);
  const [partyValues, setPartyValues] = useState({
    mobile_no: "",
    party_name: "",
    address: "",
    postal_code: "",
    gstNo: "",
    panNo: "",
    aadharNo: "",
    email: "",
    id: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const validateRegex = (val, regex) => {
    return regex.test(val);
  };

  const [errors, setErrors] = useState({
    mobile_no: "",
    party_name: "",
    address: "",
    postal_code: "",
    aadharNo: "",
    gstNo: "",
    email: "",
    panNo: "",
  });

  const [partyList, setPartyList] = useState({
    totalParties: [],
    partyList: [],
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleBulkUpload = () => {
    setShowModal({
      state: true,
      type: modalTypes.bulk_upload,
    });
  };

  const handleInputChange = (feild, value) => {
    setPartyValues((prev) => ({
      ...prev,
      [feild]: value,
    }));
  };

  const handleUploadProducts = async () => {
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    await DepartmentServices.uploadPartyInBulk(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload(!reload);
          closeModal();
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const getPartyList = async () => {
    await DepartmentServices.getPartyList()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setPartyList({
            partyList: res?.data?.data?.filter(
              (item) => item.is_active === true
            ),
            totalParties: res?.data?.data,
          });
        } else {
          setPartyList({});
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.responseHandler(error);
      });
  };

  useEffect(() => {
    getPartyList();
  }, [reload]);

  const emptyPartyValueState = () => {
    setPartyValues({
      mobile_no: "",
      party_name: "",
      address: "",
      postal_code: "",
      gstNo: "",
      panNo: "",
      aadharNo: "",
      email: "",
      id: "",
    });
  };

  const closeModal = () => {
    setShowModal({
      state: false,
      type: null,
    });
  };

  const handleUpdatePartyStatus = async (id) => {
    const params = {
      party_id: id,
    };
    await DepartmentServices.updatePartyStatus(params)
      .then((res) => {
        setReload(!reload);
        toast.success(res?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const addParty = async () => {
    try {
      await DepartmentServices.addParty(partyValues).then((res) => {
        if (res?.data?.errorCode === 0) {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          setReload((reload) => !reload);
          emptyPartyValueState();
          setErrors({});
        } else {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          emptyPartyValueState();
        }
      });
    } catch (error) {
      requestAndErrorHandler?.errorHandler(error);
    }
  };

  const updatePartyDetails = async () => {
    await DepartmentServices.updateParty(partyValues)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          setReload((reload) => !reload);
          emptyPartyValueState();
          setErrors({});
          setIsEdit(false);
        } else {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          emptyPartyValueState();
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const handleAddandUpdateParty = async (isUpdate = false) => {
    const validationErrors = {};
    if (_.isEmpty(partyValues.mobile_no)) {
      validationErrors.mobile_no = "Mobile No. is required";
    }
    if (_.isEmpty(partyValues.party_name)) {
      validationErrors.party_name = "Party name is required";
    }
    if (_.isEmpty(partyValues.address)) {
      validationErrors.address = "Address is required";
    }
    if (_.isEmpty(partyValues.postal_code)) {
      validationErrors.postal_code = "Postal Code is required";
    }
    if (
      !_.isEmpty(partyValues.aadharNo) &&
      !validateRegex(partyValues.aadharNo, /^\d{12}$/)
    ) {
      validationErrors.aadharNo = "Aadhar no. is not valid";
    }
    if (
      !_.isEmpty(partyValues.gstNo) &&
      !validateRegex(
        partyValues.gstNo,
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
      )
    ) {
      validationErrors.gstNo = "invalid gst no.";
    }
    if (
      !_.isEmpty(partyValues.panNo) &&
      !validateRegex(partyValues.panNo, /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
    ) {
      validationErrors.panNo = "invalid pan no.";
    }
    if (
      !_.isEmpty(partyValues.email) &&
      !validateRegex(partyValues.email, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      validationErrors.email = "invalid email address";
    }
    if (!_.isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }
    if (isUpdate) {
      updatePartyDetails();
    } else {
      addParty();
    }
  };

  function hanleEditClick(row) {
    setShowModal({
      state: true,
      type: modalTypes?.add_party,
    });

    setPartyValues((prev) => ({
      ...prev,
      party_name: row?.name,
      mobile_no: row.primary_mobile_no,
      address: row?.address,
      postal_code: row.postal_code,
      gstNo: row?.gst_no === "null" ? "" : row?.gst_no,
      panNo: row?.panNo === "null" ? "" : row?.panNo,
      email: row?.email === "null" ? "" : row?.email,
      aadharNo: row?.aadhar_no === "null" ? "" : row?.aadhar_no,
      id: row.id,
    }));
    setIsEdit(true);
  }

  function handleDeleteClick(row) {
    setShowModal({
      state: true,
      type: modalTypes?.deleteModal,
    });
    setPartyValues((prev) => ({
      ...prev,

      id: row.id,
    }));
  }

  const handleDeleteParty = async () => {
    const params = {
      id: partyValues?.id,
    };
    await DepartmentServices.deleteParty(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          setReload((reload) => !reload);
          emptyPartyValueState();
          setErrors({});
          setIsEdit(false);
        } else {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          closeModal();
          emptyPartyValueState();
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const downloadSampleExcelFile = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(PARTY_MASTER_SAMPLE);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Party_Master_Sample.xlsx"
    );
  };

  const filterParty = () => {
    return partyList?.partyList?.filter(
      (option) =>
        option?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        option?.primary_mobile_no?.includes(searchQuery)
    );
  };

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 });

  const handlePartyStatusFilter = (value) => {
    const data = partyList?.totalParties?.filter(
      (option) => option?.is_active === value
    );

    return setPartyList((prev) => ({
      ...prev,
      partyList: data,
    }));
  };

  return (
    <LayoutContainer
      title1={"Party Master"}
      title2={"Party Master"}
      title3={"Party Master"}
    >
      <div className="py-2">
        <div className="px-2 border_bottom">
          <div className="d-flex gap-3 gap-0 justify-content-between align-items-center flex-wrap">
            <div className={`px-2 mt-2 w-sm-25 ${isMobile && "w-100"} d-flex`}>
              <div className="col-7">
                <p className="mb-0">Search party</p>
                <input
                  className="form-control"
                  placeholder="Search Party"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target?.value)}
                />
              </div>

              <div className="ms-2 col-6">
                <p className="mb-0">Party Status</p>
                <select
                  className="form-select"
                  onChange={(e) => {
                    const booleanValue = e?.target?.value === "true";
                    handlePartyStatusFilter(booleanValue);
                  }}
                >
                  {partyStatus?.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-end px-2 ">
              <div>
                <FUSButton
                  className={"py-2"}
                  labelText={"Bulk Upload"}
                  buttonType="secondary"
                  onClick={handleBulkUpload}
                />
              </div>
              <div className="mx-2">
                <FUSButton
                  className={"py-2"}
                  iconSrc={"fa6"}
                  iconName={isMobile ? "" : "FaPlus"}
                  iconSize={14}
                  buttonType="primary"
                  labelText={"Add Party"}
                  onClick={() =>
                    setShowModal({
                      state: true,
                      type: modalTypes.add_party,
                    })
                  }
                />
              </div>
              <div className="ms-2">
                <FUSButton
                  className={"py-2"}
                  labelText={"Sample File"}
                  buttonType="success"
                  iconSrc={"fa"}
                  iconName={isMobile ? "" : "FaFileExcel"}
                  size={20}
                  onClick={downloadSampleExcelFile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="mt-3">
            <table className="table table-bordered border border-1 ">
              <thead>
                <tr>
                  <th className="text-center">Party Name</th>
                  <th className="text-center">Mobile No.</th>
                  <th className="text-center">Address</th>
                  <th className="text-center">Postal Code</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filterParty().length > 0 ? (
                  filterParty().map((item) => (
                    <tr key={item.id}>
                      <td className="text-center">{item.name}</td>
                      <td className="text-center">{item.primary_mobile_no}</td>
                      <td className="text-center">{item.address}</td>
                      <td className="text-center">{item.postal_code}</td>
                      <td
                        className={`${
                          item.is_active ? "text-success" : "text-danger"
                        }  fw-bold text-center`}
                      >
                        {item.is_active ? "Active" : "Deactive"}
                      </td>

                      <td>
                        <div className="d-flex justify-content-end">
                          <button
                            className="mt-1  border-0 bg-transparent"
                            onClick={() => hanleEditClick(item)}
                          >
                            <FUSIcon
                              iconSrc={"fa"}
                              iconName={"FaPen"}
                              size={17}
                              title="Edit"
                            />
                          </button>
                          <button
                            className="ms-2  border-0 bg-transparent"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <FUSIcon
                              iconSrc={"io"}
                              iconName={"IoMdTrash"}
                              size={25}
                              color="red"
                              title="Delete"
                            />
                          </button>
                          <FUSButton
                            className={"pt-1 pb-2 btn-sm"}
                            labelText={
                              item.is_active ? "Deactivate" : "Activate"
                            }
                            buttonType={item.is_active ? "danger" : "success"}
                            onClick={() => handleUpdatePartyStatus(item.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">
                      <FUSEmptyList value={"data not found"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal.state && showModal.type === modalTypes.bulk_upload && (
        <FUSModal
          title={"Bulk Upload Parties"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            closeModal();
            setSelectedFile(null);
          }}
          centered
        >
          <div className="my-3">
            <input
              type="file"
              className="form-control"
              accept=".xlsx,.xls,.excel"
              onChange={handleFileChange}
            />
          </div>
          <div className="container d-flex justify-content-end">
            <div className="mt-3 d-flex justify-content-end">
              <FUSButton
                labelText={"Cancel"}
                buttonType="secondary"
                className={"form-control py-2 me-2 "}
                onClick={() => {
                  closeModal();
                  setSelectedFile(null);
                }}
              />
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <FUSButton
                className={"py-2"}
                labelText={"Upload"}
                buttonType={_.isNil(selectedFile) ? "secondary" : "primary"}
                onClick={handleUploadProducts}
                disabled={_.isNil(selectedFile)}
              />
            </div>
          </div>
        </FUSModal>
      )}

      {showModal.state && showModal.type === modalTypes.add_party && (
        <FUSModal
          title={isEdit ? "Update Party" : "Add Party"}
          showModal={showModal.state}
          size={"lg"}
          handleClose={() => {
            closeModal();
            emptyPartyValueState();
            setErrors({});
            setIsEdit(false);
          }}
          centered
        >
          <div className="row">
            <div className="col-6">
              <span className="text-secondary">
                <FaAsterisk size={10} color="red" className="mb-1" /> Mobile No
              </span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Mobile Number ..."
                  type="number"
                  value={partyValues.mobile_no}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      handleInputChange("mobile_no", e.target.value);
                    }
                  }}
                />
                {errors.mobile_no && (
                  <div className="text-danger">{errors.mobile_no}</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <span className="text-secondary">
                <FaAsterisk size={10} color="red" className="mb-1" /> Party Name
              </span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Party Name ..."
                  type="text"
                  value={partyValues.party_name}
                  onChange={(e) =>
                    handleInputChange("party_name", e.target.value)
                  }
                />
                {errors.party_name && (
                  <div className="text-danger">{errors.party_name}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">
                <FaAsterisk size={10} color="red" className="mb-1" /> Address
              </span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Address ..."
                  type="text"
                  value={partyValues.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                />
                {errors.address && (
                  <div className="text-danger">{errors.address}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">
                <FaAsterisk size={10} color="red" className="mb-1" /> Postal
                Code
              </span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Postal Code ..."
                  type="text"
                  maxLength={6}
                  value={partyValues.postal_code}
                  onChange={(e) =>
                    handleInputChange("postal_code", e.target.value)
                  }
                />
                {errors.postal_code && (
                  <div className="text-danger">{errors.postal_code}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">GST No. (Optional)</span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter GST No ..."
                  type="text"
                  value={partyValues.gstNo}
                  onChange={(e) => handleInputChange("gstNo", e.target.value)}
                />
                {errors.gstNo && (
                  <div className="text-danger">{errors.gstNo}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">PAN No. (Optional)</span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter PAN No ..."
                  type="text"
                  value={partyValues.panNo}
                  onChange={(e) => handleInputChange("panNo", e.target.value)}
                  maxLength={10}
                />
                {errors.panNo && (
                  <div className="text-danger">{errors.panNo}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">Aadhar No. (Optional)</span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Aadhar No ..."
                  type="text"
                  value={partyValues.aadharNo}
                  onChange={(e) =>
                    handleInputChange("aadharNo", e.target.value)
                  }
                  maxLength={12}
                />
                {errors.aadharNo && (
                  <div className="text-danger">{errors.aadharNo}</div>
                )}
              </div>
            </div>
            <div className="col-6 mt-2">
              <span className="text-secondary">Email Address (Optional)</span>
              <div>
                <input
                  className="form-control text_style"
                  placeholder="Enter Email Address ..."
                  type="text"
                  value={partyValues.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-end">
            <div className="mt-3 d-flex justify-content-end">
              <FUSButton
                labelText={"Cancel"}
                buttonType="secondary"
                className={"form-control py-2 me-2 "}
                onClick={() => {
                  closeModal();
                  emptyPartyValueState();
                  setErrors({});
                  setIsEdit(false);
                }}
              />
            </div>
            <div className="mt-3 d-flex justify-content-end">
              {isEdit ? (
                <FUSButton
                  labelText={"Update Party"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleAddandUpdateParty(true)}
                />
              ) : (
                <FUSButton
                  labelText={"Add Party"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleAddandUpdateParty()}
                />
              )}
            </div>
          </div>
        </FUSModal>
      )}

      {showModal.state && showModal.type === modalTypes.deleteModal && (
        <FUSModal
          title={"Confirm Delete  Party"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            closeModal();
            emptyPartyValueState();
          }}
          centered
        >
          <div className="my-3">
            <p className="fs-5 fw-bold">
              {`Please Confirm if you want to delete this party?`}
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <FUSButton
              className={"py-2"}
              labelText={"No"}
              buttonType={"secondary"}
              onClick={() => {
                closeModal();
                emptyPartyValueState();
              }}
            />
            <FUSButton
              className={"ms-2"}
              labelText={"Yes"}
              buttonType={"primary"}
              onClick={handleDeleteParty}
            />
          </div>
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default PartyMaster;
