import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import JsonToXmlConverter from "../CollectionReport/XMLfile";
import "react-datepicker/dist/react-datepicker.css";
import TokenService from "../../services/token.service";
import ExportExcel from "../ExportExcel/ExportExcel";
import _ from "lodash";
import { Input } from "react-bootstrap-typeahead";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import VARIABLES from "../../services/variable.service";

const HistoryCollectionReportWithDateRange = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });
  const token = TokenService.getUser();
  const [reportTable, setReporttable] = useState([]);
  const [reportTableTally, setReporttableTally] = useState([]);
  const [recieved, setReceived] = useState({});
  const [recievedAdhoc, setReceivedAdhoc] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [cashDataTally, setCashDataTally] = useState([]);
  const [onlineDataTally, setOnlineDataTally] = useState([]);
  const [tallyImportedStatus, setTallyImportedStatus] = useState([]);
  const [totalTallyData, setTotalTallyData] = useState([]);
  const [disableCashCheck, setDisableCashCheck] = useState(false);
  const [active, setActive] = useState("report");
  const [disableOnlineCheck, setDisableOnlineCheck] = useState(false);
  const [excelFiltered, setExcelFiltered] = useState([]);
  const setFilterData = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const [fromDate, setFromDate] = useState(getPreviousDay());
  const [tallyDate, setTallyDate] = useState(getPreviousDay());
  const [toDate, setToDate] = useState(getPreviousDay());

  const [fillter, setFilter] = useState({
    collector_id: "-1",
    payment_type_id: "-1",
  });
  useEffect(() => {
    MstService.getCollector()
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFilterData("collector_id", "ALL");
    setFilterData("payment_type_id", "ALL");
  }, []);

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  const getTallyImportStatus = () => {
    setTallyImportedStatus([]);
    setDisableCashCheck(false);
    setDisableOnlineCheck(false);
    MstService.getTallyImportStatus({
      from_date: getISODate(fromDate),
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setTallyImportedStatus(response?.data?.data);
          setDisableCashCheck((prev) => {
            const data = response?.data?.data.filter(
              (item) => item?.cash_save && !item?.is_imported_cash
            );
            return data?.length > 0;
          });
          setDisableOnlineCheck((prev) => {
            const data = response?.data?.data.filter(
              (item) => item?.online_save && !item?.is_imported_online
            );
            return data?.length > 0;
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      const end_date = active === "report" ? toDate : fromDate;
      MstService.tallyExport({
        params: fillter,
        reportName: "submitBeatHistoryWithRange",
        from_date: getISODate(fromDate),
        to_date: getISODate(end_date),
      })
        .then((response) => {
          setReporttableTally(
            response?.data?.data?.reportTable.filter(
              (item) => item?.payment_type !== "CHEQUE"
            )
          );
          setTotalTallyData(response?.data?.data.reportTable);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  useEffect(() => {
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      const end_date = active === "report" ? toDate : fromDate;
      MstService.generateReport({
        params: fillter,
        reportName: "submitBeatHistoryWithRange",
        from_date: getISODate(fromDate),
        to_date: getISODate(end_date),
      })
        .then((response) => {
          setReporttable(response?.data?.data?.reportTable);
          setReceived(response?.data?.data?.received);
          setReceivedAdhoc(response?.data?.data?.recievedAdhoc);
          setExcelFiltered(response?.data?.data?.filteredExcelReport);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });

      getTallyImportStatus();
      if (
        token?.agent?.agent_user_id === "47503f6e-9674-e3ca-0f2e-755c95d6d38d"
      ) {
        const end_date = active === "report" ? toDate : fromDate;
        setExcelData({});
        MstService.getRLExportData({
          from_date: getISODate(fromDate),
          to_date: getISODate(end_date),
        })
          .then((response) => {
            setExcelData(response?.data?.data);
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      }
    }
  }, [fillter, fromDate, toDate]);

  const rowStyleForBillMissing = (row) => {
    const style = {};
    if (row?.is_bill_missing === "R") {
      style.backgroundColor = "#750089";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
    }

    return style;
  };

  const dateFormatter = (cell) => {
    if (cell === "01 Jan, 1900 12:01 AM") {
      return "";
    }
    return cell;
  };

  const renderTableData = (data) => {
    return (
      data?.length > 0 &&
      data.map((element, i) => (
        <>
          <tr key={i} style={rowStyleForBillMissing(element)}>
            <td>{element.invoice_format_date} </td>
            <td>{element.format_date} </td>
            <td>{element.ref_no}</td>
            <td>{element.party_name}</td>
            <td>{element.collector_code}</td>
            <td>{element.name}</td>
            <td>{element.opening}</td>
            <td>{element.pending}</td>
            <td>{element.payment_type === "CASH" ? element.payment : 0}</td>
            <td>{element.payment_type === "ONLINE" ? element.payment : 0}</td>
            <td>{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
            <td>{dateFormatter(element?.format_updated_on)}</td>
            <td>
              {element.payment_type === "ONLINE" || "CHEQUE"
                ? element.payment_ref
                : 0}
            </td>
            <td>{element.remaining}</td>
          </tr>
        </>
      ))
    );
  };

  const DownloadCollectionSheet = () => {
    let worksheet;
    if (token?.agent?.agent_user_id === VARIABLES.NADEEM_AGENCY_ID) {
      worksheet = XLSX.utils.json_to_sheet(excelFiltered);
    } else {
      worksheet = XLSX.utils.json_to_sheet(reportTable);
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `collectionRecord_${new Date().toLocaleString()}.xlsx`
    );
  };

  const MaincashAmount = parseFloat(
    _.isNil(recieved?.cash) ? 0 : recieved?.cash
  );
  const MainonlineAmount = parseFloat(
    _.isNil(recieved?.online) ? 0 : recieved?.online
  );
  const MainchequeAmount = parseFloat(
    _.isNil(recieved?.cheque) ? 0 : recieved?.cheque
  );
  const MainBeatTotalAmount = parseFloat(
    MaincashAmount + MainonlineAmount + MainchequeAmount
  );

  const AdhocCashAmount = parseFloat(
    _.isNil(recievedAdhoc?.cash) ? 0 : recievedAdhoc?.cash
  );
  const AdhocOnlineAmount = parseFloat(
    _.isNil(recievedAdhoc?.online) ? 0 : recievedAdhoc?.online
  );
  const AdhocChequeAmount = parseFloat(
    _.isNil(recievedAdhoc?.cheque) ? 0 : recievedAdhoc?.cheque
  );
  const AdhocBeatTotalAmount = parseFloat(
    AdhocCashAmount + AdhocOnlineAmount + AdhocChequeAmount
  );

  const markTallyStatusCash = () => {
    const updatedData = tallyImportedStatus.map((item) => {
      if (item.cash_save) {
        item.is_imported_cash = true;
      }
      return item;
    });
    const date = "from_date";
    let newArray = updatedData.map((obj) => ({
      ...obj,
      online_save: false,
      [date]: getISODate(fromDate),
    }));
    MstService.insertTallyImportStatus(newArray)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          getTallyImportStatus();
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const markTallyStatusOnline = (item, val) => {
    const updatedData = tallyImportedStatus.map((item) => {
      if (item.online_save) {
        item.is_imported_online = true;
      }
      return item;
    });
    const date = "from_date";
    let newArray = updatedData.map((obj) => ({
      ...obj,
      online_save: false,
      [date]: getISODate(fromDate),
    }));
    MstService.insertTallyImportStatus(newArray)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          getTallyImportStatus();
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const saveTallyData = () => {
    const date = "from_date";
    let newArray = tallyImportedStatus.map((obj) => ({
      ...obj,
      [date]: getISODate(fromDate),
    }));
    MstService.insertTallyImportStatus(newArray)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          getTallyImportStatus();
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    let newData = [...tallyImportedStatus];

    const newDataCash = newData.filter(
      (obj) => obj.cash_save && !obj.is_imported_cash
    );
    const cashData = totalTallyData?.filter((item) => {
      return (
        newDataCash?.some((obj) => obj?.collector_id === item?.collector_id) &&
        item?.payment_type === "CASH"
      );
    });

    const newDataOnline = newData.filter(
      (obj) => obj.online_save && !obj.is_imported_online
    );
    const onlineData = totalTallyData?.filter((item) => {
      return (
        newDataOnline?.some(
          (obj) => obj?.collector_id === item?.collector_id
        ) && item?.payment_type === "ONLINE"
      );
    });
    setCashDataTally(cashData);
    setOnlineDataTally(onlineData);
  }, [tallyImportedStatus, totalTallyData]);

  const setCheck = (checked, objProperty, index) => {
    let newData = [...tallyImportedStatus];
    newData[index][objProperty] = checked;
    setTallyImportedStatus(newData);

    const newDataCash = newData.filter(
      (obj) => obj.cash_save && !obj.is_imported_cash
    );
    const cashData = totalTallyData?.filter((item) => {
      return (
        newDataCash?.some((obj) => obj?.collector_id === item?.collector_id) &&
        item?.payment_type === "CASH"
      );
    });

    const newDataOnline = newData.filter(
      (obj) => obj.online_save && !obj.is_imported_online
    );
    const onlineData = totalTallyData?.filter((item) => {
      return (
        newDataOnline?.some(
          (obj) => obj?.collector_id === item?.collector_id
        ) && item?.payment_type === "ONLINE"
      );
    });
    setCashDataTally(cashData);
    setOnlineDataTally(onlineData);
  };
  //merge check

  return (
    <>
      <div className="py-1">
        <div className="border_bottom_style">
          {(token?.agent?.agent_user_id ===
            "fbd66a3c-c2b5-6cec-80ea-99ac40ec1fdf" ||
            token?.agent?.agent_user_id ===
              "ae81e637-04e7-19f0-2596-c56f90d10c92") && (
            <div>
              <div className="d-flex gap-5 px-3 mb-3">
                <Form.Check
                  type="checkbox"
                  checked={active === "report"}
                  onChange={() => setActive("report")}
                  label="Show Report"
                />
                <Form.Check
                  type="checkbox"
                  checked={active === "tally"}
                  onChange={() => {
                    setActive("tally");
                    setToDate(fromDate);
                  }}
                  label="Show Tally"
                />
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between px-3">
            <div className="">
              <div className="d-flex gap-4">
                <div>
                  {active === "report" ? (
                    <span className="text-secondary">From Date</span>
                  ) : (
                    <span className="text-secondary">Date</span>
                  )}
                  <br />
                  <DatePicker
                    className="px-3 py-2 border_datepicker rounded rounded-1 mt-1"
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate}
                    maxDate={getPreviousDay()}
                    onChange={(date) => {
                      setFromDate(date);
                      setTallyDate(date);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
                {active === "report" ? (
                  <div>
                    <span className="text-secondary">To Date</span>
                    <br />
                    <DatePicker
                      className="px-3 py-2 border_datepicker rounded rounded-1 mt-1"
                      dateFormat="yyyy-MM-dd"
                      selected={toDate}
                      maxDate={getPreviousDay()}
                      minDate={fromDate}
                      onChange={(date) => setToDate(date)}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <span className="text-secondary">Collector</span>
                  <Form.Control
                    style={{ width: "150px" }}
                    className="px-3 py-2 border_div_style form-control mt-1"
                    as="select"
                    onChange={(e) =>
                      setFilterData("collector_id", e.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    {collectorList?.map((item) => (
                      <option
                        key={item.collector_user_id}
                        value={item.collector_user_id}
                      >
                        {item.name}
                      </option>
                    ))}
                    ;
                  </Form.Control>
                </div>
                <div>
                  <span className="text-secondary">Payment Type</span>
                  <Form.Control
                    style={{ width: "150px" }}
                    className="px-3 py-2 border_div_style form-control mt-1"
                    as="select"
                    onChange={(e) =>
                      setFilterData("payment_type_id", e.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    {paymentTypeList?.map((item) => {
                      return (
                        <option value={item.id}>{item.payment_type}</option>
                      );
                    })}
                    ;
                  </Form.Control>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 justify-content-end align-items-center">
              {/* Gargi Enterprise Tally Export*/}
              {token?.agent?.agent_user_id ===
                "fbd66a3c-c2b5-6cec-80ea-99ac40ec1fdf" &&
                reportTable?.length > 0 && (
                  <>
                    {active === "tally" && (
                      <FUSButton
                        labelText={"Tally XML File"}
                        buttonType="primary"
                        onClick={() =>
                          setShowModal((prev) => ({
                            ...prev,
                            state: true,
                            type: "GargiTallyModal",
                          }))
                        }
                      />
                    )}
                  </>
                )}
              {/* Mosaram Vyapar Tally Export*/}
              {token?.agent?.agent_user_id ===
                "ae81e637-04e7-19f0-2596-c56f90d10c92" &&
                reportTable?.length > 0 && (
                  <div>
                    {active === "tally" && (
                      <FUSButton
                        labelText={"Tally XML File"}
                        buttonType="primary"
                        onClick={() =>
                          setShowModal((prev) => ({
                            ...prev,
                            state: true,
                            type: "MosaramTallyModal",
                          }))
                        }
                      />
                    )}
                  </div>
                )}
              {token?.agent?.agent_user_id ===
                "47503f6e-9674-e3ca-0f2e-755c95d6d38d" &&
                excelData?.length > 0 && (
                  <div className="">
                    <ExportExcel
                      excelData={excelData}
                      fileName={"NADEEM1"}
                      btnText={"RL Export"}
                    ></ExportExcel>
                  </div>
                )}
              <FUSButton
                iconSrc="tb"
                iconName="TbFileXFilled"
                iconSize={20}
                className={"excel_btn_style"}
                onClick={() => DownloadCollectionSheet()}
              />
            </div>
          </div>
        </div>
        <div className="px-2">
          <div className="px-1">
            <div className="table_border_color  my-3 d-flex flex-column rounded rounded-2 overflow-hidden">
              <div className="row border_bottom_table">
                <div className="col-sm-3 px-0 col-12">
                  <div className="table-heading dark_bg_grey py-sm-0 py-3">
                    <span></span>
                  </div>
                </div>
                <div className="col-12 col-sm-9 px-0">
                  <div className="table-content">
                    <div className="table-content-child table_heading_text dark_bg_grey">
                      Cash
                    </div>
                    <div className="table-content-child table_heading_text dark_bg_grey">
                      Online
                    </div>
                    <div className="table-content-child table_heading_text dark_bg_grey">
                      Cheque
                    </div>
                    <div className="table-content-child table_heading_text dark_bg_grey">
                      Total
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border_bottom_table">
                <div className="col-sm-3 px-0 col-12">
                  <div className="table-heading low_bg_slate py-sm-0 py-3">
                    <span className="table_label_text">Main Beat</span>
                  </div>
                </div>
                <div className="col-12 col-sm-9 px-0">
                  <div className="table-content">
                    <div className="table-content-child  bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{MaincashAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-white">
                      <span className="table_label_text fw-normal">
                        ₹{MainonlineAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{MainchequeAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-white">
                      <span className="table_label_text fw-normal">
                        ₹{MainBeatTotalAmount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border_bottom_table">
                <div className="col-sm-3 px-0 col-12">
                  <div className="table-heading low_bg_slate  py-sm-0 py-3">
                    <span className="table_label_text">Adhoc</span>
                  </div>
                </div>
                <div className="col-12 col-sm-9 px-0">
                  <div className="table-content">
                    <div className="table-content-child  bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{AdhocCashAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-white">
                      <span className="table_label_text fw-normal">
                        ₹{AdhocOnlineAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{AdhocChequeAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-white">
                      <span className="table_label_text fw-normal">
                        ₹{AdhocBeatTotalAmount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 px-0 col-12">
                  <div className="table-heading low_dark_bg py-sm-0 py-3 bg-success">
                    <span className="table_label_text">Total</span>
                  </div>
                </div>
                <div className="col-12 col-sm-9 px-0">
                  <div className="table-content">
                    <div className="table-content-child  bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{MaincashAmount + AdhocCashAmount}
                      </span>
                    </div>
                    <div className="table-content-child  bg-white">
                      <span className="table_label_text fw-normal">
                        ₹{MainonlineAmount + AdhocOnlineAmount}
                      </span>
                    </div>
                    <div className="table-content-child   bg-light">
                      <span className="table_label_text fw-normal">
                        ₹{MainchequeAmount + AdhocChequeAmount}
                      </span>
                    </div>
                    <div className="table-content-child text-white fw-bold  bg-white ">
                      <span className="table_label_text fw-normal">
                        ₹{MainBeatTotalAmount + AdhocBeatTotalAmount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-1">
            <div className="react-bootstrap tableScrollable  mb-5">
              <table
                id="table-to-xls"
                border="1"
                className="table table-bordered"
              >
                <thead>
                  <tr>
                    <th rowSpan={2}>Invoice Date</th>
                    <th rowSpan={2}>Date</th>
                    <th rowSpan={2}>Ref_no</th>
                    <th rowSpan={2}>Party Name</th>
                    <th rowSpan={2}>Collector Code</th>
                    <th rowSpan={2}>Name</th>
                    <th rowSpan={2}>Opening</th>
                    <th rowSpan={2}>Pending</th>
                    <th colSpan={3} align="center">
                      Received
                    </th>
                    <th rowSpan={2}>Payment Received On</th>
                    <th rowSpan={2}>Reference No.</th>
                    <th rowSpan={2}>Remaining amt. after payment</th>
                  </tr>
                  <tr>
                    <th style={{ width: 100 }}>
                      Cash
                      <span style={{ padding: 5, display: "block" }}>
                        ₹{recieved?.cash}
                      </span>
                    </th>
                    <th style={{ width: 100 }}>
                      Online
                      <span style={{ padding: 5, display: "block" }}>
                        ₹{recieved?.online}
                      </span>
                    </th>
                    <th style={{ width: 100 }}>
                      Cheque
                      <span style={{ padding: 5, display: "block" }}>
                        ₹{recieved?.cheque}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportTable?.length > 0 ? (
                    renderTableData(reportTable)
                  ) : (
                    <tr>
                      <td colSpan={12} align="center">
                        Please Choose Option to show data
                      </td>
                    </tr>
                  )}
                  {fillter.payment_type_id === "ALL" ? (
                    <tr>
                      <th
                        className="dark_bg_grey table_heading_text text-center"
                        colSpan="8"
                      >
                        Total
                      </th>
                      <td className="dark_bg_grey table_heading_text">
                        ₹{MaincashAmount + AdhocCashAmount}
                      </td>
                      <td className="dark_bg_grey table_heading_text">
                        ₹{MainonlineAmount + AdhocOnlineAmount}
                      </td>
                      <td className="dark_bg_grey table_heading_text">
                        ₹{MainchequeAmount + AdhocChequeAmount}
                      </td>
                      <td colSpan="3" className="dark_bg_grey"></td>
                    </tr>
                  ) : (
                    <tr>
                      <th
                        className="dark_bg_grey table_heading_text text-center"
                        colSpan="8"
                      >
                        Total
                      </th>
                      <td className="dark_bg_grey table_heading_text">
                        {fillter.payment_type_id === "PYID0001" && (
                          <span>₹{MaincashAmount + AdhocCashAmount}</span>
                        )}
                      </td>
                      <td className="dark_bg_grey table_heading_text">
                        {fillter.payment_type_id === "PYID0002" && (
                          <span>₹{MainonlineAmount + AdhocOnlineAmount}</span>
                        )}
                      </td>
                      <td className="dark_bg_grey table_heading_text">
                        {fillter.payment_type_id === "PYID0003" && (
                          <span>₹{MainchequeAmount + AdhocChequeAmount}</span>
                        )}
                      </td>
                      <td colSpan="2" className="dark_bg_grey"></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={
            <>
              {showModal.type === "GargiTallyModal" && (
                <>
                  <div className="d-flex gap-3 my-3">
                    {disableCashCheck && (
                      <JsonToXmlConverter
                        data={cashDataTally}
                        agency_id={token?.agent?.agent_user_id}
                        payment_type={"CASH"}
                        date={tallyDate}
                        flag={true}
                      />
                    )}
                    {disableOnlineCheck ? (
                      <JsonToXmlConverter
                        data={onlineDataTally}
                        agency_id={token?.agent?.agent_user_id}
                        payment_type={"ONLINE"}
                        date={tallyDate}
                        flag={true}
                      />
                    ) : null}
                  </div>
                </>
              )}

              {showModal.type === "MosaramTallyModal" && (
                <div className="my-3">
                  {disableCashCheck ? (
                    <JsonToXmlConverter
                      data={cashDataTally}
                      agency_id={token?.agent?.agent_user_id}
                      payment_type={"CASH"}
                      date={tallyDate}
                      flag={true}
                    />
                  ) : null}
                </div>
              )}
            </>
          }
          showModal={showModal.state}
          handleClose={() =>
            setShowModal((prev) => ({
              ...prev,
              state: false,
            }))
          }
          centered
        >
          {showModal.type === "GargiTallyModal" && (
            <>
              <div>
                <div>
                  <div>
                    <div>
                      <div className="col-md-4 col-sm-10">
                        <label style={{ display: "block" }}>Date</label>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={tallyDate}
                          onChange={(date) => {
                            setTallyDate(date);
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </div>
                      <table className="table table-bordered mt-2 mb-2">
                        <thead>
                          <tr>
                            <th>Mode</th>
                            <th>Selected Collector</th>
                            <th>Uploaded Collector</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Collector for Tally Cash Download</td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) =>
                                    item?.cash_save && !item.is_imported_cash
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) => item?.is_imported_cash === true
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {disableCashCheck ? (
                                <button
                                  className="btn btn-danger py-0 mt-3"
                                  onClick={() => markTallyStatusCash(true)}
                                >
                                  Mark Tally Cash Uploaded
                                </button>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>Collector for Tally Online Download</td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) =>
                                    item?.online_save &&
                                    !item.is_imported_online
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) => item?.is_imported_online === true
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {disableOnlineCheck ? (
                                <button
                                  className="btn btn-danger py-0 mt-3"
                                  onClick={() => markTallyStatusOnline(true)}
                                >
                                  Mark Tally Online Uploaded
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse bd-highlight  mb-3">
                      <FUSButton
                        labelText={"Save Tally Uploaded"}
                        buttonType="primary"
                        onClick={() => saveTallyData()}
                      />
                    </div>
                    <div>
                      <table className="table table-bordered">
                        <thead className="text-center">
                          <tr>
                            <th>Collector</th>
                            <th>Tally Cash</th>
                            {/* <th>Is Tally Cash Uploaded</th> */}
                            <th>Tally Online</th>
                            {/* <th>Is Tally Online Uploaded</th> */}
                          </tr>
                        </thead>
                        {tallyImportedStatus?.map((item, index) => {
                          return (
                            <tbody className="text-center">
                              <tr className="py-0">
                                <td>{item.name}</td>
                                <td>
                                  {item?.is_imported_cash === false ? (
                                    <Input
                                      type="checkbox"
                                      disabled={disableCashCheck}
                                      checked={item?.cash_save}
                                      onChange={(e) => {
                                        setCheck(
                                          e.target.checked,
                                          "cash_save",
                                          index
                                        );
                                      }}
                                    />
                                  ) : (
                                    "Yes"
                                  )}
                                </td>
                                {/* <td>
                                          {item.is_imported_cash === false ? (
                                            <button
                                              className="btn btn-danger py-0"
                                              onClick={() =>
                                                markTallyStatus(item, true)
                                              }
                                            >
                                              Mark Tally Cash Uploaded
                                            </button>
                                          ) : null}
                                        </td> */}
                                <td>
                                  {item?.is_imported_online === false ? (
                                    <Input
                                      type="checkbox"
                                      disabled={disableOnlineCheck}
                                      checked={item?.online_save}
                                      onChange={(e) => {
                                        setCheck(
                                          e.target.checked,
                                          "online_save",
                                          index
                                        );
                                      }}
                                    />
                                  ) : (
                                    "Yes"
                                  )}
                                </td>
                                {/* <td>
                                          {item.is_imported_online === false ? (
                                            <button
                                              className="btn btn-danger py-0"
                                              onClick={() =>
                                                markTallyStatus(item, false)
                                              }
                                            >
                                              Mark Tally Online Uploaded
                                            </button>
                                          ) : null}
                                        </td> */}
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showModal.type === "MosaramTallyModal" && (
            <>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <span className="text-secondary">Date</span>
                        <br />
                        <DatePicker
                          className="px-3 py-2 border_input_color rounded rounded-1 mb-3"
                          dateFormat="yyyy-MM-dd"
                          selected={tallyDate}
                          onChange={(date) => {
                            setTallyDate(date);
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </div>
                      <table className="table table-bordered mt-2 mb-2">
                        <thead>
                          <tr>
                            <th>Mode</th>
                            <th>Selected Collector</th>
                            <th>Uploaded Collector</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Collector for Tally Cash Download</td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) =>
                                    item?.cash_save && !item.is_imported_cash
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {tallyImportedStatus
                                .filter(
                                  (item) => item?.is_imported_cash === true
                                )
                                .map((itemVal) => (
                                  <span>{itemVal?.name}, </span>
                                ))}
                            </td>
                            <td>
                              {disableCashCheck ? (
                                <button
                                  className="btn btn-danger py-0 mt-3"
                                  onClick={() => markTallyStatusCash(true)}
                                >
                                  Mark Tally Cash Uploaded
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse bd-highlight mb-3">
                      <FUSButton
                        labelText={"Save Tally Uploaded"}
                        buttonType="primary"
                        onClick={() => saveTallyData()}
                      />
                    </div>
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Collector</th>
                            <th>Tally Cash</th>
                          </tr>
                        </thead>
                        {tallyImportedStatus?.map((item, index) => {
                          return (
                            <tbody>
                              <tr className="py-0">
                                <td>{item.name}</td>
                                <td>
                                  {item?.is_imported_cash === false ? (
                                    <Input
                                      type="checkbox"
                                      disabled={disableCashCheck}
                                      checked={item?.cash_save}
                                      onChange={(e) => {
                                        setCheck(
                                          e.target.checked,
                                          "cash_save",
                                          index
                                        );
                                      }}
                                    />
                                  ) : (
                                    "Yes"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </FUSModal>
      )}
    </>
  );
};

export default HistoryCollectionReportWithDateRange;
