import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import _ from "lodash";

const LiveCollectionDashboard = () => {
  // collection types in which amount is pending
  const collection_types = {
    collection: "Collectors",
    cashier: "Cashier",
    godown: "Godown Collector",
    bankDeposit: "Bank Depositor",
  };

  // cash memo types
  const memo_types = {
    BEAT: "BEAT",
    GODOWN: "GODOWN",
    CONSOLIDATED: "CONSOLIDATED",
    BANK_DEPOSIT: "BANK_DEPOSIT",
    OPEN_PARTY: "OPEN_PARTY",
  };

  // user roles according to differing collection types
  const roles = {
    collector: "USR0003",
    godown: "USR0008",
    cashier: "USR0007",
  };
  const [selected, setSelected] = useState(collection_types.collection); //state to handle currently active type
  const [data, setData] = useState([]); // state to map data for various types
  const [responseData, setResponseData] = useState(); // state to hold api response
  const [amounts, setAmounts] = useState({
    total_collector: 0,
    total_godown: 0,
    total_cashier: 0,
    total_bank_deposited: 0,
  }); // state to hold pending amount values

  // function to get pending collection details
  const getPendingCollectionDetails = () => {
    MstService.pendingCollectionDetails({
      memo_types: memo_types,
      roles: roles,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setResponseData(res?.data?.data);
          setData(res?.data?.data?.collectionData);
        }
      })
      .catch((error) => {
        requestAndErrorHandler(error);
      });
  };

  // function to get pending collection amount details for each collection type
  const getPendingCollectionAmountDetails = () => {
    MstService.pendingCollectionAmountDetails({
      memo_types: memo_types,
      roles: roles,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setAmounts({
            total_collector: res?.data?.data?.collectionData?.total_collection,
            total_godown: res?.data?.data?.godownData?.total_godown,
            total_cashier: res?.data?.data?.cashierData?.total_cashier_amount,
            total_bank_deposited:
              res?.data?.data?.bankDeposit?.total_bank_deposit,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler(error);
      });
  };
  useEffect(() => {
    getPendingCollectionDetails();
    getPendingCollectionAmountDetails();
  }, []);

  // function to handle selection to collection types
  const handleSelection = (role) => {
    setSelected(role);
    switch (role) {
      case collection_types?.collection:
        setData(responseData?.collectionData);
        break;
      case collection_types?.godown:
        setData(responseData?.godownData);
        break;
      case collection_types?.cashier:
        setData(responseData?.cashierData);
        break;
      case collection_types?.bankDeposit:
        setData(responseData?.bankDeposit);
        break;
      default:
        setData([]);
        break;
    }
  };

  // function to check if amount value is null or is NaN
  function checkNaN(value) {
    if (isNaN(value) || _.isNull(value)) {
      return parseFloat(0).toFixed(2);
    } else {
      return parseFloat(value).toFixed(2);
    }
  }
  return (
    <>
      <h6>Cash Flow</h6>
      <div className="container mt-4 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3 ">
            <div
              className={`card shadow border-0  ${
                selected === collection_types?.collection &&
                "bg-success text-white"
              }`}
              onClick={() => handleSelection(collection_types?.collection)}
            >
              <div class="card-body">
                <p class="card-title fs-6">{collection_types?.collection}</p>
                <h4
                  class={`card-text   ${
                    selected === collection_types?.collection
                      ? " text-white"
                      : "text-primary"
                  }`}
                >
                  ₹ {checkNaN(amounts?.total_collector)}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <div
              className={`card shadow border-0  ${
                selected === collection_types?.godown && "bg-success text-white"
              }`}
              onClick={() => handleSelection(collection_types?.godown)}
            >
              <div class="card-body">
                <p class="card-title fs-6">{collection_types?.godown}</p>
                <h4
                  class={`card-text   ${
                    selected === collection_types?.godown
                      ? " text-white"
                      : "text-primary"
                  }`}
                >
                  ₹ {checkNaN(amounts?.total_godown)}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <div
              className={`card shadow border-0  ${
                selected === collection_types?.cashier &&
                "bg-success text-white"
              }`}
              onClick={() => handleSelection(collection_types?.cashier)}
            >
              <div class="card-body">
                <p class="card-title fs-6">{collection_types?.cashier}</p>
                <h4
                  class={`card-text   ${
                    selected === collection_types?.cashier
                      ? " text-white"
                      : "text-primary"
                  }`}
                >
                  ₹ {checkNaN(amounts?.total_cashier)}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <div
              className={`card shadow border-0  ${
                selected === collection_types?.bankDeposit &&
                "bg-success text-white"
              }`}
              onClick={() => handleSelection(collection_types?.bankDeposit)}
            >
              <div class="card-body">
                <p class="card-title fs-6">{collection_types?.bankDeposit}</p>
                <h4
                  class={`card-text   ${
                    selected === collection_types?.bankDeposit
                      ? " text-white"
                      : "text-primary"
                  }`}
                >
                  ₹ {checkNaN(amounts?.total_bank_deposited)}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4 ">
          <table className="table  table-bordered shadow ">
            <thead>
              <th>Name</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Type</th>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>₹ {item?.filled_amount}</td>
                    <td>{item?.submission_date}</td>
                    <td>{item?.memo_type}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>Data not exists</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LiveCollectionDashboard;
