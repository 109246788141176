import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";

const AddSubscription = () => {

    const [DataList, setDataList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [subsSelected, setSubsSelected] = useState([]);
    const [planBusiness, setPlanBusiness] = useState([]);
    const [selectedFeatFuncList, setSelectedFeatFuncList] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState([]);

    const [selectedPlanBuss, setSelectedPlanBuss] = useState();

    const [featureDiv, setFeatureDiv] = useState(true);

    const getTypeAndSourceByFeatureID = (PostDataList = {}) => {
        MstService.getTypeAndSourceByFeatureID({ featurelist: selectedFeature.join(",") })
            .then((response) => {
                setSelectedFeatFuncList(response.data.data);
                setFeatureDiv(false);

                setDataList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }


    const selectPlanBusiness = (event) => {
        setSelectedPlanBuss(event.target.value);
    };

    const getPlanBusiness = () => {
        setPlanBusiness([]);
        MstService.getPlanBusiness()
            .then((response) => {
                setPlanBusiness(response.data.data);
                setSelectedPlanBuss(response?.data?.data[0]?.plan_buss_id)
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getFeatureList = () => {
        setPlanBusiness([]);
        MstService.getFeatureList()
            .then((response) => {
                setFeatureList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const featureCol = [
        {
            dataField: 'disp_name',
            text: 'Feature Name'
        },
        {
            dataField: 'code',
            text: 'Feature Code'
        }];

    const columns = [
        {
            dataField: 'feature_name',
            text: 'Feature Name'
        },
        {
            dataField: 'function_name',
            text: 'Feature Name'
        },
        {
            dataField: 'source_name',
            text: 'Source Name'
        }];

    const handleSubsOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSubsSelected(selected => [...selected, row]);
        }
    }

    const handleOnSelect = (row, isSelect) => {
        const selectedID = parseInt(row.id);
        if (isSelect) {

            setSelectedFeature([...selectedFeature, selectedID]);

        } else {
            let filltered = selectedFeature.filter(id => id !== selectedID);
            setSelectedFeature(filltered);
        }
    }


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const featureListOption = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "8",
                value: 8,
            },
            {
                text: "15",
                value: 15,
            },
            {
                text: "All",
                value: featureList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "11",
                value: 11,
            },
            {
                text: "15",
                value: 15,
            },
            {
                text: "All",
                value: DataList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const onFeatureSelected = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        selected: selectedFeature
    };

    const selectSubsRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleSubsOnSelect
    };

    useEffect(() => {
        getFeatureList();
        getPlanBusiness();
    }, [])

    const NextClick = () => {
        getTypeAndSourceByFeatureID()
    }

    const insertData = () => {
        MstService.insertSubscription({ planbusiness_id: selectedPlanBuss, data: selectedFeatFuncList })
            .then((response) => {
                setFeatureDiv(false);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const PreviousClick = () => {
        setFeatureDiv(true);
    }

    return (
        <>
            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Add Subscriptions"
                right={
                    <>
                        {featureDiv ? (
                            <FUSButton
                                className={"py-2"}
                                buttonType="primary"
                                labelText={"Next"}
                                onClick={() => NextClick()}
                            />

                        ) : (
                            <div className="d-flex">
                                <FUSButton
                                    className={"py-2"}
                                    buttonType="secondary"
                                    labelText={"Previous"}
                                    onClick={() => PreviousClick()}
                                />


                                <FUSButton className={"py-2"} buttonType="primary" labelText={"Save"} onClick={() => insertData()} />



                            </div>
                        )}
                    </>
                }
            >

                <div >

                    {featureDiv ?
                        <div className="px-3"><div >
                            <BootstrapTable
                                classes="border border-1"
                                keyField='id'
                                data={featureList}
                                columns={featureCol}
                                selectRow={onFeatureSelected}
                                pagination={paginationFactory(options)}

                            />



                        </div></div>
                        : <>
                            <div className="border_bottom_style px-3">
                                <div className="col-md-4">
                                    <form role="search" onSubmit={null}>
                                        <div>
                                            <span className="text-secondary">Select</span>
                                            <select onChange={selectPlanBusiness} className="px-3 py-2 border_div_style form-control mt-1" >
                                                {
                                                    planBusiness.length > 0 && planBusiness && planBusiness.map((item, index) => {
                                                        return <option key={index} value={item.plan_buss_id}>{item.disp_text}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="px-3">
                                <BootstrapTable
                                    classes="border border-1"
                                    keyField='func_id'
                                    data={DataList}
                                    columns={columns}
                                    selectRow={selectSubsRow}
                                    pagination={paginationFactory(featureListOption)}

                                />

                            </div>




                        </>
                    }
                </div>
            </LayoutContainer >
        </>
    )
}
export default AddSubscription