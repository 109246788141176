import React, { useState, useEffect, useContext } from "react";
import HistoryCollectionReportWithDateRange from "./HistoryCollectionReportWithDateRange";
import OpenPartyReport from "../OpenPartyReport/OpenPartyReport";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import GodownCollectionReport from "./GodownCollectionReport";
import HistoryCollectionReportWithMarg from "./HistoryCollectionReportWithMarg";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { useMediaQuery } from 'react-responsive';
const HistoryReportView = () => {
  const { isFeatureEnabled, isFunctionalityEnabled } = useContext(StoreContext);
  const [collectionType, setCollectionType] = useState("BEAT");
  const agent = TokenService.getUser();
  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 778 });


  useEffect(() => {
    renderComponent();
  }, [collectionType]);

  const renderComponent = () => {
    if (collectionType === "BEAT") {
      if (agent?.agent?.agent_user_id === VARIABLES.SAGAR_AGENCY_ID) {
        return <HistoryCollectionReportWithMarg />;
      } else {
        return <HistoryCollectionReportWithDateRange />;
      }
    } else if (collectionType === "OPEN") {
      return <OpenPartyReport collectionType={"HISTORY"} />;
    } else if (collectionType === "GODOWN") {
      return <GodownCollectionReport collectionType={"HISTORY"} />;
    } else {
      return <HistoryCollectionReportWithDateRange />;
    }
  };

  return (
    <>
      <LayoutContainer
        title1={"Reports"}
        title2={"Reports"}
        title3={`${collectionType === "BEAT"
          ? "Collection History"
          : collectionType === "OPEN"
            ? "Open Party Collection"
            : "Godown Collection"
          }`}
        right={
          <div className="d-flex flex-row-reverse flex-wrap gap-3 ">
            <FUSButton
              labelText={
                ` ${isMobile ? "Beat" : "Beat Collection"}`
              }
              buttonType={collectionType === "BEAT" ? "primary" : "secondary"}
              onClick={() => setCollectionType("BEAT")}
            />
            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.REPORTS,
              ROLES_PERMISSIONS.PERMISSIONS.REPORTS.OPEN_PARTY_COLLECTION_REPORT
            ) && (
                <FUSButton
                  labelText={
                    ` ${isMobile ? "Open" : "Open Collection"}`
                  }
                  buttonType={collectionType === "OPEN" ? "primary" : "secondary"}
                  onClick={() => setCollectionType("OPEN")}
                />
              )}
            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.REPORTS,
              ROLES_PERMISSIONS.PERMISSIONS.REPORTS.GODOWN_COLLECTION_REPORT
            ) && (
                <FUSButton
                  labelText={
                    ` ${isMobile ? "Godown" : "Godown Collection"}`
                  }
                  buttonType={
                    collectionType === "GODOWN" ? "primary" : "secondary"
                  }
                  onClick={() => setCollectionType("GODOWN")}
                />
              )}
          </div>
        }
      >
        <div className="p-1">{renderComponent()}</div>
      </LayoutContainer>
    </>
  );
};
export default HistoryReportView;
