/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";


function FeatureListNew(props) {
    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [mapping, setMapping] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputText, setInputText] = useState("");
    const [featureCode, setFeatureCode] = useState("");


    const handleDelete = (row) => {

    }

    const handleEdit = (row) => {

    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "6",
                value: 6,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: mapping.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: 'disp_name',
            text: 'Feature Name',
            sort: true,
            editable: false
        },
        {
            dataField: 'code',
            text: 'Feature Code',
            sort: true,
            editable: false
        },
        {
            dataField: "action",
            text: "Action",
            formatter: (cellContent, row) => {
                return (

                    <div className="d-flex gap-3">
                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdOutlineModeEdit"
                            iconSize={16}
                            buttonType="lightgray"
                            onClick={() => handleEdit(row)}
                        />

                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                );
            },
            editable: false,
        }
    ];

    const getData = () => {
        MstService.getFeatureList()
            .then((response) => {
                setMapping(response?.data?.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const InsertData = () => {
        MstService.insertFeature({ feature_name: inputText, code: featureCode })
            .then((response) => {
                getData();
                setShowModal((prev) => ({ ...prev, state: false }))
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }






    return (
        <>

            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Create Feature List"
                right={
                    <>

                        <FUSButton

                            labelText={"Create Feature List"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }
            >
                <div className="px-1 pt-1">



                    <div className="px-2 mx-1">
                        <BootstrapTable
                            classes="border border-1"
                            remote={{ cellEdit: true }}
                            keyField="collector_code_id"
                            data={mapping}
                            columns={columns}
                            pagination={paginationFactory(options)}
                        />

                    </div>
                </div>

                {showModal.state && (
                    <FUSModal
                        title="Create Feature List"
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                        centered

                    >
                        <div>
                            <form role="search" onSubmit={null}>
                                <div className="row">
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter feature name</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={inputText}
                                                onChange={(e) => setInputText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter feature code</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={featureCode}
                                                onChange={(e) => setFeatureCode(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0 mt-2">
                                        <FUSButton labelText={"Add"} className={"form-control"} buttonType={inputText === "" ? "secondary" : "primary"} disabled={inputText === ""} onClick={() => InsertData()} type="submit" />

                                    </div>
                                </div>
                            </form>
                        </div>

                    </FUSModal>

                )}


            </LayoutContainer>
        </>
    );
}

export default FeatureListNew;
