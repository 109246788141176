import api from "./api";

const sifyItemMaster = () => api.post("sify/sifyItemMaster");
const deleteSifyItemMasterbyId = (id) =>
  api.post("sify/deleteSifyItemMasterbyId", id);
const updateSifyItemMasterDetailsById = (formData) =>
  api.post("sify/updateSifyItemMasterDetailsById",formData);
const addSifyItemMaster = (addFormData) =>
  api.post("sify/addSifyItemMaster",addFormData);

const SifyServices = {
  sifyItemMaster,
  deleteSifyItemMasterbyId,
  updateSifyItemMasterDetailsById,
  addSifyItemMaster,
};

export default SifyServices;
