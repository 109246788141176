import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";

const SourceType = () => {
    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [DataList, setDataList] = useState([]);
    const [sourcename, setSourceName] = useState("");
    const [sourceCode, setSourceCode] = useState("");

    const getSourceData = () => {
        setDataList([]);
        MstService.getSourceType()
            .then((response) => {
                setDataList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getSourceData();
    }, []);

    const InsertSourceName = () => {
        MstService.insertSourceName({ source_name: sourcename, code: sourceCode })
            .then((response) => {

                getSourceData();
                setShowModal((prev) => ({ ...prev, state: false }))
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const handleDelete = (row) => {

    }

    const handleEdit = (row) => {

    }

    const columns = [
        {
            dataField: 'disp_name',
            text: 'Source Name'
        },
        {
            dataField: 'code',
            text: 'Source Code'
        },
        {
            dataField: "action",
            text: "Action",
            formatter: (cellContent, row) => {
                return (

                    <div className="d-flex gap-3">
                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdOutlineModeEdit"
                            iconSize={16}
                            buttonType="lightgray"
                            onClick={() => handleEdit(row)}
                        />

                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                );
            },
            editable: false,
        }
    ];


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "6",
                value: 6,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: DataList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };


    return (

        <>

            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Create Source Type"
                right={
                    <>

                        <FUSButton
                            labelText={"Create Source Type"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }
            >
                <div className="px-1 pt-1">



                    <div className="px-2">
                        <div className="px-1">
                            <BootstrapTable
                                classes="border border-1"
                                keyField="id"
                                data={DataList}
                                columns={columns}
                                //filter={filterFactory()}
                                pagination={paginationFactory(options)}
                            />
                        </div>
                    </div>
                </div>

                {showModal.state && (
                    <FUSModal
                        title="Create Source Type"
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                        centered

                    >
                        <div>
                            <form role="search" onSubmit={null}>
                                <div className="row">
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter source type</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={sourcename}
                                                onChange={(e) => setSourceName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter source code</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={sourceCode}
                                                onChange={(e) => setSourceCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 px-0 mt-2 ">

                                        <FUSButton className={"form-control"} buttonType={sourcename === "" ? "secondary" : "primary"} labelText={"Add"} disabled={sourcename === ""} onClick={() => InsertSourceName()} type="submit" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </FUSModal>

                )}

            </LayoutContainer>
        </>
    )
}
export default SourceType;