import { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { Table, Button, Card } from 'react-bootstrap';
import { toast } from "react-toastify";
const UpdateMobileReq = (props) => {

    const [data, setData] = useState([]);

    props.getRequestCount(data?.length > 0 ? data.length : 0);

    const { getRetailerRequest } = AuthService;

    const getData = () => {
        getRetailerRequest().then((res) => {
            setData(res?.data);
        })
    }

    useEffect(() => {
        getData();

    }, []);

    const Update = (type, item) => {
        MstService.updateRetailerMobileReq(
            {
                type: type,
                id: item?.id
            }
        )
            .then((response) => {
                getData();

                const successToast = toast.success(
                    `${response?.data?.message}`,
                    { position: "bottom-left", autoClose: false }
                );
                setTimeout(() => {
                    toast.dismiss(successToast);
                }, 2000);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }


    return (
        <Table striped bordered hover variant="light" style={{ marginTop: 70 }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Retailer Name</th>
                    <th>New Mobile Number</th>
                    <th>Remark</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 ? data.map((item, index) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{item?.party_name}</td>
                            <td>{item?.mobile}</td>
                            <td>{item?.remark}</td>
                            <td>
                                <Button variant="success" onClick={() => Update(true, item)}>Approv</Button>
                                <Button variant="danger" onClick={() => Update(false, item)}>Reject</Button>
                            </td>
                        </tr>
                    )
                })
                    : <tr>
                        <td colSpan={5} style={{ textAlign: 'center' }}>No data found !!</td>
                    </tr>}


            </tbody>
        </Table>
    )

}
export default UpdateMobileReq;