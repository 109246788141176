import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";

const BusinessType = () => {

    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [DataList, setDataList] = useState([]);
    const [inputText, setInputText] = useState("");
    const [businessCode, setBusinessCode] = useState("");

    const InsertData = () => {
        MstService.insertBusinessType({ bus_name: inputText, code: businessCode })
            .then((response) => {
                getData();
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getData = () => {
        setDataList([]);

        MstService.getBusinessType()
            .then((response) => {
                setDataList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const handleDelete = (row) => {

    }

    const handleEdit = (row) => {

    }

    const columns = [{
        dataField: 'disp_name',
        text: 'Lob Name'
    }, {
        dataField: 'code',
        text: 'Lob Code'
    },
    {
        dataField: "action",
        text: "Action",
        classes: 'p-1',
        formatter: (cellContent, row) => {
            return (


                <div className="d-flex gap-3">
                    <FUSButton
                        className={"action_button_style"}
                        iconSrc="md"
                        iconName="MdOutlineModeEdit"
                        iconSize={16}
                        buttonType="lightgray"
                        onClick={() => handleEdit(row)}
                    />

                    <FUSButton
                        className={"action_button_style"}
                        iconSrc="md"
                        iconName="MdDeleteForever"
                        iconSize={15}
                        buttonType="lightdanger"
                        onClick={() => handleDelete(row)}
                    />
                </div>
            );
        },
        editable: false,
    }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "6",
                value: 6,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: DataList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (

        <>

            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Create Line of Business"
                right={
                    <>

                        <FUSButton
                            className={"border border-0"} labelText={"Create LOB"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }

            >
                <div className="px-1 pt-1">

                    <div className="px-2 mx-1">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={DataList}
                            columns={columns}
                            //filter={filterFactory()}
                            pagination={paginationFactory(options)}
                        />
                    </div>
                </div>
            </LayoutContainer>

            {showModal.state && (
                <FUSModal
                    title="Create LOB"
                    showModal={showModal.state}
                    size={"md"}
                    handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                    centered

                >
                    <div>
                        <form role="search" onSubmit={null}>
                            <div className="row">
                                <div className="col-md-12 px-0">
                                    <div>
                                        <span className="text-secondary">Enter lob name</span>
                                        <input
                                            type="text"
                                            className="px-3 py-2 text_style form-control mt-1"
                                            placeholder="Enter here..."
                                            value={inputText}
                                            onChange={(e) => setInputText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 px-0">
                                    <div>
                                        <span className="text-secondary">Enter lob code</span>
                                        <input
                                            type="text"
                                            className="px-3 py-2 text_style form-control mt-1"
                                            placeholder="Enter Here"
                                            value={businessCode}
                                            onChange={(e) => setBusinessCode(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 px-0 mt-3">
                                    <FUSButton className={"form-control"} buttonType={inputText === "" ? "secondary" : "primary"} labelText={"Add"} disabled={inputText === ""} onClick={() => InsertData()} type="submit" />

                                </div>
                            </div>
                        </form>
                    </div>

                </FUSModal>

            )}
        </>
    )
}
export default BusinessType;