import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../../src/i18n/en/translation.json"
import hi from "../../src/i18n/hi/translation.json"
import LanguageDetector from "i18next-browser-languagedetector";

export const resourcesList = {
  en: {
    translation: en,
  },
  hi: {
    translation: hi,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    compatibilityJSON: "v3",
    lng: "en",
    fallbackLng: "en",
    resources: resourcesList,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
