import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
const PlanPeriod = () => {
    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [DataList, setDataList] = useState([]);
    const [periodName, setPeriodName] = useState("");
    const [periodCode, setPeriodCode] = useState("");

    const getData = () => {
        setDataList([]);
        MstService.getPlanType()
            .then((response) => {
                setDataList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const insertPlanPeriod = () => {
        MstService.insertPlanPeriod({ period_name: periodName, period_code: periodCode })
            .then((response) => {
                getData();
                setShowModal((prev) => ({ ...prev, state: false }))
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const handleDelete = (row) => {

    }

    const handleEdit = (row) => {

    }

    const columns = [
        {
            dataField: 'period_name',
            text: 'Plan Perioid'
        },
        {
            dataField: 'code',
            text: 'Code'
        },
        {
            dataField: "action",
            text: "Action",
            classes: 'p-1',
            formatter: (cellContent, row) => {
                return (


                    <div className="d-flex gap-3">
                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdOutlineModeEdit"
                            iconSize={16}
                            buttonType="lightgray"
                            onClick={() => handleEdit(row)}
                        />

                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                );
            },
            editable: false,
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "6",
                value: 6,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: DataList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };




    return (

        <>

            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Create Plan Period"
                right={
                    <>

                        <FUSButton
                            labelText={"Create Plan Period"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }
            >
                <div className="px-1 pt-1">



                    <div className="px-3">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={DataList}
                            columns={columns}
                            pagination={paginationFactory(options)}
                        />
                    </div>

                </div>
                {showModal.state && (
                    <FUSModal
                        title="Create Plan Period"
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                        centered

                    >
                        <div>
                            <div>
                                <form role="search" onSubmit={null}>

                                    <div className="row">
                                        <div className="col-md-12 px-0">
                                            <div>
                                                <span className="text-secondary">Enter source type</span>
                                                <input
                                                    type="text"
                                                    className="px-3 py-2 text_style form-control mt-1"
                                                    placeholder="Enter here..."
                                                    value={periodName}
                                                    onChange={(e) => setPeriodName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-0">
                                            <div>
                                                <span className="text-secondary">Enter source code</span>
                                                <input
                                                    type="text"
                                                    className="px-3 py-2 text_style form-control mt-1"
                                                    placeholder="Enter here..."
                                                    value={periodCode}
                                                    onChange={(e) => setPeriodCode(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2 px-0">
                                            <FUSButton className={"form-control"} buttonType={periodName === "" && periodCode === "" ? "secondary" : "primary"} labelText={"Add"} disabled={periodName === "" && periodCode === ""} onClick={() => insertPlanPeriod()} type="submit" />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </FUSModal>

                )}
            </LayoutContainer>
        </>
    )
}
export default PlanPeriod;