import React, { useState, useEffect } from "react";
import _ from "lodash";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Form, Alert, Col, Row, FloatingLabel } from "react-bootstrap";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { useContext } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import "./MakeCollector.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { toast } from "react-toastify";

export default function MakeCollector() {
  const [counterOn, setCounnterOn] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [pin, setPin] = useState("");
  const [showModal, setShow] = useState({
    state: false,
    type: null,
  });

  const [rowData, setRowData] = useState({});
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [totalUser, setTotalUser] = useState({});
  const { isFunctionalityEnabled } = useContext(StoreContext);

  const alertTimer = () =>
    setTimeout(() => {
      setAlertShow(false);
    }, 3000);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const findFormErrors = () => {
    const { collectorMobileNo } = form;
    const newErrors = {};
    // collector nobile no errors
    if (!collectorMobileNo || collectorMobileNo === "") {
      newErrors.collectorMobileNo = "cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(collectorMobileNo)) {
      newErrors.collectorMobileNo = "mobile mobile no should be numeric";
    } else if (collectorMobileNo.length > 10) {
      newErrors.collectorMobileNo =
        "collector mobile number should be 10 digits!";
    } else if (collectorMobileNo.length < 10) {
      newErrors.collectorMobileNo =
        "collector mobile number should be 10 digits!";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const { collectorMobileNo } = form;

      const response = await MstService.checkUser({
        login_phone: collectorMobileNo,
      }).catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
      if (response?.data?.errorCode === -102) {
        //user found
        const makeCollectorResponse = await MstService.makeCollector({
          collector_id: response?.data?.data?.id,
        }).catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
        requestAndErrorHandler.responseHandler(
          makeCollectorResponse,
          "/makecollector"
        );
      } else {
        //User not found
        requestAndErrorHandler.responseHandler(response, "/makecollector");
      }
    }
  };

  const getCollectorList = () => {
    clearInterval(alertTimer);
    MstService.getCollector("ALL")
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
  }, []);

  const pinResetModal = (row) => {
    setPin("");
    setShow((prev) => ({ ...prev, state: true, type: "resetPin" }));
    setRowData(row);
  };

  const resetPinForAgency = () => {
    let postdata = {
      pin: pin,
      collector_id: rowData?.collector_user_id,
    };
    MstService.resetPinForAgency(postdata)
      .then((response) => {
        setAlertShow(true);
        alertTimer();
        setAlertMessage(response.data.message);
        setShow(false);
        getCollectorList();
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const changeUserStatus = (row, status) => {
    let postdata = {
      status: status,
      collector_id: row?.collector_user_id,
    };
    MstService.userStatusChange(postdata)
      .then((response) => {
        alertTimer();
        setAlertMessage(response.data.message);
        getCollectorList();
        if (response?.data?.errorCode == -106) {
          toast.warning(response?.data?.message);
        } else if (response?.data?.errorCode == -323) {
          toast.error(response?.data?.message);
        } else {
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const changeBeatUpload = (row, status) => {
    let postdata = {
      status: status,
      collector_id: row?.collector_user_id,
    };
    MstService.changeBeatUpload(postdata)
      .then((response) => {
        alertTimer();
        setAlertMessage(response.data.message);
        getCollectorList();
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleClose = (state) => setShow((prev) => ({ ...prev, state }));

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: collectorList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "name",
      text: "Collector Name",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "mobile_number",
      text: "Collector Phone",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.status ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">InActive</span>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex">
              {row?.status ? (
                <FUSButton
                  className={"status_style button_width"}
                  iconSrc="tb"
                  iconName="TbUserOff"
                  iconSize={14}
                  buttonTextStyle="fs-6 fw-normal"
                  buttonType="danger"
                  labelText={"Deactivate"}
                  onClick={() => changeUserStatus(row, false)}
                />
              ) : (
                <FUSButton
                  iconSrc="tb"
                  iconName="TbUserCheck"
                  iconSize={14}
                  buttonTextStyle="fs-6 fw-normal"
                  className={"status_style button_width"}
                  buttonType="success"
                  labelText={"Activate"}
                  onClick={() => changeUserStatus(row, true)}
                />
              )}
              &nbsp; &nbsp; &nbsp;
              {row?.is_one_time_beat_upload ? (
                <FUSButton
                  className={"status_style button_width"}
                  buttonTextStyle="fs-6 fw-normal"
                  buttonType="danger"
                  labelText={"One Time Upload"}
                  onClick={() => changeBeatUpload(row, false)}
                />
              ) : (
                isFunctionalityEnabled(
                  ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
                  ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.ONE_TIME_BEAT_UPLOAD
                ) && (
                  <FUSButton
                    className={"status_style button_width"}
                    buttonTextStyle="fs-6 fw-normal"
                    buttonType="success"
                    labelText={"Daily Upload"}
                    onClick={() => changeBeatUpload(row, true)}
                  />
                )
              )}
              &nbsp; &nbsp; &nbsp;
              <FUSButton
                className={"action_button_style status_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={14}
                buttonType="lightgray"
                onClick={() => pinResetModal(row)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const active =
    collectorList &&
    collectorList.length > 0 &&
    collectorList.filter((item) => item?.status === true);
  const deactive =
    collectorList &&
    collectorList.length > 0 &&
    collectorList.filter((item) => item?.status === false);

  const handleAddCollector = () => {
    setShow((prev) => ({ ...prev, state: true, type: "addCollector" }));
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Manage Collector"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Collector"}
              onClick={handleAddCollector}
            />
          </>
        }
      >
        <div className="">
          <div className="border-bottom pb-2">
            <div className="row ">
              <div className="col">
                <div className="bg-white pt-2 pb-1 px-2">
                  <div className="d-flex gap-3">
                    <div className="total_button d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style total_text_color">
                        Total
                      </span>

                      <ScrollTrigger
                        onEnter={() => setCounnterOn(true)}
                        onExit={() => setCounnterOn(false)}
                      >
                        <span>
                          {counterOn && (
                            <CountUp
                              className="label_text_style total_text_color"
                              start={0}
                              end={collectorList.length}
                              delay={0.5}
                              duration={3}
                            />
                          )}
                        </span>
                      </ScrollTrigger>
                    </div>
                    <div className="activated_button d-flex d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style activated_text_color">
                        Activated
                      </span>

                      <ScrollTrigger
                        onEnter={() => setCounnterOn(true)}
                        onExit={() => setCounnterOn(false)}
                      >
                        <span>
                          {counterOn && (
                            <CountUp
                              className="label_text_style activated_text_color"
                              start={0}
                              end={active.length}
                              delay={0.5}
                              duration={3}
                            />
                          )}
                        </span>
                      </ScrollTrigger>
                    </div>
                    <div className="deactivated_button d-flex d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style deactivated_text_color">
                        Deactivated
                      </span>

                      <ScrollTrigger
                        onEnter={() => setCounnterOn(true)}
                        onExit={() => setCounnterOn(false)}
                      >
                        <span>
                          {counterOn && (
                            <CountUp
                              className="label_text_style deactivated_text_color"
                              start={0}
                              end={deactive.length}
                              delay={0.5}
                              duration={3}
                            />
                          )}{" "}
                        </span>
                      </ScrollTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={collectorList}
              columns={columns}
              sizePerPage={5}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
            {showModal.state ? (
              <FUSModal
                title={
                  showModal.type == "resetPin"
                    ? `${"Reset pin for : "} ${rowData?.name}`
                    : "Add Collector"
                }
                showModal={showModal.state}
                size={"md"}
                handleClose={() => handleClose(false)}
                centered
              >
                {showModal.type == "resetPin" ? (
                  <Form>
                    <Row>
                      <Form.Group
                        as={Col}
                        lg="12"
                        controlId="validationCustom03"
                        className="mx-0 px-0"
                      >
                        <span className="label_style">New Pin</span>
                        <Form.Control
                          className="py-2 my-1 text_style"
                          onChange={(e) => setPin(e.target.value)}
                          value={pin}
                          type="password"
                          maxLength={4}
                          placeholder="Enter New Pin"
                        />
                      </Form.Group>
                    </Row>
                    <div className="d-flex justify-content-end gap-3 mt-3">
                      {pin &&
                      pin !== "" &&
                      pin !== null &&
                      pin !== undefined ? (
                        <FUSButton
                          className={"form-control"}
                          buttonType="primary"
                          onClick={resetPinForAgency}
                          labelText={"Save Changes"}
                        />
                      ) : null}
                    </div>
                  </Form>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="row gap-3 px-0">
                      <div className="col-12  px-0">
                        <span className="label_style">Collector Phone</span>
                        <input
                          type="text"
                          className="form-control py-2 my-1 text_style"
                          placeholder="Enter mobile no"
                          maxLength={10}
                          onChange={(e) =>
                            setField("collectorMobileNo", e.target.value)
                          }
                          isInvalid={!!errors.collectorMobileNo}
                        />
                        {errors.collectorMobileNo && (
                          <p className="text-warning">
                            {errors.collectorMobileNo}
                          </p>
                        )}
                      </div>
                      <div className="col-12  px-0">
                        <FUSButton
                          className="form-control"
                          buttonType="primary"
                          type={"submit"}
                          labelText={"Add Collector"}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </FUSModal>
            ) : null}
            {alertShow ? (
              <Alert
                style={{
                  position: "absolute",
                  top: 0,
                  margin: 10,
                  width: "20%",
                  right: 0,
                }}
                key="success"
                variant="success"
                dismissible
              >
                {alertMessage}
              </Alert>
            ) : null}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
