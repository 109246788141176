/* eslint-disable no-fallthrough */
/* eslint-disable no-unreachable */
/* eslint-disable default-case */
import React from "react";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import UnAuthorized from "../UnAuthorized/UnAuthorized";
import { StoreContext } from "../../store/StoreContext";

import ROLES_PERMISSIONS from "../../permissions/permissions";
import { useContext } from "react";
const AuthRoles = ({ component, name }) => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const token = TokenService.getUser();

  const checkPermission = (feature, functionality) => {
    if (isFunctionalityEnabled(feature, functionality)) {
      return <React.Fragment>{component}</React.Fragment>;
    } else {
      return <UnAuthorized name={name} />;
    }
  };

  switch (token?.role?.role_id) {
    case VARIABLES.SUPER_USER:
      switch (name) {
        case "createagency":
        case "sourcetype":
        case "subscriptionplan":
        case "businesstype":
        case "featurelist":
        case "functionality":
        case "addsubscription":
        case "planbusiness":
        case "planperiod":
        case "updateAgency":
        case "featurefunctionality":
        case "quickregistration":
        case "agencylist":
        case "vendorsApproval":
        case "vendorConfiguration":
        case "vendorInvoicing":
        case "userlist":
        case "fetchOTP":
        case "lobConfiguration":
        case "showAllOrders":
        case "productItemDiscount":
        case "addDepartment":
        case "addCategory":
        case "vendorOnboarding":
        case "sectionConfiguration":
        case "appservices":
        case "rideCoupon":
        case "rideConfig":
        case "rideType":
        case "vehicleType":
        case "driverType":
        case "manageCategory":
        case "manageManufacturingDepartment":
        case "makeRideManager":
        case "makeDriver":
        case "manageUsers":
        case "createRole":
        case "assignRole":
        case "mapDepartmentToUser":
        case "manageNotification":
        case "deliveryFeesConfig":
        case "financialYear":
        case "systemConfig":
        case "vendorLOBMapping":
        case "updateFunctionalityList":
        case "offerCoupon":
        case "addrewardpoint":
        case "locationImageMapping":
        case "assignDeliveryPerson":
        case "/":
          return <React.Fragment>{component}</React.Fragment>;
          break;
        default:
          return <UnAuthorized name={name} />;
      }

    case VARIABLES.AGENCY_ROLE:
    case VARIABLES.SUPERVISOR:
      switch (name) {
        case "dashboard":
          return <React.Fragment>{component}</React.Fragment>;
        case "beatuploadmultiple":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_UPLOAD_COLLECTOR_WISE
          );
        case "products":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT.MANAGE_PRODUCT
          );
        case "onetimeupload":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.ONE_TIME_BEAT_UPLOAD
          );

        case "showbeatdetail":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.ONE_TIME_BEAT_UPLOAD
          );

        case "userhierarchy":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.USER_HIERARCHY
          );

        case "inactiveclients":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.INACTIVE_CLIENTS,
            ROLES_PERMISSIONS.PERMISSIONS.INACTIVE_CLIENTS.VIEW_INACTIVE_CLIENTS
          );

        case "collectionReport":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT
          );

        case "collectiondata":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_DATA
          );

        case "makecollector":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.CREATE_COLLECTOR
          );

        case "collectorcode":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.CREATE_COLLECTOR_CODE
          );

        case "mapping":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.MAPPING
          );

        case "advancemapping":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.MAPPING
          );

        case "bodprocess":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_FILE_UPLOAD
          );

        case "beatUpload":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_FILE_UPLOAD
          );

        case "pushnotification":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.NOTIFICATION,
            ROLES_PERMISSIONS.PERMISSIONS.NOTIFICATION.SEND_PUSH_NOTIFICATION
          );

        case "cashmemo":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
            ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO.CASH_MEMO
          );

        case "uploadexceldata":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.EXCEL_UPLOAD_VIEW
          );

        case "collectionHistoryWithRange":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_HISTORY
          );

        case "collectionreportBalance":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT_BALANCE
          );

        case "beatFileMapping":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.BEAT_FILE_MAPPING
          );

        case "cashmemohistory":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
            ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO.HISTORY_CASH_MEMO
          );

        case "fileupload":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_FILE_UPLOAD
          );

        case "createRole":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS,
            ROLES_PERMISSIONS.PERMISSIONS.ROLES_AND_PERMISSIONS.CREATE_ROLE
          );

        case "assignRole":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS,
            ROLES_PERMISSIONS.PERMISSIONS.ROLES_AND_PERMISSIONS.ASSIGN_ROLE
          );

        case "collectionReportHistory":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT_HISTORY
          );

        case "tracking":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.TRACKING,
            ROLES_PERMISSIONS.PERMISSIONS.TRACKING.TRACKING
          );
        case "expense":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.EXPENSE_TYPE
          );

        case "visitType":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.VISIT_TYPE
          );

        case "viewterritory":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.VIEW_TERRITORY
          );

        case "holidays":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.HOLIDAYS
          );
        case "departmentMapping":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.DEPARTMENT_MAPPING
          );

        case "department":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.DEPARTMENT
          );

        case "addbank":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.ADD_BANK
          );
        case "sifyitemmaster":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SIFY,
            ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_MASTER_ITEM
          );

        case "updateretailerreq":

        case "collectorroutes":
          return <React.Fragment>{component}</React.Fragment>;

        case "createWorkflow":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.CREATE_WORKFLOW
          );
        case "viewWorkflow":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.VIEW_WORKFLOW
          );
        case "expenseCategory":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.EXPENSE_CATEGORY
          );
        case "collectionInvoiceReport":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT_INVOICE
          );
        case "sifyReport":
          return <React.Fragment>{component}</React.Fragment>;
        case "masterFileUpload":
          return <React.Fragment>{component}</React.Fragment>;
        case "salesFileUpload":
          return <React.Fragment>{component}</React.Fragment>;
        case "openPartyReport":
          return <React.Fragment>{component}</React.Fragment>;
        case "openCollectionFileUpload":
          return <React.Fragment>{component}</React.Fragment>;
        case "CollectionReportMarg":
          return <React.Fragment>{component}</React.Fragment>;
        case "collectorMap":
          return <React.Fragment>{component}</React.Fragment>;
        case "godownMaster":
          return <React.Fragment>{component}</React.Fragment>;
        case "godownMapping":
          return <React.Fragment>{component}</React.Fragment>;
        case "cashFlow":
          return <React.Fragment>{component}</React.Fragment>;
        case "bankDepositReport":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.BANK_DEPOSIT,
            ROLES_PERMISSIONS.PERMISSIONS.BANK_DEPOSIT.VIEW_BANK_DEPOSIT
          );
        case "collectorDashboard":
          return <React.Fragment>{component}</React.Fragment>;
        case "purchaseFileUpload":
          return <React.Fragment>{component}</React.Fragment>;
        default:
          return <UnAuthorized name={name} />;
      }
      break;
    case VARIABLES.GODOWN_COLLECTOR:
      switch (name) {
        case "openCollectionFileUpload":
          return <React.Fragment>{component}</React.Fragment>;
          break;
      }
    case VARIABLES.VENDOR:
      switch (name) {
        case "products":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS
          );
        case "orders":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_VIEW_ORDERS
          );
        case "ordersConfiguration":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_ORDER_CONFIG
          );
        case "cutoffTime":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT.CUTOFF_TIME
          );
        case "manageUsers":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_USER
          );
        case "createRole":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS,
            ROLES_PERMISSIONS.PERMISSIONS.ROLES_AND_PERMISSIONS.CREATE_ROLE
          );
          return <React.Fragment>{component}</React.Fragment>;

        case "assignRole":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS,
            ROLES_PERMISSIONS.PERMISSIONS.ROLES_AND_PERMISSIONS.ASSIGN_ROLE
          );
          return <React.Fragment>{component}</React.Fragment>;
        case "manageSlots":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT
          );
        case "holidayconfig":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG
          );
        case "packagingPlan":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_PACKAGE_PLAN
          );
        case "purchasingPlan":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_PURCHASE_PLAN
          );
        case "manageservices":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_SERVICES
          );
        case "createWorkflow":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.CREATE_WORKFLOW
          );
        case "viewWorkflow":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.VIEW_WORKFLOW
          );
        case "vendorCoupon":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.OFFERS,
            ROLES_PERMISSIONS.PERMISSIONS.OFFERS
              .VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG
          );
        case "productItemDiscount":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.OFFERS,
            ROLES_PERMISSIONS.PERMISSIONS.OFFERS
              .VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT
          );
        case "manageManufacturingDepartment":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_LMENU_MANAGE_DEPARTMENT
          );
        case "manageCategory":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_LMENU_MANAGE_CATEGORY
          );
        case "orderTimeConfig":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG
          );
        case "partyMaster":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.PARTY_MASTER,
            ROLES_PERMISSIONS.PERMISSIONS.PARTY_MASTER.VENDOR_LMENU_PARTY_MASTER
          );
        case "viewDispatch":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.DISPATCH_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.DISPATCH_MANAGEMENT
              .VENDOR_ADMIN_LMENU_VIEW_DISPATCH
          );
        case "offerConfig":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION
          );
        case "viewOrders":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_VIEW_ORDERS
          );
        case "mapDepartmentToUser":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING
          );
        case "manageProducts":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS
          );
        case "addServiceRepresentative":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_USER
          );
        case "vendorOrderReports":
          return checkPermission(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT.VENDOR_SALES_REPORTS
          );
      }
    default:
      return <UnAuthorized name={name} />;
  }
};

export default AuthRoles;
