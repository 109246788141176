import React, { useState, useEffect } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import OrderService from "../../services/orders.service";
import { toast } from "react-toastify";
import util from "../../services/util";
import TokenService from "../../services/token.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import _ from "lodash";

const OrderConfig = () => {
  const [allConfig, setAllConfig] = useState([]);
  const [vendorConfig, setVendorConfig] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [itemObj, setItemObj] = useState({});
  const token = TokenService.getUser();
  const vendorApporval = token?.vendorApproved?.is_approved;
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const getConfig = async () => {
    try {
      const res = await OrderService.getConfigDetailsForVendor();
      if (res?.data?.errorCode === 0) {
        setAllConfig(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = (index, item) => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      setEditIndex(index);
      setItemObj(item);
      if (!_.isEmpty(item.user_value)) {
        setVendorConfig(item?.user_value);
      }
    }
  };

  const handleSave = async (item) => {
    try {
      const result = await OrderService.setConfigByVendor({
        user_value: vendorConfig,
        config_id: item?.id,
      });
      if (result?.data?.errorCode === 0) {
        setVendorConfig("");
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setEditIndex(null);
      }
      getConfig();
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleUpdate = async (item) => {
    try {
      const result = await OrderService.updateConfigByVendor({
        user_value: vendorConfig,
        config_id: item?.id,
      });
      if (result?.data?.errorCode === 0) {
        setVendorConfig("");
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setEditIndex(null);
      }
      getConfig();
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <>
      <LayoutContainer
        title1={"Products"}
        title2={`Order Configuration`}
        title3={`Order Configuration`}
      >
        <div>
          <div className="px-3 py-3">
            <table className="table table-bordered border border-1">
              <thead>
                <tr>
                  <th>LOB</th>
                  <th>Config</th>
                  <th>Description</th>
                  <th>Config Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allConfig.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item?.lob_id === "-1" ? "MULTIPLE LOB" : item?.lob}
                    </td>
                    <td>{item?.key}</td>
                    <td>{item?.description}</td>
                    <td>
                      {editIndex === index ? (
                        item?.key === "MIN ORDER PRICE" ? (
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Min Order price"
                            placeholder="Min Order price"
                            value={vendorConfig}
                            onChange={(e) => setVendorConfig(e.target.value)}
                          />
                        ) : item?.key?.includes("TIME") ? (
                          <input
                            type="time"
                            className="form-control"
                            aria-label="Default select example"
                            placeholder="Enter Close Time"
                            value={vendorConfig}
                            onChange={(e) => setVendorConfig(e.target.value)}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Default select example"
                            placeholder="Enter here"
                            value={vendorConfig}
                            onChange={(e) => setVendorConfig(e.target.value)}
                          />
                        )
                      ) : item?.user_value?.includes(":") ? (
                        util.convertTimeToAMPM(item?.user_value)
                      ) : (
                        item?.user_value
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <FUSButton
                          className={"py-1"}
                          labelText={item?.user_value ? "Update" : "Save"}
                          buttonType="primary"
                          onClick={() =>
                            item?.user_value
                              ? handleUpdate(item)
                              : handleSave(item)
                          }
                        ></FUSButton>
                      ) : (
                        <FUSButton
                          className={"py-1"}
                          labelText={item?.user_value ? "Edit" : "Add"}
                          buttonType={
                            item?.user_value ? "secondary" : "primary"
                          }
                          onClick={() => handleEdit(index, item)}
                        ></FUSButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showModal.state && (
          <FUSModal
            title={showModal.type === "VENDOR_APPROVAL" ? "" : ""}
            showModal={showModal.state}
            size={"md"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            {showModal.type === "VENDOR_APPROVAL" && (
              <h5>
                Currently your profile is in under validation process .We will
                notify you once it approved after that only you can access this
                functionality.
              </h5>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default OrderConfig;
