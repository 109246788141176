/* eslint-disable no-undef */
/* eslint-disable camelcase */
import api from "./api";

const agentDetails = (agentDetails) => api.post("AgencyDetail", agentDetails);
const getAgentDetails = () => api.post("GetAgencyDetails", {});
const uploadFile = (fileObject) => api.post("Upload/", fileObject);

const getCollector = (filter) => api.get(`getCollectorListByAgentId/${filter}`);

const getUploadedData = (collectorObject) =>
  api.post("getCollectionDataByCollectorId", collectorObject);
const checkUser = (collectorMobileNo) =>
  api.post("CheckUser", collectorMobileNo);
const makeCollector = (collectorObj) =>
  api.post("CreateCollector", collectorObj);
const getCountries = () => api.post("Countries", {});
const getStates = (countryID) => api.post("States", { country_id: countryID });
const getCity = (stateID) => api.post("City", { state_id: stateID });
const getCollectorCodes = () => api.post("GetCollectorCodes", {});
const saveMapping = (collectorCodeList) =>
  api.post("SaveMapping", collectorCodeList);
const addCollectorCode = (collectorCodeObj) =>
  api.post("AddCollectorCode", collectorCodeObj);
const getCollectionType = () => api.post("GetCollectionType", {});
const collectionReport = (collectorObject) =>
  api.post("CollectionReport", collectorObject);
const getMapping = () => api.post("GetMapping", {});
const removeCollectorCode = (collectorCodeObj) =>
  api.post("RemoveCollectorCode", collectorCodeObj);
const generateReport = (filter) => api.post("GenerateReport", filter);
const getPaymentType = () => api.get("getPaymentType", {});

const getSourceType = () => api.get("getSourceType", {});
const getFeatureType = () => api.get("getFeatureType", {});
const getSubscriptionPlan = () => api.get("getSubscriptionPlan", {});
const getBusinessType = () => api.get("getBusinessType", {});
const getFeatureList = () => api.get("getFeatureList", {});
const getFunctionalityList = () => api.get("getFunctionalityList", {});
const getFeatureFunctionalityList = () =>
  api.get("getFeatureFunctionalityList", {});
const getSubscriptionsData = () => api.get("getSubscriptionsData", {});
const getPlanBusiness = () => api.get("getPlanBusiness", {});
const getAllAgentsList = () => api.get("getAllAgentsList", {});
const getPlanType = () => api.get("getPlanType", {});

const insertSourceName = (postdata) => api.post("insertSourceName", postdata);
const insertFeatureType = (postdata) => api.post("insertFeatureType", postdata);
const insertSubscriptionPlan = (postdata) => api.post("insertPlan", postdata);
const insertBusinessType = (postdata) =>
  api.post("insertBusinessType", postdata);
const insertFeatureFunction = (postdata) =>
  api.post("insertFeatureFunction", postdata);
const insertFunctionality = (postdata) =>
  api.post("insertFunctionality", postdata);
const getTypeAndSourceByFeatureID = (postdata) =>
  api.post("getTypeAndSourceByFeatureID", postdata);
const insertSubscription = (postdata) =>
  api.post("insertSubscription", postdata);
const insertFeature = (postdata) => api.post("insertFeature", postdata);
const insertPlanBusiness = (postdata) =>
  api.post("insertPlanBusiness", postdata);
const insertPlanPeriod = (postdata) => api.post("insertPlanPeriod", postdata);
const updateCollectionData = (postdata) =>
  api.post("updateCollectionData", postdata);
const bodProcess = (postdata) => api.post("BODProcess", postdata);
const resetPinForAgency = (postdata) => api.post("resetPinForAgency", postdata);
const userStatusChange = (postdata) => api.post("userStatusChange", postdata);
const GetSubscriptionTrialList = () => api.post("GetSubscriptionTrialList", {});
const GetAgencyDetailByID = (postdata) =>
  api.post("GetAgencyDetailByID", postdata);

const UpdateAgency = (postdata) => api.post("UpdateAgencyDetail", postdata);

const checkCollectionDataExists = (postData) =>
  api.post("checkCollectionDataExists", postData);

const generateCashMemoForAllCollector = () =>
  api.post("generateCashMemoForAllCollector", {});

const generateCashMemoForAllCollectorWeb = () =>
  api.post("generateCashMemoForAllCollectorWeb", {});

const generateCashMemoForAllCollectorHistory = (filter) =>
  api.post("generateCashMemoForAllCollectorHistory", filter);

const getRetailerInvoiceByLink = (postdata) =>
  api.post("getInvoiceByLink", postdata);

const SignupOTPVerification = (postdata) =>
  api.post("SignupOTPVerification", postdata);

const OTPVerficationSignUp = (postdata) =>
  api.post("OTPVerficationSignUp", postdata);

const createRetailerPin = (postdata) =>
  api.post("CreatePinForRetailer", postdata);

const retailerMasterEntry = (postdata) =>
  api.post("retailerMasterEntry", postdata);

const retailerUpdateMobileReq = (postdata) =>
  api.post("retailerUpdateMobileReq", postdata);

const getRetailerUpdtMobReq = (postdata) =>
  api.get("getRetailerUpdtMobReq", postdata);

const updateRetailerMobileReq = (postdata) =>
  api.post("updateRetailerMobileReq", postdata);

const getRLExportData = (postdata) => api.post("getRLExportData", postdata);

const getDataFromUploadExcel = (postdata) =>
  api.post("getDataFromUploadExcel", postdata);

const getBasketAndPermissions = () => api.post("getBasketAndPermissions");

const addCustomBasket = (postdata) => api.post("addCustomBasket", postdata);

const mapBasketToUser = (postdata) => api.post("mapBasketToUser", postdata);

const getAssignedRolesAndUser = (postData) =>
  api.post("getAssignedRolesAndUser", postData);

const updateAssignedRoleStatus = (postdata) =>
  api.post("updateAssignedRoleStatus", postdata);

const updateCustomBasket = (postdata) =>
  api.post("updateCustomBasket", postdata);

const getUserBeatUplaodMapping = () => api.post("getUserBeatUplaodMapping");

const updateBeatMappingIndex = (postData) =>
  api.post("updateBeatMappingIndex", postData);
const sendMobileNotification = (postdata) =>
  api.post("sendMobileNotification", postdata);

const checkCollectionDataExistsIndividuals = (postData) =>
  api.post("checkCollectionDataExistsIndividuals", postData);

const getCollectorByHeiarchy = (filter, hierarchy) =>
  api.get(`getCollectorListByAgentId/${filter}/${hierarchy}`);

const collectionReportWithBalance = (postdata) =>
  api.post("collectionReportWithBalance", postdata);

const BODProcessCollectorWise = (postdata) =>
  api.post("BODProcessCollectorWise", postdata);

const getCollectorsAndOtherRoles = () => api.post("getCollectorsAndOtherRoles");

const getHierarchyDashboardData = () => api.post("getHierarchyDashboardData");

const addHierarchyToUser = (postdata) =>
  api.post("addHierarchyToUser", postdata);
const makeUserRetailer = (postdata) => api.post("makeUserRetailer", postdata);

const syncBulkUploadBeatData = (postdata) =>
  api.post("syncBulkUploadBeatData", postdata);

const checkBulkUploadStatusIndividual = (postdata) =>
  api.post("checkBulkUploadStatusIndividual", postdata);
const changeBeatUpload = (postdata) => api.post("changeBeatUpload", postdata);

const getCollectionDataIndividualByDate = (postdata) =>
  api.post("getCollectionDataIndividualByDate", postdata);

const getAllUsersForAgency = () => api.post("getAllUsersForAgency");

const crudAgencyHoliday = (postData) => api.post("crudAgencyHoliday", postData);

const crudDepartment = (postData) => api.post("crudDeptartment", postData);

const crudExpense = (postData) => api.post("crudExpenseType", postData);
const uploadCrudExpense = (postData) => api.post("uploadCrudExpense", postData);

const crudVisitType = (postData) => api.post("crudVisitType", postData);
const uploadCrudVisitType = (postData) =>
  api.post("uploadCrudVisitType", postData);

const crudAgencyTerritory = (postData) =>
  api.post("crudAgencyTerritory", postData);
const uploadAgencyTerritory = (postData) =>
  api.post("uploadAgencyTerritory", postData);
const uploadCrudAgencyHoliday = (postData) =>
  api.post("uploadCrudAgencyHoliday", postData);

const deleteCollectionDataIndividualByRefId = (postData) =>
  api.post("deleteCollectionDataIndividualByRefId", postData);

const updateCollectionDataIndividualByRefId = (postData) =>
  api.post("updateCollectionDataIndividualByRefId", postData);

const crudCollectorDeptMapping = (postData) =>
  api.post("crudCollectorDeptMapping", postData);

const getInactiveClientsData = () => api.get("getInactiveClientsData");

const addBankAccountDetails = (postData) =>
  api.post("addBankAccountDetails", postData);

const getBankAccountDetails = () => api.get("getBankAccountDetails");

const deleteBankAccountDetails = (id) => {
  return api.post("deleteBankAccountDetails", id);
};

const updateBankAccountDetails = (postData) =>
  api.post("updateBankAccountDetails", postData);

const getDepartmentAndFeatures = () => api.post("getDepartmentAndFeatures");

const getDepartmentAndRoles = (postdata) =>
  api.post("getDepartmentAndRoles", postdata);

const getCollectorsAndDepartments = (postdata) =>
  api.post("getCollectorsAndDepartments", postdata);

const addHierarchyToDepartment = (postData) =>
  api.post("addHierarchyToDepartment", postData);

const getDepartmentHierarchyDashboardData = () =>
  api.get("getDepartmentHierarchyDashboardData");

const deleteDepartmentHierarchy = () => api.post("deleteDepartmentHierarchy");

const setDefaultRoleToDepartment = (postData) =>
  api.post("setDefaultRoleToDepartment", postData);
const getCollectorRoutesForBulkBeat = (postData) =>
  api.post("getCollectorRoutesForBulkBeat", postData);

const createCollectorRoutesForBulkBeat = (postData) =>
  api.post("createCollectorRoutesForBulkBeat", postData);

const getApproverInHeirarchy = () => api.post("getApproverInHeirarchy");

const addHierarchyToRoles = (postData) =>
  api.post("addHierarchyToRoles", postData);

const getTaskTypesForWorkflow = () => api.get("getTaskTypesForWorkflow");

const getAllWorkflowsByAgencyId = () => api.get("getAllWorkflowsByAgencyId");

const getWorkflowsDetailsById = (postdata) =>
  api.post("getWorkflowsDetailsById", postdata);

const getActiveWorkflowsByType = (postData) =>
  api.post("getActiveWorkflowsByType", postData);

const assignTypeToWorkflow = (postData) =>
  api.post("assignTypeToWorkflow", postData);

const crudExpenseCategory = (postData) =>
  api.post("crudExpenseCategory", postData);

const getTallyImportStatus = (postData) =>
  api.post("getTallyImportStatus", postData);
const insertTallyImportStatus = (postData) =>
  api.post("insertTallyImportStatus", postData);
const tallyExport = (postData) => api.post("tallyExport", postData);
const generateOpenCollectionReport = (filter) =>
  api.post("generateOpenCollectionReport", filter);

const updateMargSerailByCollector = (postData) =>
  api.post("updateMargSerailByCollector", postData);
const generateGodwonCollectionReport = (filter) =>
  api.post("generateGodownCollectionReport", filter);

const bulkUpdateMargSerailByCollector = (postData) =>
  api.post("bulkUpdateMargSerailByCollector", postData);
const getlargestMargNumber = () => api.get("getlargestMargNumber");
const getUrlByShortURL = (postData) => api.post("getUrlByShortURL", postData);
const getCollectorLocation = () => api.get("getCollectorLocation");
const getCollectorTrack = (data) => {
  return api.post("getCollectorTrack", data);
};
const getInvoiceByCollector = (postData) =>
  api.post("getInvoiceByCollector", postData);

const updateInvoicebByCollector = (postData) =>
  api.post("updateInvoicebByCollector", postData);
const getFinancialListByAgencyId = () => api.get("getFinancialListByAgencyId");
const getCompanyListByAgencyId = () => api.get("getCompanyListByAgencyId");
const InsertMargSeries = (postData) => api.post("InsertMargSeries", postData);
const checkIsGoDownBeatAssigned = () => {
  return api.post("checkIsGoDownBeatAssigned");
};
const getGodownMasterList = () => {
  return api.post("getGodownMasterList");
};
const addGodownInMaster = (data) => {
  return api.post("addGodownInMaster", data);
};
const getGodownCollectorList = () => {
  return api.post("getGodownCollectorList");
};
const godownMappingWithCollector = (data) => {
  return api.post("godownMappingWithCollector", data);
};
const getGodownMapping = () => {
  return api.post("getGodownMapping");
};
const updateGodownMaster = (data) => {
  return api.post("updateGodownMaster", data);
};

const sifyReports = (data) => api.post("sify/sifyReports", data);
const getSifySaleUploadFileData = (data) =>
  api.post("sify/getSifySaleUploadFileData", data);

const getBankDepositSummary = (data) => {
  return api.post("bankDepositSummaryOwner", data);
};
const getbankDepositSummaryOwnerMonthWise = (data) => {
  return api.post("bankDepositSummaryOwnerMonthWise", data);
};

const bankDepositSummaryFinancialYear = (data) => {
  return api.post("bankDepositSummaryFinancialYear", data);
};

const bulkUpdateMargSeriesWise = (data) => {
  return api.post("bulkUpdateMargSeriesWise", data);
};

const getCollectorByAdminSubmitted = (postData) =>
  api.post("getCollectorByAdminSubmitted", postData);
const generateGodownCashMemoForAllCollectorHistory = (postData) =>
  api.post("generateGodownCashMemoForAllCollectorHistory", postData);

const generateOpenPartyCashMemoForAllCollectorHistory = (postData) =>
  api.post("generateOpenPartyCashMemoForAllCollectorHistory", postData);

const getCollectorByAgentId = (data) => {
  return api.post("getCollectorByAgentId", data);
};

const changeSubmission = (data) => {
  return api.post("changeSubmission", data);
};
const pendingCollectionDetails = (postData) =>
  api.post("pendingCollectionDetails", postData);
const pendingCollectionAmountDetails = (postData) =>
  api.post("pendingCollectionAmountDetails", postData);

const updateMultiPleMargNumber = (postData) =>
  api.post("updateMultiPleMargNumber", postData);

const getFinancialDataById = (postData) =>
  api.post("getFinancialDataById", postData);

const updateMargNumber = (postData) => api.post("updateMargNumber", postData);

const getCollectorWiseYearlyAndMonthlyCollection = (postData) =>
  api.post("getCollectorWiseYearlyAndMonthlyCollection", postData);

const getFinanialYearCollectionAgencyWise = (postData) =>
  api.post("getFinanialYearCollectionAgencyWise", postData);

const getCollectionByCollectorsYearly = (postData) =>
  api.post("getCollectionByCollectorsYearly", postData);

const getUserListByDate = (postData) => api.post("getUserListByDate", postData);

const getCollectorWiseWeeklyCollectionOfMonth = (postData) =>
  api.post("getCollectorWiseWeeklyCollectionOfMonth", postData);

const getPartyNamebyAgencyId = () => api.post("getPartyNamebyAgencyId");

const getPartyWiseCollectionInMonth = (postData) =>
  api.post("getPartyWiseCollectionInMonth", postData);

const getUserOTP = (postData) => api.post("getUserOTP", postData);

const associateUserWithVendor = (postData) =>
  api.post("associateUserWithVendor", postData);

const getAssociatedUsersListForVendor = (params) => {
  let url = "getAssociatedUsersListForVendor";
  const queryString = new URLSearchParams(params);
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const getFinancialYearList = () => api.get("getFinancialYearList");
const crudVendorHoliday = (postData) => api.post("crudVendorHoliday", postData);

const getSifyPurchaseData = (data) =>
  api.post("sify/getSifyPurchaseData", data);

const getUserListByFinancialYear = (data) => {
  return api.post("getUserListByFinancialYear", data);
};

const getUserListMonthWise = (data) => {
  return api.post("getUserListMonthWise", data);
};
const insertAppServiceDetailsBySuperAdmin = (data) => {
  return api.post("common/insertAppServiceDetailsBySuperAdmin", data);
};

const getAppServiceList = (data) => {
  return api.post("common/getAppServiceList", data);
};
const deleteAppServiceDetailsBySuperAdmin = (data) => {
  return api.post("common/deleteAppServiceDetailsBySuperAdmin", data);
};

const updateAppServiceDetailsBySuperAdmin = (data) => {
  return api.post("common/updateAppServiceDetailsBySuperAdmin", data);
};
const getAllVendorsAndAgency = () => {
  return api.get("/getAllVendorsAndAgency");
};
const getNotificationType = () => {
  return api.post("/getNotificationType");
};

const getPermittedCity = () => {
  return api.get("/getPermittedCity");
};

const MstService = {
  agentDetails,
  uploadFile,
  getAgentDetails,
  getCollector,
  getUploadedData,
  checkUser,
  makeCollector,
  getCountries,
  getStates,
  getCity,
  getCollectorCodes,
  saveMapping,
  addCollectorCode,
  getCollectionType,
  collectionReport,
  getMapping,
  removeCollectorCode,
  generateReport,
  getPaymentType,
  getSourceType,
  getFeatureType,
  getSubscriptionPlan,
  getBusinessType,
  getFeatureList,
  getFunctionalityList,
  getFeatureFunctionalityList,
  insertSourceName,
  insertFeatureType,
  insertSubscriptionPlan,
  insertBusinessType,
  getSubscriptionsData,
  insertFeatureFunction,
  insertFunctionality,
  getTypeAndSourceByFeatureID,
  insertSubscription,
  getPlanBusiness,
  getAllAgentsList,
  getPlanType,
  insertFeature,
  insertPlanBusiness,
  insertPlanPeriod,
  updateCollectionData,
  bodProcess,
  resetPinForAgency,
  userStatusChange,
  GetSubscriptionTrialList,
  GetAgencyDetailByID,
  UpdateAgency,
  checkCollectionDataExists,
  generateCashMemoForAllCollector,
  generateCashMemoForAllCollectorHistory,
  generateCashMemoForAllCollectorWeb,
  getRetailerInvoiceByLink,
  SignupOTPVerification,
  OTPVerficationSignUp,
  createRetailerPin,
  retailerMasterEntry,
  retailerUpdateMobileReq,
  getRetailerUpdtMobReq,
  updateRetailerMobileReq,
  getRLExportData,
  getDataFromUploadExcel,
  getBasketAndPermissions,
  addCustomBasket,
  mapBasketToUser,
  getAssignedRolesAndUser,
  updateAssignedRoleStatus,
  updateCustomBasket,
  collectionReportWithBalance,
  getUserBeatUplaodMapping,
  updateBeatMappingIndex,
  sendMobileNotification,
  checkCollectionDataExistsIndividuals,
  getCollectorByHeiarchy,
  BODProcessCollectorWise,
  getCollectorsAndOtherRoles,
  getHierarchyDashboardData,
  addHierarchyToUser,
  makeUserRetailer,
  syncBulkUploadBeatData,
  checkBulkUploadStatusIndividual,
  changeBeatUpload,
  getCollectionDataIndividualByDate,
  getAllUsersForAgency,
  crudAgencyHoliday,
  crudDepartment,
  crudExpense,
  crudVisitType,
  crudAgencyTerritory,
  crudCollectorDeptMapping,
  deleteCollectionDataIndividualByRefId,
  updateCollectionDataIndividualByRefId,
  getInactiveClientsData,
  uploadCrudExpense,
  uploadCrudVisitType,
  uploadAgencyTerritory,
  uploadCrudAgencyHoliday,
  addBankAccountDetails,
  getBankAccountDetails,
  deleteBankAccountDetails,
  updateBankAccountDetails,
  getDepartmentAndFeatures,
  getDepartmentAndRoles,
  getCollectorsAndDepartments,
  addHierarchyToDepartment,
  getDepartmentHierarchyDashboardData,
  deleteDepartmentHierarchy,
  setDefaultRoleToDepartment,
  getCollectorRoutesForBulkBeat,
  createCollectorRoutesForBulkBeat,
  getApproverInHeirarchy,
  addHierarchyToRoles,
  getTaskTypesForWorkflow,
  getAllWorkflowsByAgencyId,
  getWorkflowsDetailsById,
  getActiveWorkflowsByType,
  assignTypeToWorkflow,
  crudExpenseCategory,
  getTallyImportStatus,
  insertTallyImportStatus,
  tallyExport,
  generateOpenCollectionReport,
  updateMargSerailByCollector,
  generateGodwonCollectionReport,
  bulkUpdateMargSerailByCollector,
  getlargestMargNumber,
  getUrlByShortURL,
  getCollectorLocation,
  getCollectorTrack,
  getInvoiceByCollector,
  updateInvoicebByCollector,
  getFinancialListByAgencyId,
  getCompanyListByAgencyId,
  InsertMargSeries,
  checkIsGoDownBeatAssigned,
  getGodownMasterList,
  addGodownInMaster,
  getGodownCollectorList,
  godownMappingWithCollector,
  getGodownMapping,
  updateGodownMaster,
  sifyReports,
  getSifySaleUploadFileData,
  getBankDepositSummary,
  getbankDepositSummaryOwnerMonthWise,
  bankDepositSummaryFinancialYear,
  bulkUpdateMargSeriesWise,
  getCollectorByAdminSubmitted,
  generateGodownCashMemoForAllCollectorHistory,
  generateOpenPartyCashMemoForAllCollectorHistory,
  getCollectorByAgentId,
  changeSubmission,
  pendingCollectionDetails,
  pendingCollectionAmountDetails,
  getCollectorWiseYearlyAndMonthlyCollection,
  getFinanialYearCollectionAgencyWise,
  getFinancialDataById,
  updateMultiPleMargNumber,
  updateMargNumber,
  getCollectionByCollectorsYearly,
  getCollectorWiseWeeklyCollectionOfMonth,
  getPartyNamebyAgencyId,
  getPartyWiseCollectionInMonth,
  getUserListByDate,
  getUserOTP,
  associateUserWithVendor,
  getAssociatedUsersListForVendor,
  getFinancialYearList,
  crudVendorHoliday,
  getSifyPurchaseData,
  getUserListByFinancialYear,
  getUserListMonthWise,
  insertAppServiceDetailsBySuperAdmin,
  getAppServiceList,
  deleteAppServiceDetailsBySuperAdmin,
  updateAppServiceDetailsBySuperAdmin,
  getAllVendorsAndAgency,
  getNotificationType,
  getPermittedCity,
};

export default MstService;
