/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import "./DataTable.css";
export default function DataTable(props) {
  const { id, data, columns, pagination } = props;

  const options = {
    custom: true,
    page: props?.page,
    totalSize: data?.length,
    sizePerPageList: [
      {
        text: "8",
        value: 8,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data?.length,
      },
    ],
  };

  return (
    <PaginationProvider pagination={pagination || paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => (
        <div
          className="horizontal_table"
        >
          <BootstrapTable
            classes="border border-1"
            bootstrap4
            keyField={id}
            data={data}
            columns={columns}
            {...props}
            filter={filterFactory()}
            {...paginationTableProps}
          />

          {data?.length > 0 && (
            <div className="d-flex align-items-center gap-5 mt-3">
              <div className="d-flex">
                <div className="bottom_dropdown">
                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
                <div>
                  <PaginationTotalStandalone {...paginationProps} />{" "}
                </div>
              </div>
              <div className="">
                <div>
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </PaginationProvider>
  );
}
