/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import { toast } from "react-toastify";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

const VendorConfiguration = () => {
  const [AllVendors, setAllVendors] = useState([]);
  const [vendorsConfig, setAllVendorsConfig] = useState([]);
  const [vendorLob, setVendorLob] = useState([]);
  const [vendor_id, setSelectedVendor_id] = useState("");
  const [transaction_type, setTransactionType] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [amount, setAmount] = useState("");
  const [itemObj, setItemObj] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 });

  const getAllVendors = async (second) => {
    try {
      const result = await VendorService.getAllVendors();
      if (result?.data?.errorCode === 0) {
        setAllVendors(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const fetchLobByVendor = (second) => {
    const filteredLOB = AllVendors.filter(
      (item, index) => item.id === vendor_id
    )[0]?.lob;
    setVendorLob(filteredLOB);
  };

  const fetchAllVendorConfig = async () => {
    try {
      const result = await VendorService.getVendorConfig();
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setAllVendorsConfig(result.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmitConfig = async (second) => {
    try {
      const result = await VendorService.addVendorConfig(itemObj);
      if (result?.data?.errorCode === 0) {
        fetchAllVendorConfig();
        setEdit(false);
        setItemObj({});
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setShowModal((prev) => ({
          ...prev,
          state: false,
          type: "",
        }));
      } else if (result?.data?.errorCode === -409) {
        toast.warning(result?.data?.message);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleUpdateConfig = async (second) => {
    try {
      const result = await VendorService.updateConfigBySuperAdmin(itemObj);
      if (result?.data?.errorCode === 0) {
        fetchAllVendorConfig();
        setTransactionType(" ");
        setAmount(" ");
        setSelectedVendor_id(" ");
        setItemObj({});
        setShowModal((prev) => ({
          ...prev,
          state: false,
          type: "",
        }));
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: true,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = (data) => {
    setItemObj(data);
    setEdit(true);
    setShowModal((prev) => ({
      ...prev,
      state: true,
      type: "TRANSACTION_DETAIL",
    }));
  };
  const handleDelete = async (vendor_id) => {
    try {
      const result = await VendorService.deleteVendorConfigBySuperAdmin({
        vendor_id,
      });
      if (result?.data?.errorCode === 0) {
        getAllVendors();
        fetchAllVendorConfig();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: true,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getAllVendors();
    fetchAllVendorConfig();
  }, []);

  useEffect(() => {
    fetchLobByVendor();
  }, [vendor_id, itemObj]);
  return (
    <>
      <LayoutContainer
        title1="Vendor"
        title2="Vendor"
        title3={isMobile ? "Vendor Transaction" : "Vendor Transaction Details"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={isMobile ? "" : "FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={
                isMobile ? "Add Transaction" : "Add Transaction Details"
              }
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "TRANSACTION_DETAIL",
                }))
              }
            />
          </>
        }
      >
        <div className="px-3 py-2">
          <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th>Vendor Id</th>
                <th>Name</th>
                <th>Transaction Type</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vendorsConfig &&
                vendorsConfig.map((data) => (
                  <>
                    <tr>
                      <td>{data?.vendor_id}</td>
                      <td>{data?.vendor_name}</td>
                      <td> {data?.transaction_type}</td>
                      <td> {data?.amount}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleEdit(data)}
                        >
                          Edit
                        </button>
                        <button
                          className="ms-1 btn btn-sm btn-danger"
                          onClick={() => handleDelete(data?.vendor_id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>

        {showModal.state && (
          <FUSModal
            title={"Add Transaction Details"}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setItemObj({});
            }}
            centered
          >
            {showModal.type === "TRANSACTION_DETAIL" && (
              <div>
                <div className="row">
                  <div className="col-md-12 ps-0">
                    <div>
                      <span className="text-secondary">Select Vendor</span>
                      <select
                        className="form-select text_style mt-1"
                        aria-label="Default select example"
                        name="vendor_id"
                        value={itemObj?.vendor_id}
                        disabled={isEdit}
                        onChange={(e) =>
                          setItemObj((prev) => ({
                            ...prev,
                            vendor_id: e.target.value,
                          }))
                        }
                      >
                        <option selected>Select Vendor</option>
                        {AllVendors &&
                          AllVendors.map((vendor, i) => (
                            <>
                              <option value={vendor?.id} key={i}>
                                {vendor?.vendor_name}
                              </option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3 ps-0">
                    <div>
                      <span className="text-secondary">
                        Select Transaction Type
                      </span>
                      <select
                        className="form-select text_style mt-1"
                        aria-label="Default select example"
                        name="transaction_type"
                        value={itemObj?.transaction_type}
                        onChange={(e) =>
                          setItemObj((prev) => ({
                            ...prev,
                            transaction_type: e.target.value,
                          }))
                        }
                      >
                        <option selected>Select</option>
                        <option value="Per Transaction">
                          INR Per Transaction
                        </option>
                        <option value="Percentage">Percentage %</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3 pe-0">
                    {transaction_type === "perTransaction" ? (
                      <span className="text-secondary">Rupees</span>
                    ) : (
                      <span className="text-secondary">Value</span>
                    )}

                    <input
                      type="number"
                      className="form-control  mt-1 text_style"
                      placeholder={
                        transaction_type === "perTransaction" ? "Rs." : "value"
                      }
                      value={itemObj?.amount}
                      onChange={(e) =>
                        setItemObj((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-12  mt-4 px-0">
                    {isEdit ? (
                      <FUSButton
                        labelText={"Update"}
                        buttonType="primary"
                        className="form-control"
                        onClick={(e) => handleUpdateConfig()}
                      />
                    ) : (
                      <FUSButton
                        labelText={"Add"}
                        buttonType="primary"
                        className="form-control"
                        onClick={(e) => handleSubmitConfig()}
                        disabled={_.isNil(itemObj.vendor_id) || _.isNil(itemObj.transaction_type) ||_.isNil(itemObj.amount) }
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default VendorConfiguration;
