import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function Autocomplete(props) {
    //const [select , setSelect] = useState([]);
    const set = (value)=>{
        onUpdate(value);
        setSelected(index, value, keyType);
    }
    const { setSelected, options, index, keyType, value, onUpdate } = props;

   return (
    <Typeahead
      id="basic-example"
      onChange={set}
      options={options}
      placeholder="Choose collector"
      //selected={set}
    />
  );
};


