// validation.js
import _ from "lodash";

export const validateFields = (data) => {
  const validationErrors = {};

  if (_.isNull(data.category_id)) {
    validationErrors.category_id = "Please Choose Category";
  }
  if (data?.name === "") {
    validationErrors.name = "Please Enter Product Name";
  }

  return validationErrors;
};

export const categoryValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.category_name) || _.isEmpty(data.category_name)) {
    validationErrors.categoryName = "Please Enter Category Name";
  } else if (!_.isString(data.category_name)) {
    validationErrors.categoryName = "Category Name must be a string";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }
  return validationErrors;
};

export const itemValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.sku) || _.isEmpty(data.sku)) {
    validationErrors.sku = "Please Enter SKU Code";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }
  if (_.isNull(data.product_id) || _.isEmpty(data.product_id)) {
    validationErrors.product_id = "Please Select Item";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }

  if (_.isNull(data.qty) || _.isEmpty(data.qty)) {
    validationErrors.qty = "Please Fill Quantity";
  }

  if (_.isNull(data.description) || _.isEmpty(data.description)) {
    validationErrors.description = "Please Enter Description";
  }

  if (_.isNull(data.price) || _.isEmpty(data.price)) {
    validationErrors.price = "Please Enter Price";
  } else if (isNaN(Number(data.price))) {
    validationErrors.price = "Price must be a valid number";
  }

  return validationErrors;
};
export const productValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please select LOB";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }

  if (_.isNull(data.product_name) || _.isEmpty(data.product_name)) {
    validationErrors.product_name = "Please fill Product Name";
  }

  return validationErrors;
};
export const serviceValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.sku) || _.isEmpty(data.sku)) {
    validationErrors.sku = "Please Enter SKU Code";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }
  if (_.isNull(data.product_id) || _.isEmpty(data.product_id)) {
    validationErrors.product_id = "Please Select Item";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }

  if (_.isNull(data.description) || _.isEmpty(data.description)) {
    validationErrors.description = "Please Enter Description";
  }

  if (_.isNull(data.price) || _.isEmpty(data.price)) {
    validationErrors.price = "Please Enter Price";
  } else if (isNaN(Number(data.price))) {
    validationErrors.price = "Price must be a valid number";
  }

  return validationErrors;
};
export const checkfalsyValue = (obj) => {
  const errors = {};
  _.forOwn(obj, (value, key) => {
    if (_.isNil(value) || value === "") {
      errors[key] = `${_.startCase(key)} is required`;
    }
  });
  return errors;
};
