/* eslint-disable default-case */
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import Autocomplete from "../Autocomplete/Autocomplete";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { useMediaQuery } from "react-responsive";

function AdvanceMapping(props) {
  const [mapping, setMapping] = useState([]);
  const [collectorCodeList, setCollectorCodeList] = useState([]);
  const [collectorList, setCollectorList] = useState([]);
  const [collectionTypeList, setCollectionTypeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const setSelected = (index, value, key) => { };
  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })
  const cellEdit = {
    mode: "click",
    errorMessage: errorMessage,
    blurToSave: true,
  };
  /*  const handleDelete = useCallback((row) => {
        setMapping(oldValues => {
            return oldValues.filter((item) => item.collector_code_id !== row.collector_code_id)
          })
    },[]); */

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: mapping.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "collector_code",
      text: "Collector Code",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "Collector",
      sort: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <Autocomplete
          options={collectorList}
          setSelected={setSelected}
          index={rowIndex}
          keyType={"collector"}
          {...editorProps}
          value={value}
        />
      ),
      validator: (newValue, row, column) => {
        if (_.isEmpty(newValue)) {
          return {
            valid: false,
            message: "Please select collector",
          };
        }
        return true;
      },
      filter: textFilter(),
    },
    {
      dataField: "collection_type",
      text: "Type",
      sort: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <Autocomplete
          options={collectionTypeList}
          setSelected={setSelected}
          index={rowIndex}
          keyType={"collectionType"}
          {...editorProps}
          value={value}
        />
      ),
      validator: (newValue, row, column) => {
        if (_.isEmpty(newValue)) {
          return {
            valid: false,
            message: "Please select collection type",
          };
        }
        return true;
      },
      filter: textFilter(),
    },
  ];

  const handleTableChange = (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    setTimeout(() => {
      const result = data.map((row) => {
        if (row.collector_code_id === rowId) {
          const newRow = { ...row };

          switch (dataField) {
            case "name":
              newRow["name"] = newValue[0].name;
              newRow["collector_id"] = newValue[0].collector_user_id;
              break;
            case "collection_type":
              newRow["collection_type"] = newValue[0].collection_type;
              newRow["collection_type_id"] = newValue[0].id;
              break;
          }
          return newRow;
        }
        return row;
      });

      setMapping(result);
      setErrorMessage(null);
    }, 100);
  };

  const loadData = () => {
    MstService.getMapping()
      .then((response) => {
        setMapping(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getCollectorCodes()
      .then((response) => {
        setCollectorCodeList(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.getCollector()
      .then((response) => {
        var arr = response?.data?.data?.map((item) => ({
          ...item,
          label: item.name,
        }));
        setCollectorList(arr);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.getCollectionType()
      .then((response) => {
        var arr = response?.data?.data?.map((item) => ({
          ...item,
          label: item.collection_type,
        }));
        setCollectionTypeList(arr);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async () => {
    const collectorMappingList = mapping.filter(
      (item) => item.collection_type !== null && item.collector_id !== null
    );
    const response = await MstService.saveMapping({
      collectorCodeList: collectorMappingList,
    }).catch((error) => {
      requestAndErrorHandler.errorHandler(error);
    });
    if (response?.data?.errorCode === -102) {
      const makeCollectorResponse = await MstService.makeCollector(
        response?.data?.data?.id
      ).catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
      requestAndErrorHandler.responseHandler(
        makeCollectorResponse,
        "/advancemapping"
      );
    } else {
      requestAndErrorHandler.responseHandler(response, "/advancemapping");
    }
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={isMobile ? "Code Mapping" : "Collector Code Mapping"}
        right={
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"px-3"}
                buttonType="secondary"
                labelText={"Cancel"}
                onClick={() => loadData()}
              />

              <FUSButton
                className={"px-3"}
                buttonType="primary"
                labelText={isMobile ? "Save" : "Save Mapping"}
                onClick={() => handleSubmit()}
              />
            </div>
          </>
        }
      >
        <div className="px-3">
          <div className="mb-5">
            <BootstrapTable
              classes="border border-1"
              remote={{ cellEdit: true }}
              keyField="collector_code_id"
              data={mapping}
              columns={columns}
              cellEdit={cellEditFactory(cellEdit)}
              onTableChange={handleTableChange}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}

export default AdvanceMapping;
