import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import OfferService from "../../services/offer.service";
import { toast } from "react-toastify";
import filterFactory, {
  numberFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import TokenService from "../../services/token.service";
import VendorService from "../../services/vendor.services";
import _ from "lodash";
const OfferConfig = () => {
  const user_id = TokenService?.getUser()?.vendor?.vendor_user_id;
  const [screenName, setScreenName] = useState(null);
  const [sectionDetails, setSectionDetails] = useState(null);
  const [sectionConfigurationData, setSectionConfigurationData] = useState([]);
  const [isOfferCard, setIsOfferCard] = useState(null);
  const [slotPosition, setSlotPosition] = useState(null);
  const [editSlotPosition, setEditSlotPosition] = useState(null);
  const [discountId, setDiscountId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [maxItem, setMaxItems] = useState(null);
  const [vendorLocationId, setVendorLocationId] = useState(null);
  const [lobData, SetLobData] = useState(null);
  const [lobID, setLobID] = useState(null);
  const [productDataByLob, setProductDataByLOb] = useState([]);
  const [editProductDataByLob, setEditProductDataByLOb] = useState(null);
  const [productID, setproductID] = useState(null);
  const [uIScreen, setUIScreen] = useState(null);
  const [isBtnDisalbed, SetisBtnDisalbed] = useState(true);
  const [discountName, setDiscountName] = useState([]);
  const [editDiscountName, setEditDiscountName] = useState(null);
  const [forEditSectionType, setForEditSectionType] = useState(null);
  const [itemNameForEdit, setItemNameForEdit] = useState(null);
  const [recID, setRecID] = useState(null);
  const [editSectionId, setEditSectionId] = useState(null);
  const [productNameForEdit, setProductNameForEdit] = useState(null);
  const [discountNameForEdit, setDiscountNameForEdit] = useState(null);
  const [navigationScreen, setNavigatonScreen] = useState(null);

  const [availablePositions, setAvailablePositions] = useState({
    data: [],
    errorMessage: null,
  });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });
  const handleUpload = (row) => {
    // Find the file input element by its ID and trigger a click event
    document.getElementById(`fileInput-${row.rec_id}`).click();
  };
  let offerCardValueHold = [];

  // Iterate over each object in sectionConfigurationData
  for (let i = 0; i < sectionConfigurationData.length; i++) {
    // Check if the section_type of the current object matches "offer_card"
    if (sectionConfigurationData[i].section_type === "offer_card") {
      // If it matches, push the object into offerCardValueHold
      offerCardValueHold.push(sectionConfigurationData[i]);
    }
  }

  const handleFileChange = async (e, row) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "OFFER_IMAGE");
    formData.append("userId", user_id);
    formData.append("collection_data_id", row?.rec_id);
    formData.append("filename", file?.name);
    formData.append("mimetype", file?.type);

    try {
      const response = await VendorService.uploadImageSignup(formData);
      if (response?.data?.errorCode === 0) {
        fetchSectionConfiguration();
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const columns = [
    {
      dataField: "display_sec_text",
      text: "Display Section Text",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "Item Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "discount_name",
      text: "Discount Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "slot_position",
      text: "Position",
      sort: true,
    },

    {
      dataField: "section_type",
      text: "Section Type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "location_name",
      text: "Location Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "image_url",
      text: "Image",
      formatter: (cellContent, row) =>
        row.section_type === "item" ? (
          row.image_name === null ? null : (
            <>
              <img
                src={row.image_name}
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <p className="ms-1">
                <a
                  href="#"
                  class="link-danger"
                  onClick={() => handleImageDelete(row)}
                >
                  Delete
                </a>
              </p>
            </>
          )
        ) : row.image_url === null ? (
          <>
            <input
              type="file"
              id={`fileInput-${row?.rec_id}`}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, row)}
            />
            <div className="col-md-auto">
              <FUSButton
                iconSrc="fa6"
                iconName="FaImage"
                iconSize={14}
                iconColor={"#7966e4"}
                className={"image_upload_style"}
                onClick={() => {
                  handleUpload(row);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <img
              src={row.image_url}
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
            <p className="ms-1">
              <a
                href="#"
                class="link-danger"
                onClick={() => handleImageDelete(row)}
              >
                Delete
              </a>
            </p>
          </>
        ),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row.rec_id)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const handleEditClick = async (row) => {
    setRecID(row.rec_id);
    setForEditSectionType(row.section_type);
    setSlotPosition(row.slot_position);
    setMaxItems(row.max_items);
    setEditSectionId(row.section_id);
    setProductNameForEdit(row.name);
    setDiscountNameForEdit(row.discount_name);
    // Open modal after setting data
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "OFFERCONFIGEDIT",
    }));
    if (row.section_type === "item") {
      try {
        const result = await OfferService.getProductDetailsByLobIDandSectionID({
          lob_id: row.lob_id,
          vendor_id: user_id,
          section_id: row.section_id,
        });
        if (result?.data?.errorCode === 0) {
          setEditProductDataByLOb(result.data.data);
        } else {
          setEditProductDataByLOb([]);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
    if (row.section_type === "offer_card") {
      try {
        const result = await OfferService.getDiscountNameBySectionId({
          vendor_user_id: user_id,
          section_id: row.section_id,
        });
        if (result?.data?.errorCode === 0) {
          setDiscountName(result?.data?.data);
        } else {
          setDiscountName([]);
        }
        fetchSectionConfiguration();
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: sectionConfigurationData.length,
      },
    ],
  };

  const fetchUIConfig = async () => {
    try {
      const res = await OfferService.getUIConfig();
      if (res?.data?.errorCode === 0) {
        setScreenName(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    fetchUIConfig();
  }, []);

  const fetchSectionConfiguration = () => {
    OfferService.getSectionConfiguration()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setSectionConfigurationData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    fetchSectionConfiguration();
    handleLOBList();
  }, []);

  const getAllowedPositionForDropDown = async (section_id) => {
    await OfferService.getAllowedPositionForDropDown(section_id)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setAvailablePositions({
            data: res?.data?.data,
            errorMessage: null,
          });
          SetisBtnDisalbed(false);
        } else {
          setAvailablePositions({
            data: [],
            errorMessage: res?.data?.message,
          });
          SetisBtnDisalbed(true);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };
  const handleDelete = async (id) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await OfferService.deleteSectionConfiguration({
          rec_id: id,
        });
        if (result?.data?.errorCode === 0) {
          fetchSectionConfiguration();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handleSectionDatails = async (e) => {
    try {
      const result = await OfferService.getVendorSectionDetailsById({
        ui_id: screenName[0]?.id,
      });
      if (result?.data?.errorCode === 0) {
        setSectionDetails(result.data.data);
        setUIScreen(e.target.value);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleLOBList = async (e) => {
    try {
      const result = await OfferService.getLobNamebyVendorID({
        vendor_id: user_id,
      });
      if (result?.data?.errorCode === 0) {
        SetLobData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getProductDetailsByLobIDandSectionID = async (e) => {
    try {
      const result = await OfferService.getProductDetailsByLobIDandSectionID({
        lob_id: e.target.value,
        vendor_id: user_id,
        section_id: sectionId,
      });
      if (result?.data?.errorCode === 0) {
        if (isOfferCard === "item_card") {
          setProductDataByLOb(result.data.data);
        } else {
          const filterDiscountedData = result?.data?.data?.filter(
            (item) => !_.isNil(item?.discount_value)
          );
          setProductDataByLOb(filterDiscountedData);
        }
      } else {
        setProductDataByLOb([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handlIsOfferCardChange = async (e) => {
    const itemValue = JSON.parse(e?.target?.value);

    if (itemValue?.section_type === "item_card") {
      getAllowedPositionForDropDown(itemValue?.section_id);
    } else {
      setAvailablePositions({
        data: [],
        errorMessage: null,
      });
    }
    if (itemValue.section_type === "offer_card") {
      try {
        const result = await OfferService.getDiscountNameBySectionId({
          vendor_user_id: user_id,
          section_id: itemValue?.section_id,
        });
        if (result?.data?.errorCode === 0) {
          setDiscountName(result?.data?.data);
        } else {
          setDiscountName([]);
        }
        fetchSectionConfiguration();
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
    if (itemValue.section_type === "item") {
      getProductDetailsByLobIDandSectionID(itemValue?.lob_id);
    }
    setIsOfferCard(itemValue.section_type);
    setSectionId(itemValue.section_id);
    setLobID(itemValue?.lob_id);
    setDiscountId(null);
    const selectedSectionId = itemValue.section_id;
    // Find the selected section_data object
    const selectedSectionData = sectionDetails.section_data.find(
      (item) => item.section_id === parseInt(selectedSectionId)
    );
    // Extract max_items from the selected section_data object and set it to maxItems state
    if (selectedSectionData) {
      setMaxItems(selectedSectionData.max_items);
    }
  };

  const insertSectionVendorData = async () => {
    try {
      await OfferService.insertSectionVendorData({
        section_id: sectionId,
        discount_id: discountId,
        slot_position: slotPosition,
        vendor_location_id: vendorLocationId,
        item_id: productID,
        lob_id: lobID,
        vendor_user_id: user_id,
        navigation_screen: navigationScreen,
      }).then((res) => {
        if (res?.data?.errorCode === 201) {
          toast.success(res?.data?.message, {
            autoClose: 3000,
            position: "bottom-left",
          });
          fetchSectionConfiguration();
        } else {
          toast.error(res?.data?.message, {
            autoClose: 3000,
            position: "bottom-left",
          });
        }
      });
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleConfirm = () => {
    if (isOfferCard === "offer_card") {
      if (offerCardValueHold.length >= maxItem) {
        alert("offer card card limit exceeded");
        setShowModal((prev) => ({ ...prev, state: false }));
      } else {
        const confirmYes = window.confirm("Do you want to submit");
        if (confirmYes) {
          insertSectionVendorData();
          fetchSectionConfiguration();
          setScreenName(null);
          setIsOfferCard(null);
          setUIScreen(null);
          setDiscountId(null);
          setEditSectionId(null);
          setEditSlotPosition(null);
          setShowModal((prev) => ({ ...prev, state: false }));
          const successToast = toast.success("Submit successfully", {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      }
    } else {
      const confirmYes = window.confirm("Do you want to submit");
      if (confirmYes) {
        insertSectionVendorData();
        fetchSectionConfiguration();
        setScreenName(null);
        setIsOfferCard(null);
        setUIScreen(null);
        setDiscountId(null);
        setShowModal((prev) => ({ ...prev, state: false }));
        const successToast = toast.success("Submit successfully", {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    }
  };

  const checkSlotIsExistsData = async () => {
    try {
      await OfferService.getSectionPositonIsExist({
        section_id: sectionId,
        slot_position: slotPosition,
        vendor_location_id: vendorLocationId,
      }).then((response) => {
        if (response?.data?.errorCode === 0) {
          SetisBtnDisalbed(true);
          alert(
            "This slot already used, Please select other slot for this location!!"
          );
        } else {
          SetisBtnDisalbed(false);
        }
      });
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleUpdateOfferSlot = async () => {
    const flag = "offer_card";
    const recId = recID;
    try {
      const result = await OfferService.updateOfferCardAndItemSlot({
        editDiscountName: editDiscountName,
        editSlotPosition: editSlotPosition,
        itemNameForEdit: itemNameForEdit,
        recId: recId,
        flag: flag,
      });
      if (result?.data?.errorCode === 0) {
        fetchSectionConfiguration();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setEditSlotPosition(null);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleUpdateItemSlot = async () => {
    const flag = "item";
    try {
      const result = await OfferService.updateOfferCardAndItemSlot({
        editDiscountName: editDiscountName,
        editSlotPosition: editSlotPosition,
        itemNameForEdit: itemNameForEdit,
        recId: recID,
        flag: flag,
      });
      if (result?.data?.errorCode === 0) {
        fetchSectionConfiguration();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setEditSlotPosition(null);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleImageDelete = async (row) => {
    try {
      const response = await OfferService.deleteImage({
        image_name: row?.image_url,
        tag: "ITEM_IMAGE",
      });
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        fetchSectionConfiguration();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    if (slotPosition != null) {
      checkSlotIsExistsData();
    }
  }, [slotPosition]);
  const checkIsPositoionExists = async () => {
    try {
      await OfferService.checkIsPositoionExists({
        sectionId: editSectionId,
        postion: editSlotPosition,
        vendor_user_id: user_id,
      }).then((response) => {
        if (response?.data?.errorCode === 0) {
          // SetisBtnDisalbed(true);
          alert(
            "This position already used, Please select other position for configration!!"
          );
          setEditSlotPosition(null);
        } else {
          // SetisBtnDisalbed(false);
        }
      });
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    if (editSlotPosition != null) {
      checkIsPositoionExists();
    }
  }, [editSlotPosition]);
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Offer Configration"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add New Section"}
              onClick={() => {
                fetchUIConfig();
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "OFFERCONFIG",
                }));
              }}
            />
          </>
        }
      >
        <div className="px-3 py-3">
          <div className="col-md-12">
            <BootstrapTable
              classes="border border-1"
              keyField="fcm_key"
              data={sectionConfigurationData}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={showModal.type === "OFFERCONFIG" ? "Offer Configration" : ""}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setAvailablePositions({
                data: [],
                errorMessage: null,
              });
              setScreenName(null);
              setIsOfferCard(null);
              setUIScreen(null);
              setDiscountId(null);
              //setLobID(null);
              setShowModal((prev) => ({ ...prev, state: false }));
            }}
            centered
          >
            {showModal.type === "OFFERCONFIG" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select UI Screen</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={handleSectionDatails}
                      >
                        <option value={""}>Select</option>
                        {screenName &&
                          screenName.map((item, index) => (
                            <option key={index} value={item.display_name}>
                              {item.display_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select Your Location</div>
                    <div className="form-group">
                      <select
                        disabled={uIScreen == null}
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={(e) => setVendorLocationId(e.target.value)}
                      >
                        <option value={""}>Select</option>
                        {sectionDetails?.location_data &&
                          sectionDetails?.location_data.map((item, index) => (
                            <option key={index} value={item.location_id}>
                              {item.location_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select Section</div>
                    <div className="form-group">
                      <select
                        disabled={uIScreen == null}
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={handlIsOfferCardChange}
                      >
                        <option value={""}>Select</option>
                        {sectionDetails?.section_data &&
                          sectionDetails?.section_data.map((item, index) => (
                            <option key={index} value={JSON.stringify(item)}>
                              {item.section_type}
                              {" ("}
                              {item.name}
                              {")"}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select Position</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={(e) => setSlotPosition(e.target.value)}
                      >
                        <option value={""}>Select</option>
                        {isOfferCard !== "item_card"
                          ? maxItem?.length > 0 &&
                            Array.apply(null, Array(parseInt(maxItem))).map(
                              function (y, i) {
                                return <option value={i + 1}>{i + 1}</option>;
                              }
                            )
                          : availablePositions?.data?.length > 0 &&
                            availablePositions?.data?.map((item) => (
                              <option value={item?.positions}>
                                {item?.positions}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  {/* if select offer card then show below feild  section_type*/}
                  {isOfferCard === "offer_card" ? (
                    <>
                      <div className="col-md-6 ps-0 ms-0">
                        <div>Select Your Active Discount</div>
                        <div className="form-group">
                          <select
                            name="filter"
                            id="filter"
                            className="form-select py-2 text_style"
                            onChange={(e) => {
                              const selectedDiscountId = e.target.value;
                              setDiscountId(selectedDiscountId);

                              // Find the selected discount object
                              const selectedDiscount = discountName.find(
                                (item) =>
                                  item.disount_id ===
                                  parseInt(selectedDiscountId)
                              );

                              // If the selected discount exists, update lobID state
                              if (selectedDiscount) {
                                setLobID(selectedDiscount.lob_id);
                              }
                            }}
                          >
                            <option value={""}>Select</option>
                            {discountName &&
                              discountName.map((item, index) => (
                                <option key={index} value={item.disount_id}>
                                  {item.discount_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {isOfferCard !== "item" && isOfferCard !== "offer_card" ? (
                    <div className="col-md-6 ps-0 ms-0">
                      <div>Select LOB</div>
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2 text_style"
                          onChange={(e) => {
                            setLobID(e.target.value);
                            if (isOfferCard !== "ride_banner") {
                              getProductDetailsByLobIDandSectionID(e);
                            }
                          }}
                        >
                          <option value={""}>Select</option>
                          {lobData &&
                            lobData.map((item, index) => (
                              <option key={index} value={item.lob_id}>
                                {item.lob_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {isOfferCard !== "offer_card" &&
                  isOfferCard !== "ride_banner" ? (
                    <div className="col-md-6 ps-0 ms-0">
                      <div>Select Product</div>
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2 text_style"
                          onChange={(e) => setproductID(e.target.value)}
                        >
                          <option value={""}>Select</option>
                          {productDataByLob &&
                            productDataByLob.map((item, index) => (
                              <option key={index} value={item.item_id}>
                                {item.item_name}
                                {"  "}
                                {item.attr_values}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {isOfferCard === "ride_banner" ? (
                    <div className="col-md-6 ps-0 ms-0">
                      <div>Select Navigation Screen</div>
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2 text_style"
                          onChange={(e) => setNavigatonScreen(e.target.value)}
                        >
                          <option value={-1}>Select Screen</option>
                          <option value={"bookRide"}>Book A Ride</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>
                {!_.isNil(availablePositions?.errorMessage) &&
                  _.isEmpty(availablePositions?.data) && (
                    <div className="mt-2">
                      <p className="text-danger fs-5">
                        {availablePositions?.errorMessage}
                      </p>
                    </div>
                  )}

                <div className="col-3 mt-3 px-0">
                  <FUSButton
                    disabled={isBtnDisalbed}
                    labelText={"Submit"}
                    buttonType="primary"
                    className={"form-control py-2"}
                    onClick={() => handleConfirm()}
                  />
                </div>
              </div>
            )}
          </FUSModal>
        )}

        {showEditModal.state && (
          <FUSModal
            title={
              showEditModal.type === "OFFERCONFIGEDIT"
                ? "Edit Configration"
                : ""
            }
            showModal={showEditModal.state}
            size={"md"}
            handleClose={() => {
              setDiscountId(null);
              setEditSlotPosition(null);
              setItemNameForEdit(null);
              setDiscountName(null);
              setEditDiscountName(null);
              setRecID(null);
              setEditSectionId(null);
              setProductNameForEdit(null);
              setDiscountNameForEdit(null);
              setEditShowModal((prev) => ({ ...prev, state: false }));
            }}
            centered
          >
            {showEditModal.type === "OFFERCONFIGEDIT" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select Position</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={(e) => setEditSlotPosition(e.target.value)}
                      >
                        <option value={slotPosition} selected>
                          {slotPosition}
                        </option>
                        {maxItem?.length > 0 &&
                          Array.apply(null, Array(parseInt(maxItem))).map(
                            (y, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                  {forEditSectionType === "item" ||
                  forEditSectionType === "banner" ? (
                    <>
                      <div className="col-md-6 ps-0 ms-0">
                        <div>Select Product</div>
                        <div className="form-group">
                          <select
                            name="filter"
                            id="filter"
                            className="form-select py-2 text_style"
                            onChange={(e) => setItemNameForEdit(e.target.value)}
                          >
                            <option value={productNameForEdit} selected>
                              {productNameForEdit}
                            </option>
                            {editProductDataByLob &&
                              editProductDataByLob.map((item, index) => (
                                <option key={index} value={item.item_id}>
                                  {item.item_name}
                                  {"  "}
                                  {item.attr_values}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {forEditSectionType === "offer_card" ? (
                    <>
                      <div className="col-md-6 ps-0 ms-0">
                        <div>Select Your Active Discount</div>
                        <div className="form-group">
                          <select
                            name="filter"
                            id="filter"
                            className="form-select py-2 text_style"
                            onChange={(e) => {
                              setEditDiscountName(e.target.value);
                            }}
                          >
                            <option value={discountNameForEdit} selected>
                              {discountNameForEdit}
                            </option>
                            {discountName &&
                              discountName.map((item, index) => (
                                <option key={index} value={item.disount_id}>
                                  {item.discount_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {editSlotPosition && (editDiscountName || itemNameForEdit) ? (
                  <>
                    <div className="col-3 mt-3 px-0">
                      <FUSButton
                        disabled={isBtnDisalbed}
                        labelText={"Update"}
                        buttonType="primary"
                        className={"form-control py-2"}
                        onClick={() =>
                          editSlotPosition && editDiscountName
                            ? handleUpdateOfferSlot()
                            : handleUpdateItemSlot()
                        }
                      ></FUSButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-3 mt-3 px-0">
                      <FUSButton
                        disabled
                        labelText={"Update"}
                        buttonType="primary"
                        className={"form-control py-2"}
                      ></FUSButton>
                    </div>
                  </>
                )}
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default OfferConfig;
