import "../BODProcess/BODProcess.css";
import { now } from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
export default function ShowBeatDetail({ route }) {
  const [counterOn, setCounnterOn] = useState(false);

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showBeatDetailData, setShowBeatDetailData] = useState([]);
  const [load, setLoad] = useState(true);
  const [updateData, setUpdateData] = useState({
    ref_no: "",
    party_name: "",
    opening: "",
    pending: "",
  });

  const { state } = useLocation();
  const { collector_id, date } = state;
  const navigate = useNavigate();

  useEffect(() => {
    MstService.getCollectionDataIndividualByDate({
      collector_id: collector_id,
      date: date,
    })
      .then((response) => setShowBeatDetailData(response.data.data))
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [load]);

  const handleDelete = (ref_no) => {
    let text = window.confirm("Are you sure");
    if (text) {
      MstService.deleteCollectionDataIndividualByRefId({ ref_no: ref_no })
        .then((response) => setLoad((value) => !value))
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {
      setShowModal((prev) => ({ ...prev, state: false, type: "EDIT" }));
    }
  };

  const handleEditClick = (ref_no, party_name, opening, pending) => {
    setShowModal((prev) => ({ ...prev, state: true, type: "EDIT" }));
    const obj = {
      ref_no: ref_no,
      old_ref_no: ref_no,
      party_name: party_name,
      opening: opening,
      pending: pending,
    };
    setUpdateData(obj);
  };

  const updateBeatData = () => {
    let text = window.confirm("Are you sure");
    if (text) {
      MstService.updateCollectionDataIndividualByRefId(updateData)
        .then((response) => {
          setLoad((value) => !value);
          setShowModal((prev) => ({ ...prev, state: false, type: "EDIT" }));
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {
      setShowModal((prev) => ({ ...prev, state: false, type: "EDIT" }));
    }
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <LayoutContainer
        title1={"Beat"}
        title2={"Beat Upload"}
        title3={"Bulk/Incremental"}
        right={
          <div>
            <FUSButton
              iconSrc="md"
              iconName="MdArrowBack"
              iconSize={18}
              buttonType="primary"
              labelText={"Go Back"}
              onClick={() =>
                navigate("/onetimeupload", {
                  state: { collector_id: collector_id },
                })
              }
            />
          </div>
        }
      >
        <div className="py-2">
          <div className="border-bottom pb-2 px-3">
            <div className="d-flex justify-content-between mb-1">
              <div className="record_button px-2 py-2 d-flex justify-content-between align-items-center gap-3 px-3">
                <span className="label_text_style total_text_color">
                  No of Records Uploaded
                </span>
                <span>
                  <ScrollTrigger onEnter={() => setCounnterOn(true)} onExit={() => setCounnterOn(false)}>
                    {counterOn && <CountUp className="label_text_style total_text_color" start={0} end={showBeatDetailData.length} delay={0.5} duration={3} />}

                  </ScrollTrigger>
                </span>
              </div>
              <div>
                <ReactHTMLTableToExcel
                  id="btnExport"
                  className="download-table-xls-button btn lightGreenColor block xl_button_style"
                  table="table-to-xls-collector-beat-detail"
                  filename={`collectionBeatReportHistory_${now().toLocaleString()}`}
                  sheet="tablexls"
                  buttonText={
                    <FUSIcon
                      iconName="TbFileXFilled"
                      iconSrc={"tb"}
                      size={20}
                      color="green"
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-2 pt-1 px-3">
            <div className="react-bootstrap">
              <table
                id="table-to-xls-collector-beat-detail"
                border="1"
                className="table table-bordered mb-5"
              >
                <thead>
                  <tr>
                    <th>Party Name</th>
                    <th>Date</th>
                    <th>Ref_no</th>

                    <th>Opening</th>
                    <th>Pending</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {showBeatDetailData.length > 0 &&
                    showBeatDetailData?.map((item, index) => (
                      <tr>
                        <td>{item?.party_name}</td>
                        <td>{item?.date}</td>
                        <td>{item?.ref_no}</td>

                        <td>{item?.opening}</td>
                        <td>{item?.pending}</td>
                        <td>
                          <div className="d-flex gap-3">
                            <div>
                              <FUSButton
                                iconSrc="md"
                                iconName="MdOutlineModeEdit"
                                iconSize={15}
                                buttonType="lightgray"
                                className={"action_button_style"}
                                onClick={() =>
                                  handleEditClick(
                                    item?.ref_no,
                                    item?.party_name,
                                    item?.opening,
                                    item?.pending,
                                    index
                                  )
                                }
                              />
                            </div>
                            <div>
                              <FUSButton
                                className={"action_button_style"}
                                iconSrc="md"
                                iconName="MdDeleteForever"
                                iconSize={15}
                                buttonType="lightdanger"
                                onClick={() => handleDelete(item?.ref_no)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {showModal.state && (
            <FUSModal
              title="Update Beat Data"
              showModal={showModal.state}
              handleClose={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: false,
                  type: "EDIT",
                }))
              }
              centered
              size={"md"}
            >
              {showModal.type === "EDIT" && (
                <Form>
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <Row>
                      <Col md={12} className="px-0 mt-2">
                        <div>
                          <span className="label_style">Party Name</span>
                          <Form.Control
                            className="py-2 my-1 text_style"
                            type="text"
                            name="party_name"
                            onChange={handleOnchange}
                            value={updateData.party_name}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="px-0 mt-2">
                        <div>
                          <span className="label_style">Ref No</span>
                          <Form.Control
                            className="py-2 my-1 text_style"
                            type="text"
                            name="ref_no"
                            onChange={handleOnchange}
                            value={updateData.ref_no}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="px-0 mt-2">
                        <div>
                          <span className="label_style">Opening</span>
                          <Form.Control
                            className="py-2 my-1 text_style"
                            type="text"
                            name="opening"
                            onChange={handleOnchange}
                            value={updateData.opening}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="px-0 mt-2">
                        <div>
                          <span className="label_style">Pending</span>
                          <Form.Control
                            className="py-2 my-1 text_style"
                            type="text"
                            name="pending"
                            onChange={handleOnchange}
                            value={updateData.pending}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end gap-3 mt-3">
                      {updateData.party_name &&
                        updateData.party_name !== "" &&
                        updateData.party_name !== null &&
                        updateData.party_name !== undefined ? (
                        <FUSButton
                          className={"form-control"}
                          buttonType="primary"
                          onClick={updateBeatData}
                          labelText={"Save Changes"}
                        />
                      ) : null}
                    </div>
                  </Form.Group>
                </Form>
              )}
            </FUSModal>
          )}
        </div>
      </LayoutContainer>
    </>
  );
}
