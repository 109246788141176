import React, { useContext, useEffect, useState } from "react";
import VendorService from "../../services/vendor.services";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { StoreContext } from "../../store/StoreContext";
import TokenService from "../../services/token.service";

const OrderTimeConfig = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [deliverySlots, setDeliverySlots] = useState([]);
  const [reload, setReload] = useState(true);
  const [slotData, setSlotData] = useState([]);
  const [vendorTime, setVendorTimes] = useState([]);
  const [times, setTimes] = useState({
    start_date: "",
    end_date: "",
    slot_id: "",
  });
  const token = TokenService.getUser();
  const vendorUserId = token?.vendor?.vendor_user_id;

  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const getSlotsAndTime = async () => {
    await VendorService.getAllSystemSlotsAndTime()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setSlotData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const getVendorLocation = async (vendorUserId) => {
    await VendorService.getVendorLocation({ vendor_user_id: vendorUserId })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setLocationData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const getVendorSlotTimings = async (locationId) => {
    await VendorService.getVendorOrderTimeConfigonLocation({
      location_id: locationId,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorTimes(res?.data?.data?.order_range);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  useEffect(() => {
    getSlotsAndTime();
    getVendorLocation(vendorUserId);
  }, [reload]);

  useEffect(() => {
    if (selectedLocation) {
      getVendorSlotTimings(selectedLocation);
    }
  }, [selectedLocation, reload]);

  const constructDateFromTime = (time) => {
    const currentDate = new Date();
    const [hours, minutes] = time?.split(":");
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes
    );
  };

  const generateStartTimeOptions = (startTime, endTime) => {
    const start = constructDateFromTime(startTime);
    const end = constructDateFromTime(endTime);

    const timeOptions = [];

    while (start <= end) {
      timeOptions.push({
        label: start.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),

        value: `${start.getHours()}:00:00`,
      });
      start.setMinutes(start.getMinutes() + 60);
    }

    return timeOptions;
  };

  const generateEndTimeOptions = (end, start, item) => {
    const endDate = constructDateFromTime(end);
    const startDate = constructDateFromTime(start);
    const timeOptions = [];
    while (endDate >= startDate) {
      timeOptions.push({
        label: endDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        value: `${endDate.getHours()}:00:00`,
      });
      endDate.setMinutes(endDate.getMinutes() - 60);
    }
    return timeOptions;
  };

  function handleDateSelection(value, id, flag) {
    if (flag === "start_date") {
      setTimes((prev) => ({
        ...prev,
        start_date: value,
        slot_id: id,
      }));
    } else {
      setTimes((prev) => ({
        ...prev,
        end_date: value,
      }));
    }
  }

  const handleSelectLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleSelect = (value) => {
    if (value !== -1) {
      const filteredSlots = slotData.filter((slot) => value === slot.slot_id);
      setDeliverySlots(filteredSlots);

      setTimes({
        slot_id: filteredSlots[0]?.slot_id,
        start_date: filteredSlots[0]?.start_time,
        end_date: filteredSlots[0]?.end_time,
      });
    } else {
      setDeliverySlots([]);
    }
  };

  const handleSaveDeliverySlot = async () => {
    const requestData = {
      slot_id: times.slot_id,
      start_time: times.start_date,
      end_time: times.end_date,
      vendor_location_id: selectedLocation,
    };

    await VendorService.insertOrderTimeConfigByVendor(requestData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal({ state: false });
          setReload((reload) => !reload);

          const successToast = toast.success(`Slot Added Successfully`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const updateSlotStatus = (item) => {
    VendorService.updateOrderTimeConfigStatus({ id: item?.id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload((reload) => !reload);
          const action = item?.is_active ? "Activate" : "Deactivate";
          displaySuccessToast(`${action} Successfully`);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const displaySuccessToast = (message) => {
    const successToast = toast.success(message, {
      position: "bottom-left",
      autoClose: false,
    });

    setTimeout(() => {
      toast.dismiss(successToast);
    }, 2000);
  };
  const updatedeliverySlots = async (value, slots, item) => {
    await VendorService.manageDeliverySlots({
      id: item?.id,
      slot_id: slots.slot_id,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload((reload) => !reload);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Delivery Slot Config"
        right={
          <>
            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
              ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                .VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_ADD
            ) ? (
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                buttonType="primary"
                labelText={"Add Slots"}
                onClick={() =>
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "ADDSLOTS",
                  }))
                }
              />
            ) : null}
          </>
        }
      >
        <div className="px-3 py-3">
          <div className="col-md-3 mt-2">
            <select
              className="form-select text_style mt-1 mb-3"
              onChange={(e) => handleSelectLocation(e.target.value)}
            >
              <option value={-1}>Select Location</option>
              {locationData?.length > 0 &&
                locationData?.map((item) => (
                  <option key={item.id} value={item?.id}>
                    {item?.location_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-12">
            <table className="table table-bordered border border-1">
              <thead>
                <th>Order Slot</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Delivery Slots</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {vendorTime?.length > 0 ? (
                  vendorTime?.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.slot_name}</td>
                      <td>
                        {constructDateFromTime(
                          item?.order_start_time
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>
                        {constructDateFromTime(
                          item?.order_end_time
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>
                        {item?.delivery_slots.map((slots) => (
                          <div className="form-check ms-2" key={slots?.slot_id}>
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={slots?.is_active}
                                onChange={(e) =>
                                  updatedeliverySlots(
                                    e.target.checked,
                                    slots,
                                    item
                                  )
                                }
                              />
                              {slots?.slot_name}
                            </label>
                          </div>
                        ))}
                      </td>
                      <td>
                        <p
                          className={` mt-2 ${
                            item?.is_active ? "text-success " : "text-danger"
                          } `}
                        >
                          {item?.is_active ? "Active" : "Inactive"}
                        </p>
                      </td>
                      <td>
                        {isFunctionalityEnabled(
                          ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
                          ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                            .VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_DEACTIVE
                        ) ? (
                          <button
                            className={`btn btn-sm text-white px-3 fs-6
                            ${item?.is_active ? "btn-danger" : "btn-success"}`}
                            onClick={() => updateSlotStatus(item)}
                          >
                            {!item?.is_active ? "Activate" : "Deactivate"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div className="py-5 my-5">
                        <FUSEmptyList title="Data not found" />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {showModal.state && (
          <FUSModal
            title={showModal.type === "ADDSLOTS" ? "Add Slots" : ""}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setSelectedLocation(null);
              setDeliverySlots([]);
            }}
            centered
          >
            {showModal.type === "ADDSLOTS" && (
              <div className="row">
                <div className="col-md-12 mt-2">
                  <select
                    className="form-select text_style mt-1 mb-3"
                    onChange={(e) => handleSelectLocation(e.target.value)}
                  >
                    <option value={-1}>Select Location</option>
                    {locationData?.length > 0 &&
                      locationData?.map((item) => (
                        <option key={item.id} value={item?.id}>
                          {item?.location_name}
                        </option>
                      ))}
                  </select>
                  <select
                    className="form-select text_style mt-1"
                    onChange={(e) => handleSelect(e.target.value)}
                  >
                    <option value={-1}>Select a slot</option>
                    {slotData?.length > 0 &&
                      slotData?.map((item) => (
                        <option key={item.slot_id} value={item?.slot_id}>
                          {item?.slot_name}
                        </option>
                      ))}
                  </select>
                </div>

                {deliverySlots?.length > 0 &&
                  deliverySlots?.map((item, rowIndex) => (
                    <>
                      <div key={item.slot_id}>
                        <div className="col-md-12 px-0 mt-3">
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) =>
                              handleDateSelection(
                                e.target.value,
                                item?.slot_id,
                                "start_date"
                              )
                            }
                          >
                            {generateStartTimeOptions(
                              item.start_time,
                              item.end_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 px-0 mt-3">
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) =>
                              handleDateSelection(
                                e.target.value,
                                rowIndex,
                                "end_date"
                              )
                            }
                          >
                            {generateEndTimeOptions(
                              item.end_time,
                              item.start_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 px-0 mt-3 mb-1">
                          <FUSButton
                            labelText={"Add Slot"}
                            className={"form-control"}
                            buttonType="primary"
                            onClick={() => handleSaveDeliverySlot()}
                          ></FUSButton>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default OrderTimeConfig;
