import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ride from "../../services/ride.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import _ from "lodash";

const RideTimeConfig = () => {
  const [timeConfig, setConfigData] = useState([]);
  const [cityCodeList, setCityCodeList] = useState([]);
  const [rideTypeList, setRideTypeList] = useState([]);
  const [state, setState] = useState({
    city_code: null,
    ride_type: null,
    hour: null,
  });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const columns = [
    {
      dataField: "city_code",
      text: "City code",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "ride_type",
      text: "Ride type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "hours",
      text: "Hours",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: 20,
      },
    ],
  };

  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDITTIMECONFIG",
    }));
    setState({
      city_code: row.city_code,
      ride_type: row.ride_type,
      hour: row.hours,
      id: row.id,
    });
  };

  const getAllRideTimeConfig = async () => {
    try {
      const res = await ride.getAllRideTimeConfig();
      if (res?.data?.errorCode === 0) {
        setConfigData(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getAllRideTimeConfig();
    getCityCodeLists();
    getRideTypeList();
  }, []);
  const getCityCodeLists = async () => {
    try {
      const res = await ride.getCityCodeList();
      if (res?.data?.errorCode === 0) {
        setCityCodeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getRideTypeList = async () => {
    try {
      const res = await ride.getRideTypeList();
      if (res?.data?.errorCode === 0) {
        setRideTypeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const insertRideTimeConfig = async () => {
    try {
      const result = await ride.insertRideTimeConfig(state);
      if (result?.data?.errorCode === 0) {
        getAllRideTimeConfig();
        setState({ city_code: null, ride_type: null, hour: null });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else if (result?.data?.errorCode === -409) {
        getAllRideTimeConfig();
        setState({ city_code: null, ride_type: null, hour: null });
        const successToast = toast.warning(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await ride.deleteRideTimeConfig({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getAllRideTimeConfig();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handleUpdate = async () => {
    try {
      const result = await ride.updateRideTimeConfig({ configObject: state });
      if (result?.data?.errorCode === 0) {
        getAllRideTimeConfig();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setState({
          city_code: null,
          ride_type: null,
          hour: null,
        });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  return (
    <LayoutContainer
      title1="Ride Config"
      title2="Ride Config"
      title3="Ride Config"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Ride Time Config"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADDRIDETIMECONFIG",
              }));
            }}
          />
        </>
      }
    >
      <div className="px-3 py-3">
        <div className="col-md-12">
          <BootstrapTable
            classes="border border-1"
            keyField="price"
            data={timeConfig}
            columns={columns}
            pagination={paginationFactory(featureListOption)}
            filter={filterFactory()}
          />
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={showModal.type === "ADDRIDETIMECONFIG" ? "Add Config" : ""}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setState({
              city_code: null,
              ride_type: null,
              hour: null,
            });
          }}
          centered
        >
          {showModal.type === "ADDRIDETIMECONFIG" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select City Code</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      value={state?.city_code}
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          city_code: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select</option>
                      {cityCodeList &&
                        cityCodeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select Ride Type</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      value={state?.ride_type}
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          ride_type: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select</option>
                      {rideTypeList &&
                        rideTypeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Select Hours</label>
                    <select
                      name="filter"
                      id="filter"
                      value={state?.hour}
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          hour: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select</option>
                      {!_.isEmpty(hours) &&
                        hours.map((item, index) => (
                          <>
                            <option key={index} value={item}>
                              {item}
                            </option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                {state.city_code && state.ride_type && state.hour ? (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      onClick={() => {
                        insertRideTimeConfig();
                        setShowModal((prev) => ({ ...prev, state: false }));
                      }}
                    ></FUSButton>
                  </>
                ) : (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      disabled
                    ></FUSButton>
                  </>
                )}
              </div>
            </div>
          )}
        </FUSModal>
      )}
      {showEditModal.state && (
        <FUSModal
          title={showEditModal.type === "EDITTIMECONFIG" ? "Edit Config" : ""}
          showModal={showEditModal.state}
          size={"md"}
          handleClose={() => {
            setEditShowModal((prev) => ({ ...prev, state: false }));
            setState({
              city_code: null,
              ride_type: null,
              hour: null,
            });
          }}
          centered
        >
          {showEditModal.type === "EDITTIMECONFIG" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select City Code</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      value={state.city_code}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          city_code: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select City Code</option>
                      {cityCodeList &&
                        cityCodeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select Ride Type</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      value={state.ride_type}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          ride_type: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select Ride Type</option>
                      {rideTypeList &&
                        rideTypeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Select Hours</label>
                    <select
                      name="filter"
                      id="filter"
                      value={state?.hour}
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          hour: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select</option>
                      {!_.isEmpty(hours) &&
                        hours.map((item, index) => (
                          <>
                            <option key={index} value={item}>
                              {item}
                            </option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleUpdate()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default RideTimeConfig;
