import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row } from "react-bootstrap";
import RideService from "../../services/ride.service";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import DatePicker from "react-datepicker";
import VARIABLES from "../../services/variable.service";
import _ from "lodash";
const MakeDriver = () => {
  const [userMobile, setUserMobile] = useState("");
  const [userData, setUserData] = useState(null);
  const [driverList, setDriverList] = useState([]);

  const [driverType, setDriverType] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [userId, setUserId] = useState(null);

  const initialFormData = {
    dl_no: "",
    licenseDate: null,
    gender: "",
    email: "",
    aadhar_no: "",
    voter_id_no: "",
    pan_no: "",
    registration_no: "",
    vehicle_type: "",
    model_no: "",
    permit_no: "",
    permitDate: null,
    capacity: "",
    referred_by: "",
    driver_type: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const handleCancel = () => {
    setFormData(initialFormData);
    setUserId(null);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date, name) => {
    setFormData({
      ...formData,
      [name]: date,
    });
  };
  const handleSubmitInformation = (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,

      licenseDate: formData.licenseDate
        ? dateConverter(formData.licenseDate)
        : null,
      permitDate: formData.permitDate
        ? dateConverter(formData.permitDate)
        : null,
      user_id: userId,
    };

    if (showModal.type === "UPDATE") {
      RideService.updateDriverInfo(dataToSend)
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            const successToast = toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
            setShowModal((prev) => ({
              ...prev,
              state: false,
              type: "",
            }));
            setFormData(initialFormData);
            setUserId(null);
          } else {
            const errorToast = toast.error(`${res?.data?.message}`, {
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(errorToast);
            }, 2000);
            setFormData(initialFormData);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.responseHandler(error);
        });
    } else {
      RideService.insertDriverInfo(dataToSend)
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            const successToast = toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
            setShowModal((prev) => ({
              ...prev,
              state: false,
              type: "",
            }));
            setFormData(initialFormData);
            setUserId(null);
          } else {
            const errorToast = toast.error(`${res?.data?.message}`, {
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(errorToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.responseHandler(error);
        });
    }
  };

  const handleSubmit = () => {
    RideService.getUserDetails({ phone_no: userMobile })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const user = res?.data?.data[0];
          setUserData(user);
          setUserMobile("");
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
          setUserMobile("");
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };
  const dateConverter = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const newDate = [date.getFullYear(), mnth, day].join("-");
    return newDate;
  };

  const handleEdit = (row) => {
    const confirmation = window.confirm(
      "Are you sure, you want to Edit Driver Information? ....Click OK to proceed, or Cancel to review your choice!"
    );
    if (confirmation) {
      setUserId(row.id);
      RideService.getDriverInfoById({ user_id: row.id })
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            const driverData = res?.data?.data;

            if (driverData) {
              const {
                driving_license_number,
                driving_license_expiry_dt,
                gender,
                email,
                aadhar_number,
                voter_id_number,
                pan_card_number,
                vehicle_registration_plate,
                vehicle_type,
                vehicle_model,
                vehicle_permit_number,
                permit_expire_dt,
                vehicle_seating_capacity,
                referred_by_phonenumber,
                driver_type,
              } = driverData;

              // Set formData state with the retrieved data
              setFormData({
                dl_no: driving_license_number,
                licenseDate: driving_license_expiry_dt,
                gender: gender,
                email: email,
                aadhar_no: aadhar_number,
                voter_id_no: voter_id_number,
                pan_no: pan_card_number,
                registration_no: vehicle_registration_plate,
                vehicle_type: vehicle_type,
                model_no: vehicle_model,
                permit_no: vehicle_permit_number,
                permitDate: permit_expire_dt,
                capacity: vehicle_seating_capacity,
                referred_by: referred_by_phonenumber,
                driver_type: driver_type,
              });

              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "UPDATE",
              }));
            }
          } else {
            setShowModal((prev) => ({
              ...prev,
              state: true,
              type: "ADD",
            }));
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleDeleteItem = (row) => {
    const confirmation = window.confirm(
      "Are you sure, you want to delete this driver?"
    );
    if (confirmation) {
      RideService.deleteRideManagerOrDriver({ user_id: row.id })
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            toast.success("Driver deleted successfully", {
              position: "bottom-left",
              autoClose: 2000,
            });
            getAllDrivers();
          } else {
            toast.error(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const columns = [
    { dataField: "full_name", text: "Driver Name", sort: true },
    { dataField: "login_phone", text: "Mobile", sort: true },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                buttonType="lightgray"
                onClick={() => handleEdit(row)}
              />
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDeleteItem(row)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const getAllDrivers = () => {
    RideService.getAllDrivers()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDriverList(res?.data?.data);
        } else {
          setDriverList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getDriverType = () => {
    RideService.getDriverType()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDriverType(res?.data?.data);
        } else {
          setDriverType([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleStartAsDriver = () => {
    if (_.isEmpty(userData) || _.isEmpty(userData.id)) {
      toast.error("User data is not available.", {
        position: "bottom-left",
        autoClose: 2000,
      });
      setUserData(null);
      return;
    }
    if (userData?.primary_role_id === VARIABLES.DRIVER) {
      const errorToast = toast.error("You are already a driver.", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(errorToast);
      }, 2000);
      setUserData(null);
    } else if (userData.primary_role_id === VARIABLES.USER) {
      var confirm = window.confirm(
        "Are you sure, you want to make Driver? Click OK to proceed, or Cancel to review your choice..."
      );
      if (confirm) {
        const { id } = userData;

        RideService.makeDriver({ user_id: id })
          .then((res) => {
            if (res?.data?.errorCode === 0) {
              const successToast = toast.success(
                "You are successfully entered as a Driver",
                {
                  position: "bottom-left",
                  autoClose: false,
                }
              );
              setTimeout(() => {
                toast.dismiss(successToast);
              }, 2000);
              getAllDrivers();
              setUserData(null);
            } else {
              const errorToast = toast.error(`${res?.data?.message}`, {
                position: "bottom-left",
                autoClose: false,
              });
              setTimeout(() => {
                toast.dismiss(errorToast);
              }, 2000);
              setUserData(null);
            }
          })
          .catch((error) => {
            requestAndErrorHandler.responseHandler(error);
          });
      } else {
        setUserData(null);
      }
    } else {
      const errorToast = toast.error(
        "Cannot create Driver because the given user is not a normal user.",
        {
          position: "bottom-left",
          autoClose: false,
        }
      );
      setTimeout(() => {
        toast.dismiss(errorToast);
      }, 2000);
      setUserData(null);
    }
  };

  useEffect(() => {
    getAllDrivers();
    getDriverType();
  }, []);

  return (
    <LayoutContainer title1="Ride" title2="Ride Manager" title3="Make Driver">
      <div className="py-2 px-1">
        <div className="border_bottom_style">
          <Row className="d-flex align-items-end gap-2 gap-sm-0">
            <Col md={3}>
              <div>
                <span>Mobile Number</span>
                <input
                  className="form-control py-2 mt-1"
                  placeholder="Enter here........"
                  value={userMobile}
                  name="userMobile"
                  onChange={(e) => setUserMobile(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
            <Col md={3}>
              <FUSButton
                className={"py-2"}
                buttonTextStyle={"fs-6"}
                buttonType={userMobile.length === 10 ? "primary" : "secondary"}
                labelText={"Fetch User Information"}
                onClick={handleSubmit}
                disabled={userMobile.length !== 10}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-3 px-2 mx-1">
          {userData && (
            <div>
              <Row className="mb-3 align-item-center">
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Name</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.full_name}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Mobile Number</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.login_phone}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="px-0">
                    {" "}
                    <FUSButton
                      className={"py-2"}
                      labelText={"Create Driver"}
                      buttonType="primary"
                      onClick={handleStartAsDriver}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="px-1 mt-3">
          <div className="pb-5 mb-5 px-2">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={driverList}
              columns={columns}
            />
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "UPDATE"
                ? "Update Driver Information"
                : "Add Driver Information"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setFormData(initialFormData);
              setUserId(null);
            }}
            centered
          >
            <div>
              <form onSubmit={handleSubmitInformation}>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>License No.</label>
                      <input
                        type="text"
                        name="dl_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.dl_no}
                        onChange={handleChange}
                        placeholder="Enter license number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <span>License Expiry Date</span>
                      <br />
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control form-control-sm mt-1"
                        selected={
                          formData?.licenseDate
                            ? new Date(formData?.licenseDate)
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "licenseDate")
                        }
                        placeholderText="Select Date"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Gender</label>
                      <select
                        name="gender"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="f">Female</option>
                      </select>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-sm mt-1"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Adhar No.</label>
                      <input
                        type="number"
                        name="aadhar_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.aadhar_no}
                        maxLength={12}
                        onChange={handleChange}
                        placeholder="Enter Adhar number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Voter ID no.</label>
                      <input
                        type="text"
                        name="voter_id_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.voter_id_no}
                        onChange={handleChange}
                        placeholder="Enter Voter ID number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Pan Card no.</label>
                      <input
                        type="text"
                        name="pan_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.pan_no}
                        onChange={handleChange}
                        placeholder="Enter Pan Card number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Vehicle Registration No.</label>
                      <input
                        type="text"
                        name="registration_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.registration_no}
                        onChange={handleChange}
                        placeholder="Enter Vehicle Registration number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Vehicle Type</label>
                      <input
                        type="text"
                        name="vehicle_type"
                        className="form-control form-control-sm mt-1"
                        value={formData.vehicle_type}
                        onChange={handleChange}
                        placeholder="Enter Vehicle Type"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Vehicle Model</label>
                      <input
                        type="text"
                        name="model_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.model_no}
                        onChange={handleChange}
                        placeholder="Enter Vehicle Model"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Vehicle Permit No.</label>
                      <input
                        type="text"
                        name="permit_no"
                        className="form-control form-control-sm mt-1"
                        value={formData.permit_no}
                        onChange={handleChange}
                        placeholder="Enter Vehicle Permit number"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <span>Permit Expiry Date</span>
                      <br />
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control form-control-sm mt-1"
                        selected={
                          formData.permitDate
                            ? new Date(formData?.permitDate)
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "permitDate")
                        }
                        placeholderText="Select Date"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group mt-3">
                      <label>Referring Phone no.</label>
                      <input
                        type="number"
                        name="referred_by"
                        className="form-control form-control-sm mt-1"
                        value={formData.referred_by}
                        onChange={handleChange}
                        placeholder="Enter Referring Phone number"
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Seats</label>
                      <select
                        name="capacity"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.capacity}
                        onChange={handleChange}
                      >
                        <option value="">Select No. of Seats</option>
                        {Array.from({ length: 4 }, (_, i) => i + 1).map(
                          (num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Driver Type</label>
                      <select
                        name="driver_type"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.driver_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Driver Type</option>
                        {driverType.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.disp_text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-center align-item-center gap-2 mt-4">
                    <FUSButton
                      buttonTextStyle="fs-6"
                      className={"py-2 px-5"}
                      labelText={"Cancel"}
                      buttonType="danger"
                      onClick={handleCancel}
                    />
                    <FUSButton
                      buttonTextStyle="fs-6"
                      className={"py-2 px-5"}
                      buttonType="primary"
                      type="submit"
                      labelText={showModal.type === "UPDATE" ? "Update" : "Add"}
                    />
                  </div>
                </Row>
              </form>
            </div>
          </FUSModal>
        )}
      </div>
    </LayoutContainer>
  );
};

export default MakeDriver;
