import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import "./VisitType.css";
import FUSUploadFile from "../../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

function VisitType() {
  const [visitdata, setVisitData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    type_name: "",
    type: "ALL",
    is_active: "",
  });

  const getVisitData = () => {
    MstService.crudVisitType({
      type: "ALL",
    })
      .then((res) => {
        if (res?.data?.data) {
          setVisitData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getVisitData();
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const visitModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: "INSERT",
    }));
    setShowModal((prev) => ({ ...prev, state: true }));
  };

  const handleClose = () => {
    setIsEdit(false);
    setFormData({
      type_name: "",
      type: "ALL",
      is_active: "",
    });
    setSelectedFile();
    setIsFilePicked(false);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const addNewVisit = () => {
    setIsEdit(false);
    MstService.crudVisitType(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);

          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFormData({
      type_name: "",
      type: "ALL",
      is_active: "",
    });
  };

  const handleEditClick = (row) => {
    setShowModal((prev) => ({ ...prev, state: true }));
    setIsEdit(true);
    MstService.crudVisitType({ type: "GET", id: row.id })
      .then((res) => {
        setFormData(res?.data?.data[0]);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const handleEditVisit = () => {
    MstService.crudVisitType({ ...formData, type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsEdit(false);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            dept_name: "",
            type: "ALL",
            is_active: "",
          });
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((row) => {
    MstService.crudVisitType({ id: row.id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setVisitData((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  // for handling excel upload

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "PLease ensure your file constains visits !"
      );
      if (!confirm) {
        setIsLoading((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      const successToast = toast.success(
        "Please Upload a Excel file",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}uploadCrudVisitType`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
            setIsLoading(!isLoading);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const columns = [
    {
      dataField: "type_name",
      text: "Purpose Name",
    },
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">Inactive</span>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({ ...prev, state: true }));
                    handleEditClick(row);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "7",
        value: 7,
      },
      {
        text: "14",
        value: 14,
      },
      {
        text: "All",
        value: visitdata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Settings"}
        title2={"Settings"}
        title3={"Visit Type"}
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <span className="beat_file_sample d-none d-sm-block">Upload expense file in</span>
              &nbsp;
              <a
                className="beat_file_sample themeColorBlue"
                style={{ cursor: "pointer" }}
              >
                Link sample expense format file
              </a>
            </div>
          </>
        }
      >
        <div className="py-2">
          <div className="border_bottom_color px-2">
            <div className="row ">
              <div className="col-12 d-flex justify-content-sm-end gap-3">
                <FUSButton
                  iconSrc={"fa6"}
                  iconName={"FaPlus"}
                  iconSize={14}
                  labelText={isMobile ? "Visit Type" : "Add Visit Type"}
                  buttonType="secondary"
                  onClick={visitModal}
                />

                <FUSButton
                  iconSrc={"md"}
                  iconName={"MdOutlineFileDownload"}
                  iconSize={19}
                  labelText={isMobile ? "Expense Type" : "Import Expense Type"}
                  buttonType="primary"
                  onClick={() =>
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "uploadFile",
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-3 px-3">
            <div className="col-11">
              <BootstrapTable
                classes="border border-1"
                keyField="id"
                data={visitdata}
                columns={columns}
                sizePerPage={5}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
          {showModal.state ? (
            <FUSModal
              title={
                showModal.type === "uploadFile"
                  ? "Add Visit Details"
                  : `${!isEdit
                    ? "Add Visit Type Detail"
                    : "Edit Visit Type Details"
                  }`
              }
              showModal={showModal.state}
              handleClose={() => handleClose(false)}
              size={"md"}
              centered
            >
              {showModal.type === "uploadFile" ? (
                <>
                  <Form>
                    <div>
                      <Form.Group controlId="formFileLg" className="mb-3 mt-5">
                        <FUSUploadFile
                          title="Upload Visit File"
                          onChange={changeHandler}
                        />

                        <Row className="row-bottom-margin mt25">
                          {isFilePicked && (
                            <Form.Group as={Col}>
                              <Form.Label>
                                {isFilePicked ? (
                                  <div>
                                    <p className="p0">
                                      {`Filename: ${selectedFile?.name} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Filetype: ${selectedFile?.type} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                    </p>
                                  </div>
                                ) : (
                                  <span>Select a file to show details</span>
                                )}
                              </Form.Label>
                              <FUSButton
                                className={"form-control mt-3"}
                                buttonType="primary"
                                labelText={"Upload"}
                                onClick={handleSubmission}
                              />
                            </Form.Group>
                          )}
                        </Row>
                      </Form.Group>
                    </div>
                  </Form>
                </>
              ) : (
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                      className="mx-0 px-0"
                    >
                      <div>
                        <span className="label_style">New Visit Type</span>
                        <Form.Control
                          className="py-2 my-1 text_style"
                          onChange={handleChange}
                          type="text"
                          name="type_name"
                          value={formData.type_name}
                          placeholder="Enter New Visit Type"
                        />
                      </div>
                      {isEdit && (
                        <>
                          <div>
                            <span className="label_style">Status</span>
                            <Form.Select
                              className="py-2 my-1 text_style"
                              name="is_active"
                              value={formData.is_active}
                              onChange={handleChange}
                            >
                              <option value="true">Activate</option>
                              <option value="false">Deactivate </option>
                            </Form.Select>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Row>
                  <div className="mt-3">
                    {formData.type_name &&
                      formData.type_name !== "" &&
                      formData.type_name !== null &&
                      formData.type_name !== undefined ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={isEdit ? handleEditVisit : addNewVisit}
                        labelText={"Save Changes"}
                      />
                    ) : null}
                  </div>
                </Form>
              )}
            </FUSModal>
          ) : null}
        </div>
      </LayoutContainer>
    </>
  );
}

export default VisitType;
