import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";

const UnAuthorized = (props) => {
  const [text, setText] = useState("");

  useEffect(() => {
    const welcomeText = "Welcome to FuseIT";
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex <= welcomeText.length) {
        setText(welcomeText.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 150);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <LayoutContainer
        title1={"Home"}
        title2={"Dashboard"}
        title3={"Dashboard"}
      >
        <div className="center pt-5">
          <p
          className="pt-5"
            style={{
              textShadow: "2px 2px 3px #7966e4",
            }}
          >
            {text}
          </p>
        </div>
      </LayoutContainer>
    </>
  );
};

export default UnAuthorized;
