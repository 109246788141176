import React, { useEffect, useState, useCallback } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import { toast } from "react-toastify";
import _ from "lodash";

function VendorHolidayConfiguration() {
  const getCurrentDay = () => {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
  };
  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [showHolidayList, setShowHolidayList] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [formData, setFormData] = useState({
    holiday_name: "",
    holiday_date: tomorrow,
    type: "ALL",
    is_active: "",
    msg: "",
  });

  const holidayDates = !_.isEmpty(showHolidayList)
    ? showHolidayList?.map((item) => new Date(item.holiday_date))
    : [];

  const isDateDisabled = (date) => {
    return holidayDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
    );
  };

  useEffect(() => {
    MstService.crudVendorHoliday({ type: "ALL" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowHolidayList(res?.data?.data);
        }else{
          setShowHolidayList([])
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddHoliday = () => {
    const dataToAdd = {
      ...formData,
      holiday_date: getISODate(formData.holiday_date),
    };

    MstService.crudVendorHoliday({ ...dataToAdd, type: "INSERT" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setFormData({
            holiday_name: "",
            holiday_date: tomorrow,
            office_loc: "",
            shifts: "",
            type: "ALL",
            is_active: "",
            msg: "",
          });
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);
        } else if (res?.data?.errorCode === -100) {
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditClick = (id) => {
    setIsEdit(true);

    MstService.crudVendorHoliday({ type: "GET", id: id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const editFormData = { ...res.data.data[0] };
          editFormData.holiday_date = new Date(editFormData.holiday_date);
          setFormData(editFormData);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditHoliday = () => {
    MstService.crudVendorHoliday({
      ...formData,
      holiday_date: getISODate(formData.holiday_date),
      type: "UPDATE",
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsLoading((isLoading) => !isLoading);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            holiday_name: "",
            holiday_date: tomorrow,
            office_loc: "",
            shifts: "",
            type: "ALL",
            is_active: "",
            msg: "",
          });
          setIsEdit(false);
        } else if (res?.data?.errorCode === -100) {
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((id) => {
    MstService.crudVendorHoliday({ id: id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setShowHolidayList((oldValues) => {
            return oldValues.filter((item) => item.id !== id);
          });
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);


  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "PLease ensure your file constains holidays !"
      );
      if (!confirm) {
        setIsLoading((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      const successToast = toast.success("Please Upload a Excel file", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}uploadCrudAgencyHoliday`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
            setIsLoading(!isLoading);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const columns = [
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-secondary">Active</span>
            ) : (
              <span className="text-danger">InActive</span>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "holiday_name",
      text: "Holiday Name",
    },
    {
      dataField: "formated_date",
      text: "Holiday Date",
    },
    {
      dataField: "msg",
      text: "Message",
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "ADD_EDIT",
                    }));
                    handleEditClick(row.id);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row.id)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      {
        text: "All",
        value: showHolidayList?.length
      }
      
    ],
  };

  return (
    <>
      <LayoutContainer
        title1={"Products"}
        title2={`Vendor Holidays`}
        title3={`Vendor Holidays`}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add New Holiday"}
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "ADD_EDIT",
                }));
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  type: "INSERT",
                }));
              }}
            />
          </>
        }
      >
        <div>
          <div>
            <div className="px-3">
              <BootstrapTable
                classes="border border-1"
                keyField="id"
                data={showHolidayList}
                columns={columns}
                sizePerPage={5}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>

          {showModal.state && (
            <FUSModal
              title={
                showModal.type === "ADD_EDIT"
                  ? isEdit
                    ? "Edit Holiday"
                    : "Add Holiday"
                  : "Add Holiday List"
              }
              showModal={showModal.state}
              size={"md"}
              handleClose={() => {
                setShowModal((prev) => ({ ...prev, state: false }));
                setIsEdit(false);
                setFormData({
                  holiday_name: "",
                  holiday_date: tomorrow,
                  office_loc: "",
                  shifts: "",
                  type: "ALL",
                  is_active: "",
                  msg: "",
                });
                setSelectedFile();
                setIsFilePicked(false);
              }}
              centered
            >
              {showModal.type === "ADD_EDIT" ? (
                <Form>
                  <Row>
                    <Col md={12} className="px-0">
                      <span className="text-secondary">Holiday Name</span>
                      <Form.Control
                        type="text"
                        name="holiday_name"
                        value={formData.holiday_name}
                        onChange={handleChange}
                        className="form-control text_style mt-1"
                        required={true}
                        placeholder="Enter here..."
                      />
                    </Col>
                    <Col md={12} className="px-0">
                      <span className="text-secondary">Message</span>
                      <Form.Control
                        type="text"
                        name="msg"
                        value={formData.msg}
                        onChange={handleChange}
                        className="form-control text_style mt-1"
                        required={true}
                        placeholder="Type here..."
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} className="ps-0">
                      <span className="text-seconadry">Status</span>
                      <Form.Select
                        className="text_style mt-1"
                        name="is_active"
                        value={formData.is_active}
                        onChange={handleChange}
                      >
                        <option value={"true"}>Activate</option>
                        <option value={"false"}>Deactivate</option>
                      </Form.Select>
                    </Col>
                    <Col md={4} className="pe-0">
                      <span className="text-seconadry">Start Date</span>
                      {_.isEmpty(holidayDates) ? (
                        <DatePicker
                          className="mt-1 border border-1 px-2 py-1 rounded"
                          dateFormat="yyyy-MM-dd"
                          selected={formData.holiday_date}
                          minDate={getCurrentDay()}
                          maxDate={null}
                          onChange={(date) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              holiday_date: date,
                            }));
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      ) : (
                        <DatePicker
                          className="mt-1 border border-1 px-2 py-1 rounded"
                          dateFormat="yyyy-MM-dd"
                          selected={formData.holiday_date}
                          filterDate={(date) => !isDateDisabled(date)}
                          minDate={getCurrentDay()}
                          maxDate={null}
                          onChange={(date) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              holiday_date: date,
                            }));
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    {formData.holiday_name &&
                    formData.holiday_name !== "" &&
                    formData.holiday_name !== null &&
                    formData.holiday_name !== undefined ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={isEdit ? handleEditHoliday : handleAddHoliday}
                        labelText={"Save"}
                      />
                    ) : null}
                  </div>
                </Form>
              ) : (
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="my-5"
                >
                  <FUSUploadFile
                    title="Upload Holiday List"
                    onChange={changeHandler}
                  />

                  <Row className="row-bottom-margin mt25">
                    {isFilePicked && (
                      <Form.Group
                        as={Col}
                        className="d-flex align-items-end px-4"
                      >
                        <Form.Label>
                          {isFilePicked ? (
                            <div>
                              <p className="p0">
                                {`Filename: ${selectedFile?.name} `}{" "}
                              </p>
                              <p className="p0">
                                {`  Filetype: ${selectedFile?.type} `}{" "}
                              </p>
                              <p className="p0">
                                {`  Size in bytes: ${selectedFile?.size}`}{" "}
                              </p>
                              <p className="p0">
                                {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                              </p>
                            </div>
                          ) : (
                            <span>Select a file to show details</span>
                          )}
                        </Form.Label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {formData.expense_type &&
                        formData.expense_type !== "" &&
                        formData.expense_type !== null &&
                        formData.expense_type !== undefined ? (
                          <FUSButton
                            labelText={"Upload"}
                            buttonType="primary"
                            onClick={handleSubmission}
                          >
                            Upload
                          </FUSButton>
                        ) : null}
                      </Form.Group>
                    )}
                  </Row>
                </Form.Group>
              )}
            </FUSModal>
          )}
        </div>
      </LayoutContainer>
    </>
  );
}

export default VendorHolidayConfiguration;
