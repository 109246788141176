import React, { Fragment, useContext } from "react";
import Products from "../Products/Products";
import ProductDiscount from "../ProductsDiscount/ProductsDiscount";
import OfferConfig from "../SendVendorNotification/OfferConfig";
import "./Wizard.css";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";

import FUSStepper from "../../FUSComponents/FUSStepper/FUSStepper";

const ProductWizard = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);

  const pages = [
    {
      tab_name: "Manage Products",
      tab_value: "manage_products",
      isActive: true,
      component: Products,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS
      ),
    },
    {
      tab_name: "Vendor Item Discount ",
      tab_value: "vendor_item_disocunt",
      isActive: true,
      component: ProductDiscount,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.OFFERS,
        ROLES_PERMISSIONS.PERMISSIONS.OFFERS
          .VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT
      ),
    },

    {
      tab_name: "Offer Config ",
      tab_value: "offer_config",
      isActive: true,
      component: OfferConfig,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
        ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
          .VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION
      ),
    },
  ];

  return <FUSStepper initialSteps={pages} />;
};

export default ProductWizard;
