import React, { Fragment } from "react";
import UpdateFunctionalityList from "../UpdateFunctionality/UpdateFucntionality";
import AddFunctionality from "../UpdateFunctionality/AddFunctionality";
import FUSStepper from "../../../FUSComponents/FUSStepper/FUSStepper";

const PermissionWizard = () => {
  const pages = [
    {
      tab_name: "Add Functionality",
      tab_value: "add_functionality",
      isActive: true,
      is_permission_enabled: true,
      component: AddFunctionality,
      template: Fragment,
    },
    {
      tab_name: "Remove Functionality",
      tab_value: "remove_functionality",
      isActive: false,
      is_permission_enabled: true,
      component: UpdateFunctionalityList,
      template: Fragment,
    },
  ];

  return <FUSStepper initialSteps={pages} />;
};

export default PermissionWizard;
