import React, { useEffect, useState } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VARIABLES from "../../services/variable.service";
import TokenService from "../../services/token.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import MstService from "../../services/mst.service";
import "./PurchaseFileUpload.css";
import "../../FUSScreens/BODProcess/BODProcess.css";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

export default function PurchaseFileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [alreadyFileUpload, setAlreadyFileUpload] = useState(false);
  const [uploadFileData, setUploadFileData] = useState([]);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      const confirm = window.confirm(
        `You're about to upload the puchase data for the date ${ISODate}. Click OK to proceed, or Cancel to review your selection.`
      );
      if (confirm) {
        uploadData();
      }
    } else {
      window.alert("Please upload an Excel file (.xlsx or .xls)");
    }
  };

  let ISODate = fromDate?.toISOString();
  ISODate = ISODate?.slice(0, 10);

  useEffect(() => {
    const getSifyPurchaseData = () => {
      MstService.getSifyPurchaseData({
        date: ISODate,
      })
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setUploadFileData(response.data.data);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    };

    getSifyPurchaseData();
  }, [ISODate, uploadFileData]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const columns = [
    {
      dataField: "bill_id",
      text: "Bill ID",
      sort: true,
      editable: false,
    },
    {
      dataField: "odnumber",
      text: "OD Number",
      sort: true,
      editable: false,
    },
    {
      dataField: "item_code",
      text: "Item Code",
      sort: true,
      editable: false,
    },
    {
      dataField: "item_name",
      text: "Item Name",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      editable: false,
    },

    {
      dataField: "gross_amount2",
      text: "Gross Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "tax_amount2",
      text: "Tax Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      editable: false,
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: uploadFileData?.length,
      },
    ],
  };

  const uploadData = () => {
    const token = TokenService.getLocalAccessToken();
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("date", ISODate);
    formData.append("file_type", "PURCHASE");
    formData.append("isReupload", alreadyFileUpload);
    axios({
      method: "post",
      url: `${VARIABLES.BASE_URL}sify/addSifyPurchaseData`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile(null);
        }

        if (response?.data?.errorCode === -100) {
          setAlreadyFileUpload(true);
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile("");
            setIsFilePicked(false);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  return (
    <>
      <LayoutContainer title1="Sify" title2="Sify" title3="Purchase File">
        <div className="p-1">
          <div className="border_bottom_style">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div>
                  <span className="text-secondary">Date</span>
                  <br />

                  <DatePicker
                    className="px-2 py-2 border border-1 rounded rounded-1 mt-1"
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                      setAlreadyFileUpload(false);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    maxDate={new Date()}
                  />
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <Form.Group controlId="formFileLg">
                  {" "}
                  {!isFilePicked && (
                    <div className="border border-1 purchase_btn d-flex  align-items-center px-3 rounded rounded-1">
                      <FUSIcon
                        iconSrc={"md"}
                        iconName={"MdOutlineFileDownload"}
                        size={20}
                        className="icon_color"
                      />
                      <Form.Control
                        type="file"
                        size="sm"
                        onChange={changeHandler}
                        accept=".xls,.xlsx"
                        className="border-0 beat_upload_text  purchase_upload_button py-0 ps-0 "
                      />
                    </div>
                  )}
                  <Row>
                    {isFilePicked && (
                      <Form.Group
                        as={Col}
                        className="d-flex align-items-center gap-3"
                      >
                        <Form.Label>
                          {isFilePicked ? (
                            <div className="mt-3">
                              <span className="text_style">
                                Filename:&nbsp;
                                <span className="text-success text_style">
                                  {selectedFile?.name}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <span>Select a file to show details</span>
                          )}
                        </Form.Label>
                        <FUSButton
                          className={"py-2"}
                          buttonType="success"
                          labelText={alreadyFileUpload ? "Re-Upload" : "Upload"}
                          onClick={handleSubmission}
                        />
                      </Form.Group>
                    )}
                  </Row>
                </Form.Group>
              </div>
            </div>
          </div>
          {alreadyFileUpload && (
            <div className="my-5 px-5 ">
              <Alert key={"danger"} variant={"danger"} className="mt-3">
                <div className="d-flex  justify-content-center">
                  File for {ISODate} already uploaded. Re-uploading will
                  overwrite existing data.
                </div>
              </Alert>
            </div>
          )}

          <div className="px-1">
            <div className="pb-5 mb-5 px-2">
              <BootstrapTable
                classes="border border-1"
                keyField="id"
                data={uploadFileData}
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
