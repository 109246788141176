import React, { useMemo } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import util from "../../../services/util";
import logo from "../../../assets/meraplace.png";
import proteconsQR from "../../../assets/proteconsQR.png";
const Invoice = ({
  selectedVendor,
  invoiceData,
  sumOfPayableCommission,
  selectedMonth,
}) => {
  const formatEnDate = (inputDate) => {
    const date = new Date(inputDate); // Convert input to Date object
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    return formattedDate;
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function getStartAndEndDateOfMonth(monthName) {
    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex === -1) {
      return "Invalid month name";
    }

    const year = new Date().getFullYear();
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);

    return {
      Start_Date: formatEnDate(startDate),
      End_Date: formatEnDate(endDate),
    };
  }

  const { Start_Date, End_Date } = getStartAndEndDateOfMonth(selectedMonth);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 10,
    },
    title: {
      fontSize: 20,
    },
    text: {
      fontSize: 16,
    },
  });

  let dueDate = new Date();
  dueDate = dueDate.setDate(dueDate.getDate() + 3);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginVertical: 20 }}>
          <View
            style={{
              justifyContent: "center",
              flexDirection: "row",
              marginVertical: 4,
            }}
          >
            <Text style={{ fontSize: 12 }}>Tax Invoice</Text>
          </View>
          {/* Selction 1 */}
          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  borderColor: "black",
                  borderRightWidth: 1,
                  width: "50%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: 5,
                  }}
                >
                  <View style={{ width: "25%" }}>
                    <Image
                      src={logo}
                      alt="img"
                      style={{ width: 50, height: 50, margin: 5 }}
                    />
                  </View>
                  <View style={{ width: "75%" }}>
                    <Text style={{ fontSize: 10, letterSpacing: 0.04 }}>
                      HKG MERAPLACE LIMITED LIABILITY PARTNERSHIP
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      Flat No 2315 B Block, 12th Avenue, Gaur City 2 Sector 16c,
                      Greater Noida West, Ghaziabad,
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      GSTIN/UIN: 09AANFH8735L1ZL
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      State Name : Uttar Pradesh, Code : 09
                    </Text>
                  </View>
                </View>
              </View>

              {/* Second Half view */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <View
                  style={{
                    borderColor: "black",
                    borderRightWidth: 1,
                    width: "50%",
                  }}
                >
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                    Invoice No.
                  </Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2, paddingTop: 2 }}>
                    {invoiceData[0]?.transaction_id}{" "}
                  </Text>

                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Due Dated Note
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                  {/* Ref NO */}
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Delivery Plan
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>

                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Ref. No and Date
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
                <View style={{ borderColor: "black", width: "50%" }}>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>Dated</Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2, paddingTop: 2 }}>
                    {formatEnDate(new Date())}
                  </Text>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Due Date
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {formatEnDate(dueDate)}
                    </Text>
                  </View>
                  {/* other ref */}
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Mode/Terms of Payments
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Other Referances
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* section 2 */}
          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  borderColor: "black",
                  borderRightWidth: 1,
                  width: "50%",
                }}
              >
                <View style={{ justifyContent: "flex-start" }}>
                  <Text style={{ fontSize: 10 }}>Consignee (Ship To)</Text>
                  <Text
                    style={{
                      fontSize: 10,

                      marginVertical: 10,
                    }}
                  >
                    {selectedVendor?.vendor_name?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    {selectedVendor?.city?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    GSTIN/UIN :{selectedVendor?.gst_no?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    Sate Name : {selectedVendor?.state?.toUpperCase()} Code:{" "}
                    {selectedVendor?.gst_no?.substring(0, 2)}
                  </Text>
                </View>
              </View>

              {/* Second Half view */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <View
                  style={{
                    borderColor: "black",
                    borderRightWidth: 1,
                    width: "50%",
                  }}
                >
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                    Buyer's Order Number
                  </Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}> </Text>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Dispatch Doc No.
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Dispatched Through
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
                <View style={{ borderColor: "black", width: "50%" }}>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>Dated</Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}> </Text>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Delivery Note Date
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Destination
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Section 3 */}
          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  borderColor: "black",
                  borderRightWidth: 1,
                  width: "50%",
                }}
              >
                <View style={{ justifyContent: "flex-start" }}>
                  <Text style={{ fontSize: 10 }}>Buyer (Bill To)</Text>
                  <Text
                    style={{
                      fontSize: 10,

                      marginVertical: 10,
                    }}
                  >
                    {selectedVendor?.vendor_name?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    {selectedVendor?.city?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    GSTIN/UIN :{selectedVendor?.gst_no?.toUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    Sate Name :{selectedVendor?.state?.toUpperCase()} Code:{" "}
                    {selectedVendor?.gst_no?.substring(0, 2)}
                  </Text>
                </View>
              </View>

              {/* Second Half view */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <View
                  style={{
                    borderColor: "black",
                    borderRightWidth: 1,
                    width: "50%",
                  }}
                >
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                    Buyer's Order Number
                  </Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}> </Text>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Dispatch Doc No.
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Dispatched Through
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
                <View style={{ borderColor: "black", width: "50%" }}>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}>Dated</Text>
                  <Text style={{ fontSize: 10, paddingLeft: 2 }}> </Text>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Delivery Note Date
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,

                        paddingLeft: 2,
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderColor: "black",
                      padding: 3,
                    }}
                  >
                    <Text style={{ fontSize: 10, paddingLeft: 2 }}>
                      Destination
                    </Text>
                    <Text style={{ fontSize: 10, padding: 2 }}> </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Section table */}
          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  width: "100%",
                }}
              >
                {/* Table */}

                {/* Table Header */}
                <View
                  style={{
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderColor: "black",
                  }}
                >
                  <View
                    style={{
                      width: "5%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>Sn. </Text>
                  </View>
                  <View style={{ width: "65%" }}>
                    <Text
                      style={{
                        fontSize: 10,

                        borderRightWidth: 1,
                        borderColor: "black",
                        textAlign: "center",
                      }}
                    >
                      Particulars
                    </Text>
                  </View>
                  <View style={{ width: "15%" }}>
                    <Text
                      style={{
                        fontSize: 10,

                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      HSN/SAC
                    </Text>
                  </View>
                  <View style={{ width: "15%" }}>
                    <Text style={{ fontSize: 10 }}>Amount</Text>
                  </View>
                </View>

                {/* Table Rows */}
                <>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        width: "5%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text style={{ fontSize: 10 }}> </Text>
                    </View>
                    <View
                      style={{
                        width: "65%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,

                          textAlign: "center",
                        }}
                      >
                        Transaction Income
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontStyle: "italic",
                          textAlign: "center",
                        }}
                      >
                        From {Start_Date} to {End_Date}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "15%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text style={{ fontSize: 10 }}> </Text>
                    </View>
                    <View style={{ width: "15%" }}>
                      <Text style={{ fontSize: 10 }}> </Text>
                    </View>
                  </View>
                </>
                <>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        width: "5%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text style={{ fontSize: 10, marginVertical: 5 }}>
                        1.
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "65%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text style={{ fontSize: 10, marginVertical: 5 }}>
                        Total Order : {invoiceData[0]?.total_order}
                        <Text
                          style={{
                            fontSize: 7,
                            color: "black",
                            marginLeft: 10,
                          }}
                        >
                          {` (Total Order: ${invoiceData[0]?.total_order} Total Order's Amount: ${invoiceData[0]?.total_order_amount})`}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "15%",
                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <Text style={{ fontSize: 10 }}>
                        {invoiceData[0]?.hsn_code
                          ? invoiceData[0]?.hsn_code
                          : null}
                      </Text>
                    </View>
                    <View style={{ width: "15%" }}>
                      <Text style={{ fontSize: 10, marginVertical: 5 }}>
                        {invoiceData[0]?.payable_commission}
                      </Text>
                    </View>
                  </View>
                </>
                {/* ))} */}

                {/* Row 2 */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "5%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text> </Text>
                  </View>
                  <View
                    style={{
                      width: "65%",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: 10 }}>SGST</Text>
                      <Text style={{ fontSize: 10 }}>CGST</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text></Text>
                  </View>
                  <View style={{ width: "15%" }}>
                    <Text style={{ fontSize: 10 }}>
                      {util?.calculatePercetage(sumOfPayableCommission, 9)}
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {util?.calculatePercetage(sumOfPayableCommission, 9)}
                    </Text>
                  </View>
                </View>
                {/* Total maount Row  */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTopWidth: 1,
                    borderColor: "black",
                  }}
                >
                  <View
                    style={{
                      width: "5%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text> </Text>
                  </View>
                  <View
                    style={{
                      width: "65%",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>Total</Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text></Text>
                  </View>
                  <View style={{ width: "15%" }}>
                    <Text style={{ fontSize: 10 }}>
                      {`${
                        util?.calculatePercetage(sumOfPayableCommission, 18) +
                        sumOfPayableCommission
                      }`}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View></View>
          </View>

          {/* Section 4 below table */}
          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  width: "100%",
                  padding: 2,
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  Amount Chargable( in words)
                </Text>
                <Text style={{ fontSize: 10, marginVertical: 5 }}>
                  {`INR ${util
                    ?.rupeesInWords(
                      util?.calculatePercetage(sumOfPayableCommission, 18) +
                        sumOfPayableCommission
                    )
                    ?.toUpperCase()} ONLY`}
                </Text>
              </View>
            </View>
            <View></View>
          </View>

          <View
            style={{
              marginHorizontal: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderColor: "black",
              }}
            >
              <View
                style={{
                  width: "50%",
                  borderRightWidth: 1,
                  borderColor: "black",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderColor: "black",
                  }}
                >
                  <View style={{ width: "80%" }}>
                    <Text
                      style={{
                        fontSize: 10,

                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      HSN/SAC
                    </Text>
                  </View>
                  <View style={{ width: "20%", paddingLeft: 5 }}>
                    <Text style={{ fontSize: 10 }}>Taxable Value</Text>
                  </View>
                </View>

                {/* rows */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "80%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {sumOfPayableCommission}
                    </Text>
                  </View>
                  <View style={{ width: "20%" }}>
                    <Text style={{ fontSize: 10 }}>
                      {sumOfPayableCommission}
                    </Text>
                  </View>
                </View>
                {/* total */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTopWidth: 1,
                    borderColor: "black",
                  }}
                >
                  <View
                    style={{
                      width: "80%",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>Total</Text>
                  </View>
                  <View style={{ width: "20%" }}>
                    <Text style={{ fontSize: 10 }}>
                      {sumOfPayableCommission}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "50%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderColor: "black",
                  }}
                >
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        fontSize: 10,

                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      CGST
                    </Text>
                  </View>
                  <View style={{ width: "34%" }}>
                    <Text
                      style={{
                        fontSize: 10,

                        borderRightWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      SGST/UTGST
                    </Text>
                  </View>
                  <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}>Total Tax Amount</Text>
                  </View>
                </View>
                {/* rows */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "33%",
                      borderRightWidth: 1,
                      borderColor: "black",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottomWidth: 1,
                        borderColor: "black",
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        <Text
                          style={{
                            fontSize: 10,

                            borderRightWidth: 1,
                            borderColor: "black",
                          }}
                        >
                          Rate
                        </Text>
                      </View>
                      <View style={{ width: "50%" }}>
                        <Text style={{ fontSize: 10 }}>Amount</Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          borderRightWidth: 1,
                          borderColor: "black",
                        }}
                      >
                        <Text style={{ fontSize: 10 }}>9%</Text>
                      </View>
                      <View style={{ width: "50%" }}>
                        <Text style={{ fontSize: 10 }}>
                          {util?.calculatePercetage(sumOfPayableCommission, 9)}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          borderColor: "black",
                        }}
                      ></View>
                      <View
                        style={{
                          width: "50%",
                          borderTopWidth: 1,
                          borderColor: "black",
                        }}
                      >
                        <Text style={{ fontSize: 10 }}>
                          {util?.calculatePercetage(sumOfPayableCommission, 9)}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={{ width: "34%" }}>
                    <View
                      style={{ flexDirection: "row", borderBottomWidth: 1 }}
                    >
                      <View style={{ width: "50%" }}>
                        <Text
                          style={{
                            fontSize: 10,

                            borderRightWidth: 1,
                            borderColor: "black",
                          }}
                        >
                          Rate
                        </Text>
                      </View>
                      <View style={{ width: "50%" }}>
                        <Text
                          style={{
                            fontSize: 10,

                            borderRightWidth: 1,
                            borderColor: "black",
                          }}
                        >
                          Amount
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          borderRightWidth: 1,
                          borderColor: "black",
                        }}
                      >
                        <Text style={{ fontSize: 10 }}>9%</Text>
                      </View>
                      <View style={{ width: "50%" }}>
                        <Text
                          style={{
                            fontSize: 10,
                            borderRightWidth: 1,
                            borderColor: "black",
                          }}
                        >
                          {util?.calculatePercetage(sumOfPayableCommission, 9)}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          borderColor: "black",
                        }}
                      ></View>
                      <View
                        style={{
                          width: "50%",
                          borderTopWidth: 1,
                          borderColor: "black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,

                            borderRightWidth: 1,
                            borderColor: "black",
                          }}
                        >
                          {util?.calculatePercetage(sumOfPayableCommission, 9)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}> </Text>
                    <Text style={{ fontSize: 10 }}> </Text>
                    <Text style={{ fontSize: 10 }}>
                      {util?.calculatePercetage(sumOfPayableCommission, 18)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                borderColor: "black",
                borderBottomWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
              }}
            >
              <Text style={{ fontSize: 10, marginVertical: 10 }}>
                Tax Amount (in words){" "}
                <Text style={{ fontSize: 10, marginLeft: 5 }}>
                  {`INR ${util
                    ?.rupeesInWords(
                      util.calculatePercetage(sumOfPayableCommission, 18)
                    )
                    ?.toUpperCase()} ONLY`}
                </Text>
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                <View>
                  <Image
                    src={proteconsQR}
                    alt="img"
                    style={{ width: 70, height: 70, margin: 10 }}
                  />
                </View>
                <View style={{ justifyContent: "flex-start" }}>
                  <Text style={{ fontSize: 10 }}>Companies Bank Details</Text>
                  <Text style={{ fontSize: 10 }}>
                    Bank Name:{" "}
                    <Text style={{ marginLeft: 10 }}>ICICI BANK</Text>{" "}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    A/c No.:{" "}
                    <Text style={{ marginLeft: 10 }}>097605002562</Text>{" "}
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    Branch and IFSC Code:{" "}
                    <Text style={{}}>
                      Pilibhit, Uttar Pradesh & ICIC0000976
                    </Text>
                  </Text>
                  <View
                    style={{
                      borderColor: "black",
                      borderTopWidth: 1,
                      borderLeftWidth: 1,
                      paddingBottom: 50,
                    }}
                  >
                    <Text style={{ fontSize: 8, textAlign: "right" }}>
                      for HKG MERAPLACE LIMITED LIABILITY PARTNERSHIP
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View></View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
