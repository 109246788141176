import React, { useContext, useEffect, useState } from "react";

import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

import "./Wizard.css";
import Orders from "../Orders/Orders";
import PurchasingPlan from "../PurchasingPlan/PurchasingPlan";
import PackagingPlan from "../PackagingPlan/PackagingPlan";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import ManageDeliverySlots from "../ManageDeliverySlots/ManageDeliverySlots";
import OrderTimeConfig from "../OrderTimeConfig/OrderTimeConfig";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { toast } from "react-toastify";
const DeliverySlotWizard = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const formtabs = {
    manage_delivery_slot: "manage_delivery_slot",
    order_time_config: "order_time_config",
  };

  const intitializeTabs = () => [
    {
      tab_name: "Manage Delivery Slot",
      tab_value: "manage_delivery_slot",
      is_active: true,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
        ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
          .VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT
      ),
    },

    {
      tab_name: "Delivery Slot Config",
      tab_value: "order_time_config",
      is_active: false,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
        ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
          .VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG
      ),
    },
  ];
  const [orderStatusType, setOrderStatusType] = useState(intitializeTabs);

  const [actievTab, setActiveTab] = useState(formtabs.manage_delivery_slot);

  useEffect(() => {
    const findFirstEnabledItem = orderStatusType.find(
      (item) => item.is_permission_enabled
    );
    if (findFirstEnabledItem) {
      setActiveTab(findFirstEnabledItem.tab_value);
      setOrderStatusType((prev) =>
        prev.map((item) => ({
          ...item,
          is_active: item.tab_value === findFirstEnabledItem.tab_value,
        }))
      );
    }
  }, []);

  function getNextItem(index, array) {
    for (let i = index; i < array.length; i++) {
      if (array[i].is_permission_enabled) {
        return array[i];
      }
    }
  }
  function getPreviousItem(index, array) {
    for (let i = index - 1; i >= 0; i--) {
      if (array[i].is_permission_enabled) {
        return array[i];
      }
    }
  }

  const handleNavigation = (direction) => {
    const currentIndex = orderStatusType.findIndex((item) => item.is_active);
    const targetItem =
      direction === "next"
        ? getNextItem(currentIndex + 1, orderStatusType)
        : getPreviousItem(currentIndex, orderStatusType);

    if (targetItem) {
      setOrderStatusType((prev) =>
        prev.map((item) => ({
          ...item,
          is_active: item.tab_value === targetItem.tab_value,
        }))
      );
      setActiveTab(targetItem.tab_value);
    }
  };
  return (
    <>
      <div
        style={{
          height: "91%",
          paddingTop: 10,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <div
          className="d-flex justify-content-between "
          style={{ height: "5%" }}
        >
          <div
            className="row  ms-2 me-2
        border  border-1 border-bottom-1
        border-top-0 border-start-0 border-end-0 rounded-0"
          >
            {orderStatusType.length > 0 &&
              orderStatusType?.map((item, index) => {
                return (
                  <div className="col-auto " style={{ cursor: "pointer" }}>
                    <p
                      className={`mb-0 ${
                        item?.is_active
                          ? `border border-success border-2 border-bottom-1 border-top-0
                         border-start-0 border-end-0 rounded-0  text-success fs-5`
                          : "fs-5"
                      }   ${
                        item.is_permission_enabled === false
                          ? "text-secondary"
                          : ""
                      }`}
                      onClick={() => {
                        if (item?.is_permission_enabled) {
                          setOrderStatusType((prev) => {
                            const newData = [...prev];
                            newData.map((item) => (item.is_active = false));
                            newData[index].is_active = true;
                            return newData;
                          });
                          setActiveTab(item?.tab_value);
                        } else {
                          toast.error(
                            `You don't have permission to access ${item.tab_name}`,
                            {
                              position: "bottom-left",
                              autoClose: 2000,
                            }
                          );
                        }
                      }}
                    >
                      {item?.tab_name}
                      <FUSIcon
                        iconSrc={"bi"}
                        iconName={"BiChevronsRight"}
                        size={10}
                        className={"ms-2"}
                      />
                    </p>
                  </div>
                );
              })}
          </div>

          <div className="me-4 d-flex ">
            {actievTab !== formtabs.manage_delivery_slot && (
              <FUSButton
                onClick={() => handleNavigation("previous")}
                labelText={"Previous"}
                iconSrc={"bi"}
                iconName={"BiChevronsLeft"}
                size={10}
                className={"btn-sm px-3 py-1 me-2"}
                buttonType="secondary"
                buttonTextStyle
              />
            )}
            {orderStatusType.findIndex((item) => item.is_active) !==
              orderStatusType.length - 1 && (
              <FUSButton
                onClick={() => handleNavigation("next")}
                labelText={"Next"}
                iconSrc={"bi"}
                iconName={"BiChevronsRight"}
                size={10}
                className={"btn-sm px-3 py-1"}
                buttonType="primary"
                buttonTextStyle
              />
            )}
          </div>
        </div>

        {actievTab == formtabs.manage_delivery_slot && <ManageDeliverySlots />}
        {actievTab == formtabs.order_time_config && <OrderTimeConfig />}
      </div>
    </>
  );
};

export default DeliverySlotWizard;
