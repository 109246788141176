/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const JsonToXmlConverter = (props) => {
  const { agency_id, payment_type } = props;
  const [xml, setXml] = useState("");
  var day, month, year;
  const dateFormatter = (dates) => {
    const date = new Date(dates);
    day = date.getDate().toString().padStart(2, "0");
    month = (date.getMonth() + 1).toString().padStart(2, "0");
    year = date.getFullYear();

    return `${year}${month}${day}`;
  };
  const setPartyName = (names) => {
    const replaceName = names.replace("&", " &amp;");
    return replaceName;
  };
  const getPaymentType = (paymentType) => {
    switch (paymentType?.toUpperCase()) {
      case "CASH":
        return "Cash";
      case "ONLINE":
        return "Indian Bank Current account 7314260623";
    }
  };

  useEffect(() => {
    let COMPANY = "";
    let STATE = "";
    let BANKNAME = "";
    switch (agency_id) {
      case "fbd66a3c-c2b5-6cec-80ea-99ac40ec1fdf":
        COMPANY = "Gargi Enterprises (23-24)";
        STATE = "Uttar Pradesh";
        BANKNAME = "Indian Bank Current account 7314260623";
        break;
      case "ae81e637-04e7-19f0-2596-c56f90d10c92":
        COMPANY = "Mosaram Vyapar  (from 1-Apr-22)";
        STATE = "Uttar Pradesh";
        BANKNAME = "";
        break;
    }
    let strResult = "";
    let xmlData = "";
    switch (payment_type) {
      case "CASH":
        props.data?.forEach((item) => {
          let DATE = props?.flag
            ? dateFormatter(props?.date)
            : dateFormatter(new Date());
          let party_name = item?.party_name;
          let COLLECTOR_CODE = item?.collector_code;
          let AMOUNT = item?.payment;
          let REF_NO = item?.ref_no;
          let COLLECTOR_NAME = item?.collector_name_org;
          let PAYMENT_TYPE = getPaymentType(item?.payment_type);
          let PARTY_NAME = setPartyName(party_name);
          let ENTEREDBY = COMPANY;
          let NARRATION = `Being payment received ${COLLECTOR_CODE}`;

          strResult += `<TALLYMESSAGE xmlns:UDF="TallyUDF">
                            <VOUCHER  VCHTYPE="Receipt" ACTION="Create">
                            <OLDAUDITENTRYIDS.LIST TYPE="Number">
                                <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                            </OLDAUDITENTRYIDS.LIST>
                            <DATE>${DATE}</DATE>
                            <VCHSTATUSDATE>${DATE}</VCHSTATUSDATE>
                            <GUID></GUID>
                            <NARRATION>${NARRATION}</NARRATION>
                            <ENTEREDBY>${ENTEREDBY}</ENTEREDBY>
                            <GSTREGISTRATION TAXTYPE="GST" TAXREGISTRATION="">Uttar Pradesh Registration</GSTREGISTRATION>
                            <VOUCHERTYPENAME>Receipt</VOUCHERTYPENAME>
                            <PARTYLEDGERNAME>${PARTY_NAME}</PARTYLEDGERNAME>
                            <VOUCHERNUMBER></VOUCHERNUMBER>
                            <CMPGSTREGISTRATIONTYPE>Regular</CMPGSTREGISTRATIONTYPE>
                            <CMPGSTSTATE>Uttar Pradesh</CMPGSTSTATE>
                            <NUMBERINGSTYLE>Auto Retain</NUMBERINGSTYLE>
                            <CSTFORMISSUETYPE>&#4; Not Applicable</CSTFORMISSUETYPE>
                            <CSTFORMRECVTYPE>&#4; Not Applicable</CSTFORMRECVTYPE>
                            <FBTPAYMENTTYPE>Default</FBTPAYMENTTYPE>
                            <PERSISTEDVIEW>Accounting Voucher View</PERSISTEDVIEW>
                            <VCHSTATUSTAXADJUSTMENT>Default</VCHSTATUSTAXADJUSTMENT>
                            <VCHSTATUSVOUCHERTYPE>Receipt</VCHSTATUSVOUCHERTYPE>
                            <VCHSTATUSTAXUNIT>Uttar Pradesh Registration</VCHSTATUSTAXUNIT>
                            <VCHGSTCLASS>&#4; Not Applicable</VCHGSTCLASS>
                            <DIFFACTUALQTY>No</DIFFACTUALQTY>
                            <ISMSTFROMSYNC>No</ISMSTFROMSYNC>
                            <ISDELETED>No</ISDELETED>
                            <ISSECURITYONWHENENTERED>No</ISSECURITYONWHENENTERED>
                            <ASORIGINAL>No</ASORIGINAL>
                            <AUDITED>No</AUDITED>
                            <ISCOMMONPARTY>No</ISCOMMONPARTY>
                            <FORJOBCOSTING>No</FORJOBCOSTING>
                            <ISOPTIONAL>No</ISOPTIONAL>
                            <USEFOREXCISE>No</USEFOREXCISE>
                            <ISFORJOBWORKIN>No</ISFORJOBWORKIN>
                            <ALLOWCONSUMPTION>No</ALLOWCONSUMPTION>
                            <USEFORINTEREST>No</USEFORINTEREST>
                            <USEFORGAINLOSS>No</USEFORGAINLOSS>
                            <USEFORGODOWNTRANSFER>No</USEFORGODOWNTRANSFER>
                            <USEFORCOMPOUND>No</USEFORCOMPOUND>
                            <USEFORSERVICETAX>No</USEFORSERVICETAX>
                            <ISREVERSECHARGEAPPLICABLE>No</ISREVERSECHARGEAPPLICABLE>
                            <ISSYSTEM>No</ISSYSTEM>
                            <ISFETCHEDONLY>No</ISFETCHEDONLY>
                            <ISGSTOVERRIDDEN>No</ISGSTOVERRIDDEN>
                            <ISCANCELLED>No</ISCANCELLED>
                            <ISONHOLD>No</ISONHOLD>
                            <ISSUMMARY>No</ISSUMMARY>
                            <ISECOMMERCESUPPLY>No</ISECOMMERCESUPPLY>
                            <ISBOENOTAPPLICABLE>No</ISBOENOTAPPLICABLE>
                            <ISGSTSECSEVENAPPLICABLE>No</ISGSTSECSEVENAPPLICABLE>
                            <IGNOREEINVVALIDATION>No</IGNOREEINVVALIDATION>
                            <CMPGSTISOTHTERRITORYASSESSEE>No</CMPGSTISOTHTERRITORYASSESSEE>
                            <PARTYGSTISOTHTERRITORYASSESSEE>No</PARTYGSTISOTHTERRITORYASSESSEE>
                            <IRNJSONEXPORTED>No</IRNJSONEXPORTED>
                            <IRNCANCELLED>No</IRNCANCELLED>
                            <IGNOREGSTCONFLICTINMIG>No</IGNOREGSTCONFLICTINMIG>
                            <ISOPBALTRANSACTION>No</ISOPBALTRANSACTION>
                            <IGNOREGSTFORMATVALIDATION>No</IGNOREGSTFORMATVALIDATION>
                            <ISELIGIBLEFORITC>Yes</ISELIGIBLEFORITC>
                            <UPDATESUMMARYVALUES>No</UPDATESUMMARYVALUES>
                            <ISEWAYBILLAPPLICABLE>No</ISEWAYBILLAPPLICABLE>
                            <ISDELETEDRETAINED>No</ISDELETEDRETAINED>
                            <ISNULL>No</ISNULL>
                            <ISEXCISEVOUCHER>No</ISEXCISEVOUCHER>
                            <EXCISETAXOVERRIDE>No</EXCISETAXOVERRIDE>
                            <USEFORTAXUNITTRANSFER>No</USEFORTAXUNITTRANSFER>
                            <ISEXER1NOPOVERWRITE>No</ISEXER1NOPOVERWRITE>
                            <ISEXF2NOPOVERWRITE>No</ISEXF2NOPOVERWRITE>
                            <ISEXER3NOPOVERWRITE>No</ISEXER3NOPOVERWRITE>
                            <IGNOREPOSVALIDATION>No</IGNOREPOSVALIDATION>
                            <EXCISEOPENING>No</EXCISEOPENING>
                            <USEFORFINALPRODUCTION>No</USEFORFINALPRODUCTION>
                            <ISTDSOVERRIDDEN>No</ISTDSOVERRIDDEN>
                            <ISTCSOVERRIDDEN>No</ISTCSOVERRIDDEN>
                            <ISTDSTCSCASHVCH>No</ISTDSTCSCASHVCH>
                            <INCLUDEADVPYMTVCH>No</INCLUDEADVPYMTVCH>
                            <ISSUBWORKSCONTRACT>No</ISSUBWORKSCONTRACT>
                            <ISVATOVERRIDDEN>No</ISVATOVERRIDDEN>
                            <IGNOREORIGVCHDATE>No</IGNOREORIGVCHDATE>
                            <ISVATPAIDATCUSTOMS>No</ISVATPAIDATCUSTOMS>
                            <ISDECLAREDTOCUSTOMS>No</ISDECLAREDTOCUSTOMS>
                            <VATADVANCEPAYMENT>No</VATADVANCEPAYMENT>
                            <VATADVPAY>No</VATADVPAY>
                            <ISCSTDELCAREDGOODSSALES>No</ISCSTDELCAREDGOODSSALES>
                            <ISVATRESTAXINV>No</ISVATRESTAXINV>
                            <ISSERVICETAXOVERRIDDEN>No</ISSERVICETAXOVERRIDDEN>
                            <ISISDVOUCHER>No</ISISDVOUCHER>
                            <ISEXCISEOVERRIDDEN>No</ISEXCISEOVERRIDDEN>
                            <ISEXCISESUPPLYVCH>No</ISEXCISESUPPLYVCH>
                            <GSTNOTEXPORTED>No</GSTNOTEXPORTED>
                            <IGNOREGSTINVALIDATION>No</IGNOREGSTINVALIDATION>
                            <ISGSTREFUND>No</ISGSTREFUND>
                            <OVRDNEWAYBILLAPPLICABILITY>No</OVRDNEWAYBILLAPPLICABILITY>
                            <ISVATPRINCIPALACCOUNT>No</ISVATPRINCIPALACCOUNT>
                            <VCHSTATUSISVCHNUMUSED>No</VCHSTATUSISVCHNUMUSED>
                            <VCHGSTSTATUSISINCLUDED>No</VCHGSTSTATUSISINCLUDED>
                            <VCHGSTSTATUSISUNCERTAIN>No</VCHGSTSTATUSISUNCERTAIN>
                            <VCHGSTSTATUSISEXCLUDED>No</VCHGSTSTATUSISEXCLUDED>
                            <VCHGSTSTATUSISAPPLICABLE>No</VCHGSTSTATUSISAPPLICABLE>
                            <VCHGSTSTATUSISGSTR2BRECONCILED>No</VCHGSTSTATUSISGSTR2BRECONCILED>
                            <VCHGSTSTATUSISGSTR2BONLYINPORTAL>No</VCHGSTSTATUSISGSTR2BONLYINPORTAL>
                            <VCHGSTSTATUSISGSTR2BONLYINBOOKS>No</VCHGSTSTATUSISGSTR2BONLYINBOOKS>
                            <VCHGSTSTATUSISGSTR2BMISMATCH>No</VCHGSTSTATUSISGSTR2BMISMATCH>
                            <VCHGSTSTATUSISGSTR2BINDIFFPERIOD>No</VCHGSTSTATUSISGSTR2BINDIFFPERIOD>
                            <VCHGSTSTATUSISRETEFFDATEOVERRDN>No</VCHGSTSTATUSISRETEFFDATEOVERRDN>
                            <VCHGSTSTATUSISOVERRDN>No</VCHGSTSTATUSISOVERRDN>
                            <VCHGSTSTATUSISSTATINDIFFDATE>No</VCHGSTSTATUSISSTATINDIFFDATE>
                            <VCHGSTSTATUSISRETINDIFFDATE>No</VCHGSTSTATUSISRETINDIFFDATE>
                            <VCHGSTSTATUSMAINSECTIONEXCLUDED>No</VCHGSTSTATUSMAINSECTIONEXCLUDED>
                            <VCHGSTSTATUSISBRANCHTRANSFEROUT>No</VCHGSTSTATUSISBRANCHTRANSFEROUT>
                            <VCHGSTSTATUSISSYSTEMSUMMARY>No</VCHGSTSTATUSISSYSTEMSUMMARY>
                            <VCHSTATUSISUNREGISTEREDRCM>No</VCHSTATUSISUNREGISTEREDRCM>
                            <VCHSTATUSISOPTIONAL>No</VCHSTATUSISOPTIONAL>
                            <VCHSTATUSISCANCELLED>No</VCHSTATUSISCANCELLED>
                            <VCHSTATUSISDELETED>No</VCHSTATUSISDELETED>
                            <VCHSTATUSISOPENINGBALANCE>No</VCHSTATUSISOPENINGBALANCE>
                            <VCHSTATUSISFETCHEDONLY>No</VCHSTATUSISFETCHEDONLY>
                            <PAYMENTLINKHASMULTIREF>No</PAYMENTLINKHASMULTIREF>
                            <ISSHIPPINGWITHINSTATE>No</ISSHIPPINGWITHINSTATE>
                            <ISOVERSEASTOURISTTRANS>No</ISOVERSEASTOURISTTRANS>
                            <ISDESIGNATEDZONEPARTY>No</ISDESIGNATEDZONEPARTY>
                            <HASCASHFLOW>Yes</HASCASHFLOW>
                            <ISPOSTDATED>No</ISPOSTDATED>
                            <USETRACKINGNUMBER>No</USETRACKINGNUMBER>
                            <ISINVOICE>No</ISINVOICE>
                            <MFGJOURNAL>No</MFGJOURNAL>
                            <HASDISCOUNTS>No</HASDISCOUNTS>
                            <ASPAYSLIP>No</ASPAYSLIP>
                            <ISCOSTCENTRE>No</ISCOSTCENTRE>
                            <ISSTXNONREALIZEDVCH>No</ISSTXNONREALIZEDVCH>
                            <ISEXCISEMANUFACTURERON>No</ISEXCISEMANUFACTURERON>
                            <ISBLANKCHEQUE>No</ISBLANKCHEQUE>
                            <ISVOID>No</ISVOID>
                            <ORDERLINESTATUS>No</ORDERLINESTATUS>
                            <VATISAGNSTCANCSALES>No</VATISAGNSTCANCSALES>
                            <VATISPURCEXEMPTED>No</VATISPURCEXEMPTED>
                            <ISVATRESTAXINVOICE>No</ISVATRESTAXINVOICE>
                            <VATISASSESABLECALCVCH>No</VATISASSESABLECALCVCH>
                            <ISVATDUTYPAID>Yes</ISVATDUTYPAID>
                            <ISDELIVERYSAMEASCONSIGNEE>No</ISDELIVERYSAMEASCONSIGNEE>
                            <ISDISPATCHSAMEASCONSIGNOR>No</ISDISPATCHSAMEASCONSIGNOR>
                            <ISDELETEDVCHRETAINED>No</ISDELETEDVCHRETAINED>
                            <CHANGEVCHMODE>No</CHANGEVCHMODE>
                            <RESETIRNQRCODE>No</RESETIRNQRCODE>
                            <VOUCHERNUMBERSERIES>Default</VOUCHERNUMBERSERIES>
                            <ALLLEDGERENTRIES.LIST>
                                <OLDAUDITENTRYIDS.LIST TYPE="Number">
                                    <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                                </OLDAUDITENTRYIDS.LIST>
                                <APPROPRIATEFOR>&#4; Not Applicable</APPROPRIATEFOR>
                                <LEDGERNAME>${PARTY_NAME}</LEDGERNAME>
                                <GSTCLASS>&#4; Not Applicable</GSTCLASS>
                                <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                                <LEDGERFROMITEM>No</LEDGERFROMITEM>
                                <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                                <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
                                <GSTOVERRIDDEN>No</GSTOVERRIDDEN>
                                <ISGSTASSESSABLEVALUEOVERRIDDEN>No</ISGSTASSESSABLEVALUEOVERRIDDEN>
                                <STRDISGSTAPPLICABLE>No</STRDISGSTAPPLICABLE>
                                <STRDGSTISPARTYLEDGER>No</STRDGSTISPARTYLEDGER>
                                <STRDGSTISDUTYLEDGER>No</STRDGSTISDUTYLEDGER>
                                <CONTENTNEGISPOS>No</CONTENTNEGISPOS>
                                <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                                <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                                <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                                <AMOUNT>${AMOUNT}</AMOUNT>
                                <BILLALLOCATIONS.LIST>
                                <NAME>${REF_NO}</NAME>
                                <BILLTYPE>Agst Ref</BILLTYPE>
                                <TDSDEDUCTEEISSPECIALRATE>No</TDSDEDUCTEEISSPECIALRATE>
                                <AMOUNT>${AMOUNT}</AMOUNT>
                                <INTERESTCOLLECTION.LIST></INTERESTCOLLECTION.LIST>
                                <STBILLCATEGORIES.LIST></STBILLCATEGORIES.LIST>
                        </BILLALLOCATIONS.LIST>
                            </ALLLEDGERENTRIES.LIST>
                            <ALLLEDGERENTRIES.LIST>
                                <OLDAUDITENTRYIDS.LIST TYPE="Number">
                                <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                                </OLDAUDITENTRYIDS.LIST>
                                <LEDGERNAME>Cash</LEDGERNAME>
                                <GSTCLASS>&#4; Not Applicable</GSTCLASS>
                                <ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>
                                <LEDGERFROMITEM>No</LEDGERFROMITEM>
                                <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                                <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
                                <GSTOVERRIDDEN>No</GSTOVERRIDDEN>
                                <ISGSTASSESSABLEVALUEOVERRIDDEN>No</ISGSTASSESSABLEVALUEOVERRIDDEN>
                                <STRDISGSTAPPLICABLE>No</STRDISGSTAPPLICABLE>
                                <STRDGSTISPARTYLEDGER>No</STRDGSTISPARTYLEDGER>
                                <STRDGSTISDUTYLEDGER>No</STRDGSTISDUTYLEDGER>
                                <CONTENTNEGISPOS>No</CONTENTNEGISPOS>
                                <ISLASTDEEMEDPOSITIVE>Yes</ISLASTDEEMEDPOSITIVE>
                                <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                                <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                                <AMOUNT>-${AMOUNT}</AMOUNT>
                            </ALLLEDGERENTRIES.LIST>
                        </VOUCHER>
                    </TALLYMESSAGE>`;
        });

        xmlData = ` <ENVELOPE>
                <HEADER>
                    <TALLYREQUEST>Import Data</TALLYREQUEST>
                </HEADER>
                <BODY>
                <IMPORTDATA>
                <REQUESTDESC>
                    <REPORTNAME>Vouchers</REPORTNAME>
                    <STATICVARIABLES>
                        <SVCURRENTCOMPANY>${COMPANY}</SVCURRENTCOMPANY>
                    </STATICVARIABLES>
                </REQUESTDESC>
                <REQUESTDATA>
                ${strResult}    
                <TALLYMESSAGE xmlns:UDF="TallyUDF">
                <COMPANY>
                    <REMOTECMPINFO.LIST MERGE="Yes">
                        <REMOTECMPNAME>${COMPANY}</REMOTECMPNAME>
                        <REMOTECMPSTATE>${STATE}</REMOTECMPSTATE>
                    </REMOTECMPINFO.LIST>
                </COMPANY>
                </TALLYMESSAGE>
                <TALLYMESSAGE xmlns:UDF="TallyUDF">
                    <COMPANY>
                        <REMOTECMPINFO.LIST MERGE="Yes">
                            <REMOTECMPNAME>${COMPANY}</REMOTECMPNAME>
                            <REMOTECMPSTATE>${STATE}</REMOTECMPSTATE>
                        </REMOTECMPINFO.LIST>
                    </COMPANY>
                </TALLYMESSAGE>
                </REQUESTDATA>
                        </IMPORTDATA>
                    </BODY>
                </ENVELOPE>`;
        break;
      case "ONLINE":
        props.data?.forEach((item) => {
          let DATE = props?.flag
            ? dateFormatter(props?.date)
            : dateFormatter(new Date());
          let party_name = item?.party_name;
          let COLLECTOR_CODE = item?.collector_code;
          let AMOUNT = item?.payment;
          let REF_NO = item?.ref_no;
          let COLLECTOR_NAME = item?.collector_name_org;
          let PAYMENT_TYPE = getPaymentType(item?.payment_type);
          let PARTY_NAME = setPartyName(party_name);
          let ENTEREDBY = COMPANY;
          let NARRATION = `Being payment received ${COLLECTOR_CODE}`;

          strResult += `
                         <TALLYMESSAGE xmlns:UDF="TallyUDF">
                         <VOUCHER VCHTYPE="Bank Receipt" ACTION="Create" OBJVIEW="Accounting Voucher View">
                          <OLDAUDITENTRYIDS.LIST TYPE="Number">
                           <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                          </OLDAUDITENTRYIDS.LIST>
                          <DATE>${DATE}</DATE>
                          <GUID></GUID>
                          <VOUCHERTYPENAME>Bank Receipt</VOUCHERTYPENAME>
                          <NARRATION>${NARRATION}</NARRATION>
                          <ENTEREDBY>${ENTEREDBY}</ENTEREDBY>
                          <PARTYLEDGERNAME>${PARTY_NAME}</PARTYLEDGERNAME>
                          <VOUCHERNUMBER></VOUCHERNUMBER>
                          <CSTFORMISSUETYPE/>
                          <CSTFORMRECVTYPE/>
                          <FBTPAYMENTTYPE>Default</FBTPAYMENTTYPE>
                          <PERSISTEDVIEW>Accounting Voucher View</PERSISTEDVIEW>
                          <VCHGSTCLASS/>
                          <VOUCHERTYPEORIGNAME>Bank Receipt</VOUCHERTYPEORIGNAME>
                          <DIFFACTUALQTY>Yes</DIFFACTUALQTY>
                          <ISMSTFROMSYNC>No</ISMSTFROMSYNC>
                          <ISDELETED>No</ISDELETED>
                          <ISSECURITYONWHENENTERED>No</ISSECURITYONWHENENTERED>
                          <ASORIGINAL>No</ASORIGINAL>
                          <AUDITED>No</AUDITED>
                          <FORJOBCOSTING>No</FORJOBCOSTING>
                          <ISOPTIONAL>No</ISOPTIONAL>
                          <EFFECTIVEDATE>${DATE}</EFFECTIVEDATE>
                          <USEFOREXCISE>No</USEFOREXCISE>
                          <ISFORJOBWORKIN>No</ISFORJOBWORKIN>
                          <ALLOWCONSUMPTION>No</ALLOWCONSUMPTION>
                          <USEFORINTEREST>No</USEFORINTEREST>
                          <USEFORGAINLOSS>No</USEFORGAINLOSS>
                          <USEFORGODOWNTRANSFER>No</USEFORGODOWNTRANSFER>
                          <USEFORCOMPOUND>No</USEFORCOMPOUND>
                          <USEFORSERVICETAX>No</USEFORSERVICETAX>
                          <ISONHOLD>No</ISONHOLD>
                          <ISBOENOTAPPLICABLE>No</ISBOENOTAPPLICABLE>
                          <ISGSTSECSEVENAPPLICABLE>No</ISGSTSECSEVENAPPLICABLE>
                          <ISEXCISEVOUCHER>No</ISEXCISEVOUCHER>
                          <EXCISETAXOVERRIDE>No</EXCISETAXOVERRIDE>
                          <USEFORTAXUNITTRANSFER>No</USEFORTAXUNITTRANSFER>
                          <IGNOREPOSVALIDATION>No</IGNOREPOSVALIDATION>
                          <EXCISEOPENING>No</EXCISEOPENING>
                          <USEFORFINALPRODUCTION>No</USEFORFINALPRODUCTION>
                          <ISTDSOVERRIDDEN>No</ISTDSOVERRIDDEN>
                          <ISTCSOVERRIDDEN>No</ISTCSOVERRIDDEN>
                          <ISTDSTCSCASHVCH>No</ISTDSTCSCASHVCH>
                          <INCLUDEADVPYMTVCH>No</INCLUDEADVPYMTVCH>
                          <ISSUBWORKSCONTRACT>No</ISSUBWORKSCONTRACT>
                          <ISVATOVERRIDDEN>No</ISVATOVERRIDDEN>
                          <IGNOREORIGVCHDATE>No</IGNOREORIGVCHDATE>
                          <ISVATPAIDATCUSTOMS>No</ISVATPAIDATCUSTOMS>
                          <ISDECLAREDTOCUSTOMS>No</ISDECLAREDTOCUSTOMS>
                          <ISSERVICETAXOVERRIDDEN>No</ISSERVICETAXOVERRIDDEN>
                          <ISISDVOUCHER>No</ISISDVOUCHER>
                          <ISEXCISEOVERRIDDEN>No</ISEXCISEOVERRIDDEN>
                          <ISEXCISESUPPLYVCH>No</ISEXCISESUPPLYVCH>
                          <ISGSTOVERRIDDEN>No</ISGSTOVERRIDDEN>
                          <GSTNOTEXPORTED>No</GSTNOTEXPORTED>
                          <IGNOREGSTINVALIDATION>No</IGNOREGSTINVALIDATION>
                          <ISGSTREFUND>No</ISGSTREFUND>
                          <OVRDNEWAYBILLAPPLICABILITY>No</OVRDNEWAYBILLAPPLICABILITY>
                          <ISVATPRINCIPALACCOUNT>No</ISVATPRINCIPALACCOUNT>
                          <IGNOREEINVVALIDATION>No</IGNOREEINVVALIDATION>
                          <IRNJSONEXPORTED>No</IRNJSONEXPORTED>
                          <IRNCANCELLED>No</IRNCANCELLED>
                          <ISSHIPPINGWITHINSTATE>No</ISSHIPPINGWITHINSTATE>
                          <ISOVERSEASTOURISTTRANS>No</ISOVERSEASTOURISTTRANS>
                          <ISDESIGNATEDZONEPARTY>No</ISDESIGNATEDZONEPARTY>
                          <ISCANCELLED>No</ISCANCELLED>
                          <HASCASHFLOW>Yes</HASCASHFLOW>
                          <ISPOSTDATED>No</ISPOSTDATED>
                          <USETRACKINGNUMBER>No</USETRACKINGNUMBER>
                          <ISINVOICE>No</ISINVOICE>
                          <MFGJOURNAL>No</MFGJOURNAL>
                          <HASDISCOUNTS>No</HASDISCOUNTS>
                          <ASPAYSLIP>No</ASPAYSLIP>
                          <ISCOSTCENTRE>No</ISCOSTCENTRE>
                          <ISSTXNONREALIZEDVCH>No</ISSTXNONREALIZEDVCH>
                          <ISEXCISEMANUFACTURERON>No</ISEXCISEMANUFACTURERON>
                          <ISBLANKCHEQUE>No</ISBLANKCHEQUE>
                          <ISVOID>No</ISVOID>
                          <ORDERLINESTATUS>No</ORDERLINESTATUS>
                          <VATISAGNSTCANCSALES>No</VATISAGNSTCANCSALES>
                          <VATISPURCEXEMPTED>No</VATISPURCEXEMPTED>
                          <ISVATRESTAXINVOICE>No</ISVATRESTAXINVOICE>
                          <VATISASSESABLECALCVCH>No</VATISASSESABLECALCVCH>
                          <ISVATDUTYPAID>Yes</ISVATDUTYPAID>
                          <ISDELIVERYSAMEASCONSIGNEE>No</ISDELIVERYSAMEASCONSIGNEE>
                          <ISDISPATCHSAMEASCONSIGNOR>No</ISDISPATCHSAMEASCONSIGNOR>
                          <ISDELETEDVCHRETAINED>No</ISDELETEDVCHRETAINED>
                          <CHANGEVCHMODE>No</CHANGEVCHMODE>
                          <RESETIRNQRCODE>No</RESETIRNQRCODE>
                          <ALTERID></ALTERID>
                          <MASTERID></MASTERID>
                          <VOUCHERKEY></VOUCHERKEY>
                          <ALLLEDGERENTRIES.LIST>
                           <OLDAUDITENTRYIDS.LIST TYPE="Number">
                            <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                           </OLDAUDITENTRYIDS.LIST>
                           <LEDGERNAME>${PARTY_NAME}</LEDGERNAME>
                           <GSTCLASS/>
                           <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                           <LEDGERFROMITEM>No</LEDGERFROMITEM>
                           <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                           <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
                           <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                           <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                           <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                           <AMOUNT>${AMOUNT}</AMOUNT>
                           <VATEXPAMOUNT>${AMOUNT}</VATEXPAMOUNT>
                           <SERVICETAXDETAILS.LIST></SERVICETAXDETAILS.LIST>
                           <BANKALLOCATIONS.LIST></BANKALLOCATIONS.LIST>
                           <BILLALLOCATIONS.LIST>
                            <NAME>${REF_NO}</NAME>
                            <BILLTYPE>Agst Ref</BILLTYPE>
                            <TDSDEDUCTEEISSPECIALRATE>No</TDSDEDUCTEEISSPECIALRATE>
                            <AMOUNT>${AMOUNT}</AMOUNT>
                            <INTERESTCOLLECTION.LIST></INTERESTCOLLECTION.LIST>
                            <STBILLCATEGORIES.LIST></STBILLCATEGORIES.LIST>
                           </BILLALLOCATIONS.LIST>
                          </ALLLEDGERENTRIES.LIST>
                          <ALLLEDGERENTRIES.LIST>
                           <OLDAUDITENTRYIDS.LIST TYPE="Number">
                            <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                           </OLDAUDITENTRYIDS.LIST>
                           <LEDGERNAME>${BANKNAME}</LEDGERNAME>
                           <GSTCLASS/>
                           <ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>
                           <LEDGERFROMITEM>No</LEDGERFROMITEM>
                           <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                           <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
                           <ISLASTDEEMEDPOSITIVE>Yes</ISLASTDEEMEDPOSITIVE>
                           <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                           <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                           <AMOUNT>-${AMOUNT}</AMOUNT>
                           <VATEXPAMOUNT>-${AMOUNT}</VATEXPAMOUNT>
                           <SERVICETAXDETAILS.LIST></SERVICETAXDETAILS.LIST>
                           <BANKALLOCATIONS.LIST>
                            <DATE>${DATE}</DATE>
                            <INSTRUMENTDATE>${DATE}</INSTRUMENTDATE>
                            <NAME></NAME>
                            <TRANSACTIONTYPE>Cheque/DD</TRANSACTIONTYPE>
                            <PAYMENTFAVOURING>${PARTY_NAME}</PAYMENTFAVOURING>
                            <UNIQUEREFERENCENUMBER></UNIQUEREFERENCENUMBER>
                            <STATUS>No</STATUS>
                            <PAYMENTMODE>Transacted</PAYMENTMODE>
                            <SECONDARYSTATUS/>
                            <BANKPARTYNAME>${PARTY_NAME}</BANKPARTYNAME>
                            <ISCONNECTEDPAYMENT>No</ISCONNECTEDPAYMENT>
                            <ISSPLIT>No</ISSPLIT>
                            <ISCONTRACTUSED>No</ISCONTRACTUSED>
                            <ISACCEPTEDWITHWARNING>No</ISACCEPTEDWITHWARNING>
                            <ISTRANSFORCED>No</ISTRANSFORCED>
                            <CHEQUEPRINTED> 1</CHEQUEPRINTED>
                            <AMOUNT>-${AMOUNT}</AMOUNT>
                            <CONTRACTDETAILS.LIST></CONTRACTDETAILS.LIST>
                            <BANKSTATUSINFO.LIST></BANKSTATUSINFO.LIST>                        
                           </BANKALLOCATIONS.LIST> 
                           </ALLLEDGERENTRIES.LIST>                   
                          </VOUCHER>
                        </TALLYMESSAGE>`;
        });

        xmlData = ` 
                <ENVELOPE>
                <HEADER>
                 <TALLYREQUEST>Import Data</TALLYREQUEST>
                </HEADER>
                <BODY>
                 <IMPORTDATA>
                  <REQUESTDESC>
                   <REPORTNAME>Vouchers</REPORTNAME>
                   <STATICVARIABLES>
                    <SVCURRENTCOMPANY>${COMPANY}</SVCURRENTCOMPANY>
                   </STATICVARIABLES>
                  </REQUESTDESC>
                  <REQUESTDATA>
                ${strResult} 
                <TALLYMESSAGE xmlns:UDF="TallyUDF">
                <COMPANY>
                <REMOTECMPINFO.LIST MERGE="Yes">
                <REMOTECMPNAME>${COMPANY}</REMOTECMPNAME>
                <REMOTECMPSTATE>${STATE}</REMOTECMPSTATE>
                </REMOTECMPINFO.LIST>
                </COMPANY>
                </TALLYMESSAGE>
                <TALLYMESSAGE xmlns:UDF="TallyUDF">
                    <COMPANY>
                    <REMOTECMPINFO.LIST MERGE="Yes">
                    <REMOTECMPNAME>${COMPANY}</REMOTECMPNAME>
                    <REMOTECMPSTATE>${STATE}</REMOTECMPSTATE>
                    </REMOTECMPINFO.LIST>
                    </COMPANY>
                </TALLYMESSAGE>
                </REQUESTDATA>
                </IMPORTDATA>
                </BODY>
                </ENVELOPE>`;

        break;
    }

    setXml(xmlData);
  }, [props.data, dateFormatter]);

  const handleDownload = () => {
    const blob = new Blob([xml], { type: "application/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${year}${month}${day}.xml`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <span>
      <FUSButton
        buttonTextStyle="fw-0 fs-6"
        className={"py-2"}
        buttonType="primary"
        onClick={handleDownload}
        labelText={`${payment_type} XML File`}
      />
    </span>
  );
};

export default JsonToXmlConverter;
