import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DataTable from "../DataTable/DataTable";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { toast } from "react-toastify";
const AgencyList = () => {
  const [agencyList, setAgencyList] = useState([]);
  const [collectorList, setCollectorList] = useState([]);
  const [reload, setReload] = useState(true);
  const [pagination, setPagination] = useState(1);
  const [agencyId, setAgencyId] = useState(null);

  const SelectAgency = (id) => {
    MstService.getCollectorByAgentId({ agent_id: id })
      .then((response) => {
        setCollectorList(response?.data?.data);
        setPagination(1);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error, "/");
      });
    setReload((value) => !value);
    setAgencyId(id);
  };

  const getCollectors = (id) => {
    MstService.getCollectorByAgentId({ agent_id: id })
      .then((response) => {
        setCollectorList(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error, "/");
      });
  };

  const handleState = (row) => {
    MstService.changeSubmission({ collector_id: row?.collector_id })
      .then((response) => {
        getCollectors(agencyId);
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error, "/");
      });
  };

  const columns = [
    {
      dataField: "name",
      text: "Collector Name",
      sort: true,
    },

    {
      dataField: "action",
      text: "Is Collector Submitted",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_collector_submitted ? (
              <button
                className="btn btn-success btn-xs"
                onClick={() => handleState(row)}
              >
                Submitted
              </button>
            ) : (
              <button disabled className="btn btn-danger btn-xs">
                Not Submitted
              </button>
            )}
          </>
        );
      },
      editable: false,
    },
  ];

  const getAgentDetails = async () => {
    await MstService.getAgentDetails()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setAgencyList(response?.data?.data);
        } else {
          setAgencyList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error, "/");
      });
  };
  useEffect(() => {
    getAgentDetails();
  }, [reload]);

  return (
    <>
      <LayoutContainer
        title1="Agency List"
        title2="Agency List"
        title3="Agency List"
      >
        <div className="py-2">
          <div className="border_bottom_style ">
            <div className="col-md-3 px-3">
              <span className="text-secondary">Select Agency</span>
              <select
                className="form-select mt-1"
                onChange={(e) => SelectAgency(e.target.value)}
              >
                {agencyList?.length > 0 &&
                  agencyList?.map((curElem) => {
                    return (
                      <option value={curElem.id}>{curElem.agent_name}</option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="px-3">
            <DataTable
              id={"agencyList"}
              data={collectorList}
              columns={columns}
              sizePerPage={5}
              page={pagination}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default AgencyList;
