import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { Col, Row } from "react-bootstrap";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import { toast } from "react-toastify";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import DataTable from "../DataTable/DataTable";
const NotificationMapping = () => {
  const [notificationTemplateData, setNotificationTemplateData] = useState([]);
  const [notificationType, setNotificationType] = useState([]);
  const [notificationMappingData, setNotificationMappingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const findFormErrors = () => {
    const { notification_type_id, template_id } = form;
    const newErrors = {};
    if (!notification_type_id || notification_type_id === "-1")
      newErrors.notification_type_id = "Type cannot be blank!";
    if (!template_id || template_id === "-1") {
      newErrors.template_id = "Title cannot be blank!";
    }

    return newErrors;
  };

  const setField = (field, value) => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
    setForm({
      ...form,
      [field]: value,
    });
  };

  const getNotificationTemplate = async () => {
    await VendorService?.getNotificationTemplate()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setNotificationTemplateData(response.data.data);
        } else {
          setNotificationTemplateData([]);
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  const notificationMapping = async () => {
    try {
      const response = await VendorService?.createNotificationMapping(form);
      if (response?.data?.error_code === 0) {
        setIsLoading((prev) => !prev);
        setForm({});
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else {
        const errorToast = toast.error(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });

        setTimeout(() => {
          toast.dismiss(errorToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      notificationMapping();
      setShowModal((prev) => ({ ...prev, state: false }));
    }
  };

  const handleAction = async (action, id) => {
    const postdata = { id: id };
    try {
      let result;
      if (action === "delete") {
        result = await VendorService.deleteNotificationMapping(postdata);
      } else if (action === "status") {
        result = await VendorService.updateNotificationMappingStatus(postdata);
      }

      if (result?.data?.errorCode === 0) {
        setIsLoading((prev) => !prev);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getNotificationType = async () => {
    try {
      const response = await VendorService?.getNotificationType();
      if (response?.data?.errorCode === 0) {
        setNotificationType(response?.data?.data);
      } else {
        setNotificationType([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getNotificationTemplate();
    getNotificationType();
  }, []);

  const GetAction = (cell, row) => {
    return (
      <div className="d-flex gap-2">
        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdDeleteForever"
          iconSize={15}
          buttonType="lightdanger"
          onClick={() => handleAction("delete", row.id)}
        />

        <FUSButton
          className={"py-1 fs-6"}
          buttonType={row.is_active === true ? "danger" : "primary"}
          labelText={row.is_active === true ? "Deactivate" : "Activate"}
          onClick={() => handleAction("status", row.id)}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Type",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "body",
      text: "Body",
      sort: true,
    },
    {
      text: "Action",
      dataField: "",
      formatter: GetAction,
    },
  ];

  useEffect(() => {
    const getNotificationMapping = async () => {
      try {
        const response = await VendorService.getNotificationMapping();
        if (response?.data?.errorCode === 0) {
          setNotificationMappingData(response.data.data);
        } else {
          setNotificationMappingData([]);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    };

    getNotificationMapping();
  }, [isLoading]);
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Notification Mapping"
        right={
          <FUSButton
            iconSrc={"fa6"}
            iconSize={14}
            buttonType="primary"
            labelText={"+ Add Notification Mapping"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "CREATE_NOTIFICATION_MAPPING",
              }));
              setForm({});
            }}
          />
        }
      >
        {notificationMappingData.length > 0 ? (
          <div className="px-3">
            <DataTable
              id={"notificationMappingData"}
              data={notificationMappingData}
              columns={columns}
              sizePerPage={5}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList title={"No Notification Mapping Found"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>

      {showModal.state && (
        <FUSModal
          title="Create Notification Mapping"
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setErrors({});
          }}
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col xs={12} md={12} className="px-0 pb-3">
                    <div>
                      <span className="text-secondary">Notification Type</span>
                      <select
                        className="form-select text_style mt-1"
                        onChange={(e) =>
                          setField("notification_type_id", e.target.value)
                        }
                        value={form?.notification_type_id}
                      >
                        <option value={-1}>Select</option>
                        {notificationType.length > 0 &&
                          notificationType.map((item) => (
                            <option value={item.notification_type_id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {errors.notification_type_id && (
                        <p className="text-warning">
                          {errors.notification_type_id}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0 pb-3">
                    <div>
                      <span className="text-secondary">
                        Notification Template
                      </span>
                      <select
                        className="form-select text_style mt-1"
                        onChange={(e) =>
                          setField("template_id", e.target.value)
                        }
                        value={form?.template_id}
                      >
                        <option value={-1}>Select</option>
                        {notificationTemplateData.length > 0 &&
                          notificationTemplateData.map((item) => (
                            <option value={item.template_id}>
                              {item.title}, {item.body}
                            </option>
                          ))}
                      </select>
                      {errors.template_id && (
                        <p className="text-warning">{errors.template_id}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mt-3">
                <FUSButton
                  buttonType="primary"
                  labelText={"Add"}
                  type={"submit"}
                  className="form-control"
                ></FUSButton>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default NotificationMapping;
