/* eslint-disable no-underscore-dangle */
import axios from "axios";
import TokenService from "./token.service";
import VARIABLES from "./variable.service";

const instance = axios.create({
  baseURL: VARIABLES.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
       config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // eslint-disable-next-line no-param-reassign
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);
/* instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data.message === "No token provided!") {
        TokenService.removeUser();
        window.location = "/";
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
); */

export default instance;
