
import TokenService from "./token.service";
import { toast } from "react-toastify";
const responseHandler = (response, redirectURL, isRedirect = true) => {

    if (response?.data?.errorCode === 201 || response?.data?.errorCode === 200 || response?.data?.errorCode === 0) {
        if (isRedirect) {
            let text = response?.data?.message;
            if (window.confirm(text) === true) {
                window.location = redirectURL;
            }
        }
    }
    if (response?.data?.errorCode === -101 ||
        response?.data?.errorCode === -102 ||
        response?.data?.errorCode === -103 ||
        response?.data?.errorCode === -100
    ) {
        const successToast = toast.error(
            `${response?.data?.message}`,
             { position: "bottom-left", autoClose: false }
           );
           setTimeout(() => {
             toast.dismiss(successToast);
           }, 2000);
    }
}

const errorHandler = (error) => {

    if (error?.response?.data?.errorCode === -999) {
        TokenService.removeUser();
        window.location = "/login";
    }

}

const requestAndErrorHandler = {
    responseHandler,
    errorHandler
}

export default requestAndErrorHandler;