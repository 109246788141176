import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from "react-datepicker";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import { toast } from "react-toastify";
import _ from "lodash";
import * as XLSX from "xlsx";
const VendorOrderReports = () => {
  const getFinancialYear = (year) => {
    const startYear = year;
    const endYear = year + 1;
    return `${startYear}-${endYear}`;
  };
  const getTodayDay = () => {
    return new Date();
  };
  const [orderReportData, setOrderReportData] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const [selectedStartDate, setStartDate] = useState("");
  const [selectedEndDate, setEndDate] = useState("");
  const [rangeStartDate, setRangeStartDate] = useState(getTodayDay());
  const [rangeEndDate, setRangeEndDate] = useState(getTodayDay());
  const filterType = {
    date: "date",
    month: "month",
    year: "year",
    dateRange: "dateRange",
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const currentMonth = monthNames[d.getMonth()];
  const [selectedType, setSelectedType] = useState(filterType.date);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [financialData, setFinancialData] = useState([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentFinancialYearStart =
      currentYear - (new Date().getMonth() < 3 ? 1 : 0);

    const financialYears = [
      getFinancialYear(currentFinancialYearStart - 1),
      getFinancialYear(currentFinancialYearStart),
    ];

    setFinancialData([
      { id: "current", display_value: financialYears[1] },
      { id: "previous", display_value: financialYears[0] },
    ]);
  }, []);
  const handleFiscalYearChange = (e) => {
    setOrderReportData([]);
    setSubTotal("");
    const selectedYear = e?.target?.value;
    if (selectedYear) {
      const [startYear, endYear] = selectedYear.split("-");
      const start = `${startYear}-04-01`;
      const end = `${parseInt(endYear)}-03-31`;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const getStartAndEndDateOfMonth = (month) => {
    setOrderReportData([]);
    setSubTotal("");
    const monthNo = parseInt(month?.target?.value);
    if (monthNo < 1 || monthNo > 12) {
      throw new Error("Month must be between 1 and 12.");
    }
    // Create a date object for the first day of the given month in the current year
    const year = new Date().getFullYear();
    const startDate = new Date(year, monthNo - 1, 1);

    // Create a date object for the last day of the given month in the current year
    const endDate = new Date(year, monthNo, 0);
    setStartDate(formatDate(startDate));
    setEndDate(formatDate(endDate));
    setSelectedMonth(monthNames[monthNo - 1]);
  };

  const columns = [
    {
      dataField: "order_date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = monthNames[date.getMonth()].substring(0, 3);
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        };
        return (
          <>
            <p>{formatDate(row.order_date)}</p>
          </>
        );
      },
    },
    {
      dataField: "total_orders",
      text: "Total Orders",
      sort: true,
    },
    {
      dataField: "total_order_amount",
      text: "Order Price",
      sort: true,
    },
    {
      dataField: "purchasing_price",
      text: "Purchase Price",
      sort: true,
    },
    {
      dataField: "packaging_charge",
      text: "Packaging Charge",
      sort: true,
    },
    {
      dataField: "platform_charge",
      text: "PlatForm Charge",
      sort: true,
    },
    {
      dataField: "delivery_charge",
      text: "Delivery Charge",
      sort: true,
    },
    {
      dataField: "total_charges",
      text: "Total Charges",
      sort: true,
    },
    {
      dataField: "gross_margin",
      text: "Gross Margin",
      sort: true,
    },
    {
      dataField: "net_margin",
      text: "Net Margin",
      sort: true,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: orderReportData?.length,
      },
    ],
  };
  const getReceivedOrderReportByVendor = async () => {
    try {
      const res = await VendorService.getReceivedOrderReportByVendor({
        start_date: selectedStartDate,
        end_date: selectedEndDate,
      });
      if (res?.data?.errorCode === 0) {
        setOrderReportData(res?.data?.data?.orderReport);
        setSubTotal(res?.data?.data?.orderSubtotal);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    getReceivedOrderReportByVendor();
  }, [selectedStartDate, selectedEndDate]);

  const handleExelDownload = () => {
    if (_.isEmpty(orderReportData)) {
      const successToast = toast.success("Data not found", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      const excelSheetData = orderReportData?.map((item) => ({
        ...item,
        order_date: formatDate(item?.order_date),
      }));
      const worksheet = XLSX.utils.json_to_sheet(excelSheetData);
      const header = [
        "Date",
        "Total Orders",
        "Order Price",
        "Purchase Price",
        "Packaging Charge",
        "PlatForm Charge",
        "Delivery Charge",
        "Total Charges",
        "Gross Margin",
        "Net Margin",
      ];
      header.forEach((text, index) => {
        const headerCell = XLSX.utils.encode_cell({ r: 0, c: index });
        if (worksheet[headerCell]) {
          worksheet[headerCell].s = {
            font: { bold: true, color: { rgb: "00FF00" } },
          };
          worksheet[headerCell].v = text;
        }
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `OrderReport${
          selectedStartDate + " " + new Date().toLocaleString()
        }.xlsx`
      );
    }
  };
  return (
    <>
      <LayoutContainer title1="Reports" title2="Reports" title3="Sales Reports">
        <div className="px-2 py-2">
          <div className="d-flex justify-content-between">
            <div className="row col-8">
              <div className="col-md-3">
                <select
                  className="form-select text_style mt-1"
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    setSelectedMonth(currentMonth);
                    setStartDate("");
                    setEndDate("");
                    setRangeStartDate("");
                    setRangeEndDate("");
                    setOrderReportData([]);
                    setSubTotal("");
                  }}
                >
                  <option value="">Select Option</option>
                  <option value={filterType.date}>Date</option>
                  <option value={filterType.month}>Month</option>
                  <option value={filterType.year}>Year</option>
                  <option value={filterType.dateRange}>Date Range</option>
                </select>
              </div>
              {selectedType === filterType.date ? (
                <div className="col-md-3">
                  <DatePicker
                    className="form-control  mt-1 text_style"
                    dateFormat="yyyy-MM-dd"
                    maxDate={getTodayDay()}
                    selected={rangeStartDate}
                    onChange={(date) => {
                      setRangeStartDate(date);
                      setStartDate(formatDate(date));
                      setEndDate(formatDate(date));
                      setOrderReportData([]);
                      setSubTotal("");
                    }}
                    placeholderText="Select Date"
                  />
                </div>
              ) : selectedType === filterType.month ? (
                <div className="col-md-3">
                  <select
                    className="form-select mt-1"
                    onChange={getStartAndEndDateOfMonth}
                  >
                    <option>Select Month</option>
                    {monthNames.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              ) : selectedType === filterType.year ? (
                <div className="col-md-3">
                  <select
                    className="form-select mt-1"
                    onChange={handleFiscalYearChange}
                  >
                    <option value="">Select FY</option>
                    {financialData.length > 0 &&
                      financialData.map((item) => (
                        <option key={item.id} value={item.display_value}>
                          {item.display_value}
                        </option>
                      ))}
                  </select>
                </div>
              ) : selectedType === filterType.dateRange ? (
                <>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control  mt-1 text_style"
                      dateFormat="yyyy-MM-dd"
                      selected={rangeStartDate}
                      maxDate={getTodayDay()}
                      onChange={(date) => {
                        setRangeStartDate(date);
                        setStartDate(formatDate(date));
                        setRangeEndDate("");
                        setEndDate("");
                      }}
                      placeholderText="Select Start Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control  mt-1 text_style"
                      dateFormat="yyyy-MM-dd"
                      selected={rangeEndDate}
                      maxDate={getTodayDay()}
                      minDate={rangeStartDate}
                      onChange={(date) => {
                        setRangeEndDate(date);
                        setEndDate(formatDate(date));
                      }}
                      placeholderText="Select End Date"
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-md-auto">
              <button
                className="btn lightGreenColor padf_button_style"
                onClick={() => handleExelDownload()}
                disabled={
                  orderReportData && orderReportData?.length > 0 ? false : true
                }
              >
                <FUSIcon
                  iconSrc={"tb"}
                  iconName={"TbFileXFilled"}
                  size={20}
                  className="text-success"
                />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="bg-white pt-2 pb-1 px-2">
              <div className="d-flex gap-3">
                <div className="total_buttons d-flex justify-content-between align-items-center gap-2 px-3">
                  <span className=" total_text_color">Total Orders:</span>
                  <h5 className="font-weight-light text-warning pt-2">
                    {subTotal?.subtotal_orders || 0}
                  </h5>
                </div>
                <div className="total_buttons d-flex justify-content-between align-items-center gap-2 px-3">
                  <span className="total_text_color">Total Order Price:</span>
                  <h5 className="font-weight-light text-warning pt-2">
                    ₹{subTotal?.subtotal_order_amount || 0}
                  </h5>
                </div>
                <div className="total_buttons d-flex justify-content-between align-items-center gap-2 px-3">
                  <span className="total_text_color">
                    Total Purchase Price:
                  </span>
                  <h5 className="font-weight-light text-warning pt-2">
                    ₹{subTotal?.subtotal_purchasing_price || 0}
                  </h5>
                </div>

                <div className="total_buttons d-flex justify-content-between align-items-center gap-2 px-3">
                  <span className="total_text_color">Total GrossMargin:</span>
                  <h5 className="font-weight-bold text-success pt-2">
                    ₹{subTotal?.subtotal_gross_margin || 0}
                  </h5>
                </div>
                <div className="total_buttons d-flex justify-content-between align-items-center gap-2 px-3 total_text_color">
                  <span>Total NetMargin:</span>
                  <h5 className="font-weight-light text-success pt-2">
                    ₹{subTotal?.subtotal_net_margin || 0}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-3">
          <div className="col-12">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={orderReportData}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default VendorOrderReports;
