import * as React from "react";

import { Wizard, useWizard } from "react-use-wizard";
import FileUpload from "../FileUpload/FileUpload";
import Mapping from "../Mapping/Mapping";
import MakeCollector from "../MakeCollector/MakeCollector";

function UploadDataWizard() {
  return (
    <Wizard>
      <FileUpload />
      <Mapping />
      <MakeCollector />
    </Wizard>
  );
}

export default UploadDataWizard;
