import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import "./AddRole.css";
import MstService from "../../../services/mst.service";
import _ from "lodash";
import { toast } from "react-toastify";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import Stepper from "react-stepper-horizontal";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../../FUSComponents/FUSIcon/FUSIcon";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";
function AddRole() {
  const token = TokenService.getUser();
  const [selectOptions, setSelectedOptions] = useState([]); // state to store selected options
  const [data, setData] = useState([]); // state to stote api response
  const [roleData, setRoleData] = useState([]); // state to manage data mapping
  const [formData, setFormData] = useState({
    basket_name: "",
    parent_basket: "",
    description: "",
    fea_func_list: "",
  });

  const [isRoleSelected, setIsRoleSelected] = useState(false); // state to show data only if role is selected
  const [isRoleFetched, setIsRoleFetched] = useState(false); // state to check if role is fetched
  const [isRoleSubmitted, setIsRoleSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [departmentOption, setDepartmentOptions] = useState([]);
  const [userData, setUserData] = useState({
    userList: [],
    selectedUser: [],
  });

  const handleRoleAndDescriptionSubmission = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const getAllVendorsAndAgency = async () => {
    await MstService.getAllVendorsAndAgency().then((response) => {
      if (response?.data?.errorCode === 0) {
        const options = response?.data?.data?.map((item) => ({
          label: item.user_name,
          value: item.user_id,
        }));
        setUserData((prev) => ({
          ...prev,
          userList: options,
        }));
      } else {
        setUserData({
          userList: [],
          selectedUser: [],
        });
      }
    });
  };
  const getDeptAndFeatures = async () => {
    await MstService.getDepartmentAndFeatures()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setData(response?.data?.data);

          const options = response?.data?.data?.map((item) => ({
            label: item.dept_name,
            value: item.dept_name,
          }));
          const uniqueArray = (a) =>
            [...new Set(a.map((o) => JSON.stringify(o)))].map((s) =>
              JSON.parse(s)
            );
          if (token?.role?.role_id === VARIABLES.VENDOR) {
            const departments = VARIABLES.DEPARTMENTS;
            const filterVedorDepartment = uniqueArray(options).filter((item) =>
              departments.includes(item.value)
            );
            setDepartmentOptions(filterVedorDepartment);
          } else {
            setDepartmentOptions(uniqueArray(options));
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getDeptAndFeatures();
    getAllVendorsAndAgency();
  }, []);

  const handleUserSelect = (optiondata) => {
    setUserData((prev) => ({
      ...prev,
      selectedUser: optiondata,
    }));
  };
  const handleSelect = (optiondata) => {
    if (optiondata) {
      setIsRoleSelected(true);
      setSelectedOptions(optiondata);
      const confirmationData = [];
      const confirmationDataFilter = data.filter(
        (item) => item.dept_name === optiondata.value
      );
      confirmationDataFilter.map((itemValue) => {
        const uniqueFeatureNames = [
          ...new Set(itemValue.features.map((item) => item.feature_name)),
        ].map((featureValue) => ({
          feature_name: featureValue,
          functional: itemValue.features.filter(
            (itemFun) => itemFun.feature_name === featureValue
          ),
          feature_is_active: true,
        }));
        uniqueFeatureNames.map((item) =>
          item.functional.map((sub) => (sub.is_active = true))
        );
        const role_is_active = true;
        const filterDataValue = {
          ...itemValue,
          uniquefeatures: uniqueFeatureNames,
          role_is_active: role_is_active,
        };
        confirmationData.push(filterDataValue);
      });
      setRoleData(confirmationData);
    } else {
      setIsRoleSelected(false);
      setRoleData([]);
      setSelectedOptions(optiondata);
    }
  };

  const handleSelectState = () => {
    // for next button
    if (!formData.basket_name) {
      toast.error(`please enter a role name`, {
        position: "bottom-left",
        autoClose: 2000,
      });

      setIsRoleFetched(false);
    } else if (
      token?.role?.role_id === VARIABLES.SUPER_USER &&
      (_.isEmpty(userData?.selectedUser) || _.isNil(userData?.selectedUser))
    ) {
      toast.error(`please select a user`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else if (selectOptions.length === 0) {
      toast.error(`please select a role`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else {
      const allRolesInactive = roleData.every(
        (role) => role.role_is_active === false
      );
      if (allRolesInactive) {
        toast.error(`minimum one feature is required`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      } else {
        setIsRoleFetched(true);
        setActiveStep(activeStep + 1);
      }
    }
  };
  const handleFeatureSelection = useCallback(
    (roleIndex, fetureIndex, checkValue) => {
      setRoleData((pre) => {
        const selectedRoleDataClone = [...pre];
        selectedRoleDataClone[roleIndex].uniquefeatures[
          fetureIndex
        ].feature_is_active = checkValue;
        selectedRoleDataClone[roleIndex].uniquefeatures[
          fetureIndex
        ].functional = selectedRoleDataClone[roleIndex].uniquefeatures[
          fetureIndex
        ].functional.map((functionalItem) => {
          const functionalItemClone = { ...functionalItem };
          functionalItemClone.is_active = checkValue;
          return functionalItemClone;
        });

        const currentRoleFeaturesUnchecked = selectedRoleDataClone[
          roleIndex
        ].uniquefeatures.every((feature) => !feature.feature_is_active);
        if (currentRoleFeaturesUnchecked) {
          selectedRoleDataClone[roleIndex].role_is_active = false;
        }

        const someFeatureChecked = selectedRoleDataClone[
          roleIndex
        ].uniquefeatures.some((feature) => feature.feature_is_active);
        if (someFeatureChecked) {
          selectedRoleDataClone[roleIndex].role_is_active = true;
        }
        return selectedRoleDataClone;
      });
    },
    []
  );

  const handleFunctionalityCheck = (
    roleIndex,
    featureIndex,
    funcIndex,
    funcCheckValue
  ) => {
    setRoleData((prevRoleData) => {
      const roleDataClone = [...prevRoleData];
      roleDataClone[roleIndex].uniquefeatures[featureIndex].functional[
        funcIndex
      ].is_active = funcCheckValue;

      // Check if all functionalities of the feature are unchecked
      const allFunctionalitiesUnchecked = roleDataClone[
        roleIndex
      ].uniquefeatures[featureIndex].functional.every(
        (func) => !func.is_active
      );

      // Update feature_is_active based on allFunctionalitiesUnchecked
      roleDataClone[roleIndex].uniquefeatures[featureIndex].feature_is_active =
        !allFunctionalitiesUnchecked;
      const someFeatureChecked = roleDataClone[roleIndex].uniquefeatures.some(
        (feature) => feature.feature_is_active
      );
      if (someFeatureChecked) {
        roleDataClone[roleIndex].role_is_active = true;
      } else {
        roleDataClone[roleIndex].role_is_active = false;
      }
      return roleDataClone;
    });
  };

  const handlePrevious = () => {
    setIsRoleFetched(false);
    setActiveStep(activeStep - 1);
  };
  const handleSubmitButton = async () => {
    const fea_func_list = [];
    roleData.length > 0 &&
      roleData.map((roleItemValue) => {
        roleItemValue.uniquefeatures.map((sub) =>
          sub.functional
            .filter((funItem) => funItem.is_active === true)
            .map((sub2) => (sub2.id, fea_func_list.push(sub2.id)))
        );
      });

    const parentBasketID = roleData[0]?.basket_id;

    await MstService.addCustomBasket({
      ...formData,
      parent_basket: parentBasketID,
      fea_func_list: fea_func_list.join(","),
      user_id: userData?.selectedUser?.value,
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          const successToast = toast.success(`Role created  successfully`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsRoleSubmitted(true);
          setActiveStep(activeStep + 1);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleAnotherRoleCreation = () => {
    window.location.reload();
  };
  const handleRoleCheckUncheck = useCallback((roleIndex, checkValue) => {
    setRoleData((pre) => {
      const selectedRoleDataClone = [...pre];
      selectedRoleDataClone[roleIndex].role_is_active = checkValue;
      selectedRoleDataClone[roleIndex].uniquefeatures.map(
        (item) => (item.feature_is_active = checkValue)
      );
      selectedRoleDataClone[roleIndex].uniquefeatures.map((item) =>
        item.functional.map((functItem) => {
          functItem.is_active = checkValue;
        })
      );
      return selectedRoleDataClone;
    });
  }, []);
  const steps = [
    { title: "Create Role" },
    { title: "Preview " },
    { title: "Success" },
  ];

  return (
    <LayoutContainer
      title1={"Roles And Permissions"}
      title2={"Roles And Permissions"}
      title3={"Create Custom Role"}
      right={
        <>
          {isRoleSelected && (
            <div className="d-flex gap-3">
              {!isRoleFetched ? (
                <FUSButton
                  buttonType="primary"
                  labelText={"Next"}
                  onClick={handleSelectState}
                />
              ) : isRoleSubmitted ? (
                <></>
              ) : (
                <>
                  <FUSButton
                    buttonType="secondary"
                    labelText={"Previous"}
                    onClick={handlePrevious}
                  />
                  <FUSButton
                    buttonType="primary"
                    labelText={"Submit"}
                    onClick={handleSubmitButton}
                  />
                </>
              )}
            </div>
          )}
        </>
      }
    >
      <div className="px-3">
        <div className="row">
          <div className="col-12">
            <Stepper
              steps={steps}
              activeStep={activeStep}
              activeColor={
                activeStep === steps.length - 1 ? "green" : "dodgerblue"
              }
              size={25}
              circleTop={10}
              titleTop={2}
              titleFontSize={14}
              defaultBarColor={"grey"}
              completeBarColor={"green"}
              completeColor={
                activeStep === steps.length - 1 ? "#7966E4" : "#7966E4"
              }
            />
          </div>
        </div>
        <div className="mt-5">
          {!isRoleFetched && (
            <div className="row">
              {token?.role?.role_id === VARIABLES.SUPER_USER && (
                <div className="col-sm-4 col-12 mt-sm-0 mt-3">
                  <span className="text-secondary">Select User : </span>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={userData?.selectedUser}
                    onChange={handleUserSelect}
                    isClearable={true}
                    isSearchable={true}
                    options={userData?.userList}
                    placeholder="Select a user..."
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: "white",
                        primary: "lightblue",
                      },
                    })}
                  />
                </div>
              )}

              <div
                className={` col-12 mt-sm-0 mt-3 ${
                  token?.role?.role_id === VARIABLES.SUPER_USER
                    ? "col-sm-4"
                    : "col-sm-6"
                }  `}
              >
                <span className="text-secondary">Enter Custom Role Name :</span>
                <input
                  placeholder="Enter Custom role name"
                  className="form-control slide-right shadow-none p-2"
                  name="basket_name"
                  value={formData.basket_name}
                  required
                  onChange={(e) => handleRoleAndDescriptionSubmission(e)}
                />
              </div>
              <div
                className={` col-12 mt-sm-0 mt-3 ${
                  token?.role?.role_id === VARIABLES.SUPER_USER
                    ? "col-sm-4"
                    : "col-sm-6"
                }  `}
              >
                <span className="text-secondary">Select Department : </span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectOptions}
                  onChange={handleSelect}
                  isClearable={true}
                  isSearchable={true}
                  options={departmentOption}
                  placeholder="Select a department..."
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: "white",
                      primary: "lightblue",
                    },
                  })}
                />
              </div>
              <div className="col-12 mt-3">
                <span className="text-secondary">
                  Enter Cusotm Role Description :
                </span>
                <input
                  placeholder="Enter Custom role Description"
                  className="form-control slide-right shadow-none p-2 "
                  name="description"
                  value={formData.description}
                  onChange={(e) => handleRoleAndDescriptionSubmission(e)}
                />
              </div>
            </div>
          )}
          {isRoleSelected && (
            <div className="row">
              <div
                className="accordion accordion-flush my-3 d-flex flex-column gap-3"
                id="accordionFlushExample"
              >
                {roleData.length > 0 &&
                  roleData?.map((item, index) => (
                    <div className="d-flex ">
                      <div
                        className="accordion-item border border-1 rounded rounded-2 overflow-hidden w-100"
                        key={index}
                      >
                        <h2 className="accordion-header d-flex ">
                          <div className="pt-3  ps-4 table_bg">
                            {!isRoleFetched ? (
                              <input
                                className="me-2  p-2 form-check-input"
                                id={`flexCheckDefault-${index}`}
                                checked={item?.role_is_active}
                                type="checkbox"
                                onChange={(e) =>
                                  handleRoleCheckUncheck(
                                    index,
                                    e.target.checked
                                  )
                                }
                              />
                            ) : item.role_is_active ? (
                              <i className="fa fa-check text-success me-2 fs-6 mt-1"></i>
                            ) : (
                              <i class="fa-solid fa-xmark text-danger me-2 fs-5 mt-1"></i>
                            )}
                          </div>
                          <button
                            className={`accordion-button table_bg ${
                              !isRoleFetched && "collapsed"
                            }   ${
                              isRoleSubmitted && "collapsed"
                            } mainheaderbtn  `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#role-collapse-${index}`}
                            aria-expanded={false}
                            aria-controls={`role-collapse-${index}`}
                          >
                            {isRoleFetched
                              ? `${item.dept_name} -> ${formData.basket_name}    `
                              : `${item.dept_name} -> ${item.role_name} `}
                          </button>

                          <div className="py-3 px-2 table_bg"></div>
                        </h2>

                        <div
                          id={`role-collapse-${index}`}
                          className={`accordion-collapse collapse ${
                            isRoleFetched && !isRoleSubmitted ? "show" : ""
                          }`}
                        >
                          <div className="accordion-body pt-0">
                            <div
                              className="accordion"
                              id={`subaccordion-${index}`}
                            >
                              {item.uniquefeatures?.map((feature, fIndex) => (
                                <div
                                  className="accordion-item px-4 pb-3"
                                  key={fIndex}
                                >
                                  <h2 className="accordion-header">
                                    <div className="form-check d-flex justify-content-center mt-0">
                                      {!isRoleFetched ? (
                                        <input
                                          className="form-check-input my-4 shadow-none p-2 me-1"
                                          type="checkbox"
                                          id={`flexCheckDefault-${index}`}
                                          checked={feature.feature_is_active}
                                          onChange={(e) =>
                                            handleFeatureSelection(
                                              index,
                                              fIndex,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      ) : feature.feature_is_active ? (
                                        <i className="fa fa-check text-success me-2 fs-6 mt-3"></i>
                                      ) : (
                                        <i class="fa-solid fa-xmark text-danger me-2 fs-5 mt-3"></i>
                                      )}
                                      <button
                                        className={`accordion-button ${
                                          isRoleFetched ? "" : "collapsed"
                                        } childaccordionbtn px-3`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#feature-collapse-${index}-${fIndex}`}
                                        aria-expanded="false"
                                        aria-controls={`feature-collapse-${index}-${fIndex}`}
                                      >
                                        {feature.feature_name}
                                      </button>
                                    </div>
                                  </h2>
                                  <div
                                    id={`feature-collapse-${index}-${fIndex}`}
                                    className={`accordion-collapse collapse ${
                                      isRoleFetched ? "show" : ""
                                    }`}
                                  >
                                    <div className="accordion-body  pt-0">
                                      <div className="d-flex flex-wrap">
                                        {feature?.functional?.map(
                                          (functionality, funcIndex) => (
                                            <div
                                              className="mt-1 px-0 me-3"
                                              key={funcIndex}
                                            >
                                              <div className="form-check">
                                                {!isRoleFetched ? (
                                                  <input
                                                    className="form-check-input shadow-none p-2 "
                                                    type="checkbox"
                                                    id={`flexCheckDefault-${index}-${funcIndex}`}
                                                    onChange={(e) => {
                                                      handleFunctionalityCheck(
                                                        index,
                                                        fIndex,
                                                        funcIndex,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    checked={
                                                      functionality.is_active
                                                    }
                                                  />
                                                ) : functionality.is_active ? (
                                                  <i className="fa fa-check text-success me-2 fs-6 mt-3"></i>
                                                ) : (
                                                  <i class="fa-solid fa-xmark text-danger me-2 fs-5 mt-3"></i>
                                                )}
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`flexCheckDefault-${index}-${funcIndex}`}
                                                >
                                                  {
                                                    functionality.functionality_name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {isRoleFetched && isRoleSubmitted ? (
                  <div className="d-flex justify-content-end mt-3">
                    <span
                      className="d-flex align-items-center create_role"
                      onClick={handleAnotherRoleCreation}
                    >
                      <FUSIcon
                        iconSrc={"md"}
                        iconName={"MdOutlineAdd"}
                        size={18}
                      />
                      Create Another Role
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
}

export default AddRole;
