import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ride from "../../services/ride.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
// import TokenService from "../../services/token.service";

function RideCoupon() {
  //   const vendor_user_id = TokenService?.getUser()?.vendor?.vendor_user_id;
  //   const vendor_user_id = TokenService?.getUser();
  const [discountDetailsData, setDicoundDetailsData] = useState([]);
  const [cityCodeList, setCityCodeList] = useState([]);
  const [rideTypeList, setRideTypeList] = useState([]);
  const [state, setState] = useState({
    cityCode: null,
    rideType: null,
    discountName: null,
    discountType: null,
    frequency: null,
    discountValue: null,
    maxDiscountValue: null,
    minTxnvalue: null,
    startDate: null,
    endDate: null,
    isActive: null,
    description: null,
    terms: null,
    id: null,
  });
  const [,] = useState(null);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const columns = [
    {
      dataField: "city_code",
      text: "city code",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "ride_type",
      text: "ride type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "discount_name",
      text: "discount_name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "discount_type",
      text: "discount type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "frequency",
      text: "frequency",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "discount_value",
      text: "discount value",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "max_discount_value",
      text: "Max Discount Value",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "min_txn_value",
      text: "min_txn_value",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "start_dt",
      text: "start date",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        return <>{row.start_dt?.substring(0, 10)}</>;
      },
    },
    {
      dataField: "end_dt",
      text: "end date",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        return <>{row.end_dt?.substring(0, 10)}</>;
      },
    },
    {
      dataField: "terms",
      text: "terms",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "is_active",
      text: "is_active",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      filter: textFilter(),
    },

    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: 20,
      },
    ],
  };

  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDITCOUPON",
    }));
    setState({
      cityCode: row.city_code,
      rideType: row.ride_type,
      discountName: row.discount_name,
      discountType: row.discount_type,
      frequency: row.frequency,
      discountValue: row.discount_value,
      maxDiscountValue: row.max_discount_value,
      minTxnvalue: row.min_txn_value,
      startDate: row.start_dt?.substring(0, 10),
      endDate: row.end_dt?.substring(0, 10),
      isActive: row.is_active,
      description: row.description,
      terms: row.terms,
      id: row.id,
    });
  };

  const getRideDiscountDetails = async () => {
    try {
      const res = await ride.getRideDiscountDetails();
      setDicoundDetailsData([]);
      if (res?.data?.errorCode === 0) {
        setDicoundDetailsData(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getRideDiscountDetails();
    getCityCodeLists();
    getRideTypeList();
  }, []);
  const getCityCodeLists = async () => {
    try {
      const res = await ride.getCityCodeList();
      if (res?.data?.errorCode === 0) {
        setCityCodeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    // Function to extract "2024-06-20" (YYYY-MM-DD) from the initial date string
    const extractFormattedDate = (dateString) => {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Set startDate in state with extracted formatted date
    setState((prevState) => ({
      ...prevState,
      startDate: extractFormattedDate(state.startDate),
    }));
  }, []);
  const getRideTypeList = async () => {
    try {
      const res = await ride.getRideTypeList();
      if (res?.data?.errorCode === 0) {
        setRideTypeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const insertRideDiscountCoupon = async () => {
    try {
      const result = await ride.insertRideDiscountCoupon({
        vendor_user_id: "f50ec375-385c-4839-03a0-7a624f75b42a",
        city_code: state.cityCode,
        ride_type: state.rideType,
        discount_name: state.discountName,
        discount_type: state.discountType,
        frequency: state.frequency,
        discount_value: state.discountValue,
        max_discount_value: state.maxDiscountValue,
        min_txn_value: state.minTxnvalue,
        start_dt: state.startDate,
        end_dt: state.endDate,
        description: state.description,
        terms: state.terms,
      });
      if (result?.data?.errorCode === 0) {
        getRideDiscountDetails();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await ride.deleteRideCouponDeatails({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getRideDiscountDetails();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handleUpdate = async () => {
    try {
      const result = await ride.updateRideDiscountCoupon(state);
      if (result?.data?.errorCode === 0) {
        getRideDiscountDetails();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setState({
          cityCode: null,
          rideType: null,
          discountName: null,
          discountType: null,
          frequency: null,
          discountValue: null,
          maxDiscountValue: null,
          minTxnvalue: null,
          startDate: null,
          endDate: null,
          isActive: null,
          description: null,
          terms: null,
          id: null,
        });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getTodayDate = () => {
    const today = new Date();
    // Format the date to 'YYYY-MM-DD'
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const dateConverter = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const newDate = [date.getFullYear(), mnth, day].join("-");
    return newDate;
  };
  return (
    <LayoutContainer
      title1="Ride Coupon"
      title2="Ride Coupon"
      title3="Ride Coupon"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add New Coupon"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADDCOUPON",
              }));
            }}
          />
        </>
      }
    >
      <div className="px-3 py-3">
        <div className="col-md-12">
          <BootstrapTable
            classes="border border-1"
            keyField="price"
            data={discountDetailsData}
            columns={columns}
            pagination={paginationFactory(featureListOption)}
            filter={filterFactory()}
          />
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={showModal.type === "ADDCOUPON" ? "Add Coupon" : ""}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setState({
              cityCode: null,
              rideType: null,
              discountName: null,
              discountType: null,
              frequency: null,
              discountValue: null,
              maxDiscountValue: null,
              minTxnvalue: null,
              startDate: null,
              endDate: null,
              isActive: null,
              description: null,
              terms: null,
              id: null,
            });
          }}
          centered
        >
          {showModal.type === "ADDCOUPON" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select City Code</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          cityCode: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select City Code</option>
                      {cityCodeList &&
                        cityCodeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select Ride Type</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          rideType: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select Ride Type</option>
                      {rideTypeList &&
                        rideTypeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount Name</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount Name"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount type</label>
                    <select
                      type=""
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount type"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountType: e.target.value,
                        }))
                      }
                    >
                      <option selected>Select Discount Type</option>
                      <option value="percentage">PERCENTAGE</option>
                      <option value="amount">AMOUNT</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Frequency</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Frequency"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          frequency: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount value"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Max Discount Value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Max Discount Value"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          maxDiscountValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Min Txn Value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Min Txn Value"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          minTxnvalue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Start Date</label>
                    <input
                      type="date"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Start Date"
                      min={getTodayDate()}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>End Date</label>
                    <input
                      disabled={state.startDate == null}
                      type="date"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter End Date"
                      min={state.startDate}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          endDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Description</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Description"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          description: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Terms</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Terms"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          terms: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                {state.cityCode &&
                state.rideType &&
                state.discountName &&
                state.discountType &&
                state.frequency &&
                state.discountValue &&
                state.maxDiscountValue &&
                state.minTxnvalue &&
                state.startDate &&
                state.endDate &&
                state.description &&
                state.terms ? (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      onClick={() => {
                        insertRideDiscountCoupon();
                        setShowModal((prev) => ({ ...prev, state: false }));
                        getRideDiscountDetails();
                      }}
                    ></FUSButton>
                  </>
                ) : (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      disabled
                    ></FUSButton>
                  </>
                )}
              </div>
            </div>
          )}
        </FUSModal>
      )}
      {showEditModal.state && (
        <FUSModal
          title={showEditModal.type === "EDITCOUPON" ? "Edit Coupon" : ""}
          showModal={showEditModal.state}
          size={"md"}
          handleClose={() => {
            setEditShowModal((prev) => ({ ...prev, state: false }));
            setState({
              cityCode: null,
              rideType: null,
              discountName: null,
              discountType: null,
              frequency: null,
              discountValue: null,
              maxDiscountValue: null,
              minTxnvalue: null,
              startDate: null,
              endDate: null,
              isActive: null,
              description: null,
              terms: null,
              id: null,
            });
          }}
          centered
        >
          {showEditModal.type === "EDITCOUPON" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select City Code</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      value={state.cityCode}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          cityCode: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select City Code</option>
                      {cityCodeList &&
                        cityCodeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select Ride Type</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      value={state.rideType}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          rideType: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select Ride Type</option>
                      {rideTypeList &&
                        rideTypeList.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount Name</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount Name"
                      value={state.discountName}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount type</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount type"
                      value={state.discountType}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountType: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Frequency</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Frequency"
                      value={state.frequency}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          frequency: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Discount value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Discount value"
                      value={state.discountValue}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          discountValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Max Discount Value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Max Discount Value"
                      value={state.maxDiscountValue}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          maxDiscountValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Min Txn Value</label>
                    <input
                      type="number"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Min Txn Value"
                      value={state.minTxnvalue}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          minTxnvalue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Start Date</label>
                    <input
                      type="date"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Start Date"
                      value={state.startDate}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: e.target.value,
                          endDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>End Date</label>
                    <input
                      type="date"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter End Date"
                      value={state.endDate}
                      min={state.startDate}
                      onChange={(e) => {
                        setState((prevState) => ({
                          ...prevState,
                          endDate: dateConverter(e.target.value),
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Description</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Description"
                      value={state.description}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          description: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div className="form-group ">
                    <label>Terms</label>
                    <input
                      type="text"
                      name=""
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Terms"
                      value={state.terms}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          terms: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <div>Select Status</div>
                  <div className="form-group">
                    <select
                      name="filter"
                      id="filter"
                      className="form-control form-control-sm mt-1"
                      value={state.isActive}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          isActive: e.target.value,
                        }))
                      }
                    >
                      <option value={""}>Select Status</option>
                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleUpdate()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
}

export default RideCoupon;
