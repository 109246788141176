import React, { useContext, useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { StoreContext } from "../../store/StoreContext";
import VendorService from "../../services/vendor.services";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import _ from "lodash";
const ManageUsers = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [loginPhone, setLoginPhone] = useState();
  const [vendorId, setVendorId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [reload, setIsReload] = useState(true);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const token = TokenService.getUser();
  const [vendorList, setVendorList] = useState([]);

  const getAllVendors = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmit = async () => {
    const response = await MstService.checkUser({
      login_phone: loginPhone,
    }).catch((error) => {
      requestAndErrorHandler.errorHandler(error);
    });

    if (response?.data?.errorCode === -102) {
      await MstService.associateUserWithVendor({
        login_phone: loginPhone,
        vendor_id: vendorId,
      })
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            setShowModal((prev) => ({ ...prev, state: false }));
            toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: 3000,
            });
            setLoginPhone("");
            setIsReload((reload) => !reload);
          } else if (res?.data?.errorCode === -106) {
            toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: 3000,
            });
            setLoginPhone("");
            setIsReload((reload) => !reload);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {
      setLoginPhone("");
      const successToast = toast.error(`${response?.data?.message}`, {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };

  const fetchAssociatedUsersListForVendor = async () => {
    const params = {
      vendor_id: !_.isNil(vendorId) ? vendorId : token?.vendor?.vendor_user_id,
    };
    await MstService.getAssociatedUsersListForVendor(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setUserData(res?.data?.data);
        } else {
          setUserData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    fetchAssociatedUsersListForVendor();
    getAllVendors();
  }, [reload, vendorId]);
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Manage Users"
        right={
          <>
            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
              ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                .VENDOR_ADMIN_BUTTON_MANAGE_USER_ADD
            ) || token?.role?.role_id === VARIABLES?.SUPER_USER ? (
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                buttonType="primary"
                labelText={"Add User"}
                onClick={() =>
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "ADDUSER",
                  }))
                }
              />
            ) : null}
          </>
        }
      >
        <div className="px-3 py-2">
          <div className="col-md-9">
            {token?.role?.role_id === VARIABLES.SUPER_USER && (
              <div className="col-6 px-0 mb-3">
                <span className="text-secondary mb-2">Select Vendor</span>
                <div>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setVendorId(() => e.target.value);
                    }}
                  >
                    <option value={-1}>Select Vendor</option>
                    {vendorList?.length > 0 &&
                      vendorList?.map((item, index) => (
                        <option value={item?.user_id} key={index}>
                          {item?.user_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div>
              <table className="table table-bordered border border-1">
                <thead>
                  <th>Name</th>
                  <th>Mobile No.</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {userData?.length > 0 ? (
                    userData?.map((item) => (
                      <tr>
                        <td>{item?.name}</td>
                        <td>{item.login_phone}</td>
                        <td>
                          {item?.is_active ? (
                            <span className="text-success">Active</span>
                          ) : (
                            <span className="text-danger">InActive</span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <div className="py-5 my-5">
                          <FUSEmptyList title="Data not found" />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {showModal.state && (
          <FUSModal
            title={showModal.type === "ADDUSER" ? "Add User" : ""}
            showModal={showModal.state}
            size={"md"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            {showModal.type === "ADDUSER" && (
              <div className="row">
                {token?.role?.role_id === VARIABLES.SUPER_USER && (
                  <div className="col-12 px-0">
                    <span className="text-secondary mb-2">Select Vendor</span>
                    <div>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setVendorId(() => e.target.value);
                        }}
                      >
                        <option value={-1}>Select Vendor</option>
                        {vendorList?.length > 0 &&
                          vendorList?.map((item, index) => (
                            <option value={item?.user_id} key={index}>
                              {item?.user_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-12 px-0 mt-2">
                  <span className="text-secondary">Phone Number</span>
                  <div>
                    <input
                      className="form-control text_style mt-1"
                      placeholder="Enter here..."
                      type="text"
                      maxLength={10}
                      value={loginPhone}
                      onChange={(e) => setLoginPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-12 mt-3 px-0">
                  <FUSButton
                    labelText={"Add User"}
                    buttonType={loginPhone ? "primary" :"secondary"}
                    className={"form-control py-2"}
                    onClick={() => handleSubmit()}
                    disabled={_.isEmpty(loginPhone)}
                  ></FUSButton>
                </div>
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default ManageUsers;
