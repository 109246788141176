import React, { useEffect, useState } from "react";
import { Form, Alert, Col, Row, FloatingLabel } from "react-bootstrap";
import MstService from "../../services/mst.service";
import DataTable from "../DataTable/DataTable";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
import _ from "lodash";
import util from "../../services/util";
import DatePicker from "react-datepicker";
import "./CollectionData.css";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import paginationFactory from "react-bootstrap-table2-paginator";
export default function CollectionData() {
  const [dataList, setDataList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("-1");
  const [collectorList, setCollectorList] = useState([]);
  const [rowData, setRowData] = useState("");
  const [remark, setRemark] = useState("");
  const [payment, setPayment] = useState("");
  const [paymentType, setPaymentType] = useState("PYID0001");
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentRef, setPaymentRef] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [showModal, setShow] = useState({
    state: false,
    type: null,
  });
  const [validated, setValidated] = useState(false);

  const getTodayDay = () => {
    var d = new Date();
    return d;
  };

  const [fromDate, setFromDate] = useState(getTodayDay());

  const alertTimer = () =>
    setTimeout(() => {
      setAlertShow(false);
    }, 3000);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const handleClose = (state) => setShow((prev) => ({ ...prev, state: false }));

  const getCollectorList = () => {
    clearInterval(alertTimer);
    MstService.getCollector()
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getPaymentType = () => {
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
    getPaymentType();
    setSelectedOption("ALL");
  }, []);

  const handleEdit = (row) => {
    setRemark("");

    setShow((prev) => ({ ...prev, state: true, type: "EDIT" }));
    setRowData(row);
    setPayment(row?.payment);
    setRemark(row?.remarks);
    setPaymentType(row?.payment_type_id);
    setPaymentRef(row?.payment_ref);
  };
  const handleReset = (row) => {
    setRemark("");

    setShow((prev) => ({ ...prev, state: true, type: "RESET" }));
    setRowData(row);
    setPayment(row?.payment);
    setRemark(row?.remarks);
    setPaymentType(row?.payment_type_id);
    setPaymentRef(row?.payment_ref);
  };
  const dateFormatter = (cell, row) => {
    if (cell.toString().includes("01 Jan, 1900")) {
      return "";
    }
    return (
      <div style={{ width: "80px" }}>
        <div>
          <span className="party_name_style fw-normal">
            {row.format_updated_on}
          </span>
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "party_name",
      text: "Party",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div style={{ width: "130px" }}>
            <div>
              <span className="party_name_style">{row.party_name}</span>
            </div>
            <div className="d-flex gap-2">
              <span className="date_style">{row.format_date} </span>
              <span className="date_style">{row.ref_no}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "invoice_format_date",
      text: "Invoice Date",
      sort: true,
      filter: textFilter(),
      footer: "Total",
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div style={{ width: "70px" }}>
            <span className="date_style">{row.invoice_format_date}</span>
          </div>
        );
      },
    },
    {
      dataField: "collector_name",
      text: "Collector Code",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "name",
      text: "Collector Name",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "opening",
      text: "Opening (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "pending",
      text: "Pending (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment",
      text: "Payment (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      editable: false,
      footer: "",
    },
    {
      dataField: "payment_ref",
      text: "Payment Ref.",
      sort: true,
      filter: textFilter(),
      editable: false,
      footer: "",
    },
    {
      dataField: "format_updated_on",
      text: "Payment Received On",
      sort: true,
      editable: false,
      footer: "",
      formatter: dateFormatter,
    },
    {
      dataField: "remarks",
      text: "Remark",
      sort: true,
      filter: textFilter(),
      editable: false,
      footer: "",
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                // color={!row?.is_bill_missing === "R" ? "#fff" : "#000"}
                buttonType="lightgray"
                onClick={() => handleEdit(row)}
              />

              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineRefresh"
                iconSize={15}
                // color={row?.is_bill_missing === "R" ? "#fff" : "#000"}
                buttonType="lightdanger"
                onClick={() => handleReset(row)}
              />
            </div>
          </>
        );
      },
      editable: false,
      footer: "",
    },
  ];

  useEffect(() => {
    setDataList([]);
    if (selectedOption !== "-1") {
      let collectorObject = {
        collector_user_id: selectedOption,
        date: fromDate,
      };
      MstService.getUploadedData(collectorObject)
        .then((response) => {
          setDataList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  }, [selectedOption, fromDate]);

  const handleSave = () => {
    let collectionDataObject;

    if (showModal.type === "RESET") {
      collectionDataObject = {
        tag: "RESET",
        id: rowData.id,
        filterTag: "PENDING",
        remarks: remark === undefined ? "" : remark,
      };
    } else {
      collectionDataObject = {
        tag: "PAYMENT",
        payment: payment,
        id: rowData.id,
        payment_ref: paymentRef,
        filterTag: "PENDING",
        remarks: remark === undefined ? "" : remark,
        payment_type_id: paymentType === null ? "PYID0001" : paymentType,
        collector_id: rowData.collector_id,
      };
    }

    MstService.updateCollectionData({
      collectionDataObject,
      collector_id: rowData.collector_id,
    })
      .then((response) => {
        setAlertShow(true);
        alertTimer();
        setShow((prev) => ({ ...prev, state: false }));
        getCollectorList();
        setAlertMessage(response.data.message);
        if (selectedOption !== "-1") {
          let collectorObject = {
            collector_user_id: selectedOption,
            date: fromDate,
          };
          MstService.getUploadedData(collectorObject)
            .then((response) => {
              setDataList(response.data.data);
            })
            .catch((error) => {
              requestAndErrorHandler.errorHandler(error);
            });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const rowStyleForBillMissing = (row, rowIndex) => {
    const style = {};
    if (row?.is_bill_missing === "R") {
      style.backgroundColor = "#750089";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
    }

    return style;
  };

  const options = {
    custom: true,
    totalSize: dataList?.length,
    sizePerPageList: [
      {
        text: "4",
        value: 4,
      },
      {
        text: "8",
        value: 8,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: dataList?.length,
      },
    ],
  };

  return (
    <>
      <LayoutContainer
        title1={"Reports"}
        title2={"Reports"}
        title3={"Live Beat"}
      >
        <div className="pb-5">
          <div className="border_bottom_style">
            <div className="px-3 d-flex align-items-center gap-5 mt-3">
              <div>
                <span className="text-secondary">Select Department</span>
                <div className="mt-1">
                  <Form.Control
                    className="px-3 py-2 border_div_style form-control"
                    as="select"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option key="ALL" value="ALL">
                      All
                    </option>
                    {collectorList?.map((item) => (
                      <option
                        key={item.collector_user_id}
                        value={item.collector_user_id}
                      >
                        {item.name}{" "}
                        {item?.is_one_time_beat_upload === true
                          ? "( Bulk/Incremental )"
                          : "( Daily )"}
                      </option>
                    ))}
                    ;
                  </Form.Control>
                </div>
              </div>
              <div>
                <span className="text-secondary">From Date</span>
                <div className="mt-1">
                  <DatePicker
                    className="px-3 py-2 border_div_style rounded rounded-1"
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate}
                    maxDate={getTodayDay()}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-5">
            <DataTable
              id={"uploadedList"}
              data={dataList}
              columns={columns}
              sizePerPage={4}
              rowStyle={rowStyleForBillMissing}
              cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
              pagination={paginationFactory(options)}
            />
          </div>
          {showModal.state && (
            <FUSModal
              title={
                showModal.type === "RESET"
                  ? `${"Reset record for : "} ${rowData.ref_no} | ${rowData.party_name
                  }`
                  : `${"Update record for : "} ${rowData.ref_no} | ${rowData.party_name
                  }`
              }
              showModal={showModal.state}
              size={"md"}
              handleClose={() => handleClose(false)}
              centered
            >
              {showModal.type === "RESET" ? (
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                      className="mx-0 px-0"
                    >
                      <span className="label_style">Remark</span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                        as="textarea"
                        placeholder="Leave a comment here"
                      />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    {remark &&
                      remark !== "" &&
                      remark !== null &&
                      remark !== undefined ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={handleSave}
                        labelText={"Save Changes"}
                      />
                    ) : null}
                  </div>
                </Form>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      className="mx-0 px-0 mt-2"
                    >
                      <span className="label_style">Payment</span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        required
                        value={payment}
                        onChange={(e) => setPayment(e.target.value)}
                        type="number"
                        placeholder="payment"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom02"
                      className="mx-0 px-0 mt-2"
                    >
                      <span className="label_style">Payment Ref.</span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        required
                        value={paymentRef}
                        onChange={(e) => setPaymentRef(e.target.value)}
                        type="text"
                        placeholder="Enter Payment Ref"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustomUsername"
                      className="mx-0 px-0 mt-2"
                    >
                      <span className="label_style">Select Payment Type</span>
                      <Form.Select
                        className="py-2 my-1 text_style"
                        aria-label="Floating label select example"
                        value={paymentType}
                        onChange={(e) => {
                          setPaymentType(e.target.value);
                        }}
                      >
                        {paymentTypeList &&
                          paymentTypeList.length > 0 &&
                          paymentTypeList.map((item, index) => {
                            return (
                              <option key={index} value={item?.id}>
                                {item?.payment_type}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                      className="mx-0 px-0 mt-2"
                    >
                      <span className="label_style d-flex align-items-center">
                        Remark
                        <span className="text-danger fw-bold">*</span>
                      </span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "50px" }}
                      />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    {remark &&
                      remark !== "" &&
                      remark !== null &&
                      remark !== undefined ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={handleSave}
                        labelText={"Save Changes"}
                      />
                    ) : null}
                  </div>
                </Form>
              )}
            </FUSModal>
          )}

          {alertShow ? (
            <Alert
              style={{
                position: "absolute",
                top: 0,
                margin: 10,
                width: "20%",
                right: 0,
              }}
              key="success"
              variant="success"
              dismissible
            >
              {alertMessage}
            </Alert>
          ) : null}
        </div>
      </LayoutContainer>
    </>
  );
}
