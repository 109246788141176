import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { now } from "lodash";
import "./GenerateCashMemo.css";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
const GenerateCashMemo = () => {
  const [collectorData, setCollectorData] = useState([]);
  const [total, setTotal] = useState({});
  const [selectName, setSelectName] = useState("");

  const componentPDF = useRef();
  const componentPDF2 = useRef();
  const componentPDF3 = useRef();

  const RENDER_TABLE = {
    CONSOLIDATE_COLLECTION_RECEIPT: "Consolidated Collection Receipt",
    COLLECTION_RECEIPT: "Collection Receipt",
    COLLECTION_RECEIPT_SLIP: "Collection Receipt Slip",
  };

  const [activeTable, setActiveTable] = useState(
    RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT
  );

  useEffect(() => {
    if (selectName === "ALL") {
      setActiveTable(RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT);
    } else {
      setActiveTable(RENDER_TABLE.COLLECTION_RECEIPT);
    }
  }, [selectName]);


  const collectors = collectorData?.map((item) => item?.collectorDetails?.name);

  const showData = collectorData.filter((element) => {
    if (selectName === "ALL") {
      return collectorData;
    } else {
      return (
        element?.collectorDetails?.name.trim().toLowerCase() ===
        selectName.trim().toLowerCase()
      );
    }

  });


  useEffect(() => {
    MstService.generateCashMemoForAllCollectorWeb()
      .then((res) => {
        setCollectorData(res?.data?.data);
        setTotal(res?.data?.total);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Denomination Details",
  });

  const generatePDF2 = useReactToPrint({
    content: () => componentPDF2.current,
    documentTitle: "Cash Summary",
  });
  const generatePDF3 = useReactToPrint({
    content: () => componentPDF3.current,
    documentTitle: "Cash Deposit Slip",
  });



  const handleNext = () => {
    if (activeTable === RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT) {
      setActiveTable(RENDER_TABLE.COLLECTION_RECEIPT);
    } else if (activeTable === RENDER_TABLE.COLLECTION_RECEIPT) {
      setActiveTable(RENDER_TABLE.COLLECTION_RECEIPT_SLIP);
    }
  };

  const handlePrevious = () => {
    if (activeTable === RENDER_TABLE.COLLECTION_RECEIPT_SLIP) {
      setActiveTable(RENDER_TABLE.COLLECTION_RECEIPT);
    } else if (activeTable === RENDER_TABLE.COLLECTION_RECEIPT) {
      setActiveTable(RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT);
    }
  };

  return (
    <>
      <LayoutContainer title1={"Receipt"} title2={"Receipt"} title3={"Daily"}>
        <div className="px-2 border-bottom">
          <div className="row justify-content-between">
            <div className="col-sm-4 col-12  mt-1 ps-1  pb-3">
              <span className="grayColor fontStyle">Select Collector</span>
              <Form.Control
                className="mt-1"
                as="select"
                onChange={(e) => setSelectName(e.target.value)}
              >
                <option key="-1" value="-1">
                  select collector
                </option>
                <option value="ALL">All</option>
                {collectors?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-sm-2 col-12">
              <div className="pt-4">
                {activeTable ===
                  RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT && (
                    <div>
                      {showData.length > 0 ? (
                        <div className="d-flex justify-content-end ">
                          <div className="d-flex align-items-center gap-3">
                            <div>
                              <ReactHTMLTableToExcel
                                id="btnExport"
                                className="download-table-xls-button btn lightGreenColor block padf_button_style "
                                table="table-to-cashmemo1"
                                filename={`Denomination Details${now().toLocaleString()}`}
                                sheet="tablexls"
                                buttonText={
                                  <FUSIcon
                                    iconSrc={"tb"}
                                    iconName={"TbFileXFilled"}
                                    color="green"
                                    size={20}
                                  />
                                }
                              />
                            </div>
                            <div>
                              <button
                                className="btn lightRedColor padf_button_style"
                                onClick={generatePDF}
                              >
                                <FUSIcon
                                  iconSrc={"bi"}
                                  iconName={"BiSolidFilePdf"}
                                  size={20}
                                  className="redColor"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}

                {activeTable === RENDER_TABLE.COLLECTION_RECEIPT && (
                  <div>
                    {showData.length > 0 ? (
                      <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ReactHTMLTableToExcel
                              id="btnExport"
                              className="download-table-xls-button btn lightGreenColor block padf_button_style "
                              table="table-to-cashmemo2"
                              filename={`Cash Summary${now().toLocaleString()}`}
                              sheet="tablexls"
                              buttonText={
                                <FUSIcon
                                  iconSrc={"tb"}
                                  iconName={"TbFileXFilled"}
                                  color="green"
                                  size={20}
                                />
                              }
                            />
                          </div>
                          <div>
                            <button
                              className="btn lightRedColor padf_button_style"
                              onClick={generatePDF2}
                            >
                              <FUSIcon
                                iconSrc={"bi"}
                                iconName={"BiSolidFilePdf"}
                                size={20}
                                className="redColor"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}

                {activeTable === RENDER_TABLE.COLLECTION_RECEIPT_SLIP && (
                  <div>
                    {showData.length > 0 ? (
                      <div className="d-flex justify-content-end ">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ReactHTMLTableToExcel
                              id="btnExport"
                              className="download-table-xls-button btn lightGreenColor block padf_button_style"
                              table="table-to-xls"
                              filename={`Cash Deposit Slip${now().toLocaleString()}`}
                              sheet="tablexls"
                              buttonText={
                                <FUSIcon
                                  iconSrc={"tb"}
                                  iconName={"TbFileXFilled"}
                                  className="text-success"
                                  size={20}
                                />
                              }
                            />
                          </div>
                          <div>
                            <button
                              className="btn lightRedColor padf_button_style"
                              onClick={generatePDF3}
                            >
                              <FUSIcon
                                iconSrc={"bi"}
                                iconName={"BiSolidFilePdf"}
                                size={20}
                                className="redColor"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          {showData.length > 0 ? (
            <div className="data_container px-3 pt-3">
              <div>
                {activeTable ===
                  RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT && (
                    <div>
                      <div ref={componentPDF}>
                        <table id="table-to-cashmemo1" className="">
                          <div className="my-class" role="region" tabindex="0">
                            <table>
                              <thead>
                                <tr>
                                  <th colspan="7">Consolidated Cash Receipt</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    colSpan={2}
                                    className="heading_style pt-3 pb-0"
                                  >
                                    Agency Name
                                  </td>
                                  <td
                                    colSpan={2}
                                    className="heading_style pt-3 pb-0"
                                  >
                                    Beat Date
                                  </td>
                                  <td
                                    colSpan={3}
                                    className="heading_style pt-3 pb-0"
                                    align="end"
                                  >
                                    Total Collected Cash
                                  </td>
                                </tr>
                                <tr>
                                  <td className="py-0 normal_heading" colSpan={2}>
                                    {total?.agent_name}
                                  </td>
                                  <td
                                    className="py-0 normal_heading "
                                    colSpan={2}
                                  >
                                    {total?.beat_date}
                                  </td>
                                  <td
                                    className="py-0 amount_style"
                                    colSpan={3}
                                    align="end"
                                  >
                                    ₹
                                    {(
                                      parseFloat(total?.total_cash || "0.00") +
                                      parseFloat(
                                        total?.total_adhoc_cash || "0.00"
                                      )
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="heading_style py-1"
                                    align="left"
                                    colSpan={6}
                                  >
                                    Collector Name
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="pb-3 pt-0 normal_heading"
                                  >
                                    {total?.collectorNames}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    className="border border-1 border_style fs-4 normal_heading"
                                    align="center"
                                    rowSpan={3}
                                  >
                                    Total
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                    colSpan={3}
                                  >
                                    Beat Collection
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                    colSpan={3}
                                  >
                                    Adhoc Collection
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Cash
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Online
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Cheque
                                  </td>

                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Cash
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Online
                                  </td>
                                  <td
                                    className="border border-1 normal_heading"
                                    align="center"
                                  >
                                    Cheque
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    className="border border-1 border_style py-2 normal_heading fw-normal"
                                    align="center"
                                  >
                                    ₹{total?.total_cash || "0.00"}
                                  </td>
                                  <td
                                    className="border border-1 border_style py-2 normal_heading fw-normal"
                                    align="center"
                                  >
                                    ₹{total?.total_online || "0.00"}
                                  </td>
                                  <td
                                    className="border border-1 border_style py-2 normal_heading fw-normal"
                                    align="center"
                                  >
                                    ₹{total?.total_cheque || "0.00"}
                                  </td>
                                  <td
                                    className="border border-1 border_style py-2 normal_heading fw-normal"
                                    align="center"
                                  >
                                    ₹{total?.total_adhoc_cash || "0.00"}
                                  </td>
                                  <td
                                    className="border border-1 border_style py-2 normal_heading fw-normal"
                                    align="center"
                                  >
                                    ₹{total?.total_adhoc_online || "0.00"}
                                  </td>
                                  <td
                                    className="normal_heading fw-normal py-2"
                                    align="center"
                                  >
                                    ₹{total?.total_adhoc_cheque || "0.00"}
                                  </td>
                                </tr>

                                <tr align="left">
                                  <th colspan="7">Denomination Details</th>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="border border-1 border_top_style normal_heading bg_style"
                                  >
                                    Note
                                  </td>
                                  <td
                                    colSpan={2}
                                    className="border border-1 border_top_style normal_heading bg_style"
                                  >
                                    Count
                                  </td>
                                  <td
                                    colSpan={2}
                                    className="border border-1 border_top_style normal_heading bg_style"
                                  >
                                    Amount
                                  </td>
                                </tr>

                                {total?.rs_2000 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      2000/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_2000}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{2000 * total?.rs_2000}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_500 > 0 && (
                                  <tr>
                                    <td
                                      className="border border-1 border_style normal_heading fw-normal"
                                      colSpan={3}
                                    >
                                      500/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_500}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{500 * total?.rs_500}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_200 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      200/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_200}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{200 * total?.rs_200}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_100 > 0 && (
                                  <tr>
                                    <td
                                      className="border border-1 border_style normal_heading fw-normal"
                                      colSpan={3}
                                    >
                                      100/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_100}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{100 * total?.rs_100}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_50 > 0 && (
                                  <tr>
                                    <td
                                      className="border border-1 border_style normal_heading fw-normal"
                                      colSpan={3}
                                    >
                                      50/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_50}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{50 * total?.rs_50}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_20 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      20/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_20}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{20 * total?.rs_20}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_10 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      10/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_10}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {" "}
                                      ₹{10 * total?.rs_10}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_5 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      5/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_5}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{5 * total?.rs_5}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_2 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      2/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_2}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{2 * total?.rs_2}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.rs_1 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      1/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.rs_1}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{1 * total?.rs_1}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.coin_20 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      Coin 20/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.coin_20}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{20 * total?.coin_20}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.coin_10 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      Coin 10/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.coin_10}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{10 * total?.coin_10}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.coin_5 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      Coin 5/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.coin_5}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{5 * total?.coin_5}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.coin_2 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      Coin 2/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.coin_2}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{2 * total?.coin_2}/-
                                    </td>
                                  </tr>
                                )}
                                {total?.coin_1 > 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      Coin 1/-
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      {total?.coin_1}
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="border border-1 border_style normal_heading fw-normal"
                                    >
                                      ₹{1 * total?.coin_1}/-
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Verified By</td>
                                  <td colspan="6"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </table>
                      </div>
                    </div>
                  )}

                {activeTable === RENDER_TABLE.COLLECTION_RECEIPT && (
                  <div>
                    <div ref={componentPDF2}>
                      <table id="table-to-cashmemo2">
                        <div className="my-class" role="region" tabindex="0">
                          <table>
                            <thead>
                              <tr align="center">
                                <th colspan="7">
                                  Collection Receipt - Collector wise
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  className="normal_heading  border border-1 border_top_style"
                                  rowSpan={2}
                                >
                                  Collector
                                </td>
                                <td
                                  className="normal_heading border border-1 border_top_style"
                                  colSpan={3}
                                >
                                  Beat
                                </td>
                                <td
                                  className="normal_heading border border-1 border_top_style"
                                  colSpan={3}
                                >
                                  Adhoc
                                </td>
                              </tr>
                              <tr>
                                <td className="normal_heading border border-1">
                                  Cash
                                </td>
                                <td className="normal_heading border border-1">
                                  Online
                                </td>
                                <td className="normal_heading border border-1">
                                  Cheque
                                </td>

                                <td className="normal_heading border border-1">
                                  Cash
                                </td>
                                <td className="normal_heading border border-1">
                                  Online
                                </td>
                                <td className="normal_heading border border-1">
                                  Cheque
                                </td>
                              </tr>
                              <tr>
                                <td className="normal_heading border border-1 fs-4">
                                  Total
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_cash || "0.00"}
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_online || "0.00"}
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_cheque || "0.00"}
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_adhoc_cash || "0.00"}
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_adhoc_online || "0.00"}
                                </td>
                                <td className="normal_heading border border-1 fw-normal">
                                  ₹{total?.total_adhoc_cheque || "0.00"}
                                </td>
                              </tr>

                              {showData?.length > 0 &&
                                showData?.map((item) => (
                                  <tr>
                                    <td className="normal_heading fw-normal">
                                      {item?.collectorDetails?.name}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]?.cash ||
                                        "0.00"}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]?.online ||
                                        "0.00"}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]?.cheque ||
                                        "0.00"}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_cash || "0.00"}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_online || "0.00"}
                                    </td>
                                    <td className="normal_heading fw-normal">
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_cheque || "0.00"}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </table>
                    </div>
                  </div>
                )}

                {activeTable === RENDER_TABLE.COLLECTION_RECEIPT_SLIP && (
                  <div>
                    <div ref={componentPDF3}>
                      <table id="table-to-xls">
                        {showData?.length > 0 &&
                          showData?.map((item) => (
                            <div
                              className="my-class"
                              role="region"
                              tabindex="0"
                            >
                              <table className="border_style">
                                <thead>
                                  <tr align="center">
                                    <th colspan="7">Collection Receipt Slip</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={2}
                                      className="heading_style pt-3 pb-0"
                                    >
                                      Agency Name
                                    </td>
                                    <td
                                      colSpan={2}
                                      className="heading_style pt-3 pb-0"
                                    >
                                      Beat Date
                                    </td>
                                    <td
                                      colSpan={3}
                                      className="heading_style pt-3 pb-0"
                                      align="end"
                                    >
                                      Total Collected Cash
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="py-0 normal_heading"
                                      colSpan={2}
                                    >
                                      {item?.agent?.agent_name}
                                    </td>
                                    <td
                                      className="py-0 normal_heading"
                                      colSpan={2}
                                    >
                                      {
                                        item?.demonizationDetails[0]
                                          ?.submission_date
                                      }
                                    </td>
                                    <td
                                      className="py-0 amount_style"
                                      colSpan={3}
                                      align="end"
                                    >
                                      ₹
                                      {(
                                        parseFloat(
                                          item?.demonizationDetails[0]?.cash ||
                                          "0.00"
                                        ) +
                                        parseFloat(
                                          item?.demonizationDetails[0]
                                            ?.adhoc_cash || "0.00"
                                        )
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="heading_style py-1"
                                      align="left"
                                      colSpan={6}
                                    >
                                      Collector Name
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan={6}
                                      className="pb-3 pt-0 normal_heading"
                                    >
                                      {item?.collectorDetails?.name}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                                    <td>
                                                        <table>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>Beat Cash</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.cash || '0.00'
                                                            }</td></tr>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>Beat Online</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.online || '0.00'
                                                            }</td></tr>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>Beat Cheque</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.cheque || '0.00'
                                                            }</td></tr>
                                                        </table>
                                                    </td>

                                                    <td>
                                                        <table>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>ADHOC Cash</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.adhoc_cash || '0.00'
                                                            }</td></tr>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>ADHOC Online</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.adhoc_online || '0.00'
                                                            }</td></tr>
                                                            <tr><td style={{ color: "black", fontWeight: "bold" }}>ADHOC Cheque</td><td style={{ color: "black", fontWeight: "bold" }}>₹{item?.demonizationDetails[0]?.adhoc_cheque || '0.00'
                                                            }</td></tr>
                                                        </table>
                                                    </td>
                                                </tr> */}
                                  <tr>
                                    <td
                                      className="border border-1 border_style fs-4 normal_heading"
                                      align="center"
                                      rowSpan={3}
                                    >
                                      Total
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                      colSpan={3}
                                    >
                                      Beat Collection
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                      colSpan={3}
                                    >
                                      Adhoc Collection
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Cash
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Online
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Cheque
                                    </td>

                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Cash
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Online
                                    </td>
                                    <td
                                      className="border border-1 normal_heading"
                                      align="center"
                                    >
                                      Cheque
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]?.cash ||
                                        "0.00"}
                                    </td>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]?.online ||
                                        "0.00"}
                                    </td>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]?.cheque ||
                                        "0.00"}
                                    </td>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_cash || "0.00"}
                                    </td>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_online || "0.00"}
                                    </td>
                                    <td
                                      className="border border-1 border_style py-2 normal_heading fw-normal"
                                      align="center"
                                    >
                                      ₹
                                      {item?.demonizationDetails[0]
                                        ?.adhoc_cheque || "0.00"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table className="border_top_style">
                                <thead>
                                  <tr>
                                    <th colspan="12">Cash Memo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="border border-1 border_top_style normal_heading bg_style"
                                    >
                                      Note
                                    </td>
                                    <td
                                      colSpan={4}
                                      className="border border-1 border_top_style normal_heading bg_style"
                                    >
                                      Count
                                    </td>
                                    <td
                                      colSpan={4}
                                      className="border border-1 border_top_style normal_heading bg_style"
                                    >
                                      Amount
                                    </td>
                                  </tr>

                                  {item?.demonizationDetails[0]?.rs_2000 >
                                    0 && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          2000/-
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {item?.demonizationDetails[0]?.rs_2000}
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {2000 *
                                            item?.demonizationDetails[0]?.rs_2000}
                                          /-
                                        </td>
                                      </tr>
                                    )}
                                  {item?.demonizationDetails[0]?.rs_500 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        500/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_500}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {500 *
                                          item?.demonizationDetails[0]?.rs_500}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_200 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        200/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_200}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {200 *
                                          item?.demonizationDetails[0]?.rs_200}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_100 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        100/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_100}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {100 *
                                          item?.demonizationDetails[0]?.rs_100}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_50 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        50/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_50}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {50 *
                                          item?.demonizationDetails[0]?.rs_50}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_20 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        20/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_20}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {20 *
                                          item?.demonizationDetails[0]?.rs_20}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_10 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        10/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_10}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {10 *
                                          item?.demonizationDetails[0]?.rs_10}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_5 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        5/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_5}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {5 * item?.demonizationDetails[0]?.rs_5}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_2 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        2/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_2}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {2 * item?.demonizationDetails[0]?.rs_2}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.rs_1 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        1/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.rs_1}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {1 * item?.demonizationDetails[0]?.rs_1}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.coin_20 >
                                    0 && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          Coin 20/-
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {item?.demonizationDetails[0]?.coin_20}
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {20 *
                                            item?.demonizationDetails[0]?.coin_20}
                                          /-
                                        </td>
                                      </tr>
                                    )}
                                  {item?.demonizationDetails[0]?.coin_10 >
                                    0 && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          Coin 10/-
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {item?.demonizationDetails[0]?.coin_10}
                                        </td>
                                        <td
                                          colSpan={4}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {10 *
                                            item?.demonizationDetails[0]?.coin_10}
                                          /-
                                        </td>
                                      </tr>
                                    )}
                                  {item?.demonizationDetails[0]?.coin_5 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        Coin 5/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.coin_5}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {5 *
                                          item?.demonizationDetails[0]?.coin_5}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.coin_2 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        Coin 2/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.coin_2}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {2 *
                                          item?.demonizationDetails[0]?.coin_2}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  {item?.demonizationDetails[0]?.coin_1 > 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        Coin 1/-
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {item?.demonizationDetails[0]?.coin_1}
                                      </td>
                                      <td
                                        colSpan={4}
                                        className="border border-1 border_style normal_heading fw-normal"
                                      >
                                        {1 *
                                          item?.demonizationDetails[0]?.coin_1}
                                        /-
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td colspan="4">Verified By</td>
                                    <td colspan="4"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </table>
                    </div>
                  </div>
                )}
              </div>

              <div className="bottom_navigate d-flex  gap-3">
                <div
                  className="d-flex align-items-center  gap-2"
                  onClick={handlePrevious}
                >
                  <span>
                    <FUSIcon
                      iconSrc={"fa6"}
                      iconName={"FaArrowLeftLong"}
                      className={`textStyle ${activeTable ===
                        RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT
                        ? "text-secondary"
                        : "text-primary"
                        }`}
                    />
                  </span>
                  <span
                    className={`textStyle  ${activeTable ===
                      RENDER_TABLE.CONSOLIDATE_COLLECTION_RECEIPT
                      ? "text-secondary"
                      : "text-primary"
                      }`}
                  >
                    Previous
                  </span>
                </div>
                <div
                  className="d-flex align-items-center gap-2"
                  onClick={handleNext}
                >
                  <span
                    className={`textStyle ${activeTable === RENDER_TABLE.COLLECTION_RECEIPT_SLIP
                      ? "text-secondary"
                      : "text-primary"
                      }`}
                  >
                    Next
                  </span>
                  <span>
                    <FUSIcon
                      iconSrc={"fa6"}
                      iconName={"FaArrowRightLong"}
                      className={`textStyle ${activeTable === RENDER_TABLE.COLLECTION_RECEIPT_SLIP
                        ? "text-secondary"
                        : "text-primary"
                        }`}

                    />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="pt-5">
              <FUSEmptyList
                title={"No Data Found"}
                subTitle={"Upload to see the details"}
              />
            </div>
          )}
        </div>
      </LayoutContainer>
    </>
  );
};

export default GenerateCashMemo;
