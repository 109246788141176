/* eslint-disable default-case */
class Tally {
  //getItemXml

  dateFormatter = (dates) => {
    const date = new Date(dates);
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();

    return `${year}${month}${day}`;
  };

  getRoundOff = (num) => {
    return Math.round(parseFloat(num));
  };

  getRoundOffValue = (num) => {
    let diff = Math.round(parseFloat(num)) - parseFloat(num);
    return Math.round(diff * 100) / 100;
  };

  getItemXml(reportType, data, fromdate) {
    let xml = "";
    switch (reportType) {
      case "CIG":
        xml = this.getHeaderXml(reportType, data, fromdate);
        data?.details &&
          data?.details.map((value) => {
            xml += `<ALLINVENTORYENTRIES.LIST>
                       <STOCKITEMNAME>${value?.item_name.replace(
                         "&",
                         " &amp;"
                       )}</STOCKITEMNAME>
                       <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                       <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                       <ISAUTONEGATE>No</ISAUTONEGATE>
                       <ISCUSTOMSCLEARANCE>No</ISCUSTOMSCLEARANCE>
                       <ISTRACKCOMPONENT>No</ISTRACKCOMPONENT>
                       <ISTRACKPRODUCTION>No</ISTRACKPRODUCTION>
                       <ISPRIMARYITEM>No</ISPRIMARYITEM>
                       <ISSCRAP>No</ISSCRAP>
                       <RATE></RATE>
                       <AMOUNT>${value.Taxable_Sales_Value}</AMOUNT>
                       <ACTUALQTY>${value.Quantity} PAC</ACTUALQTY>
                       <BILLEDQTY>${value.Quantity} PAC</BILLEDQTY>
                       <BATCHALLOCATIONS.LIST>
                        <GODOWNNAME>Main Location</GODOWNNAME>
                        <BATCHNAME>Primary Batch</BATCHNAME>
                        <DESTINATIONGODOWNNAME>Main Location</DESTINATIONGODOWNNAME>
                        <INDENTNO/>
                        <ORDERNO/>
                        <TRACKINGNUMBER/>
                        <DYNAMICCSTISCLEARED>No</DYNAMICCSTISCLEARED>
                        <AMOUNT>${value.Taxable_Sales_Value}</AMOUNT>
                        <ACTUALQTY>${value.Quantity} PAC</ACTUALQTY>
                        <BILLEDQTY>${value.Quantity} PAC</BILLEDQTY>
                        <ADDITIONALDETAILS.LIST>        </ADDITIONALDETAILS.LIST>
                        <VOUCHERCOMPONENTLIST.LIST>        </VOUCHERCOMPONENTLIST.LIST>
                       </BATCHALLOCATIONS.LIST>
                       <ACCOUNTINGALLOCATIONS.LIST>
                        <OLDAUDITENTRYIDS.LIST TYPE="Number">
                         <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                        </OLDAUDITENTRYIDS.LIST>
                        <LEDGERNAME>GST SALE 28%</LEDGERNAME>
                        <GSTCLASS/>
                        <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                        <LEDGERFROMITEM>No</LEDGERFROMITEM>
                        <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                        <ISPARTYLEDGER>No</ISPARTYLEDGER>
                        <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                        <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                        <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                        <AMOUNT>${value.Taxable_Sales_Value}</AMOUNT>
                        <SERVICETAXDETAILS.LIST>        </SERVICETAXDETAILS.LIST>
                        <BANKALLOCATIONS.LIST>        </BANKALLOCATIONS.LIST>
                        <BILLALLOCATIONS.LIST>        </BILLALLOCATIONS.LIST>
                        <INTERESTCOLLECTION.LIST>        </INTERESTCOLLECTION.LIST>
                        <OLDAUDITENTRIES.LIST>        </OLDAUDITENTRIES.LIST>
                        <ACCOUNTAUDITENTRIES.LIST>        </ACCOUNTAUDITENTRIES.LIST>
                        <AUDITENTRIES.LIST>        </AUDITENTRIES.LIST>
                        <INPUTCRALLOCS.LIST>        </INPUTCRALLOCS.LIST>
                        <DUTYHEADDETAILS.LIST>        </DUTYHEADDETAILS.LIST>
                        <EXCISEDUTYHEADDETAILS.LIST>        </EXCISEDUTYHEADDETAILS.LIST>
                        <RATEDETAILS.LIST>        </RATEDETAILS.LIST>
                        <SUMMARYALLOCS.LIST>        </SUMMARYALLOCS.LIST>
                        <STPYMTDETAILS.LIST>        </STPYMTDETAILS.LIST>
                        <EXCISEPAYMENTALLOCATIONS.LIST>        </EXCISEPAYMENTALLOCATIONS.LIST>
                        <TAXBILLALLOCATIONS.LIST>        </TAXBILLALLOCATIONS.LIST>
                        <TAXOBJECTALLOCATIONS.LIST>        </TAXOBJECTALLOCATIONS.LIST>
                        <TDSEXPENSEALLOCATIONS.LIST>        </TDSEXPENSEALLOCATIONS.LIST>
                        <VATSTATUTORYDETAILS.LIST>        </VATSTATUTORYDETAILS.LIST>
                        <COSTTRACKALLOCATIONS.LIST>        </COSTTRACKALLOCATIONS.LIST>
                        <REFVOUCHERDETAILS.LIST>        </REFVOUCHERDETAILS.LIST>
                        <INVOICEWISEDETAILS.LIST>        </INVOICEWISEDETAILS.LIST>
                        <VATITCDETAILS.LIST>        </VATITCDETAILS.LIST>
                        <ADVANCETAXDETAILS.LIST>        </ADVANCETAXDETAILS.LIST>
                       </ACCOUNTINGALLOCATIONS.LIST>
                       <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
                       <SUPPLEMENTARYDUTYHEADDETAILS.LIST>       </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
                       <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
                       <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
                       <EXCISEALLOCATIONS.LIST>       </EXCISEALLOCATIONS.LIST>
                       <EXPENSEALLOCATIONS.LIST>       </EXPENSEALLOCATIONS.LIST>
                      </ALLINVENTORYENTRIES.LIST>`;
          });

        xml += this.getFooterXml(reportType, data, fromdate);
        return xml;
      case "CHIPS":
        xml = this.getHeaderXml(reportType, data, fromdate);
        data?.details &&
          data?.details.map((value) => {
            xml += `<ALLINVENTORYENTRIES.LIST>
                       <STOCKITEMNAME>${value?.ITEM_NAME.replace(
                         "&",
                         " &amp;"
                       )}</STOCKITEMNAME>
                       <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                       <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                       <ISAUTONEGATE>No</ISAUTONEGATE>
                       <ISCUSTOMSCLEARANCE>No</ISCUSTOMSCLEARANCE>
                       <ISTRACKCOMPONENT>No</ISTRACKCOMPONENT>
                       <ISTRACKPRODUCTION>No</ISTRACKPRODUCTION>
                       <ISPRIMARYITEM>No</ISPRIMARYITEM>
                       <ISSCRAP>No</ISSCRAP>
                       <RATE></RATE>
                       <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                       <ACTUALQTY>${value.QTY} PAC</ACTUALQTY>
                       <BILLEDQTY>${value.QTY} PAC</BILLEDQTY>
                       <BATCHALLOCATIONS.LIST>
                        <GODOWNNAME>Main Location</GODOWNNAME>
                        <BATCHNAME>Primary Batch</BATCHNAME>
                        <DESTINATIONGODOWNNAME>Main Location</DESTINATIONGODOWNNAME>
                        <INDENTNO/>
                        <ORDERNO/>
                        <TRACKINGNUMBER/>
                        <DYNAMICCSTISCLEARED>No</DYNAMICCSTISCLEARED>
                        <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                        <ACTUALQTY>${value.QTY} PAC</ACTUALQTY>
                        <BILLEDQTY>${value.QTY} PAC</BILLEDQTY>
                        <ADDITIONALDETAILS.LIST>        </ADDITIONALDETAILS.LIST>
                        <VOUCHERCOMPONENTLIST.LIST>        </VOUCHERCOMPONENTLIST.LIST>
                       </BATCHALLOCATIONS.LIST>
                       <ACCOUNTINGALLOCATIONS.LIST>
                        <OLDAUDITENTRYIDS.LIST TYPE="Number">
                         <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                        </OLDAUDITENTRYIDS.LIST>
                        <LEDGERNAME>GST SALE ${data.RATE}%</LEDGERNAME>
                        <GSTCLASS/>
                        <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                        <LEDGERFROMITEM>No</LEDGERFROMITEM>
                        <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                        <ISPARTYLEDGER>No</ISPARTYLEDGER>
                        <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                        <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                        <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                        <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                        <SERVICETAXDETAILS.LIST>        </SERVICETAXDETAILS.LIST>
                        <BANKALLOCATIONS.LIST>        </BANKALLOCATIONS.LIST>
                        <BILLALLOCATIONS.LIST>        </BILLALLOCATIONS.LIST>
                        <INTERESTCOLLECTION.LIST>        </INTERESTCOLLECTION.LIST>
                        <OLDAUDITENTRIES.LIST>        </OLDAUDITENTRIES.LIST>
                        <ACCOUNTAUDITENTRIES.LIST>        </ACCOUNTAUDITENTRIES.LIST>
                        <AUDITENTRIES.LIST>        </AUDITENTRIES.LIST>
                        <INPUTCRALLOCS.LIST>        </INPUTCRALLOCS.LIST>
                        <DUTYHEADDETAILS.LIST>        </DUTYHEADDETAILS.LIST>
                        <EXCISEDUTYHEADDETAILS.LIST>        </EXCISEDUTYHEADDETAILS.LIST>
                        <RATEDETAILS.LIST>        </RATEDETAILS.LIST>
                        <SUMMARYALLOCS.LIST>        </SUMMARYALLOCS.LIST>
                        <STPYMTDETAILS.LIST>        </STPYMTDETAILS.LIST>
                        <EXCISEPAYMENTALLOCATIONS.LIST>        </EXCISEPAYMENTALLOCATIONS.LIST>
                        <TAXBILLALLOCATIONS.LIST>        </TAXBILLALLOCATIONS.LIST>
                        <TAXOBJECTALLOCATIONS.LIST>        </TAXOBJECTALLOCATIONS.LIST>
                        <TDSEXPENSEALLOCATIONS.LIST>        </TDSEXPENSEALLOCATIONS.LIST>
                        <VATSTATUTORYDETAILS.LIST>        </VATSTATUTORYDETAILS.LIST>
                        <COSTTRACKALLOCATIONS.LIST>        </COSTTRACKALLOCATIONS.LIST>
                        <REFVOUCHERDETAILS.LIST>        </REFVOUCHERDETAILS.LIST>
                        <INVOICEWISEDETAILS.LIST>        </INVOICEWISEDETAILS.LIST>
                        <VATITCDETAILS.LIST>        </VATITCDETAILS.LIST>
                        <ADVANCETAXDETAILS.LIST>        </ADVANCETAXDETAILS.LIST>
                       </ACCOUNTINGALLOCATIONS.LIST>
                       <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
                       <SUPPLEMENTARYDUTYHEADDETAILS.LIST>       </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
                       <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
                       <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
                       <EXCISEALLOCATIONS.LIST>       </EXCISEALLOCATIONS.LIST>
                       <EXPENSEALLOCATIONS.LIST>       </EXPENSEALLOCATIONS.LIST>
                      </ALLINVENTORYENTRIES.LIST>`;
          });

        xml += this.getFooterXml(reportType, data, fromdate);
        return xml;
      case "PC":
        xml = this.getHeaderXml(reportType, data, fromdate);
        data?.details &&
          data?.details.map((value) => {
            xml += `<ALLINVENTORYENTRIES.LIST>
                       <STOCKITEMNAME>${value?.ITEM_NAME.replace(
                         "&",
                         " &amp;"
                       )}</STOCKITEMNAME>
                       <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                       <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                       <ISAUTONEGATE>No</ISAUTONEGATE>
                       <ISCUSTOMSCLEARANCE>No</ISCUSTOMSCLEARANCE>
                       <ISTRACKCOMPONENT>No</ISTRACKCOMPONENT>
                       <ISTRACKPRODUCTION>No</ISTRACKPRODUCTION>
                       <ISPRIMARYITEM>No</ISPRIMARYITEM>
                       <ISSCRAP>No</ISSCRAP>
                       <RATE></RATE>
                       <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                       <ACTUALQTY>${value.QTY} PAC</ACTUALQTY>
                       <BILLEDQTY>${value.QTY} PAC</BILLEDQTY>
                       <BATCHALLOCATIONS.LIST>
                        <GODOWNNAME>Main Location</GODOWNNAME>
                        <BATCHNAME>Primary Batch</BATCHNAME>
                        <DESTINATIONGODOWNNAME>Main Location</DESTINATIONGODOWNNAME>
                        <INDENTNO/>
                        <ORDERNO/>
                        <TRACKINGNUMBER/>
                        <DYNAMICCSTISCLEARED>No</DYNAMICCSTISCLEARED>
                        <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                        <ACTUALQTY>${value.QTY} PAC</ACTUALQTY>
                        <BILLEDQTY>${value.QTY} PAC</BILLEDQTY>
                        <ADDITIONALDETAILS.LIST>        </ADDITIONALDETAILS.LIST>
                        <VOUCHERCOMPONENTLIST.LIST>        </VOUCHERCOMPONENTLIST.LIST>
                       </BATCHALLOCATIONS.LIST>
                       <ACCOUNTINGALLOCATIONS.LIST>
                        <OLDAUDITENTRYIDS.LIST TYPE="Number">
                         <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
                        </OLDAUDITENTRYIDS.LIST>
                        <LEDGERNAME>GST SALE ${data.RATE}%</LEDGERNAME>
                        <GSTCLASS/>
                        <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
                        <LEDGERFROMITEM>No</LEDGERFROMITEM>
                        <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
                        <ISPARTYLEDGER>No</ISPARTYLEDGER>
                        <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
                        <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
                        <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
                        <AMOUNT>${value.AFTER_DIS}</AMOUNT>
                        <SERVICETAXDETAILS.LIST>        </SERVICETAXDETAILS.LIST>
                        <BANKALLOCATIONS.LIST>        </BANKALLOCATIONS.LIST>
                        <BILLALLOCATIONS.LIST>        </BILLALLOCATIONS.LIST>
                        <INTERESTCOLLECTION.LIST>        </INTERESTCOLLECTION.LIST>
                        <OLDAUDITENTRIES.LIST>        </OLDAUDITENTRIES.LIST>
                        <ACCOUNTAUDITENTRIES.LIST>        </ACCOUNTAUDITENTRIES.LIST>
                        <AUDITENTRIES.LIST>        </AUDITENTRIES.LIST>
                        <INPUTCRALLOCS.LIST>        </INPUTCRALLOCS.LIST>
                        <DUTYHEADDETAILS.LIST>        </DUTYHEADDETAILS.LIST>
                        <EXCISEDUTYHEADDETAILS.LIST>        </EXCISEDUTYHEADDETAILS.LIST>
                        <RATEDETAILS.LIST>        </RATEDETAILS.LIST>
                        <SUMMARYALLOCS.LIST>        </SUMMARYALLOCS.LIST>
                        <STPYMTDETAILS.LIST>        </STPYMTDETAILS.LIST>
                        <EXCISEPAYMENTALLOCATIONS.LIST>        </EXCISEPAYMENTALLOCATIONS.LIST>
                        <TAXBILLALLOCATIONS.LIST>        </TAXBILLALLOCATIONS.LIST>
                        <TAXOBJECTALLOCATIONS.LIST>        </TAXOBJECTALLOCATIONS.LIST>
                        <TDSEXPENSEALLOCATIONS.LIST>        </TDSEXPENSEALLOCATIONS.LIST>
                        <VATSTATUTORYDETAILS.LIST>        </VATSTATUTORYDETAILS.LIST>
                        <COSTTRACKALLOCATIONS.LIST>        </COSTTRACKALLOCATIONS.LIST>
                        <REFVOUCHERDETAILS.LIST>        </REFVOUCHERDETAILS.LIST>
                        <INVOICEWISEDETAILS.LIST>        </INVOICEWISEDETAILS.LIST>
                        <VATITCDETAILS.LIST>        </VATITCDETAILS.LIST>
                        <ADVANCETAXDETAILS.LIST>        </ADVANCETAXDETAILS.LIST>
                       </ACCOUNTINGALLOCATIONS.LIST>
                       <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
                       <SUPPLEMENTARYDUTYHEADDETAILS.LIST>       </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
                       <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
                       <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
                       <EXCISEALLOCATIONS.LIST>       </EXCISEALLOCATIONS.LIST>
                       <EXPENSEALLOCATIONS.LIST>       </EXPENSEALLOCATIONS.LIST>
                      </ALLINVENTORYENTRIES.LIST>`;
          });

        xml += this.getFooterXml(reportType, data, fromdate);
        return xml;
      default:
    }
  }

  getCustomerName(reportType, customerName) {
    switch (reportType) {
      case "CIG":
        switch (customerName) {
          case "CIGG CASH SALE":
            return "cash";
          default:
            customerName = customerName.replace("&", " &amp;");
            return customerName;
        }
      case "CHIPS":
        return "MISC. SUNDRY DEBTORS CHIPS";
      case "PC":
        return "MISC. SUNDRY DEBTORS PP";
    }
  }

  getHeaderXml(reportType, data, fromdate) {
    switch (reportType) {
      case "CIG":
        return `<ENVELOPE>
        <HEADER>
         <TALLYREQUEST>Import Data</TALLYREQUEST>
        </HEADER>
        <BODY>
         <IMPORTDATA>
          <REQUESTDESC>
           <REPORTNAME>Vouchers</REPORTNAME>
           <STATICVARIABLES>
            <SVCURRENTCOMPANY>Mosaram Vyapar  (from 1-Apr-${fromdate
              .getFullYear()
              .toString()
              .substr(-2)})</SVCURRENTCOMPANY>
           </STATICVARIABLES>
          </REQUESTDESC>
          <REQUESTDATA>
           <TALLYMESSAGE xmlns:UDF="TallyUDF">
            <VOUCHER VCHTYPE="TAX INVOICE (GST)" ACTION="Create" OBJVIEW="Invoice Voucher View">
             <OLDAUDITENTRYIDS.LIST TYPE="Number">
              <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
             </OLDAUDITENTRYIDS.LIST>
             <DATE>${this.dateFormatter(fromdate)}</DATE>
             <GUID></GUID>
             <GSTREGISTRATIONTYPE>Unregistered</GSTREGISTRATIONTYPE>
             <VATDEALERTYPE>Unregistered</VATDEALERTYPE>
             <STATENAME>Uttar Pradesh</STATENAME>
             <VOUCHERTYPENAME>TAX INVOICE (GST)</VOUCHERTYPENAME>
             <ENTEREDBY>mosaram</ENTEREDBY>
             <COUNTRYOFRESIDENCE>India</COUNTRYOFRESIDENCE>
             <PLACEOFSUPPLY>Uttar Pradesh</PLACEOFSUPPLY>
             <PARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYNAME>
             <PARTYLEDGERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYLEDGERNAME>
             <PARTYMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYMAILINGNAME>
             <CONSIGNEEMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</CONSIGNEEMAILINGNAME>
             <CONSIGNEESTATENAME>Uttar Pradesh</CONSIGNEESTATENAME>
             <VOUCHERNUMBER></VOUCHERNUMBER>
             <BASICBASEPARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBASEPARTYNAME>
             <CSTFORMISSUETYPE/>
             <CSTFORMRECVTYPE/>
             <FBTPAYMENTTYPE>Default</FBTPAYMENTTYPE>
             <PERSISTEDVIEW>Invoice Voucher View</PERSISTEDVIEW>
             <BASICBUYERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBUYERNAME>
             <CONSIGNEECOUNTRYNAME>India</CONSIGNEECOUNTRYNAME>
             <VCHGSTCLASS/>
             <VCHENTRYMODE>Item Invoice</VCHENTRYMODE>
             <DIFFACTUALQTY>No</DIFFACTUALQTY>
             <ISMSTFROMSYNC>No</ISMSTFROMSYNC>
             <ISDELETED>No</ISDELETED>
             <ISSECURITYONWHENENTERED>No</ISSECURITYONWHENENTERED>
             <ASORIGINAL>No</ASORIGINAL>
             <AUDITED>No</AUDITED>
             <FORJOBCOSTING>No</FORJOBCOSTING>
             <ISOPTIONAL>No</ISOPTIONAL>
             <EFFECTIVEDATE>${this.dateFormatter(fromdate)}</EFFECTIVEDATE>
             <USEFOREXCISE>No</USEFOREXCISE>
             <ISFORJOBWORKIN>No</ISFORJOBWORKIN>
             <ALLOWCONSUMPTION>No</ALLOWCONSUMPTION>
             <USEFORINTEREST>No</USEFORINTEREST>
             <USEFORGAINLOSS>No</USEFORGAINLOSS>
             <USEFORGODOWNTRANSFER>No</USEFORGODOWNTRANSFER>
             <USEFORCOMPOUND>No</USEFORCOMPOUND>
             <USEFORSERVICETAX>No</USEFORSERVICETAX>
             <ISONHOLD>No</ISONHOLD>
             <ISBOENOTAPPLICABLE>No</ISBOENOTAPPLICABLE>
             <ISGSTSECSEVENAPPLICABLE>No</ISGSTSECSEVENAPPLICABLE>
             <ISEXCISEVOUCHER>No</ISEXCISEVOUCHER>
             <EXCISETAXOVERRIDE>No</EXCISETAXOVERRIDE>
             <USEFORTAXUNITTRANSFER>No</USEFORTAXUNITTRANSFER>
             <IGNOREPOSVALIDATION>No</IGNOREPOSVALIDATION>
             <EXCISEOPENING>No</EXCISEOPENING>
             <USEFORFINALPRODUCTION>No</USEFORFINALPRODUCTION>
             <ISTDSOVERRIDDEN>No</ISTDSOVERRIDDEN>
             <ISTCSOVERRIDDEN>No</ISTCSOVERRIDDEN>
             <ISTDSTCSCASHVCH>No</ISTDSTCSCASHVCH>
             <INCLUDEADVPYMTVCH>No</INCLUDEADVPYMTVCH>
             <ISSUBWORKSCONTRACT>No</ISSUBWORKSCONTRACT>
             <ISVATOVERRIDDEN>No</ISVATOVERRIDDEN>
             <IGNOREORIGVCHDATE>No</IGNOREORIGVCHDATE>
             <ISVATPAIDATCUSTOMS>No</ISVATPAIDATCUSTOMS>
             <ISDECLAREDTOCUSTOMS>No</ISDECLAREDTOCUSTOMS>
             <ISSERVICETAXOVERRIDDEN>No</ISSERVICETAXOVERRIDDEN>
             <ISISDVOUCHER>No</ISISDVOUCHER>
             <ISEXCISEOVERRIDDEN>No</ISEXCISEOVERRIDDEN>
             <ISEXCISESUPPLYVCH>No</ISEXCISESUPPLYVCH>
             <ISGSTOVERRIDDEN>No</ISGSTOVERRIDDEN>
             <GSTNOTEXPORTED>No</GSTNOTEXPORTED>
             <IGNOREGSTINVALIDATION>No</IGNOREGSTINVALIDATION>
             <ISGSTREFUND>No</ISGSTREFUND>
             <OVRDNEWAYBILLAPPLICABILITY>No</OVRDNEWAYBILLAPPLICABILITY>
             <ISVATPRINCIPALACCOUNT>No</ISVATPRINCIPALACCOUNT>
             <IGNOREEINVVALIDATION>No</IGNOREEINVVALIDATION>
             <IRNJSONEXPORTED>No</IRNJSONEXPORTED>
             <IRNCANCELLED>No</IRNCANCELLED>
             <ISSHIPPINGWITHINSTATE>No</ISSHIPPINGWITHINSTATE>
             <ISOVERSEASTOURISTTRANS>No</ISOVERSEASTOURISTTRANS>
             <ISDESIGNATEDZONEPARTY>No</ISDESIGNATEDZONEPARTY>
             <ISCANCELLED>No</ISCANCELLED>
             <HASCASHFLOW>Yes</HASCASHFLOW>
             <ISPOSTDATED>No</ISPOSTDATED>
             <USETRACKINGNUMBER>No</USETRACKINGNUMBER>
             <ISINVOICE>Yes</ISINVOICE>
             <MFGJOURNAL>No</MFGJOURNAL>
             <HASDISCOUNTS>No</HASDISCOUNTS>
             <ASPAYSLIP>No</ASPAYSLIP>
             <ISCOSTCENTRE>No</ISCOSTCENTRE>
             <ISSTXNONREALIZEDVCH>No</ISSTXNONREALIZEDVCH>
             <ISEXCISEMANUFACTURERON>No</ISEXCISEMANUFACTURERON>
             <ISBLANKCHEQUE>No</ISBLANKCHEQUE>
             <ISVOID>No</ISVOID>
             <ORDERLINESTATUS>No</ORDERLINESTATUS>
             <VATISAGNSTCANCSALES>No</VATISAGNSTCANCSALES>
             <VATISPURCEXEMPTED>No</VATISPURCEXEMPTED>
             <ISVATRESTAXINVOICE>No</ISVATRESTAXINVOICE>
             <VATISASSESABLECALCVCH>No</VATISASSESABLECALCVCH>
             <ISVATDUTYPAID>Yes</ISVATDUTYPAID>
             <ISDELIVERYSAMEASCONSIGNEE>No</ISDELIVERYSAMEASCONSIGNEE>
             <ISDISPATCHSAMEASCONSIGNOR>No</ISDISPATCHSAMEASCONSIGNOR>
             <ISDELETEDVCHRETAINED>No</ISDELETEDVCHRETAINED>
             <CHANGEVCHMODE>No</CHANGEVCHMODE>
             <RESETIRNQRCODE>No</RESETIRNQRCODE>
             <ALTERID></ALTERID>
             <MASTERID></MASTERID>
             <VOUCHERKEY></VOUCHERKEY>
             <EWAYBILLDETAILS.LIST>      </EWAYBILLDETAILS.LIST>
             <EXCLUDEDTAXATIONS.LIST>      </EXCLUDEDTAXATIONS.LIST>
             <OLDAUDITENTRIES.LIST>      </OLDAUDITENTRIES.LIST>
             <ACCOUNTAUDITENTRIES.LIST>      </ACCOUNTAUDITENTRIES.LIST>
             <AUDITENTRIES.LIST>      </AUDITENTRIES.LIST>
             <DUTYHEADDETAILS.LIST>      </DUTYHEADDETAILS.LIST>`;
      case "CHIPS":
        return `<ENVELOPE>
        <HEADER>
         <TALLYREQUEST>Import Data</TALLYREQUEST>
        </HEADER>
        <BODY>
         <IMPORTDATA>
          <REQUESTDESC>
           <REPORTNAME>Vouchers</REPORTNAME>
           <STATICVARIABLES>
            <SVCURRENTCOMPANY>Mosaram Vyapar  (from 1-Apr-${fromdate
              .getFullYear()
              .toString()
              .substr(-2)})</SVCURRENTCOMPANY>
           </STATICVARIABLES>
          </REQUESTDESC>
          <REQUESTDATA>
           <TALLYMESSAGE xmlns:UDF="TallyUDF">
            <VOUCHER VCHTYPE="TAX INVOICE (GST)" ACTION="Create" OBJVIEW="Invoice Voucher View">
             <OLDAUDITENTRYIDS.LIST TYPE="Number">
              <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
             </OLDAUDITENTRYIDS.LIST>
             <DATE>${this.dateFormatter(fromdate)}</DATE>
             <GUID></GUID>
             <GSTREGISTRATIONTYPE>Unregistered</GSTREGISTRATIONTYPE>
             <VATDEALERTYPE>Unregistered</VATDEALERTYPE>
             <STATENAME>Uttar Pradesh</STATENAME>
             <VOUCHERTYPENAME>TAX INVOICE (GST)</VOUCHERTYPENAME>
             <ENTEREDBY>mosaram</ENTEREDBY>
             <COUNTRYOFRESIDENCE>India</COUNTRYOFRESIDENCE>
             <PLACEOFSUPPLY>Uttar Pradesh</PLACEOFSUPPLY>
             <PARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYNAME>
             <PARTYLEDGERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYLEDGERNAME>
             <PARTYMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYMAILINGNAME>
             <CONSIGNEEMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</CONSIGNEEMAILINGNAME>
             <CONSIGNEESTATENAME>Uttar Pradesh</CONSIGNEESTATENAME>
             <VOUCHERNUMBER></VOUCHERNUMBER>
             <BASICBASEPARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBASEPARTYNAME>
             <CSTFORMISSUETYPE/>
             <CSTFORMRECVTYPE/>
             <FBTPAYMENTTYPE>Default</FBTPAYMENTTYPE>
             <PERSISTEDVIEW>Invoice Voucher View</PERSISTEDVIEW>
             <BASICBUYERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBUYERNAME>
             <CONSIGNEECOUNTRYNAME>India</CONSIGNEECOUNTRYNAME>
             <VCHGSTCLASS/>
             <VCHENTRYMODE>Item Invoice</VCHENTRYMODE>
             <DIFFACTUALQTY>No</DIFFACTUALQTY>
             <ISMSTFROMSYNC>No</ISMSTFROMSYNC>
             <ISDELETED>No</ISDELETED>
             <ISSECURITYONWHENENTERED>No</ISSECURITYONWHENENTERED>
             <ASORIGINAL>No</ASORIGINAL>
             <AUDITED>No</AUDITED>
             <FORJOBCOSTING>No</FORJOBCOSTING>
             <ISOPTIONAL>No</ISOPTIONAL>
             <EFFECTIVEDATE>${this.dateFormatter(fromdate)}</EFFECTIVEDATE>
             <USEFOREXCISE>No</USEFOREXCISE>
             <ISFORJOBWORKIN>No</ISFORJOBWORKIN>
             <ALLOWCONSUMPTION>No</ALLOWCONSUMPTION>
             <USEFORINTEREST>No</USEFORINTEREST>
             <USEFORGAINLOSS>No</USEFORGAINLOSS>
             <USEFORGODOWNTRANSFER>No</USEFORGODOWNTRANSFER>
             <USEFORCOMPOUND>No</USEFORCOMPOUND>
             <USEFORSERVICETAX>No</USEFORSERVICETAX>
             <ISONHOLD>No</ISONHOLD>
             <ISBOENOTAPPLICABLE>No</ISBOENOTAPPLICABLE>
             <ISGSTSECSEVENAPPLICABLE>No</ISGSTSECSEVENAPPLICABLE>
             <ISEXCISEVOUCHER>No</ISEXCISEVOUCHER>
             <EXCISETAXOVERRIDE>No</EXCISETAXOVERRIDE>
             <USEFORTAXUNITTRANSFER>No</USEFORTAXUNITTRANSFER>
             <IGNOREPOSVALIDATION>No</IGNOREPOSVALIDATION>
             <EXCISEOPENING>No</EXCISEOPENING>
             <USEFORFINALPRODUCTION>No</USEFORFINALPRODUCTION>
             <ISTDSOVERRIDDEN>No</ISTDSOVERRIDDEN>
             <ISTCSOVERRIDDEN>No</ISTCSOVERRIDDEN>
             <ISTDSTCSCASHVCH>No</ISTDSTCSCASHVCH>
             <INCLUDEADVPYMTVCH>No</INCLUDEADVPYMTVCH>
             <ISSUBWORKSCONTRACT>No</ISSUBWORKSCONTRACT>
             <ISVATOVERRIDDEN>No</ISVATOVERRIDDEN>
             <IGNOREORIGVCHDATE>No</IGNOREORIGVCHDATE>
             <ISVATPAIDATCUSTOMS>No</ISVATPAIDATCUSTOMS>
             <ISDECLAREDTOCUSTOMS>No</ISDECLAREDTOCUSTOMS>
             <ISSERVICETAXOVERRIDDEN>No</ISSERVICETAXOVERRIDDEN>
             <ISISDVOUCHER>No</ISISDVOUCHER>
             <ISEXCISEOVERRIDDEN>No</ISEXCISEOVERRIDDEN>
             <ISEXCISESUPPLYVCH>No</ISEXCISESUPPLYVCH>
             <ISGSTOVERRIDDEN>No</ISGSTOVERRIDDEN>
             <GSTNOTEXPORTED>No</GSTNOTEXPORTED>
             <IGNOREGSTINVALIDATION>No</IGNOREGSTINVALIDATION>
             <ISGSTREFUND>No</ISGSTREFUND>
             <OVRDNEWAYBILLAPPLICABILITY>No</OVRDNEWAYBILLAPPLICABILITY>
             <ISVATPRINCIPALACCOUNT>No</ISVATPRINCIPALACCOUNT>
             <IGNOREEINVVALIDATION>No</IGNOREEINVVALIDATION>
             <IRNJSONEXPORTED>No</IRNJSONEXPORTED>
             <IRNCANCELLED>No</IRNCANCELLED>
             <ISSHIPPINGWITHINSTATE>No</ISSHIPPINGWITHINSTATE>
             <ISOVERSEASTOURISTTRANS>No</ISOVERSEASTOURISTTRANS>
             <ISDESIGNATEDZONEPARTY>No</ISDESIGNATEDZONEPARTY>
             <ISCANCELLED>No</ISCANCELLED>
             <HASCASHFLOW>Yes</HASCASHFLOW>
             <ISPOSTDATED>No</ISPOSTDATED>
             <USETRACKINGNUMBER>No</USETRACKINGNUMBER>
             <ISINVOICE>Yes</ISINVOICE>
             <MFGJOURNAL>No</MFGJOURNAL>
             <HASDISCOUNTS>No</HASDISCOUNTS>
             <ASPAYSLIP>No</ASPAYSLIP>
             <ISCOSTCENTRE>No</ISCOSTCENTRE>
             <ISSTXNONREALIZEDVCH>No</ISSTXNONREALIZEDVCH>
             <ISEXCISEMANUFACTURERON>No</ISEXCISEMANUFACTURERON>
             <ISBLANKCHEQUE>No</ISBLANKCHEQUE>
             <ISVOID>No</ISVOID>
             <ORDERLINESTATUS>No</ORDERLINESTATUS>
             <VATISAGNSTCANCSALES>No</VATISAGNSTCANCSALES>
             <VATISPURCEXEMPTED>No</VATISPURCEXEMPTED>
             <ISVATRESTAXINVOICE>No</ISVATRESTAXINVOICE>
             <VATISASSESABLECALCVCH>No</VATISASSESABLECALCVCH>
             <ISVATDUTYPAID>Yes</ISVATDUTYPAID>
             <ISDELIVERYSAMEASCONSIGNEE>No</ISDELIVERYSAMEASCONSIGNEE>
             <ISDISPATCHSAMEASCONSIGNOR>No</ISDISPATCHSAMEASCONSIGNOR>
             <ISDELETEDVCHRETAINED>No</ISDELETEDVCHRETAINED>
             <CHANGEVCHMODE>No</CHANGEVCHMODE>
             <RESETIRNQRCODE>No</RESETIRNQRCODE>
             <ALTERID></ALTERID>
             <MASTERID></MASTERID>
             <VOUCHERKEY></VOUCHERKEY>
             <EWAYBILLDETAILS.LIST>      </EWAYBILLDETAILS.LIST>
             <EXCLUDEDTAXATIONS.LIST>      </EXCLUDEDTAXATIONS.LIST>
             <OLDAUDITENTRIES.LIST>      </OLDAUDITENTRIES.LIST>
             <ACCOUNTAUDITENTRIES.LIST>      </ACCOUNTAUDITENTRIES.LIST>
             <AUDITENTRIES.LIST>      </AUDITENTRIES.LIST>
             <DUTYHEADDETAILS.LIST>      </DUTYHEADDETAILS.LIST>`;
      case "PC":
        return `<ENVELOPE>
        <HEADER>
         <TALLYREQUEST>Import Data</TALLYREQUEST>
        </HEADER>
        <BODY>
         <IMPORTDATA>
          <REQUESTDESC>
           <REPORTNAME>Vouchers</REPORTNAME>
           <STATICVARIABLES>
            <SVCURRENTCOMPANY>Mosaram Vyapar  (from 1-Apr-${fromdate
              .getFullYear()
              .toString()
              .substr(-2)})</SVCURRENTCOMPANY>
           </STATICVARIABLES>
          </REQUESTDESC>
          <REQUESTDATA>
           <TALLYMESSAGE xmlns:UDF="TallyUDF">
            <VOUCHER VCHTYPE="TAX INVOICE (GST)" ACTION="Create" OBJVIEW="Invoice Voucher View">
             <OLDAUDITENTRYIDS.LIST TYPE="Number">
              <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
             </OLDAUDITENTRYIDS.LIST>
             <DATE>${this.dateFormatter(fromdate)}</DATE>
             <GUID></GUID>
             <GSTREGISTRATIONTYPE>Unregistered</GSTREGISTRATIONTYPE>
             <VATDEALERTYPE>Unregistered</VATDEALERTYPE>
             <STATENAME>Uttar Pradesh</STATENAME>
             <VOUCHERTYPENAME>TAX INVOICE (GST)</VOUCHERTYPENAME>
             <ENTEREDBY>mosaram</ENTEREDBY>
             <COUNTRYOFRESIDENCE>India</COUNTRYOFRESIDENCE>
             <PLACEOFSUPPLY>Uttar Pradesh</PLACEOFSUPPLY>
             <PARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYNAME>
             <PARTYLEDGERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYLEDGERNAME>
             <PARTYMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</PARTYMAILINGNAME>
             <CONSIGNEEMAILINGNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</CONSIGNEEMAILINGNAME>
             <CONSIGNEESTATENAME>Uttar Pradesh</CONSIGNEESTATENAME>
             <VOUCHERNUMBER></VOUCHERNUMBER>
             <BASICBASEPARTYNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBASEPARTYNAME>
             <CSTFORMISSUETYPE/>
             <CSTFORMRECVTYPE/>
             <FBTPAYMENTTYPE>Default</FBTPAYMENTTYPE>
             <PERSISTEDVIEW>Invoice Voucher View</PERSISTEDVIEW>
             <BASICBUYERNAME>${this.getCustomerName(
               reportType,
               data.customer
             )}</BASICBUYERNAME>
             <CONSIGNEECOUNTRYNAME>India</CONSIGNEECOUNTRYNAME>
             <VCHGSTCLASS/>
             <VCHENTRYMODE>Item Invoice</VCHENTRYMODE>
             <DIFFACTUALQTY>No</DIFFACTUALQTY>
             <ISMSTFROMSYNC>No</ISMSTFROMSYNC>
             <ISDELETED>No</ISDELETED>
             <ISSECURITYONWHENENTERED>No</ISSECURITYONWHENENTERED>
             <ASORIGINAL>No</ASORIGINAL>
             <AUDITED>No</AUDITED>
             <FORJOBCOSTING>No</FORJOBCOSTING>
             <ISOPTIONAL>No</ISOPTIONAL>
             <EFFECTIVEDATE>${this.dateFormatter(fromdate)}</EFFECTIVEDATE>
             <USEFOREXCISE>No</USEFOREXCISE>
             <ISFORJOBWORKIN>No</ISFORJOBWORKIN>
             <ALLOWCONSUMPTION>No</ALLOWCONSUMPTION>
             <USEFORINTEREST>No</USEFORINTEREST>
             <USEFORGAINLOSS>No</USEFORGAINLOSS>
             <USEFORGODOWNTRANSFER>No</USEFORGODOWNTRANSFER>
             <USEFORCOMPOUND>No</USEFORCOMPOUND>
             <USEFORSERVICETAX>No</USEFORSERVICETAX>
             <ISONHOLD>No</ISONHOLD>
             <ISBOENOTAPPLICABLE>No</ISBOENOTAPPLICABLE>
             <ISGSTSECSEVENAPPLICABLE>No</ISGSTSECSEVENAPPLICABLE>
             <ISEXCISEVOUCHER>No</ISEXCISEVOUCHER>
             <EXCISETAXOVERRIDE>No</EXCISETAXOVERRIDE>
             <USEFORTAXUNITTRANSFER>No</USEFORTAXUNITTRANSFER>
             <IGNOREPOSVALIDATION>No</IGNOREPOSVALIDATION>
             <EXCISEOPENING>No</EXCISEOPENING>
             <USEFORFINALPRODUCTION>No</USEFORFINALPRODUCTION>
             <ISTDSOVERRIDDEN>No</ISTDSOVERRIDDEN>
             <ISTCSOVERRIDDEN>No</ISTCSOVERRIDDEN>
             <ISTDSTCSCASHVCH>No</ISTDSTCSCASHVCH>
             <INCLUDEADVPYMTVCH>No</INCLUDEADVPYMTVCH>
             <ISSUBWORKSCONTRACT>No</ISSUBWORKSCONTRACT>
             <ISVATOVERRIDDEN>No</ISVATOVERRIDDEN>
             <IGNOREORIGVCHDATE>No</IGNOREORIGVCHDATE>
             <ISVATPAIDATCUSTOMS>No</ISVATPAIDATCUSTOMS>
             <ISDECLAREDTOCUSTOMS>No</ISDECLAREDTOCUSTOMS>
             <ISSERVICETAXOVERRIDDEN>No</ISSERVICETAXOVERRIDDEN>
             <ISISDVOUCHER>No</ISISDVOUCHER>
             <ISEXCISEOVERRIDDEN>No</ISEXCISEOVERRIDDEN>
             <ISEXCISESUPPLYVCH>No</ISEXCISESUPPLYVCH>
             <ISGSTOVERRIDDEN>No</ISGSTOVERRIDDEN>
             <GSTNOTEXPORTED>No</GSTNOTEXPORTED>
             <IGNOREGSTINVALIDATION>No</IGNOREGSTINVALIDATION>
             <ISGSTREFUND>No</ISGSTREFUND>
             <OVRDNEWAYBILLAPPLICABILITY>No</OVRDNEWAYBILLAPPLICABILITY>
             <ISVATPRINCIPALACCOUNT>No</ISVATPRINCIPALACCOUNT>
             <IGNOREEINVVALIDATION>No</IGNOREEINVVALIDATION>
             <IRNJSONEXPORTED>No</IRNJSONEXPORTED>
             <IRNCANCELLED>No</IRNCANCELLED>
             <ISSHIPPINGWITHINSTATE>No</ISSHIPPINGWITHINSTATE>
             <ISOVERSEASTOURISTTRANS>No</ISOVERSEASTOURISTTRANS>
             <ISDESIGNATEDZONEPARTY>No</ISDESIGNATEDZONEPARTY>
             <ISCANCELLED>No</ISCANCELLED>
             <HASCASHFLOW>Yes</HASCASHFLOW>
             <ISPOSTDATED>No</ISPOSTDATED>
             <USETRACKINGNUMBER>No</USETRACKINGNUMBER>
             <ISINVOICE>Yes</ISINVOICE>
             <MFGJOURNAL>No</MFGJOURNAL>
             <HASDISCOUNTS>No</HASDISCOUNTS>
             <ASPAYSLIP>No</ASPAYSLIP>
             <ISCOSTCENTRE>No</ISCOSTCENTRE>
             <ISSTXNONREALIZEDVCH>No</ISSTXNONREALIZEDVCH>
             <ISEXCISEMANUFACTURERON>No</ISEXCISEMANUFACTURERON>
             <ISBLANKCHEQUE>No</ISBLANKCHEQUE>
             <ISVOID>No</ISVOID>
             <ORDERLINESTATUS>No</ORDERLINESTATUS>
             <VATISAGNSTCANCSALES>No</VATISAGNSTCANCSALES>
             <VATISPURCEXEMPTED>No</VATISPURCEXEMPTED>
             <ISVATRESTAXINVOICE>No</ISVATRESTAXINVOICE>
             <VATISASSESABLECALCVCH>No</VATISASSESABLECALCVCH>
             <ISVATDUTYPAID>Yes</ISVATDUTYPAID>
             <ISDELIVERYSAMEASCONSIGNEE>No</ISDELIVERYSAMEASCONSIGNEE>
             <ISDISPATCHSAMEASCONSIGNOR>No</ISDISPATCHSAMEASCONSIGNOR>
             <ISDELETEDVCHRETAINED>No</ISDELETEDVCHRETAINED>
             <CHANGEVCHMODE>No</CHANGEVCHMODE>
             <RESETIRNQRCODE>No</RESETIRNQRCODE>
             <ALTERID></ALTERID>
             <MASTERID></MASTERID>
             <VOUCHERKEY></VOUCHERKEY>
             <EWAYBILLDETAILS.LIST>      </EWAYBILLDETAILS.LIST>
             <EXCLUDEDTAXATIONS.LIST>      </EXCLUDEDTAXATIONS.LIST>
             <OLDAUDITENTRIES.LIST>      </OLDAUDITENTRIES.LIST>
             <ACCOUNTAUDITENTRIES.LIST>      </ACCOUNTAUDITENTRIES.LIST>
             <AUDITENTRIES.LIST>      </AUDITENTRIES.LIST>
             <DUTYHEADDETAILS.LIST>      </DUTYHEADDETAILS.LIST>`;
      default:
    }
  }

  getFooterXml(reportType, data, fromdate) {
    switch (reportType) {
      case "CIG":
        return `<SUPPLEMENTARYDUTYHEADDETAILS.LIST>      </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
        <EWAYBILLERRORLIST.LIST>      </EWAYBILLERRORLIST.LIST>
        <IRNERRORLIST.LIST>      </IRNERRORLIST.LIST>
        <INVOICEDELNOTES.LIST>      </INVOICEDELNOTES.LIST>
        <INVOICEORDERLIST.LIST>      </INVOICEORDERLIST.LIST>
        <INVOICEINDENTLIST.LIST>      </INVOICEINDENTLIST.LIST>
        <ATTENDANCEENTRIES.LIST>      </ATTENDANCEENTRIES.LIST>
        <ORIGINVOICEDETAILS.LIST>      </ORIGINVOICEDETAILS.LIST>
        <INVOICEEXPORTLIST.LIST>      </INVOICEEXPORTLIST.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <LEDGERNAME>${this.getCustomerName(
           reportType,
           data.customer
         )}</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>Yes</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>-${
           data?.Grand_Total_Amount +
           this.getRoundOffValue(data?.Grand_Total_Amount)
         }</AMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> 14</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>CGST 14%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.Total_CGST_Tax_Amount}</AMOUNT>
         <VATEXPAMOUNT>${data?.Total_CGST_Tax_Amount}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> 14</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>SGST 14%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.Total_SGST_Tax_Amount}</AMOUNT>
         <VATEXPAMOUNT>${data?.Total_SGST_Tax_Amount}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>SALES COMP CESS</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.Sales_Comp_Cess}</AMOUNT>
         <VATEXPAMOUNT>${data?.Sales_Comp_Cess}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <ROUNDTYPE>Normal Rounding</ROUNDTYPE>
         <LEDGERNAME>RoundOff</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <ROUNDLIMIT> 1</ROUNDLIMIT>
         <AMOUNT>${this.getRoundOffValue(data?.Grand_Total_Amount)}</AMOUNT>
         <VATEXPAMOUNT>${this.getRoundOffValue(
           data?.Grand_Total_Amount
         )}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <PAYROLLMODEOFPAYMENT.LIST>      </PAYROLLMODEOFPAYMENT.LIST>
        <ATTDRECORDS.LIST>      </ATTDRECORDS.LIST>
        <GSTEWAYCONSIGNORADDRESS.LIST>      </GSTEWAYCONSIGNORADDRESS.LIST>
        <GSTEWAYCONSIGNEEADDRESS.LIST>      </GSTEWAYCONSIGNEEADDRESS.LIST>
        <TEMPGSTRATEDETAILS.LIST>      </TEMPGSTRATEDETAILS.LIST>
       </VOUCHER>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
     </REQUESTDATA>
    </IMPORTDATA>
   </BODY>
  </ENVELOPE>`;
      case "CHIPS":
        return `<SUPPLEMENTARYDUTYHEADDETAILS.LIST>      </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
        <EWAYBILLERRORLIST.LIST>      </EWAYBILLERRORLIST.LIST>
        <IRNERRORLIST.LIST>      </IRNERRORLIST.LIST>
        <INVOICEDELNOTES.LIST>      </INVOICEDELNOTES.LIST>
        <INVOICEORDERLIST.LIST>      </INVOICEORDERLIST.LIST>
        <INVOICEINDENTLIST.LIST>      </INVOICEINDENTLIST.LIST>
        <ATTENDANCEENTRIES.LIST>      </ATTENDANCEENTRIES.LIST>
        <ORIGINVOICEDETAILS.LIST>      </ORIGINVOICEDETAILS.LIST>
        <INVOICEEXPORTLIST.LIST>      </INVOICEEXPORTLIST.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <LEDGERNAME>${this.getCustomerName(
           reportType,
           data.customer
         )}</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>Yes</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>-${
           data?.TOTAL_NET + this.getRoundOffValue(data?.TOTAL_NET)
         }</AMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> ${data.RATE / 2}</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>CGST ${data.RATE / 2}%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.TOTAL_GST / 2}</AMOUNT>
         <VATEXPAMOUNT>${data?.TOTAL_GST / 2}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> ${data.RATE / 2}</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>SGST ${data.RATE / 2}%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.TOTAL_GST / 2}</AMOUNT>
         <VATEXPAMOUNT>${data?.TOTAL_GST / 2}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <ROUNDTYPE>Normal Rounding</ROUNDTYPE>
         <LEDGERNAME>RoundOff</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <ROUNDLIMIT> 1</ROUNDLIMIT>
         <AMOUNT>${this.getRoundOffValue(data?.TOTAL_NET)}</AMOUNT>
         <VATEXPAMOUNT>${this.getRoundOffValue(data?.TOTAL_NET)}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <PAYROLLMODEOFPAYMENT.LIST>      </PAYROLLMODEOFPAYMENT.LIST>
        <ATTDRECORDS.LIST>      </ATTDRECORDS.LIST>
        <GSTEWAYCONSIGNORADDRESS.LIST>      </GSTEWAYCONSIGNORADDRESS.LIST>
        <GSTEWAYCONSIGNEEADDRESS.LIST>      </GSTEWAYCONSIGNEEADDRESS.LIST>
        <TEMPGSTRATEDETAILS.LIST>      </TEMPGSTRATEDETAILS.LIST>
       </VOUCHER>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
     </REQUESTDATA>
    </IMPORTDATA>
   </BODY>
  </ENVELOPE>`;
      case "PC":
        return `<SUPPLEMENTARYDUTYHEADDETAILS.LIST>      </SUPPLEMENTARYDUTYHEADDETAILS.LIST>
        <EWAYBILLERRORLIST.LIST>      </EWAYBILLERRORLIST.LIST>
        <IRNERRORLIST.LIST>      </IRNERRORLIST.LIST>
        <INVOICEDELNOTES.LIST>      </INVOICEDELNOTES.LIST>
        <INVOICEORDERLIST.LIST>      </INVOICEORDERLIST.LIST>
        <INVOICEINDENTLIST.LIST>      </INVOICEINDENTLIST.LIST>
        <ATTENDANCEENTRIES.LIST>      </ATTENDANCEENTRIES.LIST>
        <ORIGINVOICEDETAILS.LIST>      </ORIGINVOICEDETAILS.LIST>
        <INVOICEEXPORTLIST.LIST>      </INVOICEEXPORTLIST.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <LEDGERNAME>${this.getCustomerName(
           reportType,
           data.customer
         )}</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>Yes</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>Yes</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>-${
           data?.TOTAL_NET + this.getRoundOffValue(data?.TOTAL_NET)
         }</AMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> ${data.RATE / 2}</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>CGST ${data.RATE / 2}%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.TOTAL_GST / 2}</AMOUNT>
         <VATEXPAMOUNT>${data?.TOTAL_GST / 2}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <BASICRATEOFINVOICETAX.LIST TYPE="Number">
          <BASICRATEOFINVOICETAX> ${data.RATE / 2}</BASICRATEOFINVOICETAX>
         </BASICRATEOFINVOICETAX.LIST>
         <ROUNDTYPE/>
         <LEDGERNAME>SGST ${data.RATE / 2}%</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <AMOUNT>${data?.TOTAL_GST / 2}</AMOUNT>
         <VATEXPAMOUNT>${data?.TOTAL_GST / 2}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <LEDGERENTRIES.LIST>
         <OLDAUDITENTRYIDS.LIST TYPE="Number">
          <OLDAUDITENTRYIDS>-1</OLDAUDITENTRYIDS>
         </OLDAUDITENTRYIDS.LIST>
         <ROUNDTYPE>Normal Rounding</ROUNDTYPE>
         <LEDGERNAME>RoundOff</LEDGERNAME>
         <GSTCLASS/>
         <ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>
         <LEDGERFROMITEM>No</LEDGERFROMITEM>
         <REMOVEZEROENTRIES>No</REMOVEZEROENTRIES>
         <ISPARTYLEDGER>No</ISPARTYLEDGER>
         <ISLASTDEEMEDPOSITIVE>No</ISLASTDEEMEDPOSITIVE>
         <ISCAPVATTAXALTERED>No</ISCAPVATTAXALTERED>
         <ISCAPVATNOTCLAIMED>No</ISCAPVATNOTCLAIMED>
         <ROUNDLIMIT> 1</ROUNDLIMIT>
         <AMOUNT>${this.getRoundOffValue(data?.TOTAL_NET)}</AMOUNT>
         <VATEXPAMOUNT>${this.getRoundOffValue(data?.TOTAL_NET)}</VATEXPAMOUNT>
         <SERVICETAXDETAILS.LIST>       </SERVICETAXDETAILS.LIST>
         <BANKALLOCATIONS.LIST>       </BANKALLOCATIONS.LIST>
         <BILLALLOCATIONS.LIST>       </BILLALLOCATIONS.LIST>
         <INTERESTCOLLECTION.LIST>       </INTERESTCOLLECTION.LIST>
         <OLDAUDITENTRIES.LIST>       </OLDAUDITENTRIES.LIST>
         <ACCOUNTAUDITENTRIES.LIST>       </ACCOUNTAUDITENTRIES.LIST>
         <AUDITENTRIES.LIST>       </AUDITENTRIES.LIST>
         <INPUTCRALLOCS.LIST>       </INPUTCRALLOCS.LIST>
         <DUTYHEADDETAILS.LIST>       </DUTYHEADDETAILS.LIST>
         <EXCISEDUTYHEADDETAILS.LIST>       </EXCISEDUTYHEADDETAILS.LIST>
         <RATEDETAILS.LIST>       </RATEDETAILS.LIST>
         <SUMMARYALLOCS.LIST>       </SUMMARYALLOCS.LIST>
         <STPYMTDETAILS.LIST>       </STPYMTDETAILS.LIST>
         <EXCISEPAYMENTALLOCATIONS.LIST>       </EXCISEPAYMENTALLOCATIONS.LIST>
         <TAXBILLALLOCATIONS.LIST>       </TAXBILLALLOCATIONS.LIST>
         <TAXOBJECTALLOCATIONS.LIST>       </TAXOBJECTALLOCATIONS.LIST>
         <TDSEXPENSEALLOCATIONS.LIST>       </TDSEXPENSEALLOCATIONS.LIST>
         <VATSTATUTORYDETAILS.LIST>       </VATSTATUTORYDETAILS.LIST>
         <COSTTRACKALLOCATIONS.LIST>       </COSTTRACKALLOCATIONS.LIST>
         <REFVOUCHERDETAILS.LIST>       </REFVOUCHERDETAILS.LIST>
         <INVOICEWISEDETAILS.LIST>       </INVOICEWISEDETAILS.LIST>
         <VATITCDETAILS.LIST>       </VATITCDETAILS.LIST>
         <ADVANCETAXDETAILS.LIST>       </ADVANCETAXDETAILS.LIST>
        </LEDGERENTRIES.LIST>
        <PAYROLLMODEOFPAYMENT.LIST>      </PAYROLLMODEOFPAYMENT.LIST>
        <ATTDRECORDS.LIST>      </ATTDRECORDS.LIST>
        <GSTEWAYCONSIGNORADDRESS.LIST>      </GSTEWAYCONSIGNORADDRESS.LIST>
        <GSTEWAYCONSIGNEEADDRESS.LIST>      </GSTEWAYCONSIGNEEADDRESS.LIST>
        <TEMPGSTRATEDETAILS.LIST>      </TEMPGSTRATEDETAILS.LIST>
       </VOUCHER>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
      <TALLYMESSAGE xmlns:UDF="TallyUDF">
       <COMPANY>
        <REMOTECMPINFO.LIST MERGE="Yes">
         <NAME></NAME>
         <REMOTECMPNAME>Mosaram Vyapar  (from 1-Apr-${fromdate
           .getFullYear()
           .toString()
           .substr(-2)}</REMOTECMPNAME>
         <REMOTECMPSTATE>Uttar Pradesh</REMOTECMPSTATE>
        </REMOTECMPINFO.LIST>
       </COMPANY>
      </TALLYMESSAGE>
     </REQUESTDATA>
    </IMPORTDATA>
   </BODY>
  </ENVELOPE>`;
        break;
      default:
    }
  }
}

export default Tally;
