import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ride from "../../services/ride.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import _ from "lodash";

const VehicleType = () => {
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [vehicleState, setVehicleState] = useState({
    code: null,
    name: null,
    description: null,
  });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const columns = [
    {
      dataField: "code",
      text: "Vehicle code",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "Vehicle Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: 20,
      },
    ],
  };

  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDIT_VEHICLE_TYPE",
    }));
    setVehicleState(row);
  };

  useEffect(() => {
    getVehicleTypeList();
  }, []);

  const getVehicleTypeList = async () => {
    try {
      const res = await ride.getAllVehicleType();
      setVehicleTypeList([]);
      if (res?.data?.errorCode === 0) {
        setVehicleTypeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const insertVehicleType = async () => {
    try {
      const result = await ride.insertVehicleType(vehicleState);
      if (result?.data?.errorCode === 0) {
        getVehicleTypeList();
        setVehicleState({ code: null, name: null, description: null });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else if (result?.data?.errorCode === -409) {
        getVehicleTypeList();
        setVehicleState({ code: null, name: null, description: null });
        const successToast = toast.warning(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await ride.deleteVehicleType({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getVehicleTypeList();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handleUpdate = async () => {
    try {
      const result = await ride.updateVehicleType(vehicleState);
      if (result?.data?.errorCode === 0) {
        getVehicleTypeList();
        setEditShowModal((prev) => ({ ...prev, state: false }));
        setVehicleState({
          code: null,
          name: null,
          description: null,
        });
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  return (
    <LayoutContainer
      title1="Vehicle Type"
      title2="Vehicle Type"
      title3="Vehicle Type"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Vehicle Type"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_VEHICLE_TYPE",
              }));
            }}
          />
        </>
      }
    >
      <div className="px-3 py-3">
        <div className="col-md-12">
          <BootstrapTable
            classes="border border-1"
            keyField="price"
            data={vehicleTypeList}
            columns={columns}
            pagination={paginationFactory(featureListOption)}
            filter={filterFactory()}
          />
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={
            showModal.type === "ADD_VEHICLE_TYPE" ? "Add Vehicle Type" : ""
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setVehicleState({
              code: null,
              name: null,
              description: null,
            });
          }}
          centered
        >
          {showModal.type === "ADD_VEHICLE_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Vehicle Type Code</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Code"
                    value={vehicleState.code}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        code: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Vehicle Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Name"
                    value={vehicleState.name}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="col-md-12 ps-0 ms-0">
                  <label htmlFor="">Vehicle Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    name=""
                    rows="3"
                    placeholder="Enter Description"
                    value={vehicleState.description}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                {vehicleState.code && vehicleState.name ? (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      onClick={() => {
                        insertVehicleType();
                        setShowModal((prev) => ({ ...prev, state: false }));
                      }}
                    ></FUSButton>
                  </>
                ) : (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      disabled
                    ></FUSButton>
                  </>
                )}
              </div>
            </div>
          )}
        </FUSModal>
      )}
      {showEditModal.state && (
        <FUSModal
          title={
            showEditModal.type === "EDIT_VEHICLE_TYPE"
              ? "Edit Vehicle Type"
              : ""
          }
          showModal={showEditModal.state}
          size={"md"}
          handleClose={() => {
            setEditShowModal((prev) => ({ ...prev, state: false }));
            setVehicleState({
              code: null,
              name: null,
              description: null,
            });
          }}
          centered
        >
          {showEditModal.type === "EDIT_VEHICLE_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Vehicle Type Code</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Code"
                    value={vehicleState.code}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        code: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Vehicle Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Name"
                    value={vehicleState.name}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="col-md-12 ps-0 ms-0">
                  <label htmlFor="">Vehicle Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    name=""
                    rows="3"
                    placeholder="Enter Description"
                    value={vehicleState.description}
                    onChange={(e) =>
                      setVehicleState((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleUpdate()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default VehicleType;
