import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";

const FunctionalityList = () => {
    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [DataList, setDataList] = useState([]);
    const [inputText, setInputText] = useState("")
    const [functionalityCode, setFunctionalityCode] = useState("");


    const getData = () => {
        setDataList([]);

        MstService.getFunctionalityList()
            .then((response) => {
                setDataList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const handleDelete = (row) => {

    }

    const handleEdit = (row) => {

    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "6",
                value: 6,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: DataList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: 'disp_name',
            text: 'Functionality Name'
        },
        {
            dataField: 'code',
            text: 'Functionality Code'
        },
        {
            dataField: "action",
            text: "Action",
            formatter: (cellContent, row) => {
                return (


                    <div className="d-flex gap-3">
                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdOutlineModeEdit"
                            iconSize={16}
                            buttonType="lightgray"
                            onClick={() => handleEdit(row)}
                        />

                        <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                );
            },
            editable: false,
        }
    ];

    const insertData = () => {
        MstService.insertFunctionality(
            {
                func_name: inputText,
                code: functionalityCode
            }
        )
            .then((response) => {
                setShowModal((prev) => ({ ...prev, state: false }))

            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    return (

        <>

            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3="Create Functionality Type"
                right={
                    <>

                        <FUSButton
                            labelText={"Create Functionality Type"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }
            >

                <div className="px-1 pt-1">


                    <div className="px-2 mx-1">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={DataList}
                            columns={columns}
                            //filter={filterFactory()}
                            pagination={paginationFactory(options)}
                        />
                    </div>
                </div>

                {showModal.state && (
                    <FUSModal
                        title="Create Functionality Type"
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                        centered

                    >
                        <div>
                            <form role="search" onSubmit={null}>
                                <div class="row">
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter functionality name</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={inputText}
                                                onChange={(e) => setInputText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 px-0">
                                        <div>
                                            <span className="text-secondary">Enter functionality Code</span>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text_style form-control mt-1"
                                                placeholder="Enter here..."
                                                value={functionalityCode}
                                                onChange={(e) => setFunctionalityCode(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0 mt-2">
                                        <FUSButton labelText={"Add"} className={"form-control"} buttonType={inputText === "" ? "secondary" : "primary"} disabled={inputText === ""} onClick={(e) => insertData()} type="submit" />

                                    </div>
                                </div>
                            </form>
                        </div>
                    </FUSModal>

                )}

            </LayoutContainer>
        </>
    )
}
export default FunctionalityList;