import { json } from "react-router-dom";

const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.token;
};

const updateLocalAccessToken = (token) => {
  const user = JSON.parse(localStorage.getItem("user"));
  user.token = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => JSON.parse(localStorage.getItem("user"));

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const setRequest = (Req)=>{
  localStorage.setItem("request",json.parse(localStorage.getItem("request")));
}

const getRequest = () => JSON.parse(localStorage.getItem("request"));

const removeRequest = ()=>{
  localStorage.removeItem("request");
}
const setRolePermission = (value)=>{
  localStorage.setItem("rolePermission",JSON.stringify(value));
}
const getRolePermission = ()=>{
  
  const role = JSON.parse(localStorage.getItem("rolePermission"));
  return role;
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  removeRequest,
  setRequest,
  getRequest,
  setRolePermission,
  getRolePermission
};

export default TokenService;
