import React, { Fragment, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import VendorHolidayConfiguration from "../VendorsApproval/VendorHolidayConfiguration";
import OrderConfig from "../Orders/OrderConfig";
import FUSStepper from "../../FUSComponents/FUSStepper/FUSStepper";
const HolidayAndOrderWizard = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const formtabs = {
    holiday_config: "holiday_config",
    order_config: "order_config",
  };

  const Pages = [
    {
      tab_name: "Holiday Config",
      tab_value: formtabs.holiday_config,
      isActive: true,
      component: VendorHolidayConfiguration,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG
      ),
    },

    {
      tab_name: "Order Config ",
      tab_value: formtabs.order_config,
      isActive: false,
      component: OrderConfig,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_ORDER_CONFIG
      ),
    },
  ];

  return <FUSStepper initialSteps={Pages} />;
};

export default HolidayAndOrderWizard;
