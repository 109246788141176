import React from "react";
import "./FUSButton.css";
import FUSIcon from "../../FUSIcon/FUSIcon";
import { Button } from "react-bootstrap";
export const FUSButton = ({
  className,
  onClick,
  labelText,
  iconSrc,
  iconName,
  iconSize,
  iconColor,
  buttonType = "transparent",
  buttonTextStyle,
  flag = false,
  ...rest
}) => {
  const handleButtonType = () => {
    switch (buttonType) {
      case "primary":
        return "#7966e4";
      case "secondary":
        return "#77778f";
      case "success":
        return "#529C3B";
      case "danger":
        return "#dd4343";
      case "lightdanger":
        return "#f3dede";
      case "light":
        return "#f1f4fd";
      case "lightgray":
        return "#7171851a";

      case "white":
        return "#ffffff";
      default:
        return;
    }
  };

  const handleButtonLabel = () => {
    switch (buttonType) {
      case "primary":
        return "#ffffff";
      case "secondary":
        return "#ffffff";
      case "success":
        return "#ffffff";
      case "danger":
        return "#ffffff";
      case "lightdanger":
        return "#dd4343";
      case "lightgray":
        return "#717185";
      default:
        return "";
    }
  };

  return (
    <Button
      className={`btn border border-0 d-flex gap-1 align-items-center justify-content-center btn_style ${className}`}
      style={{
        backgroundColor: handleButtonType(buttonType),
      }}
      onClick={onClick}
      {...rest}
    >
      {iconName && (
        <FUSIcon
          iconSrc={iconSrc}
          iconName={iconName}
          size={iconSize}
          color={handleButtonLabel(buttonType)}
        />
      )}
      {labelText && (
        <>
          <span
            className={`${buttonTextStyle}`}
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: handleButtonLabel(buttonType),
            }}
          >
            {labelText}
          </span>
          {flag && (
            <div class="spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </>
      )}
    </Button>
  );
};
