import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import TokenService from "../../services/token.service";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import "./ImportExelDataView.css";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";

function ImportExelDataView() {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [reportTable, setReporttable] = useState([]);
  const [show, setShow] = useState(true);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("overrides", overrides);
    axios({
      method: "post",
      url: `${VARIABLES.BASE_URL}/getDataFromUploadExcel`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setReporttable(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const rowStyleForBillMissing = (row) => {
    const style = {};
    if (row[16] === "R") {
      style.backgroundColor = "#750089";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
    }

    return style;
  };

  const dateFormatter = (cell, row) => {
    if (cell?.toString()?.includes("01 Jan, 1900")) {
      return "";
    }

    return cell;
  };

  const renderTableData = (data) => {
    const style = {};
    return (
      data.length > 0 &&
      data.map((element, i) => (
        <>
          <tr key={i} style={rowStyleForBillMissing(element)}>
            <td>{element[1]} </td>
            <td>{element[2]}</td>
            <td>{element[3]}</td>
            <td>{element[4]}</td>
            <td>{element[11]}</td>
            <td>{element[6]}</td>
            <td>{element[7]}</td>
            <td>{element[12] === "CASH" ? element[8] : 0}</td>
            <td>{element[12] === "ONLINE" ? element[8] : 0}</td>
            <td>{element[12] === "CHEQUE" ? element[8] : 0}</td>
            <td>{dateFormatter(element[17])}</td>
            <td>{element[12] === "ONLINE" || "CHEQUE" ? element[14] : 0}</td>
          </tr>
        </>
      ))
    );
  };

  return (
    <>
      <LayoutContainer
        title1={"Reports"}
        title2={"Reports"}
        title3={"Excel Upload View"}
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <span className="beat_file_sample d-none d-sm-block">Upload excel file in</span>
              &nbsp;
              <a
                className="beat_file_sample themeColorBlue"
                style={{ cursor: "pointer" }}
              >
                Link sample excel format file
              </a>
            </div>
          </>
        }
      >
        <div className="px-1 py-3">
          {show ? (
            <div className="mt-5 pt-5">
              <div className="mt-5 pt-3">
                <Form>
                  <FUSUploadFile onChange={(e) => changeHandler(e)} />
                  <div>
                    {selectedFile && selectedFile !== "" ? (
                      <div className="d-flex justify-content-center align-items-center gap-3 mt-3 ms-2">
                        <p className="fs-5">
                          {`Filename: ${selectedFile?.name} `}{" "}
                        </p>
                        <FUSButton
                          labelText={"Upload"}
                          buttonType="primary"
                          onClick={() => uploadData(false)}
                        />
                      </div>
                    ) : null}
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div
                  className="react-bootstrap tableScrollable"
                  style={{ marginBottom: 35 }}
                >
                  <table
                    id="table-to-xls"
                    border="1"
                    className="table table-bordered valignCenter"
                  >
                    <thead>
                      <tr>
                        <th rowSpan={2}>Formate Date</th>
                        <th rowSpan={2}>Ref_no</th>
                        <th rowSpan={2}>Party Name</th>
                        <th rowSpan={2}>Collector Code</th>
                        <th rowSpan={2}>Name</th>
                        <th rowSpan={2}>Opening</th>
                        <th rowSpan={2}>Pending</th>
                        <th colSpan={3} align="center">
                          Received
                        </th>
                        <th rowSpan={2}>Payment Received On</th>
                        <th rowSpan={2}>Reference No.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportTable?.length ? (
                        renderTableData(reportTable)
                      ) : (
                        <tr className="text-center">
                          <td colSpan={12}>
                            <div>
                              <div className="mt-5 pb-5">
                                <FUSEmptyList title={"Data Not Found"} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </LayoutContainer>
    </>
  );
}

export default ImportExelDataView;
