import React, { useEffect, useState } from "react";
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import MstService from '../../services/mst.service';
import requestAndErrorHandler from '../../services/responseAndErrorHandler';
import _ from 'lodash';

const SendPushNotification = () => {

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [collectorList, setCollectorList] = useState([]);
    const [retailerList, setRetailerList] = useState([]);
    const [selectedOption, setSelectedOption] = useState("ALL");
    const [currentRadioValue, setCurrentRadioValue] = useState("collector")

    const handleRadioChange = (e) => {
        setCurrentRadioValue(e.target.value);
    };

    const getCollectorList = () => {
        MstService.getCollector()
            .then((response) => {
             
                setCollectorList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getRetailerList = () => {
        MstService.getCollector()
            .then((response) => {
             
                setRetailerList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getCollectorList();
        getRetailerList();
    }, []);

    const SentMessage = async (fcmkey) => {
        if (fcmkey !== "ALL") {
           await MstService.sendMobileNotification(
                {
                    fcmID: fcmkey,
                    body: message,
                    title: title
                }
            )
            
        } else if (fcmkey === "ALL" && currentRadioValue === "collector") {

            collectorList.length > 0 && collectorList.map(async (item) => {
                if (!_.isNil(item?.fcm_key)) {
                  await  MstService.sendMobileNotification(
                        {
                            fcmID: item?.fcm_key,
                            body: message,
                            title: title
                        }
                    )
                        
                }
            })

        } else if (fcmkey === "ALL" && currentRadioValue === "retailer") {
            retailerList.length > 0 && retailerList.map(async (item) => {
                if (!_.isNil(item?.fcm_key)) {
                   await MstService.sendMobileNotification(
                        {
                            fcmID: item?.fcm_key,
                            body: message,
                            title: title
                        }
                    )
                       
                }
            })

        }
    }


    return (
        <>
            <h6>
                Push Notification
            </h6>
            <div className='container-fluid mt-4 px-0'>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <div>
                                    <div>
                                        <input
                                            id="radio-item-1"
                                            name="radio-item-1"
                                            type="radio"
                                            value="collector"
                                            onChange={handleRadioChange}
                                            checked={currentRadioValue === 'collector'}
                                        />
                                        <label htmlFor="radio-item-1">Collector</label>
                                    </div>
                                    <div>
                                        <input
                                            id="radio-item-2"
                                            name="radio-item-2"
                                            type="radio"
                                            value="retailer"
                                            onChange={handleRadioChange}
                                            checked={currentRadioValue === 'retailer'}
                                        />
                                        <label htmlFor="radio-item-2">
                                            Retailer
                                        </label>
                                    </div>
                                </div>

                                {currentRadioValue === "collector" ?
                                    <div className="col-md-3">
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                        >
                                            <option value="ALL">
                                                All Collector
                                            </option>
                                            {collectorList?.map((item) => (
                                                <option
                                                    value={item?.fcm_key}>
                                                    {item?.name}
                                                </option>
                                            ))}
                                            ;
                                        </Form.Control>
                                    </div>
                                    :
                                    <div className="col-md-3">
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                        >
                                            <option value="ALL">
                                                All Retailer
                                            </option>
                                            {retailerList?.map((item) => (
                                                <option
                                                    value={item?.fcm_key} >
                                                    {item?.name}
                                                </option>
                                            ))}
                                            ;
                                        </Form.Control>
                                    </div>
                                }


                                <Card.Text>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control onChange={(e) => setTitle(e.target.value)} type="email" placeholder="Enter Title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Notification Message</Form.Label>
                                            <Form.Control onChange={(e) => setMessage(e.target.value)} as="textarea" placeholder='Enter Message' rows={3} />
                                        </Form.Group>
                                        <Button onClick={() => SentMessage(selectedOption)} disabled={title === "" || message === ""} style={{ float: 'right' }} variant="primary">Send Notification</Button>{' '}
                                    </Form>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default SendPushNotification;