import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import MstService from "../../services/mst.service";
import VendorService from "../../services/vendor.services";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import _ from "lodash";

const VendorLOBMapping = () => {
  const [vendorList, setVendorList] = useState([]);
  const [lobList, setLobList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(-1);
  const [selectedLOB, setSelectedLOB] = useState(-1);
  const [mappingData, setMappingData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
  });
  const [approve, setApprove] = useState(true);
  const getAllVendors = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setLobList(result?.data?.data);
      } else {
        setLobList([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleVendorLOBMapping = async () => {
    const postdata = {
      tag: "INSERT",
      lob_id: selectedLOB,
      vendor_id: selectedVendor,
    };

    try {
      const result = await VendorService.vendorLOBMapping(postdata);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
        setApprove(approve => !approve);
        setSelectedVendor(-1);
        setSelectedLOB(-1);
        setShowModal({ state: false });

      } else {
        toast.error(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
        setSelectedVendor(-1);
        setSelectedLOB(-1);
        setShowModal({ state: false });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const fetchMappingData = async () => {
    const postdata = {
      tag: "GET",
    };
    await VendorService.vendorLOBMapping(postdata)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setMappingData(res?.data?.data);
        } else {
          setMappingData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const handleMappingStatus = async (lob, newStatus) => {
    const postdata = {
      vendor_id: lob?.vendor_id,
      lob_id: lob?.lob_id,
    };
    try {
      const result = await VendorService.updateVendorLOBMappingStatus(postdata);

      if (result?.data?.errorCode === 0) {
        setApprove(approve => !approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    fetchMappingData();
    getAllVendors();
    getLOBForSuperUser();
  }, [approve]);

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Vendor LOB Mapping"
        right={
          <>
            <div>
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                labelText={"Add Vendor-LOB Mapping"}
                buttonType="primary"
                onClick={() =>
                  setShowModal((prev) => ({ ...prev, state: true }))
                }
              ></FUSButton>
            </div>
          </>
        }
      >
        <div className="px-3 mt-3 mx-1">
          <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
            <thead>
              <th>Vendor Name</th>
              <th>LOB Name</th>
              <th>Status</th>
              <th>Action</th>
            </thead>
            <tbody>
              {mappingData?.length > 0 ? (
                mappingData?.map((item, index) =>
                  item?.lobs?.map((lob, lobIndex) => (
                    <tr key={`${index}-${lobIndex}`}>
                      {lobIndex === 0 ? (
                        <td rowSpan={item?.lobs?.length}>
                          {item?.vendor_name}
                        </td>
                      ) : null}
                      <td>{lob?.lob_name}</td>
                      <td
                        className={`${
                          lob?.is_active ? "text-success" : "text-danger"
                        }`}
                      >
                        {lob?.is_active ? "Active" : "Inactive"}
                      </td>
                      <td>
                        <FUSButton
                          className={"py-1 fs-6"}
                          buttonType={!lob?.is_active ? "primary" : "danger"}
                          labelText={
                            !lob?.is_active ? "Activate" : "Deactivate"
                          }
                          onClick={() => handleMappingStatus(lob, false)}
                        />
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan={2}>
                    <div className="py-5 my-5">
                      <FUSEmptyList title="Data not found" />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LayoutContainer>

      {showModal.state && (
        <FUSModal
          title={"Add Mapping"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setSelectedVendor(-1);
            setSelectedLOB(-1);
          }}
          centered
        >
          <div className="row">
            <div className="col-12 px-0 mb-2">
              <span className="text-secondary mb-2">Select Vendor</span>
              <div>
                <select
                  className="form-select"
                  value={selectedVendor}
                  onChange={(e) => setSelectedVendor(e.target.value)}
                >
                  <option value={-1}>Select Vendor</option>
                  {vendorList?.length > 0 &&
                    vendorList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.user_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-12 px-0">
              <span className="text-secondary mb-2">Select LOB</span>
              <div>
                <select
                  className="form-select"
                  value={selectedLOB}
                  onChange={(e) => setSelectedLOB(e.target.value)}
                >
                  <option value={-1}>Select LOB</option>
                  {lobList?.length > 0 &&
                    lobList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.lob}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-3 mt-3 px-0">
              <FUSButton
                className={"form-control py-2"}
                buttonType="primary"
                labelText={"Map"}
                onClick={handleVendorLOBMapping}
                disabled={_.isEmpty(selectedLOB) || _.isEmpty(selectedVendor)}
              />
            </div>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default VendorLOBMapping;
