import React, { useEffect } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";

const Temp = () => {
  let token = new URLSearchParams(window.location.search).get("k");

  const getUrlByToken = () => {
    MstService.getUrlByShortURL({
      token: token,
    })
      .then((response) => {
        if (response?.data?.errorCode == 0) {
          window.location.replace(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getUrlByToken(token);
  }, []);

  return <h>ddd</h>;
};
export default Temp;
