import React, { Fragment, useContext } from "react";
import "./Wizard.css";
import Orders from "../Orders/Orders";
import PurchasingPlan from "../PurchasingPlan/PurchasingPlan";
import PackagingPlan from "../PackagingPlan/PackagingPlan";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import FUSStepper from "../../FUSComponents/FUSStepper/FUSStepper";
const OrderWizard = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);

  const pages = [
    {
      tab_name: "View Open Orders",
      tab_value: "open_orders",
      isActive: true,
      component: Orders,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_VIEW_ORDERS
      ),
    },

    {
      tab_name: "Purchasing Plan",
      tab_value: "purchasing_plan",
      isActive: false,
      component: PurchasingPlan,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_PURCHASE_PLAN
      ),
    },
    {
      tab_name: "Packaging Plan",
      tab_value: "packaging_plan",
      isActive: false,
      component: PackagingPlan,
      template: Fragment,
      is_permission_enabled: isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
        ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
          .VENDOR_ADMIN_LMENU_PACKAGE_PLAN
      ),
    },
  ];

  return <FUSStepper initialSteps={pages} />;
};

export default OrderWizard;
