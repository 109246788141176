import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import Autocomplete from "../Autocomplete/Autocomplete";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const GodownMapping = () => {
  const [mapping, setMapping] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [godownData, setGodownData] = useState([]);
  const [godownCollectorList, setGodownCollectorList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const setSelected = (index, value, key) => {};

  const getGodownMapping = () => {
    MstService.getGodownMapping()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setGodownData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const getGodownCollectorLists = () => {
    MstService.getGodownCollectorList()
      .then((response) => {
        var arr = response?.data?.data?.map((item) => ({
          ...item,
          label: item.collector_name,
        }));
        setGodownCollectorList(arr);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getGodownMapping();
    getGodownCollectorLists();
  }, [isLoading]);

  const cellEdit = {
    mode: "click",
    errorMessage: errorMessage,
    blurToSave: true,
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "12",
        value: 12,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: mapping.length,
      },
    ],
  };

  const columns = [
    {
      dataField: "godown_name",
      text: "Godown Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "godown_code",
      text: "Godown Code",
      sort: true,
      editable: false,
    },
    {
      dataField: "collector_name",
      text: "Collector",
      sort: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <Autocomplete
          options={godownCollectorList}
          setSelected={setSelected}
          index={rowIndex}
          keyType={"collector_id"}
          {...editorProps}
          value={value}
        />
      ),
      validator: (newValue, row, column) => {
        if (_.isEmpty(newValue)) {
          return {
            valid: false,
            message: "Please select collector",
          };
        }
        return true;
      },
      filter: textFilter(),
    },
  ];

  const handleTableChange = (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    setTimeout(() => {
      const result = data.map((row) => {
        if (row.collector_code_id === rowId) {
          const newRow = { ...row };

          switch (dataField) {
            case "collector_name":
              newRow["collector_name"] = newValue[0].collector_name;
              newRow["collector_id"] = newValue[0].collector_id;
              break;
          }
          return newRow;
        }
        return row;
      });

      setGodownData(result);
      setErrorMessage(null);
    }, 100);
  };

  const handleSubmit = async () => {
    const collectorMappingList = godownData.filter(
      (item) => item.collector_id !== null && item.id !== null
    );
    await MstService.godownMappingWithCollector({
      mapping_list: collectorMappingList,
    })
      .then((response) => {
        requestAndErrorHandler.responseHandler(response, "/godownMapping");
        setIsLoading((value) => !value);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Godown Mapping"}
        right={
          <>
            <div className="d-flex gap-3">
              <FUSButton
                buttonType="secondary"
                labelText={"Cancel"}
                onClick={() => {}}
              />

              <FUSButton
                buttonType="primary"
                labelText={"Save Mapping"}
                onClick={() => handleSubmit()}
              />
            </div>
          </>
        }
      >
        <div className="px-3">
          <div className="mb-5">
            <BootstrapTable
              className="style_row"
              classes="border border-1"
              remote={{ cellEdit: true }}
              keyField="collector_code_id"
              data={godownData}
              columns={columns}
              cellEdit={cellEditFactory(cellEdit)}
              filter={filterFactory()}
              onTableChange={handleTableChange}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default GodownMapping;
