/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./App.css";
import "./branding/theme.css";
import TokenService from "./services/token.service";
import { SideMenu } from "./FUSScreens/Navigation/SideMenu.jsx";
import AuthRoutes from "./routes/AuthRoutes.jsx";
import UnAuthRoutes from "./routes/UnAuthRoutes.jsx";
import StoreProvider from "./store/StoreContext";
import { LoadScript } from "@react-google-maps/api";
import FUSHeader from "./FUSComponents/FUSHeader/FUSHeader.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const token = TokenService.getUser();
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      {!token ? (
        <>
        <UnAuthRoutes />
        <ToastContainer />
        </>
      ) : (
        <LoadScript googleMapsApiKey="AIzaSyBuaMQ_qIBJYjpa55FSHE6NL07ST4czXgA">
          <StoreProvider>
            <SideMenu>
            <FUSHeader />
                <AuthRoutes />
            </SideMenu>
            <ToastContainer />
          </StoreProvider>
        </LoadScript>
      )}
    </>
  );
}

export default App;
