/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import AuthService from "../../services/auth.service";
import VARIABLES from "../../services/variable.service";
import login from "../../assets/login.png";
import ProLogo from "../../assets/ProtLogo.png";
import { NavLink } from "react-router-dom";
import TokenService from "../../services/token.service";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";

export default function Login() {
  const [otp, setOtp] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);
  const [loginShow, setIsLoginShow] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [typeText, setTypeText] = useState("");

  const [loginError, setLoginErrors] = useState({});
  const [passwordshow, setShowPassword] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const findFormErrors = () => {
    const { username, password } = form;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Can not be blank!";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(username)) {
      newErrors.username = "username should be numeric";
    } else if (username.length > 10) {
      newErrors.username = "username is too long!";
    }
    if (!password || password === "") newErrors.password = "Can not be blank!";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setIsSubmitting(true);
      const { username, password } = form;
      const data = await AuthService.login(username, password, "pin");
      if (!_.isUndefined(data)) {
        if (data.errorCode === 201) {
          TokenService.setRolePermission(data?.data?.rolePermission);
          if (data?.data?.role?.role_id === VARIABLES.SUPER_USER) {
            const successToast = toast.success("Login Successfully", {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
              window.location = "/";
            }, 2000);
          } else {
            const successToast = toast.success("Login Successfully", {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
              window.location = "/";
            }, 2000);
          }
        } else {
          setLoginErrors({ message: "Invalid user or password" });
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    }
  };

  const handleOTPVerification = async (e) => {
    try {
      const result = await AuthService.OTPVerficationForforgotPassword(
        "otp",
        form?.username,
        otp
      );
      console.log("Result VERFY", result);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setIsLoginShow(true);
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    const welcomeText =
      "Please enter your phone number and password login to FuseIT.";
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex <= welcomeText.length) {
        setTypeText(welcomeText.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  // This Function will use for MFA in future
  const pinVerification = async () => {
    try {
      const result = await AuthService.pinVerification({
        login_phone: form?.username,
        pin: form?.password,
      });

      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setIsGenerated(true);
        setLoginErrors({});
      } else {
        setLoginErrors({ message: "Invalid user or password" });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const generate_MFA_OTP = (second) => {
    try {
      const result = AuthService.generate_MFA_OTP({
        login_phone: form?.username,
      });
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    const newErrors = findFormErrors();
    if (
      !Object.keys(newErrors).length &&
      form?.password.length === 4 &&
      form?.username.length === 10
    ) {
      // Use For Future for MFA
      // pinVerification();
    }
  }, [form?.password]);

  useEffect(() => {
    generate_MFA_OTP();
  }, [isGenerated]);

  return (
    <>
      <div className="container-fluid login_container px-0">
        <div className="row">
          <div className="col-6  d-none d-lg-block px-0">
            <div
              className="login_left_view"
              style={{ backgroundColor: "#7966E41A" }}
            >
              <div
                data-aos-delay="300"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <img src={login} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6  px-0">
            <div className="login_left_view">
              <div className="login_left_view_inner">
                <div data-aos="fade-down">
                  <img src={ProLogo} alt="Bootstrap" width="130" height="40" />
                </div>
                <div className="mt-4 pt-2">
                  <span className="login_text_style">Login</span>
                </div>
                <div className="login_simple_text_style mt-2">
                  <span className="login_simple_text_style">{typeText}</span>
                </div>
                {/* <form> */}
                <div className="mt-2 pt-1">
                  <div>
                    {loginError.message && (
                      <div
                        className="text-danger fs-5"
                        data-aos-delay="100"
                        data-aos="fade-right"
                      >
                        {loginError.message}
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <label className="login_label_style">Phone Number</label>
                    <div
                      className="login_user_input mt-1"
                      data-aos="fade-right"
                      data-aos-duration="500"
                    >
                      <input
                        className="form-control"
                        type="text"
                        autoFocus
                        placeholder="Enter Number"
                        maxLength={10}
                        onChange={(e) => setField("username", e.target.value)}
                        isInvalid={!!errors.username}
                        disabled={isSubmitting}
                      />
                    </div>
                    {errors.username && (
                      <div
                        className="text-danger fs-5"
                        data-aos-delay="100"
                        data-aos="fade-right"
                      >
                        {errors.username}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="login_label_style">Password</label>
                    <div
                      className="login_user_input mt-1"
                      data-aos="fade-right"
                      data-aos-duration="500"
                    >
                      <input
                        className="form-control  "
                        type={passwordshow ? "text" : "password"}
                        placeholder="Enter password"
                        onChange={(e) => setField("password", e.target.value)}
                        isInvalid={!!errors.password}
                        maxLength={4}
                        disabled={isSubmitting}
                      />
                      {form.password.trim() && (
                        <span
                          className="passwordHideStyle"
                          onClick={() => setShowPassword(!passwordshow)}
                        >
                          {passwordshow ? (
                            <FUSIcon
                              iconSrc={"ai"}
                              iconName={"AiOutlineEye"}
                              size={17}
                              className="text-secondary"
                            />
                          ) : (
                            <FUSIcon
                              iconSrc={"ai"}
                              iconName={"AiOutlineEyeInvisible"}
                              size={17}
                              className="text-secondary"
                            />
                          )}
                        </span>
                      )}
                    </div>
                    {errors.password && (
                      <div
                        className="text-danger fs-5"
                        data-aos-delay="100"
                        data-aos="fade-right"
                      >
                        {errors.password}
                      </div>
                    )}
                    <div
                      className="d-flex justify-content-between mt-3 ps-1"
                      data-aos="fade-up"
                    >
                      {/* May Further need */}
                      {/* <div className="d-flex gap-2">
                      <input
                        type="checkbox"
                        className="checkbox_style"
                        disabled={isSubmitting}
                      />
                      <span className="remember_style">Remember Password</span>
                    </div> */}
                      <div>
                        <NavLink
                          to="/forgetpassword"
                          className="forgot_password"
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  {isGenerated && VARIABLES?.IS_MFA_AVAILABLE ? (
                    <div className="otp-container mt-4">
                      <label className="login_label_style">Enter OTP</label>
                      <div className="row p-0 m-0">
                        <div className="col-md-10 p-0 m-0">
                          <OtpInput
                            inputStyle="otp-input"
                            inputType={"tel"}
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span className="mx-1"> </span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <div className="col-md-2 mt-1">
                          {loginShow ? (
                            <div className="pt-2">
                              <FUSIcon
                                iconSrc={"fa"}
                                iconName={"FaCheck"}
                                size={17}
                                className="text-success"
                              />
                            </div>
                          ) : (
                            <>
                              <FUSButton
                                className={"form-control padding_style_button"}
                                labelText="Verify"
                                type="submit"
                                buttonType="success"
                                onClick={handleOTPVerification}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {!loginShow ? (
                    <div className="mt-4">
                      <FUSButton
                        className={"form-control padding_style_button"}
                        labelText="Login"
                        type="submit"
                        buttonType={
                          form.username === "" ? "secondary" : "primary"
                        }
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      />
                    </div>
                  ) : null}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
