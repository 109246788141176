import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import ProductService from "../../services/product.service";

const AddServiceRepresentative = () => {
  const [serviceRepData, setServiceRepData] = useState([]);
  const [allRepDetails, setAllRepDetails] = useState([]);
  const [phoneNum, setPhoneNum] = useState(null);
  const columns = [
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "login_phone",
      text: "login Phone",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "role_name",
      text: "Role",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDelete(row)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: allRepDetails?.length,
      },
    ],
  };

  const handleFetchDetails = () => {
    ProductService.fetchDetailsByPhoneNo({ phone_no: phoneNum })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setServiceRepData(res?.data?.data);
          setPhoneNum("");
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          setServiceRepData([]);
          setPhoneNum("");
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };
  const getAllServiceRepDetails = async () => {
    try {
      const result = await ProductService.getAllserviceRepDetailsByVendorId();
      if (result?.data?.errorCode === 0) {
        setAllRepDetails(result?.data?.data);
      } else {
        const errorToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(errorToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleMakeServiceRep = async (id) => {
    try {
      const result = await ProductService.makeServiceRepresentativeByVendor({
        id: id,
      });
      if (result?.data?.errorCode === 0) {
        getAllServiceRepDetails();
        setServiceRepData([]);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else {
        const errorToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(errorToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleDelete = async (row) => {
    const confirmForDelete = window.confirm(
      `Are you sure to remove ${row.full_name} from the service representative?`
    );
    if (confirmForDelete) {
      try {
        const result = await ProductService.removeServiceRepresentative({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getAllServiceRepDetails();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  useEffect(() => {
    getAllServiceRepDetails();
  }, []);
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Add Service Representative"
      >
        <div className="border_bottom_style">
          <div className="row">
            <div className="col-md-3 ps-0 ms-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control py-2"
                  placeholder="Enter Number"
                  value={phoneNum}
                  onChange={(e) => {
                    setPhoneNum(e?.target?.value);
                  }}
                  maxLength={10}
                />
              </div>
            </div>
            <div className="col-md-2 ps-0 ms-0 mt-1">
              <FUSButton
                className={"py-2"}
                buttonType="primary"
                labelText={"Fetch User"}
                onClick={handleFetchDetails}
                disabled={phoneNum?.length < 10 ? true : false}
              />
            </div>
          </div>
        </div>
        {serviceRepData && serviceRepData.length > 0 ? (
          <>
            <div className="row ">
              <div className="mt-3">
                <div>
                  <table className="table table-bordered border border-1">
                    <thead>
                      <tr>
                        <th>Login Phone</th>
                        <th>Name</th>
                        <th>address</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceRepData &&
                        serviceRepData.length > 0 &&
                        serviceRepData.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.login_phone} </td>
                                <td>{item?.full_name}</td>
                                <td>{item?.address} </td>
                                <td>
                                  <div className="ps-0 ms-0 mt-1">
                                    <FUSButton
                                      className={"py-2"}
                                      buttonType="primary"
                                      labelText={"Make Service Rep"}
                                      onClick={() =>
                                        handleMakeServiceRep(item.id)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="px-3 py-3">
          <div className="col-md-12">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={allRepDetails}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default AddServiceRepresentative;
