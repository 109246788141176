import React, { useEffect, useState } from "react";
import ProLogo from "../../assets/ProtLogo.png";
import login from "../../assets/login.png";
import Circle from "../../assets/Circle.png";
import "./ForgetPassword.css";
import AuthService from "../../services/auth.service";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [typeText, setTypeText] = useState("");

  const handleSubmitNumber = async (e) => {
    e.preventDefault();
    try {
      const res = await AuthService.forgotPassword(mobileNumber);
      if (res?.data?.errorCode === 0) {
        const successToast = toast.success(
          "OTP sent to your registered mobile number",
          { autoClose: 2000, position: "bottom-left" }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
          navigate("/verifyOtp", { state: { mobileNumber } });
        }, 2000);
      } else {
        toast.error(res?.data?.message, {
          autoClose: 2000, position: "bottom-left"
        });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    const welcomeText = "Please enter your phone number to receive an OTP to proceed.";
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex <= welcomeText.length) {
        setTypeText(welcomeText.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-6 d-none d-lg-block px-0">
            <div className="forget_left_view" style={{ backgroundColor: "#7966E41A" }}>
              <div data-aos-delay="300" data-aos="zoom-in" data-aos-duration="1500">
                <div className="leftContainerImage">
                  <img src={login} alt="Login visual" />
                  <div className="circleImage">
                    <img src={Circle} alt="Circle decoration" />
                    <span>?</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <div className="forget_left_view">
              <div className="forget_left_view_inner forget_password_container">
                <div data-aos="fade-down">
                  <img src={ProLogo} alt="ProLogo" width="130" height="40" />
                </div>
                <div className="mt-4 pt-2">
                  <span className="forget_text_style">Forgot Password</span>
                </div>
                <div className="forget_simple_text_style mt-2">
                  <span className="forget_simple_text_style">{typeText}</span>
                </div>
                <form onSubmit={handleSubmitNumber}>
                  <div className="mt-2 pt-1">
                    <div className="mt-4">
                      <label className="forget_label_style">Phone Number</label>
                      <div className="forget_user_input mt-1" data-aos="fade-right" data-aos-duration="500">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Number"
                          required
                          maxLength={10}
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-4 py-3">
                      <FUSButton
                        className={"form-control padding_style_button"}
                        labelText={"Confirm"}
                        type="submit"
                        buttonType={mobileNumber ? "primary" : "secondary"}
                        disabled={!mobileNumber}
                      />
                    </div>
                    <div className="d-flex justify-content-center mt-3 ps-1" data-aos="fade-up">
                      <div className="d-flex gap-2">
                        <span className="remember_style">Remember your Password? &nbsp;</span>
                      </div>
                      <div>
                        <NavLink to="/" className="forgot_password">
                          Login
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
