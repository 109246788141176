import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ProLogo from "../../assets/ProtLogo.png";
import verifyOtpImage from "../../assets/verifyOtpImage.png";
import "./ForgetPassword.css";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import OtpInput from "react-otp-input";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

const VerifyOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [enteredOtp, setOtp] = useState("");
    const [mobileNumber, setMobileNumber] = useState(location.state?.mobileNumber || "");
    const [typeText, setTypeText] = useState("");

    useEffect(() => {
        if (!mobileNumber) {
            navigate("/forgetPassword");
            return;
        }
        const welcomeText = "Enter OTP code sent to";
        let currentIndex = 0;

        const intervalId = setInterval(() => {
            if (currentIndex <= welcomeText.length) {
                setTypeText(welcomeText.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(intervalId);
            }
        }, 50);

        return () => clearInterval(intervalId);
    }, [mobileNumber, navigate]);

    const handleOtpVerify = async () => {
        try {
            const response = await AuthService.OTPVerficationForforgotPassword("otp", mobileNumber, enteredOtp);
            if (response?.data?.errorCode === 0) {
                const successToast = toast.success("Verification Successfully", { autoClose: 2000, position: "bottom-left" });
                setTimeout(() => {
                    toast.dismiss(successToast);
                    navigate("/resetPassword", { state: { mobileNumber } });
                }, 2000);
            } else {
                setOtp("");
                toast.error(response?.data?.message || "An error occurred", {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            requestAndErrorHandler.errorHandler(error);
        }
    };

    const resendOtp = async () => {
        try {
            const response = await AuthService.resendOTP(mobileNumber);
            if (response?.data?.data?.errorCode === 0) {
                toast.success("OTP resent successfully", { autoClose: 2000, position: "bottom-left" });
            } else {
                toast.error(response?.data?.message || "An error occurred", {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            requestAndErrorHandler.errorHandler(error);
        }
    };

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-6 d-none d-lg-block px-0">
                        <div className="forget_left_view" style={{ backgroundColor: "#7966E41A" }}>
                            <div data-aos-delay="300" data-aos="zoom-in" data-aos-duration="1500">
                                <div className="verifyOtpView">
                                    <img src={verifyOtpImage} alt="Verify OTP" />
                                </div>
                            </div>
                            <div className="backView" onClick={handleBack}>
                                <FUSIcon
                                    iconSrc={"io5"}
                                    iconName={"IoArrowBack"}
                                    size={17}
                                    color="#7966e4"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 px-0">
                        <div className="forget_left_view">
                            <div className="forget_left_view_inner otpVerifiedView">
                                <div data-aos="fade-down">
                                    <img src={ProLogo} alt="ProLogo" width="130" height="40" />
                                </div>
                                <div className="mt-4 pt-2">
                                    <span className="forget_text_style">Verify OTP</span>
                                </div>
                                <div className="forget_simple_text_style mt-2">
                                    <span className="forget_simple_text_style">{typeText}</span><br />
                                    <div className="d-flex align-items-center gap-2 mt-2">
                                        <span className="forget_number_style">+91-{mobileNumber}</span>
                                        <FUSIcon iconSrc={"md"} iconName={"MdOutlineModeEdit"} size={15} className="forget_number_style" />
                                    </div>
                                </div>
                                <form>
                                    <div className="mt-2 pt-1">
                                        <div className="mt-4">
                                            <OtpInput
                                                containerStyle={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                                value={enteredOtp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                autoFocus
                                                renderSeparator={<span></span>}
                                                renderInput={(props, index) => (
                                                    <input
                                                        {...props}
                                                        type="number"
                                                        autoFocus={index === 0}
                                                        className="otp-input"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <FUSButton
                                                className="form-control padding_style_button"
                                                labelText="Confirm"
                                                type="submit"
                                                buttonType="secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleOtpVerify();
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center mt-3 ps-1" data-aos="fade-up">
                                            <div className="d-flex gap-2">
                                                <span className="remember_style">Didn't receive OTP code? &nbsp;</span>
                                            </div>
                                            <div>
                                                <NavLink onClick={resendOtp} className="forgot_password">
                                                    Resend
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VerifyOtp;
