import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import _ from "lodash";
import CIGReport from "./CIGReport";
import PCReport from "./PCReport";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";

export default function SifyReport() {

  const sifyReportType = ["PC", "CHIPS", "CIG"];
  const [fromDate, setFromDate] = useState(new Date());
  const [CIGReportList, setCIGReportList] = useState([]);
  const [PCReportList, setPCReportList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("PC");
  const [activeAccordion, setActiveAccordion] = useState("");

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
    setActiveAccordion("");
  };

  useEffect(() => {
    const getSifyReport = async () => {
      const ISODate = fromDate?.toISOString();
      await MstService.sifyReports({
        customer: "ALL",
        reportName: selectedOption,
        date: ISODate?.slice(0, 10),
      })
        .then((response) => {
          const resposeData =
            !_.isEmpty(response?.data?.data) &&
              !_.isNil(response?.data?.data[0]?.json_agg)
              ? response?.data?.data[0]?.json_agg
              : [];
          if (selectedOption !== "CIG") {
            setPCReportList(resposeData);
          } else {
            setCIGReportList(resposeData);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    };

    if (selectedOption) {
      getSifyReport();
    }
  }, [fromDate, selectedOption]);

  return (
    <>
      <LayoutContainer
        title1="Sify"
        title2="Sify"
        title3="Sify Report"
      >
        <div className="px-1 py-1">
          <div className="border_bottom_style">
            <div className="row">
              <div className="col-md-3">
                <span className="text-secondary">Select Report Type</span>
                <Form.Control
                  className="form-select mt-1 py-2"
                  as="select"
                  value={selectedOption}
                  onChange={(e) => handleSelect(e)}
                >
                  {sifyReportType?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </div>
              <div className="col-md-3">
                <div>
                  <span className="text-secondary">Select Date</span><br />
                  <DatePicker
                    className="px-2 py-2 border border-1 rounded rounded-1 mt-1"
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-2">
            {selectedOption !== "CIG" ? (
              <PCReport
                sifyReportList={PCReportList}
                setActiveAccordion={setActiveAccordion}
                activeAccordion={activeAccordion}
                fromDate={fromDate}
                selectedOption={selectedOption}
              />
            ) : (
              <CIGReport
                sifyReportList={CIGReportList}
                setActiveAccordion={setActiveAccordion}
                activeAccordion={activeAccordion}
                fromDate={fromDate}
              />
            )}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
