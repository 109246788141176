import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row } from "react-bootstrap";
import RideService from "../../services/ride.service";
import VARIABLES from "../../services/variable.service";

const MakeRideManager = () => {
  const [userMobile, setUserMobile] = useState("");
  const [userData, setUserData] = useState(null);
  const [rideManagerList, setRideManagerList] = useState([]);

  const handleSubmit = () => {
    RideService.getUserDetails({ phone_no: userMobile })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const user = res?.data?.data[0];
          setUserData(user);
          setUserMobile("");
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
          setUserMobile("");
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const handleDeleteItem = (row) => {
    RideService.deleteRideManagerOrDriver({ user_id: row.id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          toast.success("Ride Manager deleted successfully", {
            position: "bottom-left",
            autoClose: 2000,
          });
          getAllRideManager();
        } else {
          toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const columns = [
    { dataField: "full_name", text: "Ride Manager Name", sort: true },
    { dataField: "login_phone", text: "Mobile", sort: true },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdDeleteForever"
              iconSize={15}
              buttonType="lightdanger"
              onClick={() => handleDeleteItem(row)}
            />
          </>
        );
      },
      editable: false,
    },
  ];

  const getAllRideManager = () => {
    RideService.getAllRideManager()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setRideManagerList(res?.data?.data);
        } else {
          setRideManagerList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleStartAsManager = () => {
    if (userData && userData.id) {
      const { id, primary_role_id } = userData;
      if (primary_role_id === VARIABLES.USER) {
        RideService.makeRideManager({ user_id: id })
          .then((res) => {
            if (res?.data?.errorCode === 0) {
              const successToast = toast.success(
                "You are successfully entered as a Ride Manager",
                {
                  position: "bottom-left",
                  autoClose: false,
                }
              );
              setTimeout(() => {
                toast.dismiss(successToast);
              }, 2000);
              getAllRideManager();
              setUserData(null);
            } else {
              const errorToast = toast.error(`${res?.data?.message}`, {
                position: "bottom-left",
                autoClose: false,
              });
              setTimeout(() => {
                toast.dismiss(errorToast);
              }, 2000);
              setUserData(null);
            }
          })
          .catch((error) => {
            requestAndErrorHandler.responseHandler(error);
          });
      } else {
        toast.error(
          "Can not create Ride Manager because given user is not a primary user."
        );
      }
    }
  };

  useEffect(() => {
    getAllRideManager();
  }, []);

  return (
    <LayoutContainer title1="Ride" title2="Ride" title3="Make Ride Manager">
      <div className="py-2 px-1">
        <div className="border_bottom_style">
          <Row className="d-flex align-items-end gap-2 gap-md-0">
            <Col md={3}>
              <div>
                <span>Mobile Number</span>
                <input
                  className="form-control py-2 mt-1"
                  placeholder="Enter here........"
                  value={userMobile}
                  name="userMobile"
                  onChange={(e) => setUserMobile(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
            <Col md={3}>
              <FUSButton
                className={"py-2"}
                buttonTextStyle={"fs-6"}
                buttonType={userMobile.length === 10 ? "primary" : "secondary"}
                labelText={"Fetch User Information"}
                onClick={handleSubmit}
                disabled={userMobile.length !== 10}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-3 px-2 mx-1">
          {userData && (
            <div>
              <Row className="mb-3 align-item-center">
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Name</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.full_name}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Mobile Number</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.login_phone}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="px-0">
                    {" "}
                    <FUSButton
                      className={"py-2"}
                      labelText={"Create Ride Manager"}
                      buttonType="primary"
                      onClick={handleStartAsManager}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="px-1 mt-3">
          <div className="pb-5 mb-5 px-2">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={rideManagerList}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default MakeRideManager;
