import React, { useEffect, useState } from "react";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import DepartmentServices from "../../../services/departmentServices";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";

const ManageDepartments = () => {
  const [deptValues, setDeptValues] = useState({
    dept_name: "",
    dept_type: "CUSTOM",
    vendor_id: null,
  });
  const [deptData, setDeptData] = useState([]);
  const [reload, setIsReload] = useState(true);
  const [customSelected, setCustomSelected] = useState(false);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const token = TokenService.getUser();
  const departmentCreationType = [
    {
      label: "Custom Department ",
      value: "CUSTOM",
    },
    {
      label: "System Department ",
      value: "SYSTEM",
    },
  ];

  const getAllDepartments = () => {
    DepartmentServices.getDepartment()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDeptData(res?.data?.data);
        } else {
          setDeptData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  useEffect(() => {
    getAllDepartments();
  }, [reload]);

  const handleSubmit = async () => {
    await DepartmentServices.addDepartment({
      department_name: deptValues?.dept_name,
      department_type: deptValues?.dept_type,
      vendor_id: deptValues?.vendor_id,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsReload((isReload) => !isReload);
          setShowModal({
            state: false,
            type: "",
          });
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="Manage Departments"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Department"}
            onClick={() =>
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_DEPARTMENT",
              }))
            }
          />
        </>
      }
    >
      <div className="px-3 py-2">
        <div className="col-md-9">
          <div>
            <table className="table table-bordered border border-1">
              <thead>
                <th>Department Name </th>
                <th>Creation Type</th>
                <th>Status</th>
              </thead>
              <tbody>
                {deptData?.length > 0 ? (
                  deptData?.map((item) => (
                    <tr>
                      <td>{item?.department_name}</td>
                      <td>{item?.creation_type}</td>
                      <td
                        className={`${
                          item?.is_active
                            ? "text-success fw-bold"
                            : "text-danger fw-bold"
                        }`}
                      >
                        {item?.is_active ? "Active" : "Deactive"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      {<FUSEmptyList value="No data exists" />}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={"Add Department"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          {showModal.state && (
            <div className="row">
              <div className="col-12 px-0">
                <span className="text-secondary">Department Name</span>
                <div>
                  <input
                    className="form-control text_style mt-1"
                    placeholder="Enter here..."
                    type="text"
                    value={deptValues.dept_name}
                    onChange={(e) =>
                      setDeptValues((prev) => ({
                        ...prev,
                        dept_name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {token?.role?.role_id === VARIABLES.SUPER_USER && (
                <>
                  <div className="col-12 px-0 mt-2">
                    <span className="text-secondary">Department Type</span>
                    <div>
                      <select
                        className="col-12 px-1 form-select "
                        onChange={(e) => {
                          setDeptValues((prev) => ({
                            ...prev,
                            dept_type: e.target.value,
                          }));
                          if (e.target.value === "CUSTOM") {
                            setCustomSelected(true);
                          } else {
                            setCustomSelected(false);
                          }
                        }}
                      >
                        <option value={-1}>Select Department Type</option>
                        {departmentCreationType?.map((item) => (
                          <option value={item?.value}>{item?.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {customSelected && (
                    <div className="col-12 px-0">
                      <span className="text-secondary">Vendor </span>
                      <div>
                        <input
                          className="form-control text-secondary text_style mt-1"
                          placeholder="Enter here..."
                          type="text"
                          value={deptValues.vendor_id}
                          onChange={(e) =>
                            setDeptValues((prev) => ({
                              ...prev,
                              dept_name: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="col-12 mt-3 px-0">
                <FUSButton
                  labelText={"Add Department"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleSubmit()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default ManageDepartments;
