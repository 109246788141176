import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify"
import { useMediaQuery } from "react-responsive";

function DepartmentMapping() {
  const [userAndDeptList, setUserAndDeptList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [showModal, setShowModal] = useState({
    state: false, type: ""
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    dept_id: "",
    role_id: "",
    is_active: "",
  });

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    MstService.getBasketAndPermissions()
      .then((response) => {
        setRoleList(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.crudDepartment({ type: "ALL" })
      .then((response) => {
        setDepartmentList(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const getDeptUserData = () => {
    MstService.crudCollectorDeptMapping({
      type: "ALL",
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setUserAndDeptList(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getDeptUserData();
  }, [isLoading]);

  const handleModalClose = () => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setFormData({
      dept_id: "",
      role_id: "",
    });
  };

  const mapRoleToDepartment = () => {
    MstService.crudCollectorDeptMapping({ ...formData, type: "INSERT" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsLoading((isLoading) => !isLoading);
          setFormData({ dept_id: "", role_id: "" });
          const successToast = toast.success(
            `${res.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditClick = (row) => {
    setShowModal((prev) => ({ ...prev, state: false, type: "EDIT" }));
    MstService.crudCollectorDeptMapping({ type: "GET", id: row.id })
      .then((res) => {
        setIsEdit(false);
        const modifiedData = {
          ...res?.data?.data[0],
          role_id: res?.data?.data[0]?.user_id,
        };
        setFormData(modifiedData);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditMapping = () => {
    MstService.crudCollectorDeptMapping({ ...formData, type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsEdit(false);
          setFormData({
            dept_id: "",
            role_id: "",
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((row) => {
    MstService.crudCollectorDeptMapping({ id: row.id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setUserAndDeptList((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });
          const successToast = toast.success(
            `${response.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const columns = [
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">InActive</span>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "dept_name",
      text: "Department Name",
    },
    {
      dataField: "role_name",
      text: "Role Name",
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDelete(row)}
              />

              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={15}
                buttonType="lightgray"
                onClick={() => {
                  handleEditClick(row);
                }}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: userAndDeptList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Department"}
        title2={"Department"}
        title3={isMobile ? "Department" : "Department Mapping"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconColor={"#ffffff"}
              iconSize={14}
              labelText={isMobile ? "Department Mapping" : "Add Department Mapping"}
              buttonType="primary"
              onClick={() => setShowModal((prev) => ({ ...prev, state: true, type: "ADD" }))}
            />
          </>
        }
      >
        <div>


          <div className="px-3">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={userAndDeptList}
              columns={columns}
              sizePerPage={5}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
          {showModal.state ? (
            <FUSModal
              title={showModal.type === "EDIT" ? "Update Department Mapping" : "Create Department Mapping"}
              showModal={showModal.state}
              handleClose={handleModalClose}
              size={"md"}
              centered
            >

              {showModal.type === "EDIT" ?
                <Row>
                  <Col>
                    <span className="text_seconadry">Department</span>
                    <Form.Select
                      className="mt-1 px-3 py-2 text_style form-control"
                      value={formData.dept_id}
                      name="dept_id"
                      onChange={handleOnChange}
                    >
                      <option>Select Department</option>
                      {departmentList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.dept_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <span className="text_seconadry">Select User</span>
                    <Form.Select
                      className="mt-1 px-3 py-2 text_style form-control"
                      value={formData.role_id}
                      name="role_id"
                      onChange={handleOnChange}
                    >
                      <option>Select User</option>
                      {roleList.map((item) => (
                        <option
                          key={item.collector_user_id}
                          value={item.collector_user_id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <span className="text_seconadry">Status </span>
                  <Form.Select
                    className="mt-1 px-3 py-2 text_style form-control"
                    name="is_active"
                    value={formData.is_active}
                    onChange={handleOnChange}
                  >
                    <option value="true">Activate</option>
                    <option value="false">Deactivate </option>
                  </Form.Select>
                  <Col>
                    <FUSButton className={"form-control"} labelText={"Save Changes"} buttonType="primary" onClick={handleEditMapping} />
                  </Col>
                </Row>





                :

                <div>
                  <div className="row px-3 py-1 align-items-center">
                    <div className="col-12 px-0">
                      <div>
                        <span className="text-secondary">Select Department</span>
                        <br />
                        <select
                          className="mt-1 px-3 py-2 text_style form-control"
                          value={formData.dept_id}
                          name="dept_id"
                          onChange={handleOnChange}
                        >
                          <option>Select Department</option>
                          {departmentList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.dept_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-12 mt-2 px-0">
                      <div>
                        <span className="text-secondary">Select Role</span>
                        <select
                          className="mt-1 px-3 py-2 text_style form-control"
                          value={formData.role_id}
                          name="role_id"
                          onChange={handleOnChange}
                        >
                          <option>Select Role</option>
                          {roleList.map((item) => (
                            <option key={item.role_name} value={item.role_id}>
                              {item.role_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12 mt-1 px-0">
                      <FUSButton
                        className={"form-control mt-3"}
                        buttonType="primary"
                        onClick={mapRoleToDepartment}
                        labelText={"Save"}
                      />
                    </div>
                  </div>
                </div>

              }





            </FUSModal>
          ) : null}
        </div>
      </LayoutContainer>
    </>
  );
}

export default DepartmentMapping;
