import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

const Userlist = () => {
  const [userList, setUserList] = useState([]);

  const getTodayDay = () => {
    return new Date();
  };

  const filterType = {
    date: "date",
    month: "month",
    year: "year",
  };

  const [fromDate, setFromDate] = useState(getTodayDay());
  const [firstAndLastDate, setFirstAndLastDate] = useState({
    firstDate: "",
    lastDate: "",
    year: "",
  });

  const [selectedType, setSelectedType] = useState("");
  const [financialList, setFinancialList] = useState([]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  const currentMonth = monthNames[d.getMonth()].toLowerCase();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const getUserListMonthWise = async (month) => {
    await MstService.getUserListMonthWise({ month })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setUserList(res?.data?.data);
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getUserListByFinancialYear = async (firstDate, lastDate) => {
    await MstService.getUserListByFinancialYear({ firstDate, lastDate })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setUserList(res?.data?.data);
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: userList?.length },
    ],
  };

  const columns = [
    { dataField: "full_name", text: "User Name", sort: true },
    { dataField: "login_phone", text: "Mobile", sort: true },
    { dataField: "order_count", text: "Total Order", sort: true },
    { dataField: "vendor_name", text: "Vendor Name", sort: true },
    { dataField: "address", text: "Address", sort: true },
    { dataField: "brand", text: "Brand", sort: true },
    { dataField: "model", text: "Device Model", sort: true },
    { dataField: "device_name", text: "Device Name", sort: true },
    { dataField: "system_version", text: "Version", sort: true },
  ];

  const getUserListByDate = async (date) => {
    await MstService.getUserListByDate({ selected_Date: date })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setUserList(response?.data?.data);
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    if (selectedType === filterType.date) {
      setFirstAndLastDate({ firstDate: "", lastDate: "", year: "" });
      setSelectedMonth(currentMonth);
      getUserListByDate(fromDate);
    } else if (selectedType === filterType.month) {
      setFirstAndLastDate({ firstDate: "", lastDate: "", year: "" });
      setFromDate(getTodayDay());
      getUserListMonthWise(currentMonth);
    } else if (selectedType === filterType.year) {
      setFromDate(getTodayDay());
      setSelectedMonth(currentMonth);
      getUserListByFinancialYear(
        firstAndLastDate?.firstDate,
        firstAndLastDate?.lastDate
      );
    } else if (selectedType === "") {
      setUserList([]);
    }
  }, [selectedType]);

  useEffect(() => {
    MstService.getFinancialYearList()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setFinancialList(response?.data?.data);
        } else {
          setFinancialList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  return (
    <LayoutContainer title1="User List" title2="User List" title3="User List">
      <div className="py-1 px-1">
        <div className="border_bottom_color">
          <div className="row">
            <div className="col-12 d-flex mt-1"></div>
            <div className="col-12 ">
              <div className="row gap-2 gap-md-0">
                <div className="col-md-3  ps-md-0">
                  <select
                    className="form-select mt-1 px-3 py-2 border_input_color form-control"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    <option value={filterType.date}>Date</option>
                    <option value={filterType.month}>Month</option>
                    <option value={filterType.year}>Year</option>
                  </select>
                </div>
                {selectedType === filterType.date ? (
                  <div className="col-md-3 pe-auto pe-md-0">
                    <DatePicker
                      className="px-3 py-2 border_input_color rounded rounded-1"
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate}
                      maxDate={getTodayDay()}
                      onChange={(date) => {
                        setFromDate(date);
                        getUserListByDate(date);
                      }}
                      placeholderText="Select Date"
                    />
                  </div>
                ) : selectedType === filterType.month ? (
                  <div className="col-md-3 pe-auto pe-md-0">
                    <select
                      className="form-select mt-1 px-3 py-2 border_input_color form-control"
                      value={selectedMonth}
                      onChange={(e) => {
                        setSelectedMonth(e.target.value.toLowerCase());
                        getUserListMonthWise(e.target.value.toLowerCase());
                      }}
                    >
                      <option>Select Month</option>
                      {monthNames.map((month, index) => (
                        <option key={index} value={month.toLowerCase()}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : selectedType === filterType.year ? (
                  <div className="col-md-3 pe-0">
                    <select
                      className="form-select mt-1 px-3 py-2 border_input_color form-control"
                      value={financialList?.year}
                      onChange={(e) => {
                        const selectedFinancialYear = financialList.find(
                          (item) => item.display_value === e.target.value
                        );
                        if (selectedFinancialYear) {
                          setFirstAndLastDate({
                            firstDate: selectedFinancialYear.start_date,
                            lastDate: selectedFinancialYear.end_date,
                            year: selectedFinancialYear.display_value,
                          });
                          getUserListByFinancialYear(
                            selectedFinancialYear.start_date,
                            selectedFinancialYear.end_date
                          );
                        }
                      }}
                    >
                      <option>Select Financial Year</option>
                      {financialList.map((item, index) => (
                        <option key={index} value={item.display_value}>
                          {item.display_value}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="px-1">
          <div className="pb-5 mb-5 px-2">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={userList}
              columns={columns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Userlist;
